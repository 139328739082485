import { memo, type MouseEvent, useContext, useMemo } from 'react';
import { useAppConfigValue } from '@/components/AppConfig';
import { useI18n } from '@/components/I18n';
import { SessionUserContext } from '@/components/SessionUserContext';
import { type MenuItemConfig, SidebarNavGroup, SidebarNavItem } from '@/components/SidebarNavGroup';
import { getCsrfToken } from '@/utils/csrf';
import { getAdminMenus, getDebugMenu, getMainMenu } from './menu';

type Props = {
  hasDpoAssigned: boolean;
  logoutUrl: string;
  onMenuItemClick?: () => void;
};

export const AppSidebarMenu = memo<Props>(({ hasDpoAssigned, logoutUrl, onMenuItemClick }) => {
  const i18n = useI18n();
  const sessionUser = useContext(SessionUserContext);
  const isSilentLoginEnabled = useAppConfigValue('auth_silentLogin_enabled');

  const mainMenu = useMemo(
    () => getMainMenu(i18n, sessionUser.canViewReports, sessionUser.ideationModuleRequestCount > 0),
    [i18n, sessionUser]
  );

  const menus = useMemo(
    () => [
      ...getAdminMenus(i18n, sessionUser, hasDpoAssigned),
      ...(sessionUser.canManageConfiguration ? [getDebugMenu(i18n)] : [])
    ],
    [hasDpoAssigned, i18n, sessionUser]
  );

  function getItemClickHandler(item: MenuItemConfig) {
    return (event: MouseEvent) => {
      onMenuItemClick?.();
      item.onClick?.(event);
    };
  }

  const mainItems = mainMenu.items.map(item => (
    <SidebarNavItem {...item} onClick={getItemClickHandler(item)} />
  ));

  if (!isSilentLoginEnabled) {
    mainItems.push(
      <form action={logoutUrl} method="post">
        <input name="csrf_token" type="hidden" value={getCsrfToken() ?? undefined} />
        <SidebarNavItem
          icon="logout"
          label={i18n.t('common', 'authentication.logoutLink.text')}
          type="submit"
        />
      </form>
    );
  }

  return (
    <>
      <SidebarNavGroup items={mainItems} mb="s08" title={mainMenu.title} />
      {menus.map((menu, index) => (
        <SidebarNavGroup
          key={index}
          items={menu.items.map(item => (
            <SidebarNavItem {...item} onClick={getItemClickHandler(item)} />
          ))}
          mb="s08"
          title={menu.title}
        />
      ))}
    </>
  );
});
