import type { ReactElement } from 'react';
import { Modal, type ModalProps } from '@/components/Modal';
import { GridModalContent } from './GridModalContent';
import { GridModalHeader } from './GridModalHeader';

type Props = Omit<ModalProps<'fit'>, 'mode'>;

type Component = (props: Props) => ReactElement;

type StaticComponents = {
  Content: typeof GridModalContent;
  Header: typeof GridModalHeader;
};

export const GridModal: Component & StaticComponents = props => (
  <Modal fullscreenOnMobile mode="fit" scrollContent {...props} />
);

GridModal.Content = GridModalContent;
GridModal.Header = GridModalHeader;
