import { createContext } from 'react';
import type { I18n } from './types';

const NO_PROVIDER_MSG =
  'Invalid I18nContext. Did you place an <I18nContextProvider /> at the top of your app?';

export const I18nContext = createContext<I18n>({
  i18nClient: undefined,
  isRtl: false,
  language: 'en',
  phraseSeparator: ' ',
  setLanguage: () => console.error(NO_PROVIDER_MSG), // eslint-disable-line no-console
  supportedLanguages: [],
  t: (ns, key, options) => {
    console.error(NO_PROVIDER_MSG, ns, key, options); // eslint-disable-line no-console
    return `${ns}:${key}`;
  }
});
