import { forwardRef, type ReactNode } from 'react';
import type { Property } from 'csstype';
import type { FontSizeProps } from '@/components/primitives/types';
import type { ColorToken, SwatchColor } from '@/css/types';
import { getColorToken } from '@/css/utils';
import { BadgeBase } from './BadgeBase';

/**
 * Implementation based on https://chakra-ui.com/badge
 */

type BadgeVariant = 'outline' | 'solid' | 'subtle';

export type BadgeProps = FontSizeProps & {
  'aria-hidden'?: boolean;
  'aria-label'?: string;
  children: ReactNode;
  className?: string;
  color?: SwatchColor;
  noMarginX?: boolean;
  noMarginY?: boolean;
  title?: string;
  variant?: BadgeVariant;
  wrap?: boolean;
};

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      'aria-label': ariaLabel,
      'aria-hidden': ariaHidden,
      children,
      className,
      color = 'grey',
      fontSize = 'xs',
      noMarginX,
      noMarginY,
      title,
      variant = 'subtle',
      wrap = false
    },
    ref
  ) => {
    let bgColor: ColorToken | undefined;
    let boxShadow: Property.BoxShadow | undefined;
    let textColor: ColorToken | undefined;

    if (variant === 'outline') {
      boxShadow = `inset 0 0 0px 1px ${getColorToken(`${color}500`)}`;
      textColor = `${color}500`;
    } else if (variant === 'solid') {
      bgColor = `${color}500`;
      textColor = 'white';
    } else {
      bgColor = `${color}100`;
      textColor = `${color}800`;
    }

    return (
      <BadgeBase
        ref={ref}
        aria-hidden={ariaHidden}
        aria-label={ariaLabel}
        bgColor={bgColor}
        borderRadius="sm"
        boxShadow={boxShadow}
        className={className}
        color={textColor}
        css={{ whiteSpace: wrap ? 'normal' : 'nowrap' }}
        fontSize={fontSize}
        fontWeight="bold"
        mx={noMarginX ? 0 : undefined}
        my={noMarginY ? 0 : undefined}
        title={title}
      >
        {children}
      </BadgeBase>
    );
  }
);
