import { css } from '@emotion/react';

export const overlay = css`
  background-color: rgba(193, 206, 217, 0.1);
  color: #6e8ca8;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const container = css`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
`;

export const content = css`
  background-color: #fff;
  border-radius: 2px;
  box-shadow:
    rgba(12, 15, 20, 0.02) 0 0 0 1px,
    rgba(12, 15, 20, 0.06) 0 0 1px 0,
    rgba(12, 15, 20, 0.06) 0 2px 2px 0;
  flex: 1;
  max-width: 600px;
  padding: 36px 48px;
`;

export const info = css`
  margin-bottom: 0;
`;

export const h1 = css`
  color: #092744;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 24px;
  margin-top: 0;
`;

export const h2 = css`
  color: #496279;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 24px;
`;
