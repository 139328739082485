import { useLayoutEffect, useState } from 'react';

/**
 * Uses the MatchMedia API to determine if the document matches the given media
 * query string. Also monitors the document to detect when the match changes.
 * @param query {string} A media query string, i.e. `(min-width: 768px)`.
 * @returns {boolean}
 */
export function useMatchMedia(query: string) {
  const [matches, setMatches] = useState(() => matchMedia(query).matches);

  useLayoutEffect(() => {
    const mediaQueryList = matchMedia(query);
    const onChange = (event: MediaQueryListEvent) => setMatches(event.matches);

    mediaQueryList.addEventListener('change', onChange);
    return () => mediaQueryList.removeEventListener('change', onChange);
  }, [query]);

  return matches;
}
