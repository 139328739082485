import { css } from '@emotion/react';
import { Card } from '@/components/primitives';
import type { Image } from '@/types';
import { getImageUrl } from '@/utils/getImageUrl';

type Props = {
  image: Image | null;
};

const thumbnailStyle = css({
  backgroundPosition: 'center center',
  backgroundSize: 'cover'
});

export const DashboardFilterListItemThumbnail = ({ image }: Props) => {
  const imageUrl = getImageUrl(image, { mode: 'crop', w: 28, h: 28 });

  return (
    <Card
      bgColor="grey200"
      bgImage={imageUrl ? `url(${imageUrl})` : undefined}
      borderRadius="sm"
      css={thumbnailStyle}
      data-testid="thumbnail"
      flexShrink={0}
      height={28}
      width={28}
    />
  );
};
