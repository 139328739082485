import { createContext, type ReactNode, useContext } from 'react';
import invariant from 'invariant';
import type { HorizontalScrollState } from './types';
import { useHorizontalScroll } from './useHorizontalScroll';

const HorizontalScrollContext = createContext<HorizontalScrollState | null>(null);

type Props = {
  children: ReactNode;
  /** Only use for testing purposes. */
  __testValue?: HorizontalScrollState;
};

export const HorizontalScrollContextProvider = ({ children, __testValue }: Props) => {
  const state = useHorizontalScroll();

  return (
    <HorizontalScrollContext.Provider value={__testValue ?? state}>
      {children}
    </HorizontalScrollContext.Provider>
  );
};

export function useHorizontalScrollContext() {
  const context = useContext(HorizontalScrollContext);

  invariant(
    context !== null,
    'useHorizontalScrollContext must be used within a <HorizontalScrollContextProvider /> component'
  );

  return context;
}
