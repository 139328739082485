import styled from '@emotion/styled';
import type { Properties } from 'csstype';
import { type Scale, system } from 'styled-system';
import type { BoxProps } from '@/components/primitives';
import type { GridProps, ResponsiveValue } from '@/components/primitives/types';

export type ScrollContainerProps = {
  bleed: ResponsiveValue<number>;
  columnCount: ResponsiveValue<number>;
  gap: GridProps['gridGap'];
  px: BoxProps['px'];
  totalColumnCount: number;
};

export const ScrollContainer = styled.div<ScrollContainerProps>(
  {
    display: 'grid',
    gridAutoFlow: 'column',
    overflowX: 'auto',
    scrollSnapType: 'x mandatory',
    // Scrollbar removal in Firefox
    scrollbarWidth: 'none',
    // Scrollbar removal in Legacy Edge
    msOverflowStyle: 'none',
    // Scrollbar removal in Chromium & Safari
    '::-webkit-scrollbar': { display: 'none' },
    '> *': {
      scrollSnapAlign: 'start'
    },
    // When you have an element that:
    //   a) has a permanent overflow-x: auto
    //   b) is initially rendered without scrollbar (all content in viewport)
    //   c) is subsequently resized by i.e. a browser window resize in a way that
    //      "activates" the scrollbar
    // then scroll-snap-align (often) causes it to snap to the far-most element
    // in the row, while we would like it to snap to the first. I'm not sure
    // why, seems to be a default browser behavior. To illustrate, what we want
    // is this:
    // ╔══╦══╦══╦╕
    // ╚══╩══╩══╩╛
    // But what we get is this:
    // ╒╦══╦══╦══╗
    // ╘╩══╩══╩══╝
    // The "easiest" workaround is to make sure there's no scroll-snap-align set
    // when the container is not scrollable, and apply it dynamically as soon as
    // it starts overflowing after a resize. In order to avoid too much
    // complexity and/or column-level logic, we're mapping the visualBoundary
    // property to a data-visible-boundary attribute on the container so that we
    // can target a specific boundary directly through CSS, as can be seen
    // below.
    '&[data-visible-boundary="both"] > *': {
      scrollSnapAlign: 'unset'
    }
  },
  system({
    bleed: { property: '--bleed' as keyof Properties },
    columnCount: {
      property: 'gridAutoColumns',
      // @ts-expect-error
      transform: (value: number, _: Scale | undefined, props: ScrollContainerProps) => {
        const isOverflowing = props.totalColumnCount > value;
        const gutterCount = isOverflowing ? value : Math.max(value - 1, 0);
        const divider = isOverflowing ? `(${value} + var(--bleed))` : value;
        return `calc((100% - var(--gap) * ${gutterCount}) / ${divider})`;
      }
    },
    gap: {
      properties: ['--gap' as keyof Properties, 'gridGap'],
      scale: 'space'
    },
    px: {
      properties: ['paddingLeft', 'paddingRight', 'scrollPaddingInline'],
      scale: 'space'
    }
  })
);
