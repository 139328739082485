import { type ButtonHTMLAttributes, forwardRef, type ReactNode, useMemo } from 'react';
import { Icon, type IconType } from '@/components/Icon';
import { Absolute, Flex } from '@/components/primitives';
import { getColorToken, getMotifColorToken } from '@/css/utils';
import { exhaustiveCheck } from '@/types/utils';
import { get } from '@/utils/store';
import { ButtonBase, type ButtonBaseProps, type ButtonTheme } from './ButtonBase';
import { ButtonLoader, supportsLoader } from './ButtonLoader';

export type ButtonVariant =
  | 'branded-primary'
  | 'error'
  | 'ghost'
  | 'muted'
  | 'primary'
  | 'secondary';

export type CommonButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> &
  Pick<ButtonBaseProps, 'isLoading' | 'size' | 'stretch'> & {
    variant?: ButtonVariant;
  };

type TextButtonProps = CommonButtonProps & {
  icon?: never;
  iconAfter?: IconType;
  iconBefore?: IconType;
  children: ReactNode;
};

type IconButtonProps = CommonButtonProps & {
  'aria-label': string;
  icon?: IconType;
  iconAfter?: never;
  iconBefore?: never;
  children?: never;
};

export type ButtonProps = IconButtonProps | TextButtonProps;

function getDarkModeButtonTheme(variant: ButtonVariant): ButtonTheme {
  const t: ButtonTheme = {};

  if (variant === 'branded-primary') {
    t.bgColor = getColorToken('primary');
    t.bgColorHover = getMotifColorToken('grey200');
    t.borderColor = getColorToken('primary');
    t.borderColorHover = getMotifColorToken('grey200');
    t.textColor = getColorToken('textOnPrimary');
    t.disabledBgColor = getMotifColorToken('grey560');
    t.disabledBorderColor = getMotifColorToken('grey560');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'error') {
    t.bgColor = getMotifColorToken('red300');
    t.bgColorHover = getMotifColorToken('black');
    t.borderColor = getMotifColorToken('red300');
    t.borderColorHover = getMotifColorToken('black');
    t.textColor = getMotifColorToken('text');
    t.textColorHover = getMotifColorToken('white');
    t.disabledBgColor = getMotifColorToken('grey560');
    t.disabledBorderColor = getMotifColorToken('grey560');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'ghost') {
    t.bgColor = getMotifColorToken('transparent');
    t.bgColorHover = getMotifColorToken('grey100');
    t.borderColor = getMotifColorToken('transparent');
    t.textColor = getMotifColorToken('white');
    t.textColorHover = getMotifColorToken('text');
    t.disabledBgColor = getMotifColorToken('grey560');
    t.disabledBorderColor = getMotifColorToken('grey560');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'primary') {
    t.bgColor = getMotifColorToken('grey200');
    t.bgColorHover = getColorToken('primary');
    t.borderColor = getMotifColorToken('grey200');
    t.borderColorHover = getColorToken('primary');
    t.textColor = getMotifColorToken('text');
    t.disabledBgColor = getMotifColorToken('grey560');
    t.disabledBorderColor = getMotifColorToken('grey560');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'secondary') {
    t.bgColor = getMotifColorToken('transparent');
    t.borderColor = getMotifColorToken('grey400');
    t.borderColorHover = getColorToken('primary');
    t.textColor = getMotifColorToken('white');
    t.textColorHover = getColorToken('primary');
    t.disabledBgColor = getMotifColorToken('grey560');
    t.disabledBorderColor = getMotifColorToken('grey560');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'muted') {
    t.bgColor = getMotifColorToken('transparent');
    t.borderColor = getMotifColorToken('grey400');
    t.borderColorHover = getMotifColorToken('grey200');
    t.textColor = getMotifColorToken('white');
    t.textColorHover = getColorToken('primary');
    t.disabledBgColor = getMotifColorToken('grey560');
    t.disabledBorderColor = getMotifColorToken('grey560');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else {
    /* istanbul ignore next */ exhaustiveCheck(variant);
  }

  return t;
}

function getLightModeButtonTheme(variant: ButtonVariant): ButtonTheme {
  const t: ButtonTheme = {};

  if (variant === 'branded-primary') {
    t.bgColor = getColorToken('primary');
    t.bgColorHover = getMotifColorToken('black');
    t.borderColor = getMotifColorToken('black'); // No motif swatch color used in motif design system (#3a3a4a)
    t.textColor = getColorToken('textOnPrimary');
    t.textColorHover = getMotifColorToken('white');
    t.disabledBgColor = getMotifColorToken('grey200');
    t.disabledBorderColor = getMotifColorToken('grey200');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'error') {
    t.bgColor = getMotifColorToken('error');
    t.bgColorHover = getMotifColorToken('black');
    t.borderColor = getMotifColorToken('error');
    t.borderColorHover = getMotifColorToken('black');
    t.textColor = getMotifColorToken('white');
    t.disabledBgColor = getMotifColorToken('grey200');
    t.disabledBorderColor = getMotifColorToken('grey200');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'ghost') {
    t.bgColor = getMotifColorToken('transparent');
    t.bgColorHover = getMotifColorToken('grey100');
    t.borderColor = getMotifColorToken('transparent');
    t.textColor = getMotifColorToken('text');
    t.disabledBgColor = getMotifColorToken('grey200');
    t.disabledBorderColor = getMotifColorToken('grey200');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'primary') {
    t.bgColor = getMotifColorToken('black');
    t.bgColorHover = getMotifColorToken('white');
    t.borderColor = getMotifColorToken('black');
    t.textColor = getMotifColorToken('white');
    t.textColorHover = getMotifColorToken('text');
    t.disabledBgColor = getMotifColorToken('grey200');
    t.disabledBorderColor = getMotifColorToken('grey200');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'secondary') {
    t.bgColor = getMotifColorToken('transparent');
    t.bgColorHover = getMotifColorToken('grey500');
    t.borderColor = getMotifColorToken('grey500');
    t.textColor = getMotifColorToken('text');
    t.textColorHover = getMotifColorToken('white');
    t.disabledBgColor = getMotifColorToken('grey200');
    t.disabledBorderColor = getMotifColorToken('grey200');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else if (variant === 'muted') {
    t.bgColor = getMotifColorToken('transparent');
    t.bgColorHover = getMotifColorToken('grey100');
    t.borderColor = getMotifColorToken('grey200');
    t.borderColorHover = getMotifColorToken('grey300');
    t.textColor = getMotifColorToken('textLight');
    t.textColorHover = getMotifColorToken('text');
    t.disabledBgColor = getMotifColorToken('grey200');
    t.disabledBorderColor = getMotifColorToken('grey200');
    t.disabledTextColor = getMotifColorToken('grey400');
  } else {
    /* istanbul ignore next */ exhaustiveCheck(variant);
  }

  return t;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      disabled = false,
      icon,
      iconAfter,
      iconBefore,
      isLoading = false,
      variant = 'primary',
      ...props
    },
    ref
  ) => {
    const mode = get('themeMode') === 'dark' ? 'dark' : 'light';
    const buttonTheme = useMemo(
      () => (mode === 'dark' ? getDarkModeButtonTheme(variant) : getLightModeButtonTheme(variant)),
      [mode, variant]
    );

    let content: ReactNode;

    if (iconAfter || iconBefore) {
      content = (
        <Flex alignItems="center" gap="s01">
          {iconBefore && <Icon type={iconBefore} />}
          {children}
          {iconAfter && <Icon type={iconAfter} />}
        </Flex>
      );
    } else if (icon) {
      content = <Icon type={icon} />;
    } else {
      content = children;
    }

    return (
      <ButtonBase
        ref={ref}
        aria-disabled={disabled ? true : undefined}
        buttonTheme={buttonTheme}
        disabled={disabled}
        isIconOnly={Boolean(icon)}
        isLoading={isLoading}
        {...props}
      >
        {content}
        {isLoading && supportsLoader(variant) && (
          <Absolute bottom={-1} data-testid="loader" left={-1} overflow="hidden" right={-1}>
            <ButtonLoader mode={mode} variant={variant} />
          </Absolute>
        )}
      </ButtonBase>
    );
  }
);
