import { system } from 'styled-system';
import type { BreakpointMap } from './types';

/**
 * Checks if the given value is a responsive breakpoint value object.
 * @param value Value to check
 */
export function isBreakpointMap<T>(value: unknown): value is BreakpointMap<T> {
  // We could also check whetever the object keys are all valid breakpoint
  // values. Would have to also feed the breakpoint theme from the call site.
  return typeof value === 'object';
}

export const textWrap = system({
  overflow: true,
  overflowWrap: true,
  textOverflow: true,
  wordBreak: true
});
