import { type ReactNode, useEffect, useState } from 'react';
import type { i18n } from 'i18next';
import { useEventEmitter } from '@/utils/hooks/useEventEmitter';
import { set } from '@/utils/store';
import { I18nContext } from './I18nContext';
import type { I18n } from './types';
import { getSupportedLanguages } from './utils';

type Props = {
  children: ReactNode;
  client: i18n;
};

export const getI18nObject = (client: i18n): I18n => ({
  i18nClient: client,
  isRtl: client.dir() === 'rtl',
  language: client.language,
  // Japanese does not use spaces as word/phrase separators.
  // https://www.w3.org/International/articles/typography/linebreak#table
  phraseSeparator: client.language.startsWith('ja') ? '' : ' ',
  setLanguage: nextLanguage => client.changeLanguage(nextLanguage),
  supportedLanguages: getSupportedLanguages(),
  // Needs a better solution, i18next messed up these types with complex overloads.
  t: (ns, key, options) => client.t(`${ns}:${key}`, options as any) as unknown as string // eslint-disable-line @typescript-eslint/no-explicit-any
});

export const I18nProvider = ({ children, client }: Props) => {
  const [state, setState] = useState(() => getI18nObject(client));
  const emitter = useEventEmitter();

  useEffect(() => {
    const onLanguageChanged = () => {
      set('localeId', client.language);
      setState(getI18nObject(client));
    };

    client.on('languageChanged', onLanguageChanged);

    return () => {
      client.off('languageChanged', onLanguageChanged);
    };
  }, [client]);

  useEffect(() => {
    // This is a hacky workaround to get an i18n object with a new reference
    // which in turn forces all translations to update. Same approach is used
    // above for the language switch. If we wouldn't use this, we wouldn't get
    // updated translations in any components wrapped in `memo()`.
    // The proper solution would be to directly use i18next's `useTranslation()`
    // hook which works around the issue with an internal trigger, but that will
    // require some refactoring and introduces other issues. You just can't win.
    const handleJargonChanged = () => setState(getI18nObject(client));

    emitter.on('jargonChanged', handleJargonChanged);

    return () => {
      emitter.off('jargonChanged', handleJargonChanged);
    };
  }, [client, emitter]);

  return <I18nContext.Provider value={state}>{children}</I18nContext.Provider>;
};
