import { useContext } from 'react';
import { format as formatDate, parseJSON } from 'date-fns';
import { DateFnsLocaleContext } from '@/components/I18n/DateFnsLocaleContext';
import { TimeBase, type TimeBaseProps } from './TimeBase';

type FormatArgs = Parameters<typeof formatDate>;

type Props = TimeBaseProps & {
  dateTime: FormatArgs[0] | string;
  format?: FormatArgs[1];
  showDate?: boolean;
  showTime?: boolean;
  options?: Omit<FormatArgs[2], 'locale'>;
};

const DATE_FORMAT = 'PPP';
const TIME_FORMAT = 'p';
const DATE_TIME_FORMAT = `${DATE_FORMAT}${TIME_FORMAT}`;

function parseDate(value: Date | number | string): Date {
  if (value instanceof Date) return value;
  if (typeof value === 'string') return parseJSON(value);
  return new Date(value);
}

export const Time = ({
  dateTime,
  format,
  options,
  showDate = true,
  showTime = false,
  ...props
}: Props) => {
  let _format;

  if (format) {
    _format = format;
  } else if (showDate && !showTime) {
    _format = DATE_FORMAT;
  } else if (!showDate && showTime) {
    _format = TIME_FORMAT;
  } else {
    _format = DATE_TIME_FORMAT;
  }

  const locale = useContext(DateFnsLocaleContext) ?? undefined;
  const parsedDateTime = parseDate(dateTime);

  return (
    <TimeBase {...props} dateTime={parsedDateTime.toISOString()}>
      {formatDate(parsedDateTime, _format, { ...options, locale })}
    </TimeBase>
  );
};
