// See https://www.iana.org/assignments/media-types/media-types.xhtml for a
// definitive list of MIME types.
const extensionMimeMap: { [key: string]: string[] } = {
  // Audio
  '.aac': ['audio/aac'],
  '.mp3': ['audio/mpeg'],
  '.midi': ['audio/midi', 'audio/x-midi'],
  '.oga': ['audio/ogg'],
  '.ogg': ['audio/ogg'],
  '.weba': ['audio/webm'],
  '.wma': ['audio/x-ms-wma'],
  // Images
  '.gif': ['image/gif'],
  '.jpg': ['image/jpeg'],
  '.jpeg': ['image/jpeg'],
  '.png': ['image/png'],
  // Video
  '.avi': ['video/x-msvideo'],
  '.mov': ['video/quicktime'],
  '.mp4': ['video/mp4'],
  '.webm': ['video/webm'],
  '.wmv': ['video/x-ms-wmv', 'video/x-ms-asf'],
  // Archives
  '.7z': ['application/x-7z-compressed'],
  '.rar': ['application/vnd.rar'],
  '.zip': ['application/zip'],
  // Documents
  '.csv': ['text/csv', 'application/vnd.ms-excel'],
  '.doc': ['application/msword'],
  '.docx': ['application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
  '.pdf': ['application/pdf'],
  '.ppt': ['application/vnd.ms-powerpoint'],
  '.pptm': ['application/vnd.ms-powerpoint.presentation.macroEnabled.12'],
  '.pptx': ['application/vnd.openxmlformats-officedocument.presentationml.presentation'],
  '.txt': ['text/plain'],
  '.xls': ['application/vnd.ms-excel'],
  '.xlsm': ['application/vnd.ms-excel.sheet.macroEnabled.12'],
  '.xlsx': ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  // Other
  /// Alteryx extensions
  '.yxmd': ['application/octet-stream'],
  '.yxdb': ['application/octet-stream'],
  '.yxwg': ['application/octet-stream'],
  '.yxmc': ['application/octet-stream'],
  '.yxwz': ['application/octet-stream'],
  '.yxzp': ['application/octet-stream']
};

export function getMimeMap(extensions: string[]): Map<string, string[]> {
  return extensions.reduce((acc, curr) => {
    extensionMimeMap[curr]?.forEach(mimeType => {
      // Create a map entry if it doesn't existing yet.
      if (!acc.has(mimeType)) acc.set(mimeType, []);
      // Get the existing entry (could be the one we created above).
      const entry = acc.get(mimeType);
      // If the extension isn't in the entry's value array yet, add it.
      if (entry && !entry.includes(curr)) entry.push(curr);
    });
    return acc;
  }, new Map<string, string[]>());
}

const allowedFileGroups: { name: Api.FileExtensionGroup; extensions: string[] }[] = [
  {
    name: 'AUDIO',
    extensions: ['.mp3', '.wma', '.midi', '.ogg', '.oga', '.aac', '.weba']
  },
  {
    name: 'IMAGES',
    extensions: ['.jpg', '.jpeg', '.gif', '.png']
  },
  {
    name: 'VIDEO',
    extensions: ['.mp4', '.webm', '.wmv', '.avi', '.mov']
  },
  {
    name: 'ARCHIVES',
    extensions: ['.zip', '.rar', '.7z']
  },
  {
    name: 'DOCUMENTS',
    extensions: [
      '.xls',
      '.xlsx',
      '.xlsm',
      '.pdf',
      '.doc',
      '.docx',
      '.ppt',
      '.pptx',
      '.pptm',
      '.txt',
      '.csv',
      // Alteryx extensions
      '.yxmd',
      '.yxdb',
      '.yxwg',
      '.yxmc',
      '.yxwz',
      '.yxzp'
    ]
  }
];

export function getExtensionsByFileGroups(fileGroups: Api.FileExtensionGroup[]) {
  // Using a Set since its entries are always unique.
  const extensions = fileGroups.reduce((acc, curr) => {
    const groupMatch = allowedFileGroups.find(g => g.name === curr);
    groupMatch?.extensions.forEach(ext => acc.add(ext));
    return acc;
  }, new Set<string>());
  // Converting the Set to an array.
  return Array.from(extensions);
}
