import { createContext } from 'react';

export type SessionUserContextValue = {
  __hasLoadedUserData: boolean;
  __hasLoadedPermissionData: boolean;
  avatar: { id: string; previewUrl: string | null } | null;
  canEditProfile: boolean;
  canManageConfiguration: boolean;
  canManageDataProtectionSettings: boolean;
  canManageRolesAndPermissions: boolean;
  canViewDataSets: boolean;
  canViewKpis: boolean;
  canViewReports: boolean;
  contributionCount: number;
  draftContributionCount: number;
  email: string | null;
  firstName: string | null;
  hasToCompleteProfile: boolean;
  id: string;
  ideationModuleRequestCount: number;
  isDataProtectionOfficer: boolean;
  lastName: string | null;
  taskCount: number;
};

export const DEFAULT_SESSION_USER: SessionUserContextValue = {
  __hasLoadedPermissionData: true,
  __hasLoadedUserData: true,
  avatar: null,
  canEditProfile: false,
  canManageConfiguration: false,
  canManageDataProtectionSettings: false,
  canManageRolesAndPermissions: false,
  canViewDataSets: false,
  canViewKpis: false,
  canViewReports: false,
  contributionCount: 0,
  draftContributionCount: 0,
  email: null,
  firstName: null,
  hasToCompleteProfile: false,
  id: '',
  ideationModuleRequestCount: 0,
  isDataProtectionOfficer: false,
  lastName: null,
  taskCount: 0
};

export const SessionUserContext = createContext<SessionUserContextValue>(DEFAULT_SESSION_USER);
