import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { color, compose, fontSize, fontWeight } from 'styled-system';
import type { FontSizeProps, FontWeightProps, TextColorProps } from '@/components/primitives/types';

export type TimeBaseProps = FontSizeProps & FontWeightProps & TextColorProps;

export const TimeBase = styled('time', { shouldForwardProp })<TimeBaseProps>(
  compose(color, fontSize, fontWeight)
);
