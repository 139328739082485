import { memo, type ReactNode } from 'react';
import { FauxLinkButton } from '@/components/FauxLinkButton';
import { Heading } from '@/components/Heading';
import { I18nTrans, useI18n } from '@/components/I18n';
import { Link } from '@/components/Link';
import { Flex, Text } from '@/components/primitives';
import { Thumbnail } from '@/components/Thumbnail';
import { UnstyledLink } from '@/components/UnstyledLink';
import type { Image } from '@/types';
import { truncateText } from '@/utils/common';
import { getImageUrl } from '@/utils/getImageUrl';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { ModuleStatusLabel } from '../ModuleStatusLabel';
import { ModuleCardLayout } from './ModuleCardLayout';
import placeholder from './rose-petals.svg';

export type Module = {
  communityId: string;
  endDate: string | null;
  id: string;
  image: Image | null;
  programId: string;
  programTitle: string;
  status: Api.ModuleStatus;
  startDate: string | null;
  summary: string | null;
  title: string;
  totalContributionCount: number;
  type: Api.ModuleType;
  viewer: { canAddContribution: boolean };
};

type Props = {
  data: Module;
  onRequestAddContribution: (communityId: string, programId: string, moduleId: string) => void;
  programLabel: string;
  showProgram?: boolean;
};

export const ModuleCard = memo<Props>(
  ({ data, onRequestAddContribution, programLabel, showProgram = false }) => {
    const i18n = useI18n();
    const [id, aria] = useHeadingIdAriaPair();
    const { getModulePage, getProgramPage } = useSharedLocations('main');
    const moduleRoute = getModulePage(data.id);
    const showModuleCardLabel = data.endDate || data.startDate || data.status === 'ENDED';

    const thumbnail = (
      <UnstyledLink aria-hidden tabIndex={-1} to={moduleRoute}>
        <Thumbnail
          aspectRatio={2.5}
          autosize
          borderRadius="none"
          imageUrl={getImageUrl(data.image, { mode: 'crop', w: 500, h: 200 })}
          placeholderUrl={placeholder}
        >
          {showModuleCardLabel && (
            <Flex justifyContent="flex-end" pt="s04" px="s04">
              <ModuleStatusLabel
                endDate={data.endDate}
                startDate={data.startDate}
                status={data.status}
                type={data.type}
              />
            </Flex>
          )}
        </Thumbnail>
      </UnstyledLink>
    );

    const heading = (
      <Heading id={id} mb="s03" variant="heading.500">
        <Link color="textDark" dir="auto" to={moduleRoute}>
          {truncateText(data.title, 120)}
        </Link>
      </Heading>
    );

    const content = (
      <Text lineHeight="snug" overflowWrap="break-word">
        <bdi>{truncateText(data.summary, 240)}</bdi>
      </Text>
    );

    const meta = (
      <>
        <Text color="textLight">
          <I18nTrans
            components={{ emphasizedText: <Text as="span" color="text" fontWeight="semibold" /> }}
            i18nKey="contributionCount.text_emphasizedCount"
            ns="contribution"
            values={{ count: data.totalContributionCount }}
          />
        </Text>
        {data.status === 'ACTIVE' && data.viewer.canAddContribution && (
          <>
            <Flex mx="s02">
              <Text color="neutral400">&mdash;</Text>
            </Flex>
            <FauxLinkButton
              onClick={() => onRequestAddContribution(data.communityId, data.programId, data.id)}
            >
              {i18n.t('contribution', 'moduleCard.showContributionFormButton.text')}
            </FauxLinkButton>
          </>
        )}
      </>
    );

    let parent: ReactNode = null;

    if (showProgram) {
      parent = (
        <Text
          color="textLight"
          css={{ whiteSpace: 'nowrap' }}
          fontSize="md"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {programLabel}:&nbsp;
          <Link dir="auto" fontWeight="normal" to={getProgramPage(data.programId)}>
            {data.programTitle}
          </Link>
        </Text>
      );
    }

    return <ModuleCardLayout aria={aria} slots={{ content, heading, meta, parent, thumbnail }} />;
  }
);
