export function getRootNode(): HTMLElement | null;
export function getRootNode(fallback: HTMLElement): HTMLElement;
export function getRootNode(fallback?: HTMLElement): HTMLElement | null {
  const node = document.querySelector('#root');

  if (node && node instanceof HTMLElement) return node;
  if (fallback) return fallback;

  if (process.env.NODE_ENV === 'development') {
    console.error('Unable to find the React root node. (#root)'); // eslint-disable-line no-console
  }

  return null;
}
