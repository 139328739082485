import { lazy, type ReactElement, type ReactNode, Suspense, useMemo, useRef } from 'react';
import type { To } from 'react-router-dom';
import { css } from '@emotion/react';
import { useJargon } from '@/components/AppConfig';
import { ErrorAlertList, type WrappedError } from '@/components/ErrorAlertList';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { Heading } from '@/components/Heading';
import { lookupJargonTranslation, useI18n } from '@/components/I18n';
import { Box, Spacer } from '@/components/primitives';
import { RoundLabel } from '@/components/RoundLabel';
import { Section } from '@/components/SectioningContentElement';
import { TabNav, type TabNavItem } from '@/components/TabNav';
import { ViewLayout } from '@/components/ViewLayout';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import { FeedSkeleton } from '@/features/feed/components/Feed/FeedSkeleton';
import type { ParsedMember } from '@/types';
import { exhaustiveCheck } from '@/types/utils';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import {
  type CommunityFilter,
  OverviewViewContainer
} from '../../containers/OverviewViewContainer';
import { HeaderBackgroundImage } from './HeaderBackgroundImage';
import { SectionHeader } from './SectionHeader';

const TaskListView = lazy(async () => ({
  default: (await import('@/features/tasks/views/TaskListView')).TaskListView
}));

const ProgramListContainer = lazy(async () => ({
  default: (await import('@/features/programs/main/containers/ProgramListContainer')).ProgramListContainer // prettier-ignore
}));

const FeedContainer = lazy(async () => ({
  default: (await import('@/features/feed/containers/FeedContainer')).FeedContainer
}));

export type { CommunityFilter };
export type View = 'feed' | 'overview' | 'programs' | 'tasks';
export type GetLocation = (view?: View) => To;

type Props = {
  activeView: View;
  bannerUrl?: string | null;
  communityFilter?: CommunityFilter;
  communityIds: string[];
  errors: WrappedError[];
  getLocation: GetLocation;
  headerContent: ReactNode;
  /** The number of pending tasks, taking into account the active filter. */
  taskCount: number | undefined;
  viewer: ParsedMember;
  /** The number of contributions created by the viewer, taking into account the active filter. */
  viewerContributionCount: number | undefined;
};

const FEED_PAGE_SIZE = 20;

const getCountLabelText = (count = 0) => (count > 99 ? '99+' : count);

const badgeStyle = css({
  marginBottom: -4,
  marginTop: -4,
  position: 'relative',
  top: -1
});

export const DashboardContentView = ({
  activeView,
  bannerUrl,
  communityFilter,
  communityIds,
  errors,
  getLocation,
  headerContent,
  taskCount,
  viewer,
  viewerContributionCount
}: Props) => {
  const i18n = useI18n();
  const [id0, aria0] = useHeadingIdAriaPair();
  const contentRef = useRef<HTMLDivElement>(null);
  const programLabel = lookupJargonTranslation<'program'>(useJargon().program, {
    PROGRAM: () => i18n.t('main', 'program.label_multiple')
  });

  const navConfig: TabNavItem[] = useMemo(
    () => [
      {
        end: true,
        to: getLocation('overview'),
        text: i18n.t('main', 'dashboardNav.overviewLink.text')
      },
      {
        to: getLocation('feed'),
        text: i18n.t('main', 'dashboardNav.feedLink.text')
      },
      {
        to: getLocation('programs'),
        text: programLabel
      },
      {
        badge: taskCount ? (
          <RoundLabel aria-hidden css={badgeStyle}>
            {getCountLabelText(taskCount)}
          </RoundLabel>
        ) : null,
        helpText: i18n.t('main', 'dashboardNav.tasksLink.helpText', { count: taskCount ?? 0 }),
        to: getLocation('tasks'),
        text: i18n.t('main', 'dashboardNav.tasksLink.text')
      }
    ],
    [getLocation, i18n, programLabel, taskCount]
  );

  let content: ReactElement;

  if (activeView === 'overview') {
    content = (
      <OverviewViewContainer
        communityFilter={communityFilter}
        communityIds={communityIds}
        taskCount={taskCount}
        tasksRoute={getLocation('tasks')}
        viewer={viewer}
        viewerContributionCount={viewerContributionCount}
      />
    );
  } else if (activeView === 'tasks') {
    content = (
      <Section aria={aria0}>
        <SectionHeader headingId={id0}>{i18n.t('main', 'taskList.heading')}</SectionHeader>
        <ErrorBoundary>
          <Suspense fallback={null}>
            <TaskListView aria={aria0} communityIds={communityIds} />
          </Suspense>
        </ErrorBoundary>
      </Section>
    );
  } else if (activeView === 'programs') {
    content = (
      <Section aria={aria0}>
        <SectionHeader headingId={id0}>{i18n.t('main', 'program.label_multiple')}</SectionHeader>
        <Box maxWidth="64rem">
          <ErrorBoundary>
            <Suspense fallback={null}>
              <ProgramListContainer aria={aria0} communityIds={communityIds} showLoadMore />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Section>
    );
  } else if (activeView === 'feed') {
    content = (
      <Section aria={aria0}>
        <VisuallyHidden>
          <Heading id={id0}>{i18n.t('main', 'feedList.title')}</Heading>
        </VisuallyHidden>
        <Box maxWidth="54rem">
          <ErrorBoundary>
            <Suspense fallback={<FeedSkeleton placeholderCount={FEED_PAGE_SIZE} />}>
              <FeedContainer
                aria={aria0}
                autoLoad="afterInitialLoad"
                communityIds={communityIds}
                initialPageSize={FEED_PAGE_SIZE}
              />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Section>
    );
  } else {
    /* istanbul ignore next */ exhaustiveCheck(activeView);
  }

  return (
    <>
      <HeaderBackgroundImage height={{ _: 's24', lg: 's32' }} mb="-2rem" src={bannerUrl} />
      <ViewLayout.Constrained
        maxWidth="xxl"
        px={{ _: 's04', md: 's08', xl: 's12', xxl: 's08' }}
        py="s00"
      >
        {errors.length > 0 && (
          <Box mb="s04">
            <ErrorAlertList errors={errors} />
          </Box>
        )}
        {headerContent}
        <Spacer mb={{ _: 's06', md: 's12' }} />
        <TabNav
          activeBorderWidth={4}
          aria-label={i18n.t('main', 'dashboard.label')}
          contentRef={contentRef}
          fontSize={{ _: 'md', md: 'lg' }}
          items={navConfig}
        />
        <Box ref={contentRef} my="s12">
          <ErrorBoundary>{content}</ErrorBoundary>
        </Box>
      </ViewLayout.Constrained>
    </>
  );
};
