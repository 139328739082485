import styled from '@emotion/styled';
import { breakpoints } from '@/css/breakpoints';
import type { Breakpoint } from '@/css/types';

type Props = {
  breakpoint: Breakpoint;
};

export const HideAt = styled.div<Props>`
  @media (min-width: ${props => breakpoints[props.breakpoint]}) {
    display: none;
  }
`;
