import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { aiServicesKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetAiServices } from './graphql/GetAiServices.gql';

export const aiServicesQueryOptions = queryOptions({
  queryKey: aiServicesKeys.all,
  queryFn: () => fetchDocument(GetAiServices),
  staleTime: LOADER_STALE_TIME
});

export function aiServicesLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(aiServicesQueryOptions);
}
