import { exhaustiveCheck } from '@/types/utils';

const MAXIMUM_LEVEL = 6;

// Ideally you'd want to infer a range type but TS doesn't support this.
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
export function clampNumber<T extends number = number>(min: number, max: number, value: number): T {
  return Math.max(min, Math.min(value, max)) as T;
}

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type HeadingTagName = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type HeadingVariant =
  | 'heading.100'
  | 'heading.200'
  | 'heading.300'
  | 'heading.400'
  | 'heading.500'
  | 'heading.600'
  | 'heading.700'
  | 'heading.800'
  | 'heading.900';

export function getTagFromLevel(level: HeadingLevel): HeadingTagName {
  return `h${clampNumber(1, 6, level)}` as HeadingTagName;
}

// It's better to always use getNextHeadingLevel(level) instead of doing
// `level + 1` as arithmetic operations might expand the level type from
// `HeadingLevel` to `number`.
export function getNextHeadingLevel(level: HeadingLevel): HeadingLevel {
  if (process.env.NODE_ENV !== 'production') {
    if (level < 1 || level >= MAXIMUM_LEVEL) {
      // Printing a warning instead of throwing an error as we might run into
      // unavoidable situations where components are being deeply nested and end
      // up at the max level boundary. Not sure yet how to deal with that. We'll
      // have to detect and remediate these instances but in some cases that
      // might be easier said than done.
      // eslint-disable-next-line no-console
      console.warn(
        `Invalid heading level "${level}". Please provide a level from 1-${MAXIMUM_LEVEL - 1}.`
      );
    }
  }

  // This will clamp the level between 1 and MAXIMUM_LEVEL.
  return clampNumber<HeadingLevel>(1, MAXIMUM_LEVEL, level + 1);
}

// Here if we need it.
export function isValidHeadingLevel(level: number): level is HeadingLevel {
  return level > 0 && level <= MAXIMUM_LEVEL;
}

export function getVariantFromLevel(level: HeadingLevel): HeadingVariant {
  if (level === 1) return 'heading.800';
  if (level === 2) return 'heading.700';
  if (level === 3) return 'heading.600';
  if (level === 4) return 'heading.500';
  if (level === 5) return 'heading.400';
  if (level === 6) return 'heading.300';
  /* istanbul ignore next */ return exhaustiveCheck(level);
}
