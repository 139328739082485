import type { MouseEventHandler } from 'react';
import { useI18n } from '@/components/I18n';
import { IconButton } from '@/components/IconButton';
import { Tooltip } from '@/components/Tooltip';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ClearFavoritesButton = ({ onClick }: Props) => {
  const label = useI18n().t('main', 'dashboardFilter.clearFavoritesButton.text');

  return (
    <Tooltip content={label}>
      <IconButton
        aria-label={label}
        color="grey300"
        hoverBgColor="transparent"
        hoverColor="red400"
        icon="trash"
        onClick={onClick}
      />
    </Tooltip>
  );
};
