import {
  type KeyboardEvent,
  lazy,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
  Suspense,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import type { Props } from 'react-modal';
import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { HeadingContextProvider } from '@/components/Heading';
import { Portal } from '@/components/Portal';
import { Flex } from '@/components/primitives';
import { ThemedDonutLoader } from '@/components/ThemedDonutLoader';
import type { Breakpoint } from '@/css/types';
import { variant } from '@/theme/utils';
import { hasValue } from '@/utils/common';
import { getRootNode } from '@/utils/getRootNode';
import { Delay } from '../Delay';
import { useI18n } from '../I18n';
import { ModalContent } from './ModalContent';
import { ModalContentLoader } from './ModalContentLoader';
import { ModalHeader } from './ModalHeader';
import * as css from './Modal.styles';

let REACT_ROOT = getRootNode();

const ReactModal = lazy(async () => {
  const mod = await import('react-modal');

  if (REACT_ROOT) {
    mod.default.setAppElement(REACT_ROOT);
  }

  return mod;
});

export type ModalMode = 'dialog' | 'fit' | 'fullscreen' | 'sidesheet';
export type ModalSize = 'lg' | 'md' | 'sm' | 'xl';
export type ModalProps<T extends ModalMode> = Pick<
  Props,
  'aria' | 'role' | 'shouldCloseOnEsc' | 'shouldCloseOnOverlayClick'
> & {
  animation?: 'bounce' | 'fade' | 'slide';
  centerY?: T extends 'dialog' ? boolean : never;
  children?: ReactNode;
  fullscreenOnMobile?: Breakpoint | boolean;
  mode?: T;
  onRequestClose?: (event?: KeyboardEvent | MouseEvent) => void;
  scrollContent?: boolean;
  size?: T extends 'dialog' | 'sidesheet' ? ModalSize : never;
  zIndex?: number;
};

type ModalAnimation = 'bounce' | 'fade' | 'slideFromLeft' | 'slideFromRight';

const StyledModal = styled(ReactModal)<{
  animation?: ModalAnimation;
  scrollContent: boolean;
}>(
  {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    margin: 0,
    transition: 'margin 200ms ease-out'
  },
  variant<'bounce' | 'fade' | 'slideFromLeft' | 'slideFromRight'>({
    prop: 'animation',
    variants: {
      bounce: {
        animation: `${css.bounceAnimation} 0.7s cubic-bezier(0.2, 1, 0.2, 1.04) forwards`,
        transform: 'translateY(50vh) scale(0, 1.2)'
        // will-change completely blurs the content text (web fonts).
        // willChange: 'transform, opacity'
      },
      fade: {
        animation: `${css.fadeAnimation} 200ms linear forwards`
      },
      slideFromRight: {
        animation: `${css.getSlideAnimation(false)} 240ms cubic-bezier(0, 0, 0.2, 1);`
      },
      slideFromLeft: {
        animation: `${css.getSlideAnimation(true)} 240ms cubic-bezier(0, 0, 0.2, 1);`
      }
    }
  }),
  variant<boolean>({
    prop: 'scrollContent',
    variants: {
      true: {
        overflowX: 'hidden',
        overflowY: 'auto'
      }
    }
  })
);

function parentSelector() {
  return document.body;
}

type Component = <T extends ModalMode>(props: ModalProps<T>) => ReactElement;

type StaticComponents = {
  Content: typeof ModalContent;
  Header: typeof ModalHeader;
  Loader: typeof ModalContentLoader;
  DEFAULT_Z_INDEX: number;
};

const DEFAULT_Z_INDEX = 10;

export const Modal: Component & StaticComponents = ({
  animation = 'fade',
  centerY: _centerY,
  children,
  fullscreenOnMobile = 'md',
  mode: _mode,
  scrollContent = false,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  size: _size,
  zIndex = DEFAULT_Z_INDEX,
  ...props
}) => {
  const { isRtl } = useI18n();
  // Can't initialize these above due to the conditional generic props type.
  const centerY = _centerY || false;
  const size = _size || 'lg';
  const mode = _mode || 'dialog';

  const [, forceUpdate] = useState<Record<string, unknown>>({});
  const portalStyle = useMemo(() => css.portal(zIndex), [zIndex]);
  const overlayStyle = useMemo(
    () => css.overlay(mode, centerY, fullscreenOnMobile),
    [mode, centerY, fullscreenOnMobile]
  );

  const dialogStyle = useMemo(
    () => css.dialog(mode, size, centerY, fullscreenOnMobile),
    [mode, size, centerY, fullscreenOnMobile]
  );

  useLayoutEffect(() => {
    // In unit tests we run into the issue where most tests don't actually have
    // a root container, but some tests do (i.e. to correctly test the modal
    // root-hide behavior). Even if multiple tests have a root container, they
    // will have a different reference in every test since they'll be defined in
    // that test's render(). Ideally we should add a root container to the
    // TestProviders wrapper but that will break various tests in the process
    // since it changes the snapshot of every test.
    // As a (temporary) workaround we'll re-lookup the root node every time
    // the Modal is used and reassign if necessary, but only in tests. This is
    // still not 100% correct as we can still run into race conditions but for
    // now all tests pass without react-modal errors so we'll ignore those edge
    // cases.
    if (process.env.NODE_ENV === 'test') {
      const node = getRootNode();

      if (REACT_ROOT !== node) {
        REACT_ROOT = node;

        // @ts-expect-error: The lazy wrapper is breaking these types.
        ReactModal.setAppElement?.(node);
        forceUpdate({});
      }
    }
  }, []);

  return (
    <ClassNames>
      {({ css: cssx }) => (
        <Suspense
          fallback={
            // We have to wrap this in a portal because we have some modals that
            // are rendered inside a table, which would then cause the fallback
            // to be directly rendered inside the <tbody>, of course triggering
            // "<div> cannot appear as a child of <tbody>" errors.
            <Portal name="modal-loader">
              <Delay delayInMs={400}>
                <Flex alignItems="center" className={cssx(portalStyle)} justifyContent="center">
                  <ThemedDonutLoader />
                </Flex>
              </Delay>
            </Portal>
          }
        >
          <StyledModal
            {...props}
            animation={
              animation === 'slide' ? (isRtl && 'slideFromLeft') || 'slideFromRight' : animation
            }
            ariaHideApp={hasValue(REACT_ROOT)}
            bodyOpenClassName={cssx(css.body)}
            className={cssx(dialogStyle)}
            htmlOpenClassName={cssx(css.html)}
            isOpen
            overlayClassName={cssx(overlayStyle)}
            // There's something weird going on with this default prop... It is
            // present on mount but then isn't available on unmount and triggers
            // an error. As a workaround we provide an explicit prop ourselves.
            parentSelector={parentSelector}
            portalClassName={cssx(portalStyle)}
            scrollContent={scrollContent || centerY}
            shouldCloseOnEsc={shouldCloseOnEsc}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
          >
            <HeadingContextProvider>
              <ErrorBoundary>
                <Suspense fallback={<ModalContentLoader />}>{children}</Suspense>
              </ErrorBoundary>
            </HeadingContextProvider>
          </StyledModal>
        </Suspense>
      )}
    </ClassNames>
  );
};

Modal.Content = ModalContent;
Modal.Header = ModalHeader;
Modal.Loader = ModalContentLoader;
Modal.DEFAULT_Z_INDEX = DEFAULT_Z_INDEX;
