import { useContext, useLayoutEffect, useRef } from 'react';
import { type Location, UNSAFE_DataRouterContext } from 'react-router-dom';

export type LocationChangeHandler = (location: Location) => void;

export function useLocationTracker(onChange: LocationChangeHandler) {
  const context = useContext(UNSAFE_DataRouterContext);
  const locationRef = useRef(context?.router.state.location);
  const onChangeRef = useRef(onChange);

  if (!context?.router) {
    throw new Error('No router found in context.');
  }

  useLayoutEffect(() => {
    onChangeRef.current = onChange; // Latest ref pattern
  }, [onChange]);

  // We need to pass the router object rather than the whole context object to the dep array below
  // because the context object receives a new reference on every navigation.
  const { router } = context;

  // See this comment for more info on the approach below:
  // https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219
  // Note: router.subscribe() returns an unsubscribe function that should be returned from this hook
  // in order to automatically clean up the listener when unmounted or when the router instance
  // changes.
  useLayoutEffect(
    () =>
      router.subscribe(state => {
        if (state.location !== locationRef.current) {
          onChangeRef.current(state.location);
          locationRef.current = state.location;
        }
      }),
    [router]
  );
}
