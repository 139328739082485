import { type ReactNode, useEffect, useMemo } from 'react';
import { type To, useNavigate } from 'react-router-dom';
import { useCombobox } from 'downshift';
import { useJargon } from '@/components/AppConfig';
import { ContentComboboxList } from '@/components/ContentComboboxList';
import { type I18n, type JargonMap, lookupJargonTranslation, useI18n } from '@/components/I18n';
import { Link } from '@/components/Link';
import { Card, Text } from '@/components/primitives';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import type { SuggestedContentType } from '@/features/search/types';
import { exhaustiveCheck } from '@/types/utils';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { useUpdateEffect } from '@/utils/hooks/useUpdateEffect';
import { Keys } from '@/utils/keyboard';
import { AppHeaderSearchComboboxEmptyState } from './AppHeaderSearchComboboxEmptyState';
import { useCompactSearch } from './useCompactSearch';
import * as css from './AppHeaderSearchForm.styles';

function getLabelByType(i18n: I18n, type: SuggestedContentType, jargon: JargonMap): string {
  let label: string;

  if (type === 'MODULE') {
    label = i18n.t('main', 'module.label');
  } else if (type === 'PROGRAM') {
    label = lookupJargonTranslation<'program'>(jargon.program, {
      PROGRAM: () => i18n.t('main', 'program.label')
    });
  } else if (type === 'USER_PROFILE') {
    label = i18n.t('main', 'profile.label');
  } else if (type === 'CONTRIBUTION') {
    label = i18n.t('contribution', 'contribution.label');
  } else {
    /* istanbul ignore next */ exhaustiveCheck(type);
  }

  return label;
}

const useMessages = () => {
  const i18n = useI18n();

  return useMemo(
    () => ({
      placeholderText: i18n.t('main', 'appNav.search.placeholder'),
      searchHelpText: i18n.t('main', 'appNav.search.helpText'),
      searchResultsLink: i18n.t('main', 'searchForm.searchResultsLink.text')
    }),
    [i18n]
  );
};

export const AppHeaderSearchForm = () => {
  const [items, { isLoading, setSearchText }] = useCompactSearch();
  const i18n = useI18n();
  const navigate = useNavigate();
  const messages = useMessages();
  const jargon = useJargon();
  const locations = useSharedLocations('main');
  const comboboxContext = useCombobox({
    items,
    itemToString: item => item?.title ?? '',
    stateReducer: (_, { type, changes }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return changes.selectedItem && changes.inputValue && !changes.isOpen
            ? { ...changes, inputValue: '' }
            : changes;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return { ...changes, inputValue: '', isOpen: false };
        default:
          return changes;
      }
    }
  });

  const { inputValue, selectedItem, highlightedIndex, reset } = comboboxContext;

  // Update the query searchText whenever the inputValue changes. Normally we'd
  // do this in the `onInputValueChange` event handler on `useCombobox(options)`
  // but as of react v16.13 this is throwing the "Cannot update component from
  // inside the function body of a different component" warning.
  useUpdateEffect(() => {
    setSearchText(inputValue);
  }, [inputValue, setSearchText]);

  // Navigate to the selected item's route and clear the combobox after an item
  // has been selected, or the selected item changes. We could alternatively do
  // this on useCombobox(options.onSelectedItemChange), but just as the issue
  // above this now also throws a (different) error.
  useEffect(() => {
    if (selectedItem) {
      let route: To;

      if (selectedItem.type === 'CONTRIBUTION') {
        route = locations.getContributionPage(selectedItem.id);
      } else if (selectedItem.type === 'MODULE') {
        route = locations.getModulePage(selectedItem.id);
      } else if (selectedItem.type === 'PROGRAM') {
        route = locations.getProgramPage(selectedItem.id);
      } else if (selectedItem.type === 'USER_PROFILE') {
        route = locations.getUserProfilePage(selectedItem.id);
      } else {
        /* istanbul ignore next */ exhaustiveCheck(selectedItem.type);
      }

      navigate(route);
      reset();
    }
  }, [navigate, reset, selectedItem, locations]);

  const labelProps = comboboxContext.getLabelProps();
  const inputProps = comboboxContext.getInputProps({
    placeholder: messages.placeholderText,
    onKeyDown: event => {
      if (event.key === Keys.Enter && highlightedIndex === -1) {
        navigate(locations.getSearchPage(event.currentTarget.value));
        comboboxContext.reset();
      }
    }
  });

  let content: ReactNode;

  if (items.length > 0) {
    content = items.map((item, index) => (
      <ContentComboboxList.Option
        key={item.id}
        isHighlighted={comboboxContext.highlightedIndex === index}
        option={item}
        {...comboboxContext.getItemProps({ index, item, key: item.id })}
      >
        <Text color="textLight" fontSize="md">
          {getLabelByType(i18n, item.type, jargon)}
        </Text>
      </ContentComboboxList.Option>
    ));
  } else {
    content = (
      <AppHeaderSearchComboboxEmptyState
        isLoading={isLoading}
        onRequestClose={comboboxContext.reset}
        searchText={comboboxContext.inputValue}
      />
    );
  }

  return (
    <form aria-labelledby={labelProps.id} onSubmit={event => event.preventDefault()} role="search">
      <VisuallyHidden>
        <label {...labelProps}>{messages.searchHelpText}</label>
      </VisuallyHidden>
      <ContentComboboxList.Container>
        <div {...comboboxContext.getComboboxProps()}>
          <input {...inputProps} css={css.input} type="search" />
        </div>
        <ContentComboboxList isOpen={comboboxContext.isOpen}>
          <ContentComboboxList.Listbox {...comboboxContext.getMenuProps()}>
            {content}
          </ContentComboboxList.Listbox>
          {items.length > 0 && (
            <Link
              onClick={comboboxContext.reset}
              to={locations.getSearchPage(comboboxContext.inputValue)}
            >
              <Card borderTop={1} borderTopColor="neutral200" p="s02">
                <Text fontSize="md" textAlign="center">
                  {messages.searchResultsLink}
                </Text>
              </Card>
            </Link>
          )}
        </ContentComboboxList>
      </ContentComboboxList.Container>
    </form>
  );
};
