import type { ReactNode } from 'react';
import styled from '@emotion/styled';
import { compose, space, system } from 'styled-system';
import type { GridProps, SpaceProps } from '@/components/primitives/types';
import { getColorToken } from '@/css/utils';

export type ScrollableTabListWrapperProps = SpaceProps & {
  children?: ReactNode;
  className?: string;
  gap?: GridProps['gridGap'];
};

export const ScrollableTabListWrapper = styled.div<ScrollableTabListWrapperProps>(
  {
    display: 'grid',
    flexShrink: 0,
    gridAutoColumns: 'min-content',
    gridAutoFlow: 'column',
    overflowX: 'auto',
    // Scrollbar removal in Firefox
    scrollbarWidth: 'none',
    // Scrollbar removal in Legacy Edge
    msOverflowStyle: 'none',
    // Scrollbar removal in Chromium & Safari
    '::-webkit-scrollbar': { display: 'none' },
    boxShadow: `0 -2px 0 -1px ${getColorToken('neutral300')} inset`
  },
  compose(
    space,
    system({
      gap: {
        property: 'gridGap',
        scale: 'space'
      }
    })
  )
);
