import type { StylisPlugin } from '@emotion/cache';
import { DECLARATION } from 'stylis';

export const OverflowWrapStylisPlugin: StylisPlugin = element => {
  if (
    element.type === DECLARATION &&
    typeof element.props === 'string' &&
    typeof element.children === 'string' &&
    element.props === 'overflow-wrap'
  ) {
    return `word-wrap:${element.children};`;
  }

  return undefined;
};
