import { useI18n } from '@/components/I18n';
import { Link, type LinkProps } from '@/components/Link';
import { hasValue } from '@/utils/common';

type Props = Omit<LinkProps, 'children'> & {
  itemCount?: number;
};

export const ViewMoreLink = ({ itemCount, ...props }: Props) => {
  const i18n = useI18n();

  return (
    <Link fontWeight="normal" {...props}>
      {hasValue(itemCount) && itemCount > 1
        ? i18n.t('common', 'viewAllLink.text_withCount', { itemCount })
        : i18n.t('common', 'viewAllLink.text')}
    </Link>
  );
};
