import type { ReactNode } from 'react';
import { Spacer, Text } from '@/components/primitives';
import { hasValue } from '@/utils/common';

export type TabContentProps = {
  children?: ReactNode;
} & (
  | {
      badge?: never;
      badgeCount?: number | null;
    }
  | {
      badge?: ReactNode;
      badgeCount?: never;
    }
);

export const TabContent = ({ children, badge, badgeCount }: TabContentProps) => (
  <>
    {children}
    {(badge || hasValue(badgeCount)) && (
      <>
        <Spacer mr="s02" />
        {hasValue(badgeCount) ? (
          <Text color="blue500" css={{ fontSize: '0.825em' }} fontWeight="semibold">
            {badgeCount}
          </Text>
        ) : (
          badge
        )}
      </>
    )}
  </>
);
