import { Alert } from '@/components/Alert';
import { CodeBlock } from '@/components/CodeBlock';
import { useI18n } from '@/components/I18n';
import { Flex, Spacer, Text } from '@/components/primitives';
import { ResourceNotFoundError, UserNotAuthorizedError } from '@/utils/errors';
import { useToggle } from '@/utils/hooks/useToggle';
import { ErrorAlertButton } from './ErrorAlertButton';
import { ResourceNotFoundErrorAlert } from './ResourceNotFoundErrorAlert';
import { UserNotAuthorizedErrorAlert } from './UserNotAuthorizedErrorAlert';

type Props = {
  code?: number | string;
  error?: Error;
  heading?: string;
  message: string;
  onRequestRetry?: () => void;
  stack?: string;
};

export const ErrorAlert = ({ code, error, heading, message, onRequestRetry, stack }: Props) => {
  const i18n = useI18n();
  const [isExpanded, { toggle }] = useToggle();

  if (error instanceof UserNotAuthorizedError) {
    return <UserNotAuthorizedErrorAlert error={error} />;
  }

  if (error instanceof ResourceNotFoundError) {
    return <ResourceNotFoundErrorAlert />;
  }

  return (
    <Alert icon="close" swatchColor="red">
      {/**
       * Heading block below is copied from <Alert> since this is the only way
       * to properly get a role=alert around it.
       */}
      <Flex flexDirection="column" gap="s02" mb="s02" role="alert">
        {heading && <Text fontWeight="semibold">{heading}</Text>}
        <Text as="p" fontSize="md">
          {message}
        </Text>
      </Flex>
      <Flex alignItems="baseline" gap="s04">
        {code && (
          <Text color="red800" fontFamily="mono" fontSize="sm" lineHeight="none">
            Code: {code}
          </Text>
        )}
        <Flex alignItems="baseline" gap="s02">
          {(stack || error) && (
            <ErrorAlertButton onClick={toggle} type="button">
              {isExpanded
                ? i18n.t('common', 'applicationError.toggleDetailsButton.text_hide')
                : i18n.t('common', 'applicationError.toggleDetailsButton.text_show')}
            </ErrorAlertButton>
          )}
          {onRequestRetry && (
            <ErrorAlertButton onClick={() => onRequestRetry()} type="button">
              {i18n.t('common', 'applicationError.tryAgainButton.text')}
            </ErrorAlertButton>
          )}
        </Flex>
      </Flex>
      {(stack || error) && isExpanded && (
        <>
          <Spacer mb="s03" />
          <CodeBlock>{stack || error?.stack || error?.toString()}</CodeBlock>
        </>
      )}
    </Alert>
  );
};
