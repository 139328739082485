import { ApolloLink, type Operation } from 'apollo-link';

function containsMutations(operation: Operation) {
  return operation.query.definitions.some(
    def => def.kind === 'OperationDefinition' && def.operation === 'mutation'
  );
}

export const omitTypenameLink = new ApolloLink((operation, forward) => {
  if (containsMutations(operation)) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }

  return forward(operation);
});

function omitTypename(key: string, value: unknown) {
  return key === '__typename' ? undefined : value;
}
