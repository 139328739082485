import type { MouseEventHandler, ReactElement, ReactNode } from 'react';
import type { NavLinkProps } from 'react-router-dom';
import type { LinkProps } from '@/components/Link';
import type { MotifColorToken } from '@/css/types';
import { TabNavLink, type TabNavLinkProps } from './TabNavLink';
import { TabNavWrapper, type TabNavWrapperProps } from './TabNavWrapper';

export type TabNavItem = Pick<
  NavLinkProps,
  'caseSensitive' | 'end' | 'preventScrollReset' | 'to'
> & {
  helpText?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  text: string;
} & (
    | {
        badge?: never;
        badgeCount?: number | null;
      }
    | {
        badge?: ReactNode;
        badgeCount?: never;
      }
  );

export type TabNavProps = Pick<TabNavWrapperProps, 'contentRef' | 'focusOptions' | 'gap' | 'px'> & {
  activeBorderColor?: MotifColorToken;
  activeBorderWidth?: number;
  className?: string;
  fontSize?: LinkProps['fontSize'];
  items: TabNavItem[];
  py?: TabNavLinkProps['py'];
} & ({ 'aria-label': string } | { 'aria-labelledby': string });

type TabNavComponent = {
  (props: TabNavProps): ReactElement;
  Container: typeof TabNavWrapper;
  Link: typeof TabNavLink;
};

export const TabNav: TabNavComponent = ({
  activeBorderColor,
  activeBorderWidth,
  className,
  contentRef,
  fontSize,
  focusOptions,
  gap = 's06',
  items,
  px,
  py,
  ...props
}) => (
  <TabNavWrapper
    className={className}
    contentRef={contentRef}
    focusOptions={focusOptions}
    gap={gap}
    px={px}
    {...props}
  >
    {items.map(({ helpText, onClick, text, ...rest }, index) => (
      <TabNavLink
        key={index}
        aria-label={helpText}
        borderColor={activeBorderColor}
        borderWidth={activeBorderWidth}
        fontSize={fontSize}
        onClick={onClick}
        py={py}
        {...rest}
      >
        {text}
      </TabNavLink>
    ))}
  </TabNavWrapper>
);

TabNav.Container = TabNavWrapper;
TabNav.Link = TabNavLink;
