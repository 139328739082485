import { css } from '@emotion/react';
import { Card } from '@/components/primitives';
import { getColorToken } from '@/css/utils';

const caretStyle = css({
  boxShadow: `1px -1px 1px ${getColorToken('neutral200')}`,
  // This is to "normalize" the transform origin offset. We could also set
  // this through ml/mr/mt but let's keep these together in a style
  // definition for clarity.
  marginLeft: 2,
  marginRight: 5,
  marginTop: -7,
  transform: 'rotate(-45deg)',
  transformOrigin: 'right'
});

export const DropdownCaret = () => (
  <Card
    borderBottomColor="transparent"
    borderColor="white"
    borderLeftColor="transparent"
    borderStyle="solid"
    borderWidth={5}
    css={caretStyle}
    data-testid="caret"
    height={0}
    width={0}
  />
);
