type SettingKey =
  | 'isA11yDebugEnabled'
  | 'isDebugEnabled'
  | 'localeId'
  | 'themeMode'
  | 'useSourceTextForUntranslatedTerms';

type SettingValue = boolean | number | string;

const PREFIX = 'cs.web.main';

const getPrefixedKey = (key: SettingKey) => `${PREFIX}:${key}`;

export function set(key: SettingKey, value: SettingValue) {
  localStorage.setItem(getPrefixedKey(key), value.toString());
}

export function get(key: SettingKey) {
  return localStorage.getItem(getPrefixedKey(key));
}

export function remove(key: SettingKey) {
  localStorage.removeItem(getPrefixedKey(key));
}

export function getBoolean(key: SettingKey, defaultValue = false): boolean {
  const value = get(key);

  if (value === null) return defaultValue;

  try {
    return Boolean(JSON.parse(value));
  } catch {
    return defaultValue;
  }
}

// export function getArray(key: SettingKey) {
//   const value = get(key);

//   if (value === null) return [];

//   try {
//     const parsed = JSON.parse(value);
//     return Array.isArray(parsed) ? parsed : [];
//   } catch {
//     return [];
//   }
// }
