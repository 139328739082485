import { matchPath } from 'react-router-dom';
import type { I18n } from '@/components/I18n';
import type { MenuItemConfig } from '@/components/SidebarNavGroup';
import { AppLocations } from '@/pages/config';
import { removeEmptyElementsFromArray } from '@/utils/common';

type SidebarMenu = {
  items: MenuItemConfig[];
  title: string;
};

function getContentManagementMenu(i18n: I18n): SidebarMenu {
  return {
    title: i18n.t('common', 'mainNavigation.contentMenu.heading'),
    items: [
      {
        to: AppLocations.admin.getFieldTypeListPage(),
        label: i18n.t('management', 'fieldType.label_multiple'),
        icon: 'cube'
      },
      {
        to: AppLocations.admin.getSliceTemplateListPage(),
        label: i18n.t('management', 'sliceTemplate.label_multiple'),
        icon: 'clipboard-list'
      },
      {
        to: AppLocations.admin.getProgramTemplateListPage(),
        label: i18n.t('management', 'programTemplate.label_multiple'),
        icon: 'view-grid-add'
      },
      {
        to: AppLocations.admin.getProgramListPage(),
        label: i18n.t('main', 'program.label_multiple'),
        icon: 'view-grid'
      }
    ]
  };
}

const USERS_PATHS = [
  '/admin/users/*',
  '/admin/user-invite-links/*',
  '/admin/user-removal-requests/*',
  '/admin/user-imports/*'
];

function getCommunityManagementMenu(
  i18n: I18n,
  options: Pick<UserContext, 'canManageConfiguration' | 'isDataProtectionOfficer'>
): SidebarMenu {
  return {
    title: i18n.t('management', 'communityConfiguration.label'),
    items: removeEmptyElementsFromArray([
      options.canManageConfiguration || options.isDataProtectionOfficer
        ? {
            to: AppLocations.admin.getUserListPage(),
            label: i18n.t('common', 'user.label_multiple'),
            icon: 'user',
            isActive: location => USERS_PATHS.some(path => matchPath(path, location.pathname))
          }
        : undefined,
      options.canManageConfiguration
        ? {
            to: AppLocations.admin.getUserGroupListPage(),
            label: i18n.t('common', 'userGroup.label_multiple'),
            icon: 'users'
          }
        : undefined,
      options.canManageConfiguration
        ? {
            to: AppLocations.admin.getCommunityListPage(),
            label: i18n.t('main', 'community.label_multiple'),
            icon: 'user-group'
          }
        : undefined
    ])
  };
}

function getAuthorizationMenu(i18n: I18n): SidebarMenu {
  return {
    title: i18n.t('common', 'mainNavigation.authorizationMenu.heading'),
    items: [
      {
        to: AppLocations.admin.getRoleListPage(),
        label: i18n.t('management', 'role.label_multiple'),
        icon: 'key'
      },
      {
        to: AppLocations.admin.getRoleAssignmentListPage(),
        label: i18n.t('management', 'roleAssignment.label_global_multiple'),
        icon: 'lock-open'
      }
    ]
  };
}

function getDataManagementMenu(
  i18n: I18n,
  options: Pick<UserContext, 'canManageDataProtectionSettings'>,
  hasDpoAssigned: boolean
): SidebarMenu {
  return {
    title: i18n.t('common', 'mainNavigation.dataPrivacyMenu.heading'),
    items: [
      {
        to: AppLocations.admin.getDataManagementSettingsPage(),
        label: i18n.t('management', 'dataPrivacySettings.label'),
        icon: 'finger-print',
        showNotification: options.canManageDataProtectionSettings && !hasDpoAssigned
      }
    ]
  };
}

function getSettingsMenu(i18n: I18n): SidebarMenu {
  return {
    title: i18n.t('common', 'mainNavigation.settingsMenu.heading'),
    items: [
      {
        to: AppLocations.admin.getGeneralSettingsPage(),
        label: i18n.t('management', 'generalSettings.title'),
        icon: 'adjustments'
      },
      {
        to: AppLocations.admin.getUserRegistrationSettingsPage(),
        label: i18n.t('management', 'userRegistrationSettings.heading'),
        icon: 'shield-check'
      },
      {
        to: AppLocations.admin.getUserAgreementListPage(),
        label: i18n.t('main', 'userAgreement.label_multiple'),
        icon: 'user-check'
      },
      {
        to: AppLocations.admin.getUserClaimSettingsPage(),
        label: i18n.t('management', 'userClaim.label_multiple'),
        icon: 'identification'
      },
      {
        to: AppLocations.admin.getIntegrationsPage(),
        label: i18n.t('management', 'integrations.title'),
        icon: 'chip'
      },
      {
        to: AppLocations.admin.getAIServicesPage(),
        label: i18n.t('management', 'aiServices.title'),
        icon: 'spark'
      }
    ]
  };
}

function getReportManagementMenu(
  i18n: I18n,
  options: Pick<UserContext, 'canViewDataSets' | 'canViewKpis'>
): SidebarMenu {
  return {
    title: i18n.t('common', 'mainNavigation.reportingMenu.heading'),
    items: removeEmptyElementsFromArray([
      options.canViewDataSets
        ? {
            to: AppLocations.admin.getDataSetListPage(),
            label: i18n.t('management', 'dataSet.label_multiple'),
            icon: 'table'
          }
        : undefined,
      options.canViewKpis
        ? {
            to: AppLocations.admin.getKpiListPage(),
            label: i18n.t('management', 'kpi.label_multiple'),
            icon: 'trending-up'
          }
        : undefined
    ])
  };
}

type UserContext = {
  canManageConfiguration: boolean;
  canManageDataProtectionSettings: boolean;
  canManageRolesAndPermissions: boolean;
  canViewDataSets: boolean;
  canViewKpis: boolean;
  canViewReports: boolean;
  isDataProtectionOfficer: boolean;
};

export function getAdminMenus(
  i18n: I18n,
  user: UserContext,
  hasDpoAssigned: boolean
): SidebarMenu[] {
  return [
    /** Community management menu */
    ...(user.canManageConfiguration || user.isDataProtectionOfficer
      ? [getCommunityManagementMenu(i18n, user)]
      : []),
    /** Content management menu */
    ...(user.canManageConfiguration ? [getContentManagementMenu(i18n)] : []),
    /** Authorization menu */
    ...(user.canManageRolesAndPermissions ? [getAuthorizationMenu(i18n)] : []),
    /** Data privacy menu */
    ...(user.canManageDataProtectionSettings
      ? [getDataManagementMenu(i18n, user, hasDpoAssigned)]
      : []),
    /** Settings menu */
    ...(user.canManageConfiguration ? [getSettingsMenu(i18n)] : []),
    /** Reporting menu */
    ...(user.canViewKpis || user.canViewDataSets ? [getReportManagementMenu(i18n, user)] : [])
  ];
}

export function getDebugMenu(_: I18n): SidebarMenu {
  return {
    title: 'Developer menu',
    items: [
      {
        to: AppLocations.debug.getGraphQlPage(),
        label: 'GraphQL debugger',
        icon: 'terminal'
      },
      {
        to: AppLocations.debug.getDebugSettingsPage(),
        label: 'Debug settings',
        icon: 'beaker'
      },
      {
        to: AppLocations.debug.getSandboxPage(),
        label: 'UI sandbox',
        icon: 'template'
      }
    ]
  };
}

const HOME_PATHS = [
  '/',
  '/feed',
  '/programs',
  '/tasks',
  '/communities/:communityId',
  '/communities/:communityId/feed',
  '/communities/:communityId/programs',
  '/communities/:communityId/tasks'
];

export function getMainMenu(
  i18n: I18n,
  canViewReports: boolean,
  hasIdeationModuleRequests: boolean
): SidebarMenu {
  return {
    title: i18n.t('common', 'mainNavigation.mainMenu.heading'),
    items: removeEmptyElementsFromArray([
      {
        to: AppLocations.main.getHomePage(),
        label: i18n.t('main', 'dashboard.label'),
        icon: 'home',
        isActive: location => HOME_PATHS.some(path => matchPath(path, location.pathname))
      },
      {
        to: AppLocations.main.getCurrentUserProfilePage(),
        label: i18n.t('main', 'appNav.currentUserProfileLink.text'),
        icon: 'user-circle'
      },
      {
        to: AppLocations.main.getDraftsModal(),
        label: i18n.t('main', 'appNav.draftsLink.text'),
        icon: 'pencil-alt',
        // Should never be active as it displays a globally available modal.
        isActive: false
      },
      hasIdeationModuleRequests
        ? {
            to: AppLocations.main.getCurrentUserIdeationModuleRequestsPage(),
            label: i18n.t('challenge', 'appNav.ideationModuleRequestsLink.text'),
            icon: 'speakerphone'
          }
        : undefined,
      canViewReports
        ? {
            to: AppLocations.main.getReportListPage(),
            label: i18n.t('main', 'report.label_multiple'),
            icon: 'chart-pie'
          }
        : undefined,
      {
        to: AppLocations.main.getSearchPage(),
        label: i18n.t('common', 'search.label'),
        icon: 'search-circle'
      },
      {
        to: AppLocations.main.getSubmittedUserAgreementsModal(),
        label: i18n.t('main', 'userAgreement.label_multiple'),
        icon: 'user-check',
        // Should never be active as it displays a globally available modal.
        isActive: false
      }
    ])
  };
}
