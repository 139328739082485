import { forwardRef, type ReactNode, useContext } from 'react';
import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  format,
  formatDistanceToNow,
  type Locale,
  parseJSON
} from 'date-fns';
import { type I18n, useI18n } from '@/components/I18n';
import { DateFnsLocaleContext } from '@/components/I18n/DateFnsLocaleContext';
import { Card, Text } from '@/components/primitives';
import { Tooltip } from '@/components/Tooltip';
import type { ColorToken } from '@/css/types';
import { exhaustiveCheck } from '@/types/utils';

type ModuleStatus = 'ACTIVE' | 'ENDED' | 'SCHEDULED';
type TextMode = 'LONG' | 'SHORT';

type Props = {
  endDate?: string | null;
  startDate?: string | null;
  status: ModuleStatus;
  textMode?: TextMode;
  type: Api.ModuleType;
};

type ThemeColorTuple = [bgColor: ColorToken, color: ColorToken];

function getColors(status: ModuleStatus, endDate: string | null): ThemeColorTuple {
  let colors: ThemeColorTuple;

  if (status === 'ENDED') {
    colors = ['neutral900', 'grey100'];
  } else if (status === 'SCHEDULED') {
    colors = ['blue600', 'white'];
  } else if (status === 'ACTIVE') {
    if (!endDate || differenceInDays(parseJSON(endDate), Date.now()) > 5) {
      colors = ['grey100', 'textDark'];
    } else {
      colors = ['red700', 'white'];
    }
  } else {
    /* istanbul ignore next */ exhaustiveCheck(status);
  }

  return colors;
}

function getDistanceHumanReadableToNow(dateTime: string, i18n: I18n, locale?: Locale): string {
  const parsedDateTime = parseJSON(dateTime);
  const diffInWeeks = Math.abs(differenceInWeeks(Date.now(), parsedDateTime));
  const diffInMonths = Math.abs(differenceInMonths(Date.now(), parsedDateTime));

  let distanceText: string;

  if (diffInWeeks >= 2 && diffInMonths < 2) {
    distanceText = i18n.t('common', 'dateTime.timeLeftInWeeks.text', { count: diffInWeeks });
  } else {
    distanceText = i18n.t('common', 'dateTime.timeLeft.text', {
      time: formatDistanceToNow(parsedDateTime, { locale })
    });
  }

  return distanceText;
}

const getTooltipText = (
  i18n: I18n,
  status: ModuleStatus,
  startDate: string | null,
  endDate: string | null,
  locale?: Locale
) => {
  if (status === 'SCHEDULED' && startDate) {
    return i18n.t('main', 'moduleStatus.helpText_scheduled', {
      date: format(parseJSON(startDate), 'PPPp', { locale })
    });
  }

  if (status === 'ACTIVE' && endDate) {
    return i18n.t('main', 'moduleStatus.helpText_active', {
      date: format(parseJSON(endDate), 'PPPp', { locale })
    });
  }

  return undefined;
};

function getModuleStatusText(
  i18n: I18n,
  status: ModuleStatus,
  startDate: string | null,
  endDate: string | null,
  textMode: TextMode,
  type: Api.ModuleType,
  locale?: Locale
): string | null {
  let text: string | null = null;

  if (status === 'SCHEDULED') {
    if (textMode === 'LONG' && startDate) {
      const time = getDistanceHumanReadableToNow(startDate, i18n, locale);

      if (type === 'ENRICHMENT_MODULE') {
        text = i18n.t('main', 'moduleStatus.enrichmentModule.text_scheduled', { time });
      } else if (type === 'IDEATION_MODULE') {
        text = i18n.t('main', 'moduleStatus.ideationModule.text_scheduled', { time });
      } else if (type === 'REVIEW_MODULE') {
        text = i18n.t('main', 'moduleStatus.reviewModule.text_scheduled', { time });
      }
    } else {
      text = i18n.t('main', 'moduleStatus.shortLabel.text_scheduled');
    }
  } else if (status === 'ACTIVE') {
    if (textMode === 'LONG' && endDate) {
      const time = getDistanceHumanReadableToNow(endDate, i18n, locale);

      if (type === 'ENRICHMENT_MODULE') {
        text = i18n.t('main', 'moduleStatus.enrichmentModule.text_active', { time });
      } else if (type === 'IDEATION_MODULE') {
        text = i18n.t('main', 'moduleStatus.ideationModule.text_active', { time });
      } else if (type === 'REVIEW_MODULE') {
        text = i18n.t('main', 'moduleStatus.reviewModule.text_active', { time });
      }
    } else if (endDate && differenceInDays(parseJSON(endDate), new Date()) <= 5) {
      text = i18n.t('main', 'moduleStatus.shortLabel.text_endingSoon');
    } else {
      text = i18n.t('main', 'moduleStatus.shortLabel.text_active');
    }
  } else if (status === 'ENDED') {
    if (textMode === 'LONG') {
      if (type === 'ENRICHMENT_MODULE') {
        text = i18n.t('main', 'moduleStatus.enrichmentModule.text_ended');
      } else if (type === 'IDEATION_MODULE') {
        text = i18n.t('main', 'moduleStatus.ideationModule.text_ended');
      } else if (type === 'REVIEW_MODULE') {
        text = i18n.t('main', 'moduleStatus.reviewModule.text_ended');
      }
    } else {
      text = i18n.t('main', 'moduleStatus.shortLabel.text_ended');
    }
  }

  return text;
}

export const BaseModuleLabel = forwardRef<
  HTMLDivElement,
  { bgColor: ColorToken; children: ReactNode; color: ColorToken }
>(({ bgColor, children, color }, ref) => (
  <Card ref={ref} bgColor={bgColor} borderRadius="2xl" px="s02" py="s01">
    <Text color={color} fontSize="sm" fontWeight="semibold" lineHeight="tight">
      {children}
    </Text>
  </Card>
));

export const ModuleStatusLabel = ({
  endDate = null,
  startDate = null,
  status,
  textMode = 'LONG',
  type
}: Props) => {
  const i18n = useI18n();
  const locale = useContext(DateFnsLocaleContext) ?? undefined;
  const tooltipText = getTooltipText(i18n, status, startDate, endDate, locale);
  const [bgColor, color] = getColors(status, endDate);

  let content = (
    <BaseModuleLabel bgColor={bgColor} color={color}>
      {getModuleStatusText(i18n, status, startDate, endDate, textMode, type, locale)}
    </BaseModuleLabel>
  );

  if (tooltipText) {
    content = <Tooltip content={tooltipText}>{content}</Tooltip>;
  }

  return content;
};
