import { type RefObject, useMemo } from 'react';
import {
  type GuidedFocusAfterNavigationOptions,
  useGuidedFocusAfterLinkNavigation
} from '@/utils/hooks/useGuidedFocusAfterLinkNavigation';
import {
  ScrollableTabListWrapper,
  type ScrollableTabListWrapperProps
} from '../Tabs/ScrollableTabListWrapper';
import { TabNavContext } from './TabNavContext';

const NavComponent = ScrollableTabListWrapper.withComponent('nav');

export type TabNavWrapperProps = ScrollableTabListWrapperProps & {
  /** A ref to the content that should receive focus after navigation. */
  contentRef: RefObject<HTMLElement> | null;
  focusOptions?: GuidedFocusAfterNavigationOptions;
};

export const TabNavWrapper = ({ contentRef, focusOptions, ...props }: TabNavWrapperProps) => {
  const onClick = useGuidedFocusAfterLinkNavigation(contentRef ?? { current: null }, focusOptions);

  return (
    <TabNavContext.Provider value={useMemo(() => ({ onClick }), [onClick])}>
      <NavComponent {...props} />
    </TabNavContext.Provider>
  );
};
