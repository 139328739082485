import { createParser, get } from '@styled-system/core';
import type { Theme } from '@/css/types';
import { css } from './_css';

/**
 * I'm not entirely sure how this works, but if you don't "box" the types below
 * into a [] then you get an incorrect distributed type. For example, the type
 *     Variants<'one' | 'two'>
 * would result in
 *     Record<'one', unknown> | Record<'two', unknown>
 * instead of the expected
 *     Record<'one' | 'two', unknown>
 * This difference is important, since in the first case the individual keys
 * would not be required, while in the second case they would be, which we want.
 * The only information I could find about this is in the URLs below.
 * @url https://github.com/microsoft/TypeScript/issues/33110
 * @url https://www.typescriptlang.org/docs/handbook/advanced-types.html#distributive-conditional-types
 */
type Variants<TValue extends boolean | string> = [TValue] extends [boolean]
  ? Partial<Record<'false' | 'true', unknown>>
  : [TValue] extends [string]
    ? Record<TValue, unknown>
    : never;

type VariantOptions<TValue extends boolean | string> = {
  scale?: keyof Theme;
  prop?: string;
  variants: Variants<TValue>;
  key?: string;
};

type StyleFunction<TValue extends boolean | string> = {
  (
    value: unknown,
    scale: VariantOptions<TValue>['scale'],
    props: Record<string, unknown> & { theme: Theme }
  ): Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  scale?: VariantOptions<TValue>['key'] | VariantOptions<TValue>['scale'];
  defaults?: VariantOptions<TValue>['variants'];
};

export const variant = <TValue extends boolean | string>({
  scale,
  prop = 'variant',
  // enables new api
  variants = {} as Variants<TValue>,
  // shim for v4 API
  key
}: VariantOptions<TValue>) => {
  let sx: StyleFunction<TValue>;
  if (Object.keys(variants).length) {
    sx = (value, _scale, props) => css(get(_scale, value, null))(props.theme as unknown as any);
  } else {
    sx = (value, _scale) => get(_scale, value, null);
  }
  sx.scale = scale ?? key;
  sx.defaults = variants;
  const config = {
    [prop]: sx
  };
  const parser = createParser(config);
  return parser;
};

export { css };
