import { createContext } from 'react';
import type { To } from 'react-router-dom';

const noop = () => {};

export type AppShellContextValue = {
  setParent: (hasParent: boolean, parentRoute: To | null) => void;
  setTitle: (title: string | null) => void;
};

export const AppShellContext = createContext<AppShellContextValue>({
  setParent: noop,
  setTitle: noop
});
