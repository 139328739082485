import { BlockListBuilder } from '@/components/BlockList';
import { ErrorAlert } from '@/components/ErrorAlert';

export interface WrappedError {
  code?: number | string;
  error?: Error;
  heading?: string;
  message: string;
  stack?: string;
}

type Props = {
  errors?: WrappedError[];
  onRequestRetry?: () => void;
};

export const ErrorAlertList = ({ errors = [], onRequestRetry }: Props) => (
  <BlockListBuilder
    items={errors.map(error => (
      <ErrorAlert
        code={error.code}
        error={error.error}
        heading={error.heading}
        message={error.message}
        onRequestRetry={onRequestRetry}
        stack={error.stack}
      />
    ))}
    spacing="s02"
  />
);
