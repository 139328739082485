import { memo, type MouseEventHandler, type ReactChild, useEffect, useRef, useState } from 'react';
import type { To } from 'react-router-dom';
import { useI18n } from '@/components/I18n';
import { IconButton, type IconButtonProps } from '@/components/IconButton';
import { Tooltip } from '@/components/Tooltip';
import type { ColorToken } from '@/css/types';
import type { Image } from '@/types';
import { DashboardFilterListItemIcon } from './DashboardFilterListItemIcon';
import { DashboardFilterListItemLayout } from './DashboardFilterListItemLayout';
import { DashboardFilterListItemLink } from './DashboardFilterListItemLink';
import { DashboardFilterListItemThumbnail } from './DashboardFilterListItemThumbnail';

export type CommunityData = {
  id: string;
  image: Image | null;
  name: string;
};

type FavoriteButtonProps = Pick<IconButtonProps, 'disabled' | 'onClick'> & {
  mode: 'add' | 'remove';
};

const FavoriteButton = ({ mode, ...props }: FavoriteButtonProps) => {
  const i18n = useI18n();

  let label: string;
  let color: ColorToken;
  let hoverColor: ColorToken;

  if (mode === 'add') {
    label = i18n.t('main', 'dashboardFilter.addToFavoritesButton.text');
    color = 'grey300';
    hoverColor = 'yellow400';
  } else {
    label = i18n.t('main', 'dashboardFilter.removeFromFavoritesButton.text');
    color = 'yellow400';
    hoverColor = 'red400';
  }

  return (
    <Tooltip content={label}>
      <IconButton
        aria-label={label}
        color={color}
        hoverBgColor="transparent"
        hoverColor={hoverColor}
        icon="star"
        {...props}
      />
    </Tooltip>
  );
};

type Props = {
  data: CommunityData;
  isFavorited: boolean;
  isSelected: boolean;
  onAddToFavorites: (communityId: string) => Promise<unknown>;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onRemoveFromFavorites: (communityId: string) => Promise<unknown>;
  to: To;
};

export const DashboardFilterListCommunityItem = memo<Props>(
  ({ data, isFavorited, isSelected, onAddToFavorites, onClick, onRemoveFromFavorites, to }) => {
    const [isLoading, setIsLoading] = useState(false);
    const isMountedRef = useRef(false);

    // After performing an action (favorite/unfavorite) a refetch of the items
    // will be triggered on an ancestor, which will also lead to *this* item
    // to move from the favorites to the non-favorites, or vice versa. If this
    // happens _before_ we call our "setIsLoading", we'll run into a "Can't
    // perform a React state update on an unmounted component" error.
    // Since we can't easily lift this state up into a parent, we'll instead
    // track our mounted state. It's generally frowned upon, but there's not
    // really a better way.
    useEffect(() => {
      isMountedRef.current = true;

      return () => {
        isMountedRef.current = false;
      };
    });

    let contentAfter: ReactChild;
    let contentBefore: ReactChild;

    if (data.image) {
      contentBefore = <DashboardFilterListItemThumbnail image={data.image} />;
    } else {
      contentBefore = <DashboardFilterListItemIcon bgColor="grey600" icon="user-group" />;
    }

    const handleCompleted = () => {
      if (isMountedRef.current) setIsLoading(false);
    };

    if (isFavorited) {
      contentAfter = (
        <FavoriteButton
          disabled={isLoading}
          mode="remove"
          onClick={() => {
            setIsLoading(true);
            onRemoveFromFavorites(data.id).then(handleCompleted).catch(handleCompleted);
          }}
        />
      );
    } else {
      contentAfter = (
        <FavoriteButton
          disabled={isLoading}
          mode="add"
          onClick={() => {
            setIsLoading(true);
            onAddToFavorites(data.id).then(handleCompleted).catch(handleCompleted);
          }}
        />
      );
    }

    return (
      <DashboardFilterListItemLayout
        slots={{
          content: (
            <DashboardFilterListItemLink isSelected={isSelected} onClick={onClick} to={to}>
              <bdi>{data.name}</bdi>
            </DashboardFilterListItemLink>
          ),
          contentAfter,
          contentBefore
        }}
      />
    );
  }
);
