import styled from '@emotion/styled';

export const ErrorAlertButton = styled.button`
  background: none;
  border: 0;
  color: #666;
  cursor: pointer;
  font-size: 13px;
  padding: 0;
  text-decoration: underline;
  transition: color 150ms ease-out;

  &:hover {
    color: #333;
  }
`;
