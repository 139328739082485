import { forwardRef, type RefObject, useContext } from 'react';
import { HeadingContext, HeadingContextProvider } from '@/components/Heading';
import { Box, type BoxProps } from '@/components/primitives';
import type { Aria } from '@/types';

/**
 * See comments in components/Heading for additional information.
 * Also see:
 * @url https://html.spec.whatwg.org/multipage/dom.html#sectioning-content
 */

type SectioningContentElementTagName = 'article' | 'aside' | 'nav' | 'section';

type SectioningContentElement = BoxProps & {
  aria?: Aria;
  tabIndex?: number;
};

type SectioningContentElementComponentProps = Omit<SectioningContentElement, 'as'>;

const SectioningContentElement = forwardRef<HTMLElement, SectioningContentElement>(
  ({ aria, as, ...props }, ref) => (
    <HeadingContextProvider previousContext={useContext(HeadingContext)}>
      <Box
        ref={ref as unknown as RefObject<HTMLDivElement>}
        aria-describedby={aria?.describedby}
        aria-label={aria?.label}
        aria-labelledby={aria?.labelledby}
        as={as}
        {...props}
      />
    </HeadingContextProvider>
  )
);

SectioningContentElement.displayName = 'SectioningContentElement';

function createSectioningContentElementComponent(as: SectioningContentElementTagName) {
  return forwardRef<HTMLElement, SectioningContentElementComponentProps>((props, ref) => (
    <SectioningContentElement {...props} ref={ref} as={as} />
  ));
}

export const Article = createSectioningContentElementComponent('article');

Article.displayName = 'Article';

export const Aside = createSectioningContentElementComponent('aside');

Aside.displayName = 'Aside';

export const Nav = createSectioningContentElementComponent('nav');

Nav.displayName = 'Nav';

// Sections seemingly need an accessible name in order to be given a "region"
// role. See https://www.w3.org/TR/html-aria/. Thus we'll be enforcing use of
// aria props as with the modals & forms.
const SectionSectioningContentElement = createSectioningContentElementComponent('section');

SectionSectioningContentElement.displayName = 'SectionSectioningContentElement';

type SectionProps = SectioningContentElementComponentProps & {
  aria: Aria;
};

export const Section = forwardRef<HTMLElement, SectionProps>((props, ref) => (
  <SectionSectioningContentElement ref={ref} {...props} />
));

Section.displayName = 'Section';
