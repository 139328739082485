import { useEffect, useState } from 'react';

export function usePortalTarget(name: string): HTMLElement {
  const [target] = useState(() => document.createElement('div'));

  useEffect(() => {
    target.setAttribute('data-portal-name', name);
    target.setAttribute('data-testid', `portal-${name}`);
  }, [name, target]);

  useEffect(() => {
    document.body.appendChild(target);

    return () => {
      target.parentNode?.removeChild(target);
    };
  }, [target]);

  return target;
}
