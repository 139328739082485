// Taken from https://github.com/shvaikalesh/shim-keyboard-event-key/blob/master/index.js

export function polyfillKeyboardEventKeys() {
  const event = KeyboardEvent.prototype;
  const desc = Object.getOwnPropertyDescriptor(event, 'key');

  if (!desc) return;

  const keys = {
    Win: 'Meta',
    Scroll: 'ScrollLock',
    Spacebar: ' ',

    Down: 'ArrowDown',
    Left: 'ArrowLeft',
    Right: 'ArrowRight',
    Up: 'ArrowUp',

    Del: 'Delete',
    Apps: 'ContextMenu',
    Esc: 'Escape',

    Multiply: '*',
    Add: '+',
    Subtract: '-',
    Decimal: '.',
    Divide: '/'
  };

  Object.defineProperty(event, 'key', {
    get() {
      const key: string = desc.get?.call(this);
      // @ts-expect-error
      return keys[key] ? keys[key] : key;
    }
  });
}
