import type { ReactNode } from 'react';
import type { FileApiClient } from '../FileApiClient';
import { FileApiContext } from './FileApiContext';

type Props = {
  children: ReactNode;
  client: FileApiClient;
};

export const FileApiProvider = ({ children, client }: Props) => (
  <FileApiContext.Provider value={client}>{children}</FileApiContext.Provider>
);
