import { locationAssign } from '@/utils/location';

type Config = {
  loginUrl: string;
  logoutUrl: string;
};

export class AuthenticationClient {
  config: Config;

  constructor(config: Config) {
    this.config = config;
  }

  login = (callbackUrl = window.location.href) => {
    locationAssign(`${this.config.loginUrl}?next=${encodeURIComponent(callbackUrl)}`);
  };

  logout = (callbackUrl = window.location.origin) => {
    locationAssign(`${this.config.logoutUrl}?next=${encodeURIComponent(callbackUrl)}`);
  };
}
