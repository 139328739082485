import { Icon } from '@/components/Icon';

type Props = {
  size?: number;
};

// TODO: Might be better to use <StatusIcon> in the future.

export const NotificationIcon = ({ size }: Props) => (
  <Icon color="danger" size={size} type="alert-circle" />
);
