import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  border,
  type BorderStyleProps,
  type BorderWidthProps,
  compose,
  layout,
  type LayoutProps,
  space
} from 'styled-system';
import type { BorderProps, SpaceProps } from '@/components/primitives/types';
import type { ColorToken, SpaceToken } from '@/css/types';
import { variant as variantFn } from '@/theme/utils';

type DividerAlignment = 'center' | 'end' | 'start';

type DividerBaseProps = BorderProps &
  LayoutProps &
  SpaceProps & {
    align?: DividerAlignment;
  };

const DividerBase = styled('div', { shouldForwardProp })<DividerBaseProps>(
  {
    borderStyle: 'solid',
    borderWidth: 0,
    width: '100%'
  },
  compose(
    border,
    layout,
    space,
    variantFn<DividerAlignment>({
      prop: 'align',
      variants: {
        center: {
          marginLeft: 'auto',
          marginRight: 'auto'
        },
        end: {
          marginLeft: 'auto',
          marginRight: 0
        },
        start: {
          marginLeft: 0
        }
      }
    })
  )
);

DividerBase.defaultProps = {
  align: 'center',
  borderTopColor: 'neutral200',
  borderTopWidth: 1
};

type Props = {
  align?: DividerAlignment;
  color?: ColorToken;
  height?: BorderWidthProps['borderTopWidth'];
  role?: 'separator';
  spacing?: SpaceToken;
  variant?: BorderStyleProps['borderTopStyle'];
  width?: number | string;
};

export const Divider = ({ align, color, height, width, role, spacing, variant }: Props) => (
  <DividerBase
    align={align}
    borderTopColor={color}
    borderTopStyle={variant}
    borderTopWidth={height}
    data-testid="divider"
    my={spacing}
    role={role}
    width={width}
  />
);
