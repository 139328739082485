import { type ReactNode, useMemo } from 'react';
import styled from '@emotion/styled';
import { gridGap, gridTemplateColumns, type GridTemplateColumnsProps } from 'styled-system';
import type { BreakpointMap, GridProps as PrimitiveGridProps } from '@/components/primitives/types';
import { hasValue } from '@/utils/common';

type ColumnMap = BreakpointMap<number>;

export type GridProps = {
  columnCount: ColumnMap | number;
  gap?: PrimitiveGridProps['gridGap'];
};

type Props = GridProps & {
  children: ReactNode;
};

type GetEntry<T> = NonNullable<
  {
    [K in keyof T]: [K, T[K]];
  }[keyof T]
>[];

function getEntries<T extends ColumnMap>(columns: T): GetEntry<T> {
  return Object.entries(columns) as unknown as GetEntry<T>;
}

const Grid = styled.div<GridTemplateColumnsProps & Pick<PrimitiveGridProps, 'gridGap'>>(
  { display: 'grid' },
  gridTemplateColumns,
  gridGap
);

export const AutoPlacementGrid = ({ children, columnCount, gap }: Props) => {
  const templateColumns = useMemo(() => {
    if (typeof columnCount === 'number') {
      return `repeat(${columnCount}, minmax(0, 1fr))`;
    }

    return getEntries(columnCount).reduce<BreakpointMap>((acc, [bp, count]) => {
      if (hasValue(count)) {
        acc[bp] = `repeat(${count}, minmax(0, 1fr))`;
      }
      return acc;
    }, {});
  }, [columnCount]);

  return (
    <Grid gridGap={gap} gridTemplateColumns={templateColumns}>
      {children}
    </Grid>
  );
};
