import type { ReactElement, ReactNode } from 'react';
import type { To } from 'react-router-dom';
import { Tooltip } from '@/components/Tooltip';
import { UnstyledLink } from '@/components/UnstyledLink';
import { getToken } from '@/css/utils';
import { UserAvatar, type UserAvatarProps } from './UserAvatar';

const withTooltip = (component: ReactElement, tooltip: ReactElement | number | string) => (
  <Tooltip content={tooltip}>{component}</Tooltip>
);

const withLink = (component: ReactNode, to: To, tabIndex?: number) => (
  <UnstyledLink
    css={{
      borderRadius: '50%',
      outline: 'none',
      ':focus': { boxShadow: getToken('shadows', 'outline') }
    }}
    display="flex"
    tabIndex={tabIndex}
    to={to}
  >
    {component}
  </UnstyledLink>
);

type Props = UserAvatarProps & {
  href?: string;
  route?: To;
  tabIndex?: number;
  tooltip?: ReactElement | number | string;
};

export const EnhancedUserAvatar = ({ href, route, tabIndex, tooltip, ...props }: Props) => {
  const to = route ?? href;
  let avatar = <UserAvatar {...props} />;

  if (to) {
    avatar = withLink(avatar, to, tabIndex);
  }

  if (tooltip) {
    avatar = withTooltip(avatar, tooltip);
  }

  return avatar;
};
