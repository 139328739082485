import { useEffect, useRef } from 'react';

export const usePrevious = <ValueType = unknown>(value: ValueType) => {
  const ref = useRef<ValueType | null>(null);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
