import { Skeleton } from '@/components/Skeleton/v2';
import { ContributionCardMetaLayout } from './ContributionCardMetaLayout';

export const ContributionCardMetaSkeleton = () => (
  <ContributionCardMetaLayout
    slots={{
      comments: <Skeleton.Text width="3ch" />,
      inventors: <Skeleton.Shape borderRadius="full" height="1.75rem" width="1.75rem" />,
      votes: <Skeleton.Text width="3ch" />
    }}
  />
);
