import { lazy, Suspense, useEffect } from 'react';
import { parseJSON } from 'date-fns';
import { setLocale } from 'yup';
import { Time } from '@/components/Time';
import { I18nTrans } from './I18nTrans';
import { useI18n } from './useI18n';

// addMethod(array, 'unique', function unique(caseSensitive = false, message?: string) {
//   return this.test({
//     exclusive: true,
//     message: message || locale.array.unique,
//     name: 'unique',
//     test(values) {
//       if (!values) return true;

//       let valuesToCheck = values;

//       if (!caseSensitive) {
//         valuesToCheck = values.map(value =>
//           (typeof value === 'object' ? value.key : value).toLowerCase()
//         );
//       }

//       return !valuesToCheck.some((value, index) => valuesToCheck.indexOf(value) !== index);
//     }
//   });
// });

// Lazy loading this since it contains components/Tooltip which is rather large.
const LazyInvalidFileExtensionError = lazy(async () => ({
  default: (await import('./InvalidFileExtensionError')).InvalidFileExtensionError
}));

export function useYupLocales() {
  const i18n = useI18n();

  useEffect(() => {
    setLocale({
      array: {
        max: ({ max, value }) =>
          i18n.t('common', 'validation.choiceList.error.text_tooManyItems', { count: max, value }),
        min: ({ min, value }) =>
          i18n.t('common', 'validation.choiceList.error.text_tooFewItems', { count: min, value }),
        unique: ({ label }) =>
          i18n.t('common', 'validation.choiceList.error.text_mustBeUnique', { label })
      },
      date: {
        max: ({ max }) => (
          <I18nTrans
            components={{ time: <Time dateTime={max} format="PPp" showTime /> }}
            i18nKey="validation.date.error.text_dateTooFar"
            ns="common"
            values={{ date: typeof max === 'string' ? parseJSON(max) : max.toISOString() }}
          />
        ),
        min: ({ min }) => (
          <I18nTrans
            components={{ time: <Time dateTime={min} format="PPp" showTime /> }}
            i18nKey="validation.date.error.text_dateTooEarly"
            ns="common"
            values={{ date: typeof min === 'string' ? parseJSON(min) : min.toISOString() }}
          />
        )
      },
      object: {
        maxFileSize: ({ maxSize }) =>
          i18n.t('common', 'fileUpload.error.text_fileSizeExceeded', { maxSize }),
        fileExtension: ({ allowedExtensions, extension }) => (
          <Suspense fallback={null}>
            <LazyInvalidFileExtensionError
              allowedExtensions={allowedExtensions}
              extension={extension}
            />
          </Suspense>
        )
      },
      mixed: {
        default: ({ value }) => i18n.t('common', 'validation.error.text', { value }),
        notType: ({ value }) => i18n.t('common', 'validation.error.text', { value }),
        required: ({ value }) => i18n.t('common', 'validation.error.text_required', { value })
      },
      number: {
        integer: ({ value }) =>
          i18n.t('common', 'validation.number.error.text_notANumber', { value }),
        max: ({ max, value }) =>
          i18n.t('common', 'validation.number.error.text_tooHigh', { max, value }),
        min: ({ min, value }) =>
          i18n.t('common', 'validation.number.error.text_tooLow', { min, value }),
        negative: ({ value }) =>
          i18n.t('common', 'validation.number.error.text_notNegative', { value }),
        positive: ({ value }) =>
          i18n.t('common', 'validation.number.error.text_notPositive', { value })
      },
      string: {
        domainList: () => i18n.t('common', 'validation.text.error.text_invalidDomainList'),
        max: ({ max, value }) =>
          i18n.t('common', 'validation.text.error.text_tooManyCharacters', { max, value }),
        min: ({ min, value }) =>
          i18n.t('common', 'validation.text.error.text_tooFewCharacters', { min, value }),
        secureUrl: () => i18n.t('common', 'validation.text.error.text_nonSecureUrl'),
        url: () => i18n.t('common', 'validation.text.error.text_invalidUrl')
      }
    });
  }, [i18n]);
}
