export type Key = ValueOf<typeof Keys>;

/**
 * These map to key values as defined on [MDN](https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values).
 * Various keys don't have a proper noun as identifier so we keep a map that
 * we can use in order to always reference a proper name internally. Examples of
 * non-noun keys: Space (' '), Multiply ('+'), Divide ('/') etc.
 *
 * We'll fill up this map based on need & usage.
 * Since it's a constant it should perhaps also be called KEYS instead. TBD.
 */
export const Keys = {
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  Delete: 'Delete',
  End: 'End',
  Enter: 'Enter',
  Escape: 'Escape',
  Home: 'Home',
  PageDown: 'PageDown',
  PageUp: 'PageUp',
  Space: ' ',
  Tab: 'Tab'
} as const;
