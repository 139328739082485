import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type {
  FetchInfiniteQueryOptions,
  FetchQueryOptions,
  QueryKey as RQQueryKey,
  UseMutationOptions
} from '@tanstack/react-query';
import { useMutation as useRQMutation } from '@tanstack/react-query';
import type { GetWritingAssistantUsageChartDataQueryVariables } from '@/features/writing-assistant/views/WritingAssistantUsageChartDataView/graphql/GetWritingAssistantUsageChartData.gql';
import type { RequestError } from '@/utils/api/error';
import { fetchDocument } from '@/utils/api/fetcher';
import type { GetUserImportsQueryVariables } from './admin._user-management.user-imports/graphql/GetUserImports.gql';
import type { GetUserInviteLinkUsersQueryVariables } from './admin._user-management.user-invite-links.$inviteLinkId.users/graphql/GetUserInviteLinkUsers.gql';
import type { GetUserInviteLinksQueryVariables } from './admin._user-management.user-invite-links/graphql/GetUserInviteLinks.gql';
import type { GetUserRemovalRequestsQueryVariables } from './admin._user-management.user-removal-requests/graphql/GetUserRemovalRequests.gql';
import type { GetUsersQueryVariables } from './admin._user-management.users/graphql/GetUsers.gql';
import type { GetCommunitiesQueryVariables } from './admin.communities/graphql/GetCommunities.gql';
import type { GetCommunityProgramsQueryVariables } from './admin.communities_.$communityId.$/graphql/GetCommunityPrograms.gql';
import type { GetFieldTypesQueryVariables } from './admin.field-types/graphql/GetFieldTypes.gql';
import type { GetTemplateProgramsQueryVariables } from './admin.program-templates/graphql/GetTemplatePrograms.gql';
import type { GetProgramsQueryVariables } from './admin.programs/graphql/GetPrograms.gql';
import type { GetRolesQueryVariables } from './admin.roles/graphql/GetRoles.gql';
import type { GetSliceTemplatesQueryVariables } from './admin.slice-templates/graphql/GetSliceTemplates.gql';
import type { GetUserClaimSettingsQueryVariables } from './admin.user-claims/graphql/GetUserClaimSettings.gql';
import type { GetUserGroupsQueryVariables } from './admin.user-groups/graphql/GetUserGroups.gql';

/**
 * Since we're not using suspense yet we need to manually set a stale time in
 * order to prevent a double fetch.
 */
export const LOADER_STALE_TIME = 1000 * 5;

export function queryOptions<
  const TQueryKey extends RQQueryKey,
  TQueryFnData = unknown,
  TError = RequestError,
  TData = TQueryFnData
>(options: FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey> & { queryKey: TQueryKey }) {
  return options;
}

export function infiniteQueryOptions<
  const TQueryKey extends RQQueryKey,
  TQueryFnData = unknown,
  TError = RequestError,
  TData = TQueryFnData
>(
  options: FetchInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryKey> & {
    queryKey: TQueryKey;
  }
) {
  return options;
}

export function useGraphQLMutation<
  TData,
  TVariables,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  TInputVariables = TVariables extends Api.Exact<{ [key: string]: never }> ? void : TVariables
>(
  document: TypedDocumentNode<TData, TVariables>,
  options?: UseMutationOptions<TData, RequestError, TInputVariables>
) {
  return useRQMutation<TData, RequestError, TInputVariables>({
    // @ts-expect-error
    mutationFn: variables => fetchDocument(document, variables),
    ...options
  });
}

type RootQueryKey<S extends string> = readonly [{ $$scope: S }];

type QueryKey<S extends string, Rest extends { [key: string]: unknown }> = readonly [
  Rest & RootQueryKey<S>[0]
];

export const rootQueryKey = <S extends string>(scope: S): RootQueryKey<S> => [{ $$scope: scope }];

export const queryKey = <
  S extends string,
  const R0 extends { [key: string]: unknown },
  const R1 extends { entity?: string; [key: string]: unknown }
>(
  key: QueryKey<S, R0>,
  rest: R1
): QueryKey<S, R0 & R1> => [{ ...key[0], ...rest }];

/**
 * @TODO Find a better location for these keys.
 */

export const userKeys = {
  all: rootQueryKey('admin/users'),
  list_all: () => queryKey(userKeys.all, { entity: 'list' }),
  list: (variables: GetUsersQueryVariables) => queryKey(userKeys.list_all(), { variables }),
  user_add: () => queryKey(userKeys.all, { entity: 'form/add' }),
  userById: (userId: string) => queryKey(userKeys.all, { userId }),
  userById_edit: (userId: string) => queryKey(userKeys.userById(userId), { entity: 'form/edit' })
};

// prettier-ignore
export const userGroupKeys = {
  all: rootQueryKey('admin/user-groups'),
  list_all: () => queryKey(userGroupKeys.all, { entity: 'list' }),
  list: (variables: GetUserGroupsQueryVariables) => queryKey(userGroupKeys.list_all(), { variables }),
  group_add: () => queryKey(userGroupKeys.all, { entity: 'form/add' }),
  groupById: (userGroupId: string) => queryKey(userGroupKeys.all, { userGroupId }),
  groupById_edit: (userGroupId: string) => queryKey(userGroupKeys.groupById(userGroupId), { entity: 'form/edit' })
};

// prettier-ignore
export const userImportKeys = {
  all: rootQueryKey('admin/user-imports'),
  list_all: () => queryKey(userImportKeys.all, { entity: 'list' }),
  list: (variables: GetUserImportsQueryVariables) => queryKey(userImportKeys.list_all(), { variables }),
  userImport_add: (importId: string) => queryKey(userImportKeys.all, { entity: 'form/add', importId })
};

// prettier-ignore
export const userRemovalRequestKeys = {
  all: rootQueryKey('admin/user-removal-requests'),
  list_all: () => queryKey(userRemovalRequestKeys.all, { entity: 'list' }),
  list: (variables: GetUserRemovalRequestsQueryVariables) => queryKey(userRemovalRequestKeys.list_all(), { variables }),
  request_add: () => queryKey(userRemovalRequestKeys.all, { entity: 'form/add' }),
  requestById: (requestId: string) => queryKey(userRemovalRequestKeys.all, { requestId })
};

export const userInviteLinkKeys = {
  all: rootQueryKey('admin/user-invite-links'),
  list_all: () => queryKey(userInviteLinkKeys.all, { entity: 'list' }),
  list: (variables: GetUserInviteLinksQueryVariables) => queryKey(userInviteLinkKeys.list_all(), { variables }), // prettier-ignore
  userInviteLinkById: (inviteLinkId: string) => queryKey(userInviteLinkKeys.all, { inviteLinkId }),
  userInviteLinkById_users: (
    inviteLinkId: string,
    variables: Omit<GetUserInviteLinkUsersQueryVariables, 'inviteLinkId' | 'page'>
  ) => queryKey(userInviteLinkKeys.userInviteLinkById(inviteLinkId), { entity: 'users', variables })
};

export const userRegistrationSettingsKeys = {
  all: rootQueryKey('admin/user-registration')
};

export const userClaimKeys = {
  all: rootQueryKey('admin/user-claims'),
  list_all: () => queryKey(userClaimKeys.all, { entity: 'list' }),
  list: (variables: GetUserClaimSettingsQueryVariables) => queryKey(userClaimKeys.list_all(), { variables }), // prettier-ignore
  userClaim_add: () => queryKey(userClaimKeys.all, { entity: 'form/add' }),
  userClaimById: (userClaimId: string) => queryKey(userClaimKeys.all, { userClaimId }),
  userClaimById_edit: (userClaimId: string) => queryKey(userClaimKeys.userClaimById(userClaimId), { entity: 'form/edit' }), // prettier-ignore
  identityProviderById: (identityProviderId: string) => queryKey(userClaimKeys.all, { identityProviderId }), // prettier-ignore
  identityProviderById_edit: (identityProviderId: string) =>
    queryKey(userClaimKeys.identityProviderById(identityProviderId), {
      entity: 'provider/form/edit'
    })
};

export const roleKeys = {
  all: rootQueryKey('admin/roles'),
  list_all: () => queryKey(roleKeys.all, { entity: 'list' }),
  list: (variables: GetRolesQueryVariables) => queryKey(roleKeys.list_all(), { variables }),
  role_add: () => queryKey(roleKeys.all, { entity: 'form/add' }),
  roleById: (roleId: string) => queryKey(roleKeys.all, { roleId }),
  roleById_edit: (roleId: string) => queryKey(roleKeys.roleById(roleId), { entity: 'form/edit' })
};

export const communityKeys = {
  all: rootQueryKey('admin/communities'),
  list_all: () => queryKey(communityKeys.all, { entity: 'list' }),
  list: (variables: GetCommunitiesQueryVariables) => queryKey(communityKeys.list_all(), { variables }), // prettier-ignore
  community_add: () => queryKey(communityKeys.all, { entity: 'form/add' }),
  communityById: (communityId: string) => queryKey(communityKeys.all, { communityId }),
  communityById_edit: (communityId: string) => queryKey(communityKeys.communityById(communityId), { entity: 'form/edit' }), // prettier-ignore
  programsList_all: (communityId: string) => queryKey(communityKeys.all, { communityId, entity: 'programsList' }), // prettier-ignore
  programsList: (
    communityId: string,
    variables: Omit<GetCommunityProgramsQueryVariables, 'communityId'>
  ) => queryKey(communityKeys.programsList_all(communityId), { variables, entity: 'programsList' })
};

// prettier-ignore
export const programManagementKeys = {
  all: rootQueryKey('admin/program-management'),
  program: (programId: string) => queryKey(programManagementKeys.all, { programId }),
  program_add: () => queryKey(programManagementKeys.all, { entity: 'programForm/add' }),
  program_addFromTemplate: (templateProgramId: string) => queryKey(programManagementKeys.all, { entity: 'programForm/addFromTemplate', templateProgramId }),
  program_edit: (programId: string) => queryKey(programManagementKeys.program(programId), { entity: 'programForm/edit' }),
  programRoleAssignment_add: (programId: string) => queryKey(programManagementKeys.program(programId), { entity: 'roleAssignmentForm/add' }),
  programRoleAssignmentsList: (programId: string) => queryKey(programManagementKeys.program(programId), { entity: 'roleAssignmentsList' }),
  programStagesList: (programId: string) => queryKey(programManagementKeys.program(programId), { entity: 'stagesList' }),
  programsList_all: () => queryKey(programManagementKeys.all, { entity: 'programsList' }),
  programsList: (variables: GetProgramsQueryVariables) => queryKey(programManagementKeys.programsList_all(), { variables }),
  templateProgram: (templateProgramId: string) => queryKey(programManagementKeys.all, { templateProgramId }),
  templateProgram_edit: (templateProgramId: string) => queryKey(programManagementKeys.templateProgram(templateProgramId), { entity: 'templateProgramForm/add' }),
  templateProgramsList_all: () => queryKey(programManagementKeys.all, { entity: 'templatesList' }),
  templateProgramsList: (variables: GetTemplateProgramsQueryVariables) => queryKey(programManagementKeys.templateProgramsList_all(), { variables })
};

// prettier-ignore
export const moduleKeys = {
  all: rootQueryKey('admin/modules'),
  module: (moduleId: string) => queryKey(moduleKeys.all, { moduleId }),
  dispatcher_edit: (moduleId: string) => queryKey(moduleKeys.all, { moduleId, entity: 'dispatcher/edit' }),
  moduleTimings_edit: (moduleId: string) => queryKey(moduleKeys.all, { moduleId, entity: 'moduleTimingForm/edit' }),
  swimlane_edit: (swimlaneId: string) => queryKey(moduleKeys.all, { swimlaneId, entity: 'swimlaneForm/edit' }),
  swimlanesList: (moduleId: string) => queryKey(moduleKeys.all, { moduleId, entity: 'swimlanesList' }),
  featureSettings: (moduleId: string) => queryKey(moduleKeys.module(moduleId), { moduleId, entity: 'featureSettings' })
};

// prettier-ignore
export const ideationModuleRequestKeys = {
  all: rootQueryKey('ideation-module-requests'),
  request: (programId: string, requestId: string) => queryKey(ideationModuleRequestKeys.all, { programId, requestId })
};

// prettier-ignore
export const fieldTypeKeys = {
  all: rootQueryKey('admin/field-types'),
  list_all: () => queryKey(fieldTypeKeys.all, { entity: 'list' }),
  list: (variables: GetFieldTypesQueryVariables) => queryKey(fieldTypeKeys.list_all(), { variables }),
  fieldType_add: () => queryKey(fieldTypeKeys.all, { entity: 'form/add' }),
  fieldTypeById: (fieldTypeId: string) => queryKey(fieldTypeKeys.all, { fieldTypeId }),
  fieldTypeById_edit: (fieldTypeId: string) => queryKey(fieldTypeKeys.fieldTypeById(fieldTypeId), { entity: 'form/edit' })
};

// prettier-ignore
export const sliceTemplateKeys = {
  all: rootQueryKey('admin/slice-templates'),
  list_all: () => queryKey(sliceTemplateKeys.all, { entity: 'list' }),
  list: (variables: GetSliceTemplatesQueryVariables) => queryKey(sliceTemplateKeys.list_all(), { variables }),
  sliceTemplate_add: () => queryKey(sliceTemplateKeys.all, { entity: 'form/add' }),
  sliceTemplateById: (sliceTemplateId: string) => queryKey(sliceTemplateKeys.all, { sliceTemplateId }),
  sliceTemplateById_edit: (sliceTemplateId: string) => queryKey(sliceTemplateKeys.sliceTemplateById(sliceTemplateId), { entity: 'form/edit' })
};

export const staticRoleAssignmentKeys = {
  all: rootQueryKey('admin/role-assignments'),
  list_all: () => queryKey(staticRoleAssignmentKeys.all, { entity: 'list' }),
  // Normally we have a "list" key as well and never use the "list_all" key
  // directly, but currently the list query does not take any parameters so
  // would have exactly the same key as "list_all".
  // list: () => queryKey(staticRoleAssignmentKeys.list_all()),
  staticRoleAssignment_add: () => queryKey(staticRoleAssignmentKeys.all, { entity: 'form/add' })
};

// prettier-ignore
export const webhookKeys = {
  all: rootQueryKey('admin/webhooks'),
  list_all: () => queryKey(webhookKeys.all, { entity: 'list' }),
  webhook_add: () => queryKey(webhookKeys.all, { entity: 'form/add' }),
  webhookById: (webhookId: string) => queryKey(webhookKeys.all, { webhookId }),
  webhookById_edit: (webhookId: string) => queryKey(webhookKeys.webhookById(webhookId), { entity: 'form/edit' })
};

// prettier-ignore
export const apiClientKeys = {
  all: rootQueryKey('admin/api-clients'),
  list_all: () => queryKey(apiClientKeys.all, { entity: 'list' }),
  apiClient_add: () => queryKey(apiClientKeys.all, { entity: 'form/add' }),
  apiClientById: (apiClientId: string) => queryKey(apiClientKeys.all, { apiClientId }),
  apiClientById_edit: (apiClientId: string) => queryKey(apiClientKeys.apiClientById(apiClientId), { entity: 'form/edit' })
};

export const brandingSettingsKeys = {
  all: rootQueryKey('admin/branding-settings')
};

export const jargonKeys = {
  all: rootQueryKey('admin/jargon')
};

export const dashboardSettingsKeys = {
  all: rootQueryKey('admin/dashboard')
};

export const profileCompletionSettingsKeys = {
  all: rootQueryKey('admin/profile-completion')
};

export const localeSettingsKeys = {
  all: rootQueryKey('admin/language')
};

export const aiServicesKeys = {
  all: rootQueryKey('admin/ai-services'),
  writingAssistantUsageChart: (variables: GetWritingAssistantUsageChartDataQueryVariables) =>
    queryKey(aiServicesKeys.all, { entity: 'writingAssistantUsageChart', variables })
};
