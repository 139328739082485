import styled from '@emotion/styled';
import { compose, fontSize, space, system } from 'styled-system';
import type { FontSizeProps, SpaceProps } from '@/components/primitives/types';
import type { MotifColorToken } from '@/css/types';
import { getColorToken, getMotifColorToken, getToken } from '@/css/utils';
import { shouldForwardProp } from '../Link/utils';

type Props = {
  borderColor?: MotifColorToken;
  borderWidth: number;
};

export type TabBaseProps = Pick<FontSizeProps, 'fontSize'> & Pick<SpaceProps, 'py'> & Props;

export const TabBase = styled('button', { shouldForwardProp })<TabBaseProps>(
  {
    alignItems: 'baseline',
    background: 'none',
    border: 0,
    borderBottomColor: 'transparent',
    borderBottomStyle: 'solid',
    borderTopColor: 'transparent',
    borderTopStyle: 'solid',
    display: 'flex',
    outlineOffset: -1,
    padding: 'unset',
    transition: 'border-color 150ms ease-out',
    whiteSpace: 'nowrap'
  },
  ({ borderColor }) => ({
    color: getColorToken('textLight'),
    fontWeight: getToken('fontWeights', 'semibold'),
    lineHeight: getToken('lineHeights', 'none'),
    '&[data-active-item], &.active': {
      borderBottomColor: getMotifColorToken(borderColor ?? 'grey500'),
      color: getColorToken('textDark')
    },
    ':hover': {
      color: getColorToken('textDark'),
      // Consider removing the pointer from buttons.
      cursor: 'pointer'
    },
    '&[aria-disabled="true"], &[aria-disabled="true"]:hover': {
      color: getColorToken('grey400'),
      cursor: 'not-allowed'
    }
  }),
  compose(
    space,
    fontSize,
    system({
      borderWidth: {
        properties: ['borderBottomWidth', 'borderTopWidth']
      }
    })
  )
);
