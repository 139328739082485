/* eslint-disable */

import type { CSSObject } from '@emotion/react';

/**
 * This is copied from @styled-system/css, and includes some object breakpoint fixes from @maxim's
 * PR here: https://github.com/styled-system/styled-system/pull/591/files
 * TODO: Replace this with @styled-system/css once the PR is merged.
 */

// based on https://github.com/developit/dlv
//@ts-ignore
const get = (obj: Record<string, unknown> | undefined, key, def?, p?, undef?) => {
  key = key?.split ? key.split('.') : [key];
  for (p = 0; p < key.length; p++) {
    // biome-ignore lint/style/noParameterAssign: ignore
    obj = obj ? obj[key[p]] : undef;
  }
  return obj === undef ? def : obj;
};

const defaultBreakpoints = [40, 52, 64].map(n => `${n}em`);

const defaultTheme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72]
};

const aliases = {
  bg: 'background',
  m: 'margin',
  mt: 'marginTop',
  mr: 'marginRight',
  mb: 'marginBottom',
  ml: 'marginLeft',
  mx: 'marginX',
  my: 'marginY',
  p: 'padding',
  pt: 'paddingTop',
  pr: 'paddingRight',
  pb: 'paddingBottom',
  pl: 'paddingLeft',
  px: 'paddingX',
  py: 'paddingY'
};

const multiples: { [key: string]: string[] } = {
  marginX: ['marginLeft', 'marginRight'],
  marginY: ['marginTop', 'marginBottom'],
  paddingX: ['paddingLeft', 'paddingRight'],
  paddingY: ['paddingTop', 'paddingBottom'],
  size: ['width', 'height']
};

const scales = {
  color: 'colors',
  backgroundColor: 'colors',
  borderColor: 'colors',
  margin: 'space',
  marginTop: 'space',
  marginRight: 'space',
  marginBottom: 'space',
  marginLeft: 'space',
  marginInlineEnd: 'space',
  marginInlineStart: 'space',
  marginX: 'space',
  marginY: 'space',
  padding: 'space',
  paddingTop: 'space',
  paddingRight: 'space',
  paddingBottom: 'space',
  paddingLeft: 'space',
  paddingInlineEnd: 'space',
  paddingInlineStart: 'space',
  paddingX: 'space',
  paddingY: 'space',
  top: 'space',
  right: 'space',
  bottom: 'space',
  left: 'space',
  gridGap: 'space',
  gridColumnGap: 'space',
  gridRowGap: 'space',
  gap: 'space',
  columnGap: 'space',
  rowGap: 'space',
  fontFamily: 'fonts',
  fontSize: 'fontSizes',
  fontWeight: 'fontWeights',
  lineHeight: 'lineHeights',
  letterSpacing: 'letterSpacings',
  border: 'borders',
  borderTop: 'borders',
  borderRight: 'borders',
  borderBottom: 'borders',
  borderLeft: 'borders',
  borderWidth: 'borderWidths',
  borderStyle: 'borderStyles',
  borderRadius: 'radii',
  borderTopLeftRadius: 'radii',
  borderTopRightRadius: 'radii',
  borderBottomRightRadius: 'radii',
  borderBottomLeftRadius: 'radii',
  borderTopWidth: 'borderWidths',
  borderTopColor: 'colors',
  borderTopStyle: 'borderStyles',
  borderBottomWidth: 'borderWidths',
  borderBottomColor: 'colors',
  borderBottomStyle: 'borderStyles',
  borderLeftWidth: 'borderWidths',
  borderLeftColor: 'colors',
  borderLeftStyle: 'borderStyles',
  borderRightWidth: 'borderWidths',
  borderRightColor: 'colors',
  borderRightStyle: 'borderStyles',
  outlineColor: 'colors',
  boxShadow: 'shadows',
  textShadow: 'shadows',
  zIndex: 'zIndices',
  width: 'sizes',
  minWidth: 'sizes',
  maxWidth: 'sizes',
  height: 'sizes',
  minHeight: 'sizes',
  maxHeight: 'sizes',
  flexBasis: 'sizes',
  size: 'sizes',
  fill: 'colors',
  stroke: 'colors'
};

//@ts-ignore
const positiveOrNegative = (scale, value) => {
  if (typeof value !== 'number' || value >= 0) {
    return get(scale, value, value);
  }
  const absolute = Math.abs(value);
  const n = get(scale, absolute, absolute);
  if (typeof n === 'string') return `-${n}`;
  return n * -1;
};

const transforms = [
  'margin',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
  'marginX',
  'marginY',
  'top',
  'bottom',
  'left',
  'right'
].reduce(
  (acc, curr) => {
    acc[curr] = positiveOrNegative;
    return acc;
  },
  {} as Record<string, unknown>
);

//@ts-ignore
const responsive = styles => theme => {
  const next: { [key: string]: any } = {};
  const breakpoints = get(theme, 'breakpoints', defaultBreakpoints);
  let mediaQueries: (string | null)[] = [null];

  if (typeof breakpoints === 'object') {
    const units = Array.isArray(breakpoints) ? breakpoints : Object.values(breakpoints);
    mediaQueries = [null, ...units.map(n => `@media screen and (min-width: ${n})`)];
  }

  for (const key in styles) {
    const value = typeof styles[key] === 'function' ? styles[key](theme) : styles[key];

    if (value == null) continue;
    if (!Array.isArray(value)) {
      next[key] = value;
      continue;
    }

    for (let i = 0; i < value.slice(0, mediaQueries.length).length; i++) {
      const media = mediaQueries[i];

      if (!media) {
        next[key] = value[i];
        continue;
      }

      next[media] = next[media] || {};
      if (value[i] == null) continue;
      next[media][key] = value[i];
    }
  }

  return next;
};

export const css =
  (args: any) =>
  (props: { theme?: any } = {}): CSSObject => {
    const theme = { ...defaultTheme, ...(props.theme || props) };
    let result: CSSObject = {};
    const obj = typeof args === 'function' ? args(theme) : args;
    const styles = responsive(obj)(theme);

    for (const key in styles) {
      const x = styles[key];
      const val = typeof x === 'function' ? x(theme) : x;

      if (key === 'variant') {
        const variant = css(get(theme, val))(theme);
        result = { ...result, ...variant };
        continue;
      }

      if (val && typeof val === 'object') {
        result[key] = css(val)(theme);
        continue;
      }

      const prop = get(aliases, key, key);
      const scaleName = get(scales, prop);
      const scale = get(theme, scaleName, get(theme, prop, {}));
      const transform = get(transforms, prop, get);
      const value = transform(scale, val, val);

      if (multiples[prop]) {
        const dirs = multiples[prop];

        for (let i = 0; i < dirs.length; i++) {
          result[dirs[i]] = value;
        }
      } else {
        result[prop] = value;
      }
    }

    return result;
  };
