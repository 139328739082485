import type { ReactNode } from 'react';
import { HeadingContext } from './HeadingContext';
import { getNextHeadingLevel, type HeadingLevel } from './utils';

type Props = {
  children?: ReactNode;
  previousContext?: HeadingLevel;
};

export const HeadingContextProvider = ({ children, previousContext }: Props) => (
  <HeadingContext.Provider value={previousContext ? getNextHeadingLevel(previousContext) : 1}>
    {children}
  </HeadingContext.Provider>
);
