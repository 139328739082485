import type { Location, To } from 'react-router-dom';
import { NotificationIcon } from '@/components/NotificationIcon';
import { Flex } from '@/components/primitives';
import { MenuLink } from './MenuLink';

export type MenuItem = {
  helpText?: string;
  isActive?: boolean | ((location: Location) => boolean);
  route: To;
  showNotification?: boolean;
  text: string;
};

type Props = {
  ariaLabel: string;
  items: MenuItem[];
};

export const Menu = ({ ariaLabel, items }: Props) => (
  <Flex aria-label={ariaLabel} as="nav">
    {items.map((item, i) => (
      <MenuLink
        key={i}
        aria-label={item.helpText}
        isActive={item.isActive}
        text={item.text}
        to={item.route}
      >
        <Flex alignItems="center">
          {item.text}
          {item.showNotification && (
            <Flex mx="s01">
              <NotificationIcon />
            </Flex>
          )}
        </Flex>
      </MenuLink>
    ))}
  </Flex>
);
