import { forwardRef, memo, type ReactNode } from 'react';
import { Heading } from '@/components/Heading';
import { useI18n } from '@/components/I18n';
import { Link } from '@/components/Link';
import { Absolute, Card, Flex, Relative, Text } from '@/components/primitives';
import { Thumbnail } from '@/components/Thumbnail';
import { Time } from '@/components/Time';
import { UnstyledLink } from '@/components/UnstyledLink';
import type { Image, UserWithAvatar } from '@/types';
import { truncateText } from '@/utils/common';
import { getImageUrl } from '@/utils/getImageUrl';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { SimilarContentBadge } from '../SimilarContentBadge';
import placeholder_1 from './images/placeholder-1.svg';
import placeholder_2 from './images/placeholder-2.svg';
import placeholder_3 from './images/placeholder-3.svg';
import placeholder_4 from './images/placeholder-4.svg';
import placeholder_5 from './images/placeholder-5.svg';
import placeholder_6 from './images/placeholder-6.svg';
import placeholder_7 from './images/placeholder-7.svg';
import placeholder_8 from './images/placeholder-8.svg';
import placeholder_9 from './images/placeholder-9.svg';
import placeholder_10 from './images/placeholder-10.svg';
import { ContributionCardLabel } from './ContributionCardLabel';
import { ContributionCardLayout } from './ContributionCardLayout';
import { ContributionCardMeta, type ContributionVoteHandler } from './ContributionCardMeta';

export type { ContributionVoteHandler };

const images: string[] = [
  placeholder_1,
  placeholder_2,
  placeholder_3,
  placeholder_4,
  placeholder_5,
  placeholder_6,
  placeholder_7,
  placeholder_8,
  placeholder_9,
  placeholder_10
];

function getPlaceholderById(id: string): string {
  return images[Math.floor(id.charCodeAt(0) % 10)];
}

export type Contribution = {
  allowDownvotes: boolean;
  commentCount: number;
  creator: UserWithAvatar;
  dateCreated: string;
  description: string | null;
  downvoteCount: number;
  inventors: UserWithAvatar[];
  id: string;
  isVotingAllowed: boolean;
  moduleId: string;
  moduleTitle: string | null;
  moduleType: Api.ModuleType | null;
  originatingModuleId: string;
  originatingModuleTitle: string | null;
  similarContentLevel?: Api.SimilarContentLevel;
  thumbnail: Image | null;
  title: string;
  upvoteCount: number;
  viewer: {
    canVote: boolean;
    isCreator: boolean;
    voteValue: Api.VoteType | null;
  };
};

type Props = {
  contribution: Contribution;
  disableMyContributionBadge?: boolean;
  onVote: ContributionVoteHandler;
  showChallenge?: boolean;
};

const ContributionCard = forwardRef<HTMLDivElement, Props>(
  ({ contribution, disableMyContributionBadge = false, onVote, showChallenge = false }, ref) => {
    const i18n = useI18n();
    const [id, aria] = useHeadingIdAriaPair();
    const { getContributionPage, getModulePage } = useSharedLocations('main');
    const { moduleTitle, moduleType, similarContentLevel } = contribution;

    const location = getContributionPage(contribution.id);
    const isDispatched = contribution.moduleId !== contribution.originatingModuleId;

    const thumbnail = (
      <UnstyledLink aria-hidden tabIndex={-1} to={location}>
        <Relative>
          <Thumbnail
            aspectRatio={5 / 3}
            autosize
            bgColor="white"
            borderRadius="none"
            css={{ overflow: 'hidden' }}
            imageUrl={getImageUrl(contribution.thumbnail, { mode: 'crop', w: 300, h: 180 })}
            placeholderUrl={getPlaceholderById(contribution.id)}
          >
            {contribution.viewer.isCreator && !disableMyContributionBadge && (
              <Flex justifyContent="flex-end" pt="s04" px="s04">
                <Card bgColor="yellow400" borderRadius="2xl" px="s02" py="0.125rem">
                  <Text fontSize="md" fontWeight="bold">
                    {i18n.t('contribution', 'contributionCard.myContributionLabel.text')}
                  </Text>
                </Card>
              </Flex>
            )}
            {moduleTitle && moduleType && !similarContentLevel && isDispatched && (
              <Absolute bottom={0} left={0} right={0}>
                <ContributionCardLabel title={moduleTitle} type={moduleType} />
              </Absolute>
            )}
          </Thumbnail>
        </Relative>
      </UnstyledLink>
    );

    const timestamp = (
      <Text color="textLight" fontSize="sm" letterSpacing="tight">
        <Time dateTime={contribution.dateCreated} />
      </Text>
    );

    const heading = (
      <Heading id={id} my="s02" variant="heading.300">
        <Link color="textDark" dir="auto" to={location}>
          {truncateText(contribution.title, 50)}
        </Link>
      </Heading>
    );

    let content: ReactNode = null;
    let parent: ReactNode = null;
    let additionalContent: ReactNode = null;

    if (contribution.description) {
      content = (
        <Text fontSize="md" lineHeight="snug">
          <bdi>{truncateText(contribution.description, 150)}</bdi>
        </Text>
      );
    }

    if (showChallenge && contribution.originatingModuleTitle) {
      parent = (
        <Text
          color="textLight"
          fontSize="sm"
          lineHeight="tight"
          title={contribution.originatingModuleTitle}
        >
          <Flex css={{ whiteSpace: 'nowrap' }}>
            {i18n.t('challenge', 'ideationModule.label')}:&nbsp;
            <Link
              dir="auto"
              display="block"
              fontWeight="normal"
              overflow="hidden"
              textOverflow="ellipsis"
              to={getModulePage(contribution.originatingModuleId)}
            >
              {contribution.originatingModuleTitle}
            </Link>
          </Flex>
        </Text>
      );
    }

    if (similarContentLevel) {
      additionalContent = <SimilarContentBadge fontSize="sm" level={similarContentLevel} />;
    }

    const meta = <ContributionCardMeta contribution={contribution} onVote={onVote} />;

    return (
      <ContributionCardLayout
        ref={ref}
        aria={aria}
        slots={{
          additionalContent,
          content,
          heading,
          meta,
          parent,
          thumbnail,
          timestamp
        }}
      />
    );
  }
);

const MemoContributionCard = memo<Props>(ContributionCard);

export { MemoContributionCard as ContributionCard };
