import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, queryOptions, userRemovalRequestKeys } from '../utils';
import { GetUserRemovalRequest } from './graphql/GetUserRemovalRequest.gql';

export function getUserRemovalRequestQueryOptions(params: Params<'requestId'>) {
  return queryOptions({
    queryKey: userRemovalRequestKeys.requestById(getRouteParam(params.requestId)),
    queryFn: ({ queryKey: [{ requestId }] }) => fetchDocument(GetUserRemovalRequest, { requestId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function userRemovalRequestLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getUserRemovalRequestQueryOptions(params));
}
