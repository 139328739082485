import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, queryOptions, userKeys } from '../utils';
import { GetEditUserFormData } from './graphql/GetEditUserFormData.gql';

export function getEditUserFormDataQueryOptions(params: Params<'userId'>) {
  return queryOptions({
    queryKey: userKeys.userById_edit(getRouteParam(params.userId)),
    queryFn: ({ queryKey: [{ userId }] }) => fetchDocument(GetEditUserFormData, { userId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function editUserFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getEditUserFormDataQueryOptions(params));
}
