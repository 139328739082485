import { lazy, useState } from 'react';
import { useI18n } from '@/components/I18n';
import type { ParsedMember } from '@/types';
import { hasValue } from '@/utils/common';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import { GridModal } from '../../components/GridModal';
import type { Community, SortOrder } from './GlobalContributionGridViewContainer';

const GlobalContributionGridViewContainer = lazy(async () => ({
  default: (await import('./GlobalContributionGridViewContainer')).GlobalContributionGridViewContainer // prettier-ignore
}));

type Props = {
  communityFilter?: Community[];
  creatorOrInventorFilter?: ParsedMember[];
  initialSortOrder?: SortOrder;
  onRequestClose?: () => void;
};

export const GlobalContributionGridViewModal = ({
  communityFilter,
  creatorOrInventorFilter,
  initialSortOrder,
  onRequestClose
}: Props) => {
  const i18n = useI18n();
  const [id, aria] = useHeadingIdAriaPair();
  const [count, setCount] = useState<number>();

  let accessibleItemCountText;

  if (hasValue(count)) {
    accessibleItemCountText = i18n.t('contribution', 'contributionCount.text', { count });
  }

  return (
    <GridModal aria={aria} onRequestClose={onRequestClose}>
      <GridModal.Header
        accessibleItemCountText={accessibleItemCountText}
        id={id}
        itemCount={count}
        onCloseClick={onRequestClose}
      >
        {i18n.t('contribution', 'contribution.label_multiple')}
      </GridModal.Header>
      <GridModal.Content>
        <GlobalContributionGridViewContainer
          columnCount={{ _: 1, sm: 2, md: 3, lg: 4, xl: 5, xxl: 6 }}
          communityFilter={communityFilter}
          creatorOrInventorFilter={creatorOrInventorFilter}
          gap={{ _: 's04', lg: 's06', xl: 's08' }}
          initialPageSize={42}
          initialSortOrder={initialSortOrder}
          onCountChange={setCount}
        />
      </GridModal.Content>
    </GridModal>
  );
};
