import type { ReactNode } from 'react';
import { css, Global } from '@emotion/react';
import { FocusScope } from '@react-aria/focus';
import { Box, Fixed } from '@/components/primitives';

type Props = {
  bgColor?: string;
  children: ReactNode;
  isOpen: boolean;
};

const wrapperStyle = css({ transition: 'width 250ms ease-in-out' });

// We need to disable scrolling on both the body and the application root
// element in order to not have global scroll interfere with the inner scroll of
// the sidebar. Currently doing it by injecting a global class, might find a
// better solution in the future.
const globalStyle = css({
  html: {
    overflow: 'hidden'
  },
  body: {
    overflow: 'hidden'
  }
});

export const Sidebar = ({ bgColor, children, isOpen }: Props) => (
  <Fixed
    bgColor={bgColor}
    css={wrapperStyle}
    height="100vh"
    left={0}
    overflowX="hidden"
    overflowY="auto"
    top={0}
    width={isOpen ? '100vw' : 0}
  >
    {isOpen && (
      <>
        <Global styles={globalStyle} />
        <FocusScope autoFocus contain>
          {/**
           * Wrapping the content in a 100vw container in order to prevent the
           * content wrapping while the sidebar is sliding in or out. This is
           * also the reason why the top-level element has an
           * "overflow-x: hidden".
           */}
          <Box width="100vw">{children}</Box>
        </FocusScope>
      </>
    )}
  </Fixed>
);
