import { type RefObject, useContext, useLayoutEffect, useRef } from 'react';
import { useLogger } from '@/utils/hooks/useLogger';
import { usePrevious } from '@/utils/hooks/usePrevious';
import { GuidedFocusContext, type RequestFocusOptions } from './GuidedFocusManager';

export type ShouldFocusAfterValueChangeHandler<T> = (value: T, previousValue: T | null) => boolean;

const NO_TARGET_MSG =
  'Cannot request focus: target element not found. Perhaps the ref returned by this hook was not put on an element?';

/**
 * A hook that watches a value and calls a handler when the value changes. The
 * return value of this handler determines whether focus will be requested or
 * not. A default (simple) handler is implemented, but a custom handler can be
 * provided for more advanced scenarios.
 * @param debugName Name used for debugging purposes.
 * @param watchedValue Value that will trigger `onChange` when changed.
 * @param onChange Handler that is executed when `watchedValue` changes. The
 * return value determines whether focus should be requested or not.
 * @param options Options that are forwarded to the focus request.
 * @returns A reference to be put on an element that should receive focus.
 */
export function useFocusHandler<TValue, TElement extends HTMLElement = HTMLDivElement>(
  debugName: string,
  watchedValue: TValue,
  onChange: ShouldFocusAfterValueChangeHandler<TValue>,
  options: RequestFocusOptions = {}
): RefObject<TElement> {
  const logger = useLogger(`useFocusHandler(${debugName})`);
  const { requestFocus } = useContext(GuidedFocusContext);
  const previousWatchedValue = usePrevious(watchedValue);
  const targetRef = useRef<TElement | null>(null);
  const onChangeRef = useRef(onChange);

  useLayoutEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  const { delayInMs, lookupNestedTarget, scrollBehavior, stopPropagation } = options;

  useLayoutEffect(() => {
    if (watchedValue !== previousWatchedValue) {
      const shouldRequestFocus = onChangeRef.current(watchedValue, previousWatchedValue);

      if (shouldRequestFocus && targetRef.current) {
        requestFocus(targetRef.current, {
          delayInMs,
          lookupNestedTarget,
          scrollBehavior,
          stopPropagation
        });
      } else if (!targetRef.current) {
        logger('error', NO_TARGET_MSG);
      }
    }
  }, [
    watchedValue,
    previousWatchedValue,
    requestFocus,
    delayInMs,
    lookupNestedTarget,
    scrollBehavior,
    stopPropagation,
    logger
  ]);

  return targetRef;
}
