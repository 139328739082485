import type { FlexProps } from '@/components/primitives';
import { Skeleton } from '@/components/Skeleton/v2';
import { FeedContributionCardSkeleton } from '../FeedContributionCard/FeedContributionCardSkeleton';
import { FeedEntryLayout } from './FeedEntryLayout';

type Props = {
  py?: FlexProps['py'];
  showContent?: boolean;
};
export const FeedEntrySkeleton = ({ py, showContent = true }: Props) => (
  <FeedEntryLayout
    aria-hidden
    data-testid="placeholder"
    py={py}
    slots={{
      content: showContent ? <FeedContributionCardSkeleton /> : null,
      header: (
        <>
          <Skeleton.Text fontSize="md" lineHeight="snug" width="85%" />
          <Skeleton.Text fontSize="sm" lineHeight="snug" width="16ch" />
        </>
      ),
      iconOrActor: <Skeleton.Shape borderRadius="full" height="2.25rem" width="2.25rem" />
    }}
  />
);
