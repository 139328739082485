import type { ReactNode } from 'react';
import styled from '@emotion/styled';
import type { ColorToken } from '@/css/types';
import { getColorToken, getToken } from '@/css/utils';

// TODO: Refactor with Card & Box?

type Props = {
  backgroundColor?: ColorToken;
  children: ReactNode;
  color?: ColorToken;
};

export const RoundLabel = styled.div<Props>`
  background-color: ${props => getColorToken(props.backgroundColor ?? 'red700')};
  border-radius: 12px;
  color: ${props => getColorToken(props.color ?? 'white')};
  display: inline-block;
  font-size: ${getToken('fontSizes', 'xs')};
  font-weight: ${getToken('fontWeights', 'semibold')};
  height: 20px;
  line-height: 20px;
  min-width: 20px;
  text-align: center;
  -webkit-font-smoothing: initial;
`;
