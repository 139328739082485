import { memo, type ReactNode, Suspense, useContext, useEffect } from 'react';
import type { To } from 'react-router-dom';
import { AppShellContext } from '@/components/AppShell/AppShellContext';
import { FlexBouncyLoader } from '@/components/BouncyLoader';
import { ErrorBoundary } from '@/components/ErrorBoundary';

type Props = {
  children: ReactNode;
  hasParent?: boolean;
  parentRoute?: To | null;
  title?: string | null;
};

type StaticComponents = {
  Loader: typeof FlexBouncyLoader;
};

const Screen = ({ children, hasParent = false, parentRoute, title = null }: Props) => {
  const appShellContext = useContext(AppShellContext);

  useEffect(() => {
    appShellContext.setParent(hasParent, parentRoute ?? null);
    appShellContext.setTitle(title);
  }, [title, hasParent, parentRoute, appShellContext]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<FlexBouncyLoader />}>{children}</Suspense>
    </ErrorBoundary>
  );
};

const MemoizedScreen = memo(Screen) as unknown as StaticComponents & typeof Screen;

MemoizedScreen.Loader = FlexBouncyLoader;

export { MemoizedScreen as Screen };
