import { lazy } from 'react';
import { FormModal } from '@/components/FormModal';
import { useI18n } from '@/components/I18n';

const EditCommunityFormContainer = lazy(async () => ({
  default: (await import('./EditCommunityFormContainer')).EditCommunityFormContainer
}));

type Props = {
  communityId: string;
  onRequestClose: () => void;
  onSubmitted: () => void;
};

export const EditCommunityFormModal = ({ communityId, onRequestClose, onSubmitted }: Props) => {
  const i18n = useI18n();
  const title = i18n.t('main', 'editCommunityForm.title');

  return (
    <FormModal onRequestClose={onRequestClose} size="lg" title={title}>
      <EditCommunityFormContainer
        communityId={communityId}
        onCancel={onRequestClose}
        onSubmitted={onSubmitted}
      />
    </FormModal>
  );
};
