import { useMemo } from 'react';
import type { Aria } from '@/types';
import { useId } from './useId';

type HookReturnValue = [string, Aria];

export function useHeadingIdAriaPair(): HookReturnValue {
  const headingId = useId('heading');

  return useMemo(() => [headingId, { labelledby: headingId }], [headingId]);
}
