import { useCallback, useRef } from 'react';
import { getEmptyArray } from '../array';

export type ItemTransformer<I extends object, O> = (item: I) => O;

export type CacheableTransformer<I extends object, O> = (items: I[] | undefined) => O[];

/**
 * Takes a transform function for an individual item, gives it cache lookup
 * superpowers and returns a transform function for an array of items. By
 * returning a transform function for the whole array we can also guarantee
 * referential stability of empty arrays.
 * @param transform The transform function for an individual item.
 * @returns A cacheable transform function for an array of items.
 */
export function useCacheableTransformer<I extends object, O>(
  transform: ItemTransformer<I, O>
): CacheableTransformer<I, O> {
  const cacheRef = useRef(new WeakMap<I, O>());
  const transformRef = useRef(transform);

  // May be considered React v18 unsafe, but we need access to the most recent
  // transform function _during_ render so can't put this in a useLayoutEffect().
  transformRef.current = transform;

  return useCallback(items => {
    // Micro-optimization for referential equality purposes.
    if (!items || items.length === 0) return getEmptyArray();

    return items.map(item => {
      let value = cacheRef.current.get(item);

      if (!value) {
        value = transformRef.current(item);
        cacheRef.current.set(item, value);
      }

      return value;
    });
  }, []);
}
