import { useEffect } from 'react';
import type { i18n } from 'i18next';

type Props = {
  client: i18n;
};

function useSyncDocumentLanguageAttributes(client: i18n) {
  useEffect(() => {
    function onLanguageChanged() {
      document.documentElement.setAttribute('dir', client.dir(client.language));
      document.documentElement.lang = client.language;
    }

    // Synchronize language attributes once initially.
    onLanguageChanged();

    // Set up a listener to update the attributes on language change.
    client.on('languageChanged', onLanguageChanged);

    return () => {
      client.off('languageChanged', onLanguageChanged);
    };
  }, [client]);
}

// Normally we'd implement this as a hook, but since this needs to be used
// inside AppRoot we still need a component... For now. Will be changed soon.
// TODO: Rename this file to the hook name once component is removed.
export const DocumentLanguageAttributeManager = ({ client }: Props) => {
  useSyncDocumentLanguageAttributes(client);

  return null;
};
