import { type AnchorHTMLAttributes, forwardRef } from 'react';
import { Link as RoutedLink, type LinkProps as RoutedLinkProps, type To } from 'react-router-dom';
import styled from '@emotion/styled';
import { color, compose, display, type DisplayProps, typography } from 'styled-system';
import type { TextProps } from '@/components/primitives';
import { textWrap } from '@/components/primitives/utils';
import type { ColorToken } from '@/css/types';
import { getColorToken, getToken } from '@/css/utils';
import { css, variant } from '@/theme/utils';
import { shouldForwardProp } from './utils';

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export type LinkVariant = 'no-underline' | 'primary' | 'underline-on-hover';

type LinkBaseProps = DisplayProps &
  Omit<AnchorProps, 'color' | 'target'> &
  Omit<TextProps, 'as' | 'textStyle'> & {
    hoverColor?: ColorToken;
    target?: '_blank' | '_parent' | '_self' | '_top';
    variant?: LinkVariant;
  };

type RoutedLinkBaseProps = Omit<LinkBaseProps, 'href'> &
  Pick<RoutedLinkProps, 'reloadDocument' | 'replace' | 'state' | 'to'>;

export const baseStyle = compose(color, display, textWrap, typography);

export const fontStyle = (props: { fontWeight?: TextProps['fontWeight'] }) =>
  css({ fontWeight: props.fontWeight ?? getToken('fontWeights', 'semibold') });

export const variantStyle = ({ hoverColor }: { hoverColor?: ColorToken }) =>
  variant<LinkVariant>({
    prop: 'variant',
    variants: {
      'underline-on-hover': {
        textDecoration: 'none',
        ':hover': {
          color: hoverColor,
          textDecoration: 'underline'
        }
      },
      'no-underline': {
        textDecoration: 'none',
        ':hover': {
          color: hoverColor,
          textDecoration: 'none'
        }
      },
      primary: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: getColorToken('textDark'),
        color: getColorToken('textDark'),
        ':hover': {
          borderBottomWidth: 2,
          color: getColorToken('textDark'),
          textDecoration: 'none'
        }
      }
    }
  });

const RoutedLinkBase = styled(RoutedLink, { shouldForwardProp })<RoutedLinkBaseProps>([
  baseStyle,
  fontStyle,
  variantStyle
]);

const LinkBase = styled('a', { shouldForwardProp })<LinkBaseProps>(baseStyle, variantStyle);

export type LinkProps = Omit<RoutedLinkBaseProps, 'color' | 'hoverColor' | 'to'> & {
  /**
   * The __inherit__ value is used to refrain from setting a default color
   * here, and instead defaulting to the global stylesheet's "a" styles. This
   * fixes some issues with legacy components using (s)css files, most notably
   * the components/Button.
   */
  color?: ColorToken | '__inherit__';
  hoverColor?: ColorToken | '__inherit__';
  // Temporary workaround for state being passed to DropdownMenuItem
  locationState?: RoutedLinkBaseProps['state'];
  to?: RoutedLinkBaseProps['to'];
};

function isExternalLocation(to: To): to is string {
  return typeof to === 'string' && (to.includes('//') || to.includes(':'));
}

function isHashLink(to: To): to is string {
  return typeof to === 'string' && to.startsWith('#');
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      color: _color = 'link',
      hoverColor = 'linkHover',
      to,
      variant: _variant = 'underline-on-hover',
      ...props
    },
    ref
  ) =>
    /**
     * An `href` is *not* required on an <a>
     * @url https://stackoverflow.com/questions/10510191/valid-to-use-a-anchor-tag-without-href-attribute
     */
    !to ||
    isExternalLocation(to) ||
    isHashLink(to) ||
    // Based on https://kit.svelte.dev/docs#anchor-options-rel-external
    (typeof to === 'string' && props.rel === 'external') ? (
      <LinkBase
        {...props}
        ref={ref}
        color={_color !== '__inherit__' ? _color : undefined}
        hoverColor={hoverColor !== '__inherit__' ? hoverColor : undefined}
        href={to}
        variant={_variant}
      />
    ) : (
      <RoutedLinkBase
        {...props}
        ref={ref}
        color={_color !== '__inherit__' ? _color : undefined}
        hoverColor={hoverColor !== '__inherit__' ? hoverColor : undefined}
        state={props.state || props.locationState}
        to={to}
        variant={_variant}
      />
    )
);

Link.displayName = 'ForwardRef(Link)';
