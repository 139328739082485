import { ModalContent } from '@/components/Modal';
import type { BoxProps } from '@/components/primitives';

export const GridModalContent = (props: BoxProps) => (
  <ModalContent
    pb={{ _: 's04', sm: 's06', lg: 's08' }}
    px={{ _: 's04', sm: 's06', md: 's06', lg: 's08', xl: 's12' }}
    {...props}
  />
);
