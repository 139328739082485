import type { ElementType } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  borderRadius,
  compose,
  fontSize,
  layout,
  type LayoutProps,
  lineHeight,
  margin,
  width,
  type WidthProps
} from 'styled-system';
import type {
  BorderRadiusProps,
  FontSizeProps,
  LineHeightProps,
  MarginProps
} from '../../primitives/types';

type BaseProps = {
  as?: ElementType;
  isAnimated?: boolean;
};

type TextProps = BaseProps & FontSizeProps & LineHeightProps & MarginProps & WidthProps;

const pulse = keyframes`
  0% { opacity:   1; }
 50% { opacity: 0.4; }
100% { opacity:   1; }
`;

const Text = styled('div', { shouldForwardProp })<TextProps>(
  ({ isAnimated = true }) => ({
    animation: isAnimated ? `${pulse} 1.5s ease-in-out 0.5s infinite` : undefined
  }),
  {
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    height: 'auto',
    marginTop: 0,
    marginBottom: 0,
    transformOrigin: '0px 55%',
    transform: 'scale(1, 0.75)',
    borderRadius: '0.25rem',
    '::before': { content: '"\\00a0"' }
  },
  compose(fontSize, lineHeight, width, margin)
);

type ShapeProps = BaseProps & BorderRadiusProps & LayoutProps & MarginProps;

const Shape = styled('div', { shouldForwardProp })<ShapeProps>(
  ({ isAnimated = true }) => ({
    animation: isAnimated ? `${pulse} 1.5s ease-in-out 0.5s infinite` : undefined
  }),
  {
    backgroundColor: 'rgba(0, 0, 0, 0.11)'
  },
  compose(layout, borderRadius, margin)
);

Shape.defaultProps = {
  borderRadius: 'base'
};

export const Skeleton = { Text, Shape };
