import { onError } from 'apollo-link-error';
import type { ServerError, ServerParseError } from 'apollo-link-http-common';
import type { AuthenticationClient } from '@/libs/cs-core-auth-client';

const CSRF_TOKEN_MISMATCH_REGEX = new RegExp(/CSRF Token Mismatch/i);

function getServerErrorMessage(error: ServerError) {
  return (typeof error.result.message === 'string' && error.result.message) || '';
}

function isCSRFTokenMismatchError(error: ServerError | ServerParseError) {
  return (
    // ServerError
    ('result' in error && CSRF_TOKEN_MISMATCH_REGEX.test(getServerErrorMessage(error))) ||
    // ServerParseError
    ('bodyText' in error && CSRF_TOKEN_MISMATCH_REGEX.test(error.bodyText))
  );
}

export const createAuthenticationLink = (client: AuthenticationClient, debug = false) =>
  onError(({ networkError }) => {
    if (networkError && 'statusCode' in networkError) {
      if (networkError.statusCode === 401) {
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('[AuthenticationLink] Received a 401 response, redirecting to login.');
        }

        client.login();
      } else if (networkError.statusCode === 403 && isCSRFTokenMismatchError(networkError)) {
        if (debug) {
          // eslint-disable-next-line no-console
          console.log('[AuthenticationLink] CSRF token no longer valid, refreshing the page.');
        }

        window.location.reload();
      }
    }
  });
