import { type ReactNode, useMemo } from 'react';
import { createPath, useLocation } from 'react-router-dom';
import { css } from '@emotion/react';
import { useFocusRing } from '@react-aria/focus';
import { useJargon } from '@/components/AppConfig';
import { Badge } from '@/components/Badge';
import { Heading } from '@/components/Heading';
import { I18nTrans, lookupJargonTranslation, useI18n } from '@/components/I18n';
import { Icon } from '@/components/Icon';
import { Link } from '@/components/Link';
import { Absolute, Flex, Text } from '@/components/primitives';
import { ProgressBarWithSegments } from '@/components/ProgressBarWithSegments';
import { RelativeTime } from '@/components/Time';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import { getColorToken, getToken } from '@/css/utils';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { getLabelByTaskType, getTaskProgressText } from '../../helpers';
import { TaskCardLayout } from './TaskCardLayout';

function getPercentage(count: number, totalCount: number) {
  return totalCount > 0 ? Math.round((count / totalCount) * 100) : 0;
}

export type Task = {
  id: string;
  completedCount: number;
  deadline: string | null;
  program: { id: string; title: string } | null;
  rejectedCount: number;
  subject: { id: string; title: string } | null;
  status: Api.TaskState;
  totalCount: number;
  type: Api.TaskType;
};

type Props = {
  task: Task;
};

const cardStyle = css({
  transition: 'box-shadow 100ms ease-in-out, transform 100ms ease-in-out',
  ':hover': {
    boxShadow: getToken('shadows', 'lg'),
    transform: 'translate(2px, -2px)'
  }
});

const linkStyle = css({ ':focus': { outline: 'none' } });

export const TaskCard = ({ task }: Props) => {
  const i18n = useI18n();
  const from = createPath(useLocation());
  const [id, aria] = useHeadingIdAriaPair();
  const locations = useSharedLocations('main');
  const { isFocusVisible, isFocused, focusProps } = useFocusRing();
  const programLabel = lookupJargonTranslation<'program'>(useJargon().program, {
    PROGRAM: () => i18n.t('main', 'program.label')
  });

  const outlineStyle = useMemo(() => css({ outline: `2px solid ${getColorToken('black')}` }), []);

  const segments = [
    {
      color: getColorToken('success'),
      percent: getPercentage(task.completedCount, task.totalCount)
    },
    {
      color: getColorToken('danger'),
      percent: getPercentage(task.rejectedCount, task.totalCount)
    },
    {
      percent: getPercentage(
        task.totalCount - task.completedCount - task.rejectedCount,
        task.totalCount
      )
    }
  ];

  let moduleTaskListLocation = null;

  if (task.subject) {
    if (task.type === 'REVIEW') {
      moduleTaskListLocation = locations.getReviewModuleTaskListPage(task.subject.id);
    } else if (task.type === 'ENRICHMENT' || task.type === 'ENRICHMENT_APPROVAL') {
      moduleTaskListLocation = locations.getEnrichmentModuleTaskListPage(task.subject.id);
    }
  }

  const taskLabel = getLabelByTaskType(i18n, task.type);

  // Normally every task should have a subject. However, if this is not the case
  // (it is nullable after all) we'll use the task label as heading just to have
  // _something_.
  let heading: ReactNode = task.subject?.title ?? taskLabel;

  if (moduleTaskListLocation) {
    // Nesting a link inside the header that will cover the whole card. We can't
    // wrap it around the card since nesting anchors inside anchors is invalid
    // HTML, and also messes with a11y.
    // Due to this approach, any nested links *do* need to be positioned
    // relatively in order to still be interactive.
    heading = (
      <Link
        {...focusProps}
        color="textDark"
        css={linkStyle}
        state={{ from }}
        to={moduleTaskListLocation}
        variant="no-underline"
      >
        <Absolute
          bottom={0}
          css={isFocusVisible && isFocused ? outlineStyle : undefined}
          left={0}
          right={0}
          top={0}
          zIndex={0}
        />
        {heading}
      </Link>
    );
  }

  heading = (
    <Heading
      css={{ whiteSpace: 'nowrap' }}
      id={id}
      mb="s03"
      overflow="hidden"
      textOverflow="ellipsis"
      variant="heading.400"
    >
      {heading}
    </Heading>
  );

  const deadline = (
    <Flex alignItems="center" gap="s02">
      <Icon color="grey400" size={16} type="flag" />
      <Text color="textLight" fontSize="md" lineHeight="none">
        {task.deadline ? (
          <I18nTrans
            components={{
              time: <RelativeTime dateTime={task.deadline} options={{ addSuffix: true }} />
            }}
            i18nKey="dateTime.due.text"
            ns="common"
            values={{ time: null }}
          />
        ) : (
          i18n.t('common', 'dateTime.noDueDate.text')
        )}
      </Text>
    </Flex>
  );

  const progressText = (
    <>
      <VisuallyHidden>
        {getTaskProgressText(
          i18n,
          task.completedCount + task.rejectedCount,
          task.totalCount,
          task.type
        )}
      </VisuallyHidden>
      <Flex alignItems="center" aria-hidden gap="s02">
        <Icon size={14} type="check-circle" />
        <Text color="textLight" fontSize="sm">
          {task.completedCount + task.rejectedCount}/{task.totalCount}
        </Text>
      </Flex>
    </>
  );

  const status = (
    <>
      {task.status === 'INCOMPLETE' && (
        <Badge color={task.completedCount > 0 ? 'green' : 'grey'} fontSize="sm" noMarginX noMarginY>
          {task.completedCount > 0
            ? i18n.t('main', 'incompleteTaskStatus.label_inProgress')
            : i18n.t('main', 'incompleteTaskStatus.label_notStarted')}
        </Badge>
      )}
    </>
  );

  const parent = (
    <Text
      color="textLight"
      css={{ whiteSpace: 'nowrap' }}
      fontSize="md"
      lineHeight="tight"
      overflow="hidden"
      textOverflow="ellipsis"
    >
      {task.program ? (
        <>
          {programLabel}:&nbsp;
          <Text as="bdi" fontWeight="semibold" title={task.program.title}>
            {task.program.title}
          </Text>
        </>
      ) : (
        <>&nbsp;</>
      )}
    </Text>
  );

  const progressBar = (
    <ProgressBarWithSegments borderRadius="none" height="4px" segments={segments} />
  );

  const type = (
    <Text color="textLight" fontSize="sm">
      {taskLabel}
    </Text>
  );

  return (
    <TaskCardLayout
      aria-labelledby={aria.labelledby}
      css={cardStyle}
      slots={{
        deadline,
        heading,
        parent,
        progressBar,
        progressText,
        status,
        type
      }}
    />
  );
};
