import { useEffect, useRef, useState } from 'react';
import { Box, Fixed, Flex, Text } from '@/components/primitives';
import { useNetworkStatus } from '@/utils/hooks/useNetworkStatus';

// TODO: i18n
const ONLINE_TEXT = 'You are connected!';
const OFFLINE_TEXT = 'You are offline, please check your internet connection.';

export const NetworkStatusAlert = () => {
  const { isOnline } = useNetworkStatus();
  const [isVisible, setIsVisible] = useState(!isOnline);
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (!isOnline) {
      setIsVisible(true);
    } else {
      // TODO: Can do this with a CSS transition instead.
      timerRef.current = window.setTimeout(() => {
        setIsVisible(false);
        timerRef.current = null;
      }, 2000);
    }

    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isOnline]);

  if (isVisible) {
    return (
      <Fixed bottom={0} left={0} right={0} zIndex={100}>
        <Flex justifyContent="center">
          <Box bgColor="neutral900" maxWidth={600} px="s06" py="s04">
            <Text color="white">{isOnline ? ONLINE_TEXT : OFFLINE_TEXT}</Text>
          </Box>
        </Flex>
      </Fixed>
    );
  }

  return null;
};
