import { Skeleton } from '@/components/Skeleton/v2';
import { ContributionCardMetaSkeleton } from '@/features/contributions/components/ContributionCard';
import { FeedContributionCardLayout } from './FeedContributionCardLayout';

export const FeedContributionCardSkeleton = () => (
  <FeedContributionCardLayout
    slots={{
      description: (
        <>
          <Skeleton.Text fontSize="md" lineHeight="snug" width="100%" />
          <Skeleton.Text fontSize="md" lineHeight="snug" width="100%" />
          <Skeleton.Text fontSize="md" lineHeight="snug" width="20ch" />
        </>
      ),
      header: <Skeleton.Text lineHeight="tight" mb={{ _: 's02', md: 's03' }} width="40ch" />,
      meta: <ContributionCardMetaSkeleton />,
      thumbnail: (
        <Skeleton.Shape
          borderRadius="none"
          height={{ _: '4.5rem', md: '8.25rem' }}
          width={{ _: '6rem', md: '11rem' }}
        />
      )
    }}
  />
);
