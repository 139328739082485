import { debounce } from './debounce';

/**
 * This code is mostly taken from downshift:
 * https://github.com/downshift-js/downshift/blob/master/src/set-a11y-status.js
 * We're also reusing the same status div if it already exists (same id).
 */

const cleanupStatus = debounce(
  (mode: Mode) => {
    getStatusDiv(mode).textContent = '';
  },
  process.env.NODE_ENV === 'test' ? 50 : 500
);

type Mode = 'assertive' | 'polite';

/**
 * @param {String} status the status message
 */
export function setStatus(status: string, mode: Mode = 'polite') {
  const div = getStatusDiv(mode);

  if (!status) return;
  div.textContent = status;
  cleanupStatus(mode);
}

/**
 * Get the status node or create it if it does not already exist.
 * @return {HTMLElement} the status node.
 */
function getStatusDiv(mode: Mode) {
  const id = `cs-a11y-status-${mode}`;

  let statusDiv = document.getElementById(id);

  if (statusDiv) return statusDiv;

  statusDiv = document.createElement('div');

  statusDiv.setAttribute('id', id);
  statusDiv.setAttribute('role', 'status');
  statusDiv.setAttribute('aria-live', mode);
  statusDiv.setAttribute('aria-atomic', 'true');
  statusDiv.setAttribute('aria-relevant', 'additions text');
  statusDiv.setAttribute('data-testid', 'announcer');

  Object.assign(statusDiv.style, {
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px'
  });

  document.body.appendChild(statusDiv);

  return statusDiv;
}
