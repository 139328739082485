import styled from '@emotion/styled';

export const UnstyledButton = styled('button')({
  background: 'none',
  border: 'none',
  cursor: 'pointer', // Not sure this should be in here.
  margin: 0,
  padding: 0,
  textAlign: 'initial'
});
