import type * as Types from '@/typings/api.generated.d';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type RemoveCommunityFromFavoritesMutationVariables = Types.Exact<{
  communityId: Types.Scalars['GUID']['input'];
}>;


export type RemoveCommunityFromFavoritesMutation = { removeCommunityFromFavorites: { __typename: 'MutationResponse', correlationId: Types.Scalars['String']['output'], subjectId: Types.Scalars['GUID']['output'] | null } | null };


export const RemoveCommunityFromFavorites = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveCommunityFromFavorites"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"communityId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeCommunityFromFavorites"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"communityId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"communityId"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"MutationResponseFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"MutationResponseFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"MutationResponse"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"correlationId"}},{"kind":"Field","name":{"kind":"Name","value":"subjectId"}}]}}]} as unknown as DocumentNode<RemoveCommunityFromFavoritesMutation, RemoveCommunityFromFavoritesMutationVariables>;