import { ApolloLink, Observable } from 'apollo-link';
import type {
  DefinitionNode,
  DocumentNode,
  OperationDefinitionNode,
  OperationTypeNode
} from 'graphql-v15';
import { UserNotAuthorizedError } from '../errors';

function isOperationDefinitionNode(node: DefinitionNode): node is OperationDefinitionNode {
  return node.kind === 'OperationDefinition';
}

function getOperationType(document: DocumentNode): OperationTypeNode | undefined {
  return document.definitions.slice(0).reverse().find(isOperationDefinitionNode)?.operation;
}

export function createAuthorizationErrorLink() {
  return new ApolloLink(
    (operation, forward) =>
      new Observable(observer => {
        const sub = forward(operation).subscribe({
          next: response => {
            // "extensions" can in fact be undefined depending on the GraphQL server implementation.
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const error = response.errors?.find(e => e.extensions?.code === 'USER_AGREEMENT_GUARD');

            if (error) {
              const resourceId = error.extensions.subjectId || null;
              const type = getOperationType(operation.query) || null;
              const name = operation.operationName;

              /**
               * We fail the request to avoid some potential issues caused by previously cached data
               * in the apollo cache being nullified by this response. This would in turn trigger
               * rerenders of components that were previously rendered with data, but now receive
               * `null`. This could in turn drastically affect a previously rendered page.
               *
               * To give one example scenario:
               *
               *  1. User visits contribution page, no UA exists and contribution is loaded,
               *     displayed and also cached by apollo.
               *
               *  2. Admin adds a user agreement.
               *
               *  3. User clicks the "Edit contribution" button.
               *
               *  4. Apollo fetches the GetEditContributionForm query, contribution is suddenly
               *     returned as `null`.
               *
               *  5. Apollo updates its normalized cache and removes previously cached data for the
               *     contribution.
               *
               *  6. A rerender of the contribution page is triggered automatically since
               *     `useQuery()` automatically subscribes to any data changes.
               *
               *  7. Page is now blank with a "contribution not found" alert. The modal is
               *     immediately closed as its not being rendered in the "not found" code path.
               *
               * Obviously this is bad UX, and this can happen everywhere. The only way to prevent
               * it is by preventing the apollo cache from being updated whenever this scenario
               * could possibly occur. This can be done one of two ways:
               *
               *  1. Configure _every_ query as `fetchPolicy: "no-cache"`.
               *
               *  2. Prevent `null` data from being written away to the cache when we detect that a
               *     "USER_AGREEMENT_GUARD" error was returned in the response.
               *
               * Approach #1 is a drastic measure that would require changing hundreds of queries
               * and which would also prevent us from using apollo's key feature: the normalized
               * cache. This makes approach #2 the only viable solution. Unfortunately, the safest
               * way to implement #2 is by failing the whole request, which we're doing in this
               * custom link.
               */
              observer.error(new UserNotAuthorizedError(resourceId, name, type));
            } else {
              observer.next(response);
            }
          },
          error: error => {
            observer.error(error);
          },
          complete: () => {
            observer.complete();
          }
        });

        return () => {
          sub.unsubscribe();
        };
      })
  );
}
