import { type ComponentProps, type ElementType, forwardRef, type ReactNode } from 'react';
import { useI18n } from '@/components/I18n';
import { Absolute, Card } from '@/components/primitives';
import { AnimatedDropdownWrapper } from './AnimatedDropdownWrapper';
import { DropdownCaret } from './DropdownCaret';
import type { DropdownAlign } from './types';

export type DropdownProps = {
  WrapperComponent?: ElementType<ComponentProps<typeof AnimatedDropdownWrapper>>;
  align?: DropdownAlign;
  caretOffset?: number;
  children?: ReactNode;
  isVisible?: boolean;
  maxWidth?: string;
  offsetX?: number;
  showCaret?: boolean;
  width?: string;
};

export const DropdownBase = forwardRef<HTMLDivElement, DropdownProps>(
  (
    {
      WrapperComponent = AnimatedDropdownWrapper,
      align: _align = 'bottom-start',
      caretOffset = 8,
      children,
      isVisible = false,
      maxWidth,
      offsetX,
      showCaret = true,
      width
    },
    ref
  ) => {
    const { isRtl } = useI18n();
    const marginTop = showCaret ? 10 : 4;
    let align = _align;
    let maybeCaret: ReactNode;

    if (isRtl) {
      align = align === 'bottom-end' ? 'bottom-start' : 'bottom-end';
    }

    if (showCaret) {
      const leftPx = align === 'bottom-start' ? 0 : undefined;
      const rightPx = align === 'bottom-end' ? 0 : undefined;

      maybeCaret = (
        <Absolute data-testid="caret-container" left={leftPx} mx={caretOffset} right={rightPx}>
          <DropdownCaret />
        </Absolute>
      );
    }

    return (
      <WrapperComponent
        ref={ref}
        align={align}
        isVisible={isVisible}
        marginTop={marginTop}
        maxWidth={maxWidth}
        offsetX={offsetX}
        width={width}
      >
        {maybeCaret}
        <Card bgColor="white" border={1} borderColor="neutral200" borderRadius="md" boxShadow="md">
          {children}
        </Card>
      </WrapperComponent>
    );
  }
);
