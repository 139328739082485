import { ProgressBarWithSegments } from '@/components/ProgressBarWithSegments';
import { Skeleton } from '@/components/Skeleton/v2';
import { TaskCardLayout } from './TaskCardLayout';

export const TaskCardSkeleton = () => (
  <TaskCardLayout
    aria-hidden
    slots={{
      deadline: <Skeleton.Text fontSize="md" lineHeight="none" width="15ch" />,
      status: <Skeleton.Text fontSize="sm" lineHeight="relaxed" width="12ch" />,
      heading: <Skeleton.Text fontSize="xl" lineHeight="tight" mb="s03" width="20ch" />,
      parent: <Skeleton.Text fontSize="md" lineHeight="tight" width="40ch" />,
      progressBar: <ProgressBarWithSegments borderRadius="none" height="4px" segments={[]} />,
      progressText: <Skeleton.Text fontSize="md" width="5ch" />,
      type: <Skeleton.Text fontSize="md" lineHeight="tight" width="8ch" />
    }}
  />
);
