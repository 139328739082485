import { Alert } from '../Alert';
import { useI18n } from '../I18n';
import { Text } from '../primitives';

export const ResourceNotFoundErrorAlert = () => {
  const i18n = useI18n();

  return (
    <Alert role="alert" swatchColor="red">
      <Text fontSize="md" fontWeight="semibold">
        {i18n.t('common', 'resourceError.resourceNotFound.text')}
      </Text>
    </Alert>
  );
};
