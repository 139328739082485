import * as css from './AppError.styles';

// We can't use any primitive components here as this is rendered outside of a
// theme context

export const AppError = () => (
  <div css={css.overlay}>
    <div css={css.container}>
      <div css={css.content}>
        <h1 css={css.h1}>Oops…</h1>
        <h2 css={css.h2}>Something went wrong.</h2>
        <p css={css.info}>
          <br />
          An error occurred while loading the application. This might be because it is temporarily
          unavailable, or because something went wrong logging you in. Please contact an
          administrator if this problem persists.
        </p>
      </div>
    </div>
  </div>
);
