import { memo, type MouseEventHandler, type ReactChild, type ReactNode } from 'react';
import type { To } from 'react-router-dom';
import type { IconType } from '@/components/Icon';
import type { ColorToken } from '@/css/types';
import { DashboardFilterListItemIcon } from './DashboardFilterListItemIcon';
import { DashboardFilterListItemLayout } from './DashboardFilterListItemLayout';
import { DashboardFilterListItemLink } from './DashboardFilterListItemLink';

type Props = {
  children: ReactChild;
  contentAfter?: ReactNode;
  icon: IconType;
  iconBgColor: ColorToken;
  isSelected: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  to: To;
};

export const DashboardFilterListItem = memo<Props>(
  ({ children, contentAfter, icon, iconBgColor, isSelected, onClick, to }) => (
    <DashboardFilterListItemLayout
      slots={{
        content: (
          <DashboardFilterListItemLink isSelected={isSelected} onClick={onClick} to={to}>
            {children}
          </DashboardFilterListItemLink>
        ),
        contentAfter,
        contentBefore: <DashboardFilterListItemIcon bgColor={iconBgColor} icon={icon} />
      }}
    />
  )
);
