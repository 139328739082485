import { forwardRef, type ReactNode, useContext } from 'react';
import { useFocusRing } from '@react-aria/focus';
import { getBoolean } from '@/utils/store';
import { HeadingBase, type HeadingProps } from './HeadingBase';
import { HeadingContext } from './HeadingContext';
import { getTagFromLevel, getVariantFromLevel, type HeadingVariant } from './utils';

type Props = Omit<HeadingProps, 'as' | 'variant'> & {
  children: ReactNode;
  className?: string;
  tabIndex?: number;
  variant?: HeadingVariant;
};

/**
 * This Heading component is supposed to be used in conjunction with the
 * sectioning elements defined in components/SectioningContentElement.
 * The implementation of both roughly follows the proposed (but never
 * implemented) HTML5 Document Outline Algorithm (see links below). This was a
 * proposal that would enable the resetting and auto-incrementing of headings
 * within predefined sectioning elements (section, nav, aside, article), meaning
 * that:
 *
 *   1. Within each sectioning content element headings can start again from
 *      level 1. This makes it easier to nest HTML templates since you don't
 *      need to know their nesting level ahead of time.
 *   2. You could in theory use <h1> everywhere and the browser would infer the
 *      correct level based on its position within each section, and the
 *      position of each section within the document.
 *
 * The underlying principles are very interesting for SPAs but unfortunately
 * the spec was never finalized and also has no support in screen readers. We
 * can try to somewhat mimic its behavior in React though, which is what we did
 * with the Heading and the SectioningContentElement components.
 *
 * Before continuing on, have a look at these links to get a better
 * understanding of the HTML5 document outline algorithm:
 *
 * @url https://html.spec.whatwg.org/multipage/dom.html#sectioning-content
 * @url https://webdesign.tutsplus.com/articles/the-truth-about-multiple-h1-tags-in-the-html5-era--webdesign-16824
 * @url https://www.smashingmagazine.com/2011/08/html5-and-the-document-outlining-algorithm/
 * @url https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/Using_HTML_sections_and_outlines
 *
 * The 4 sectioning element components in components/SectioningContentElement
 * all hook into the HeadingContext, and automatically increment the heading
 * level. The Heading component below taps into this context and uses the
 * current heading level as its own heading level. This means that by combining
 * these 2 our headings will automatically increment and we don't need to
 * specify any explicit levels anymore. We can however specify style/variant
 * overrides if necessary.
 * This also means that we correctly need to use the sectioning content
 * elements, which in addition forces us to write semantic html and correctly
 * define <sections> and <articles>. Honestly also an advantage because we tend
 * to be too careless with regards to semantic markup.
 * Lastly, this means that we should only ever have 1 heading per section. This
 * is already considered to be good/best practice but often ignored. What about
 * subheadings? Well, W3C says this:
 *
 *   > h1–h6 elements must not be used to markup subheadings, subtitles,
 *   > alternative titles and taglines unless intended to be the heading for a
 *   > new section or subsection.
 *
 * Which kind of answers that question. If we need a "visual" subheading we can
 * either nest it inside the Heading and override the styling, or we can just
 * use a <Text> below the heading and style it accordingly.
 *
 * To see this in use, have a look at ./Heading.stories.tsx.
 */

const debugA11y = getBoolean('isA11yDebugEnabled');

export const Heading = forwardRef<HTMLHeadingElement, Props>(({ variant, ...props }, ref) => {
  const level = useContext(HeadingContext);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <HeadingBase
      {...props}
      {...(focusProps as HeadingProps)}
      ref={ref}
      as={getTagFromLevel(level)}
      showOutline={isFocusVisible || debugA11y}
      tabIndex={-1}
      variant={variant ?? getVariantFromLevel(level)}
    />
  );
});
