import { lazy, Suspense, useEffect } from 'react';
import type { UserNotAuthorizedError } from '@/utils/errors';
import { useEventEmitter } from '@/utils/hooks/useEventEmitter';
import { useToggle } from '@/utils/hooks/useToggle';
import { Button } from '../Button';
import { Heading } from '../Heading';
import { useI18n } from '../I18n';
import { Flex, Spacer, Text } from '../primitives';

const CurrentUserBlockingUserAgreementsForSubjectModal = lazy(async () => ({
  default: (await import('@/features/user-agreements/main/components/CurrentUserBlockingUserAgreementsForSubjectModal')).CurrentUserBlockingUserAgreementsForSubjectModal // prettier-ignore
}));

type Props = {
  error: UserNotAuthorizedError;
  onRetry: () => void;
};

export const UserNotAuthorizedErrorBoundaryContent = ({ error, onRetry }: Props) => {
  const i18n = useI18n();
  const emitter = useEventEmitter();
  const [isVisible, { on: showModal, off: hideModal }] = useToggle();

  function hideModalAndRetry() {
    hideModal();
    onRetry();
  }

  useEffect(() => {
    emitter.on('userAgreementSubmissionCompleted', onRetry);
    return () => emitter.off('userAgreementSubmissionCompleted', onRetry);
  }, [emitter, onRetry]);

  return (
    <>
      <Suspense fallback={null}>
        {isVisible && (
          <CurrentUserBlockingUserAgreementsForSubjectModal
            headingText={i18n.t(
              'common',
              'resourceError.userNotAuthorized.userAgreementList.heading'
            )}
            onRequestClose={hideModalAndRetry}
            onSubmissionCompleted={hideModalAndRetry}
            subjectId={error.resourceId}
          />
        )}
      </Suspense>
      <Flex
        alignItems="center"
        flex={1}
        flexDirection="column"
        justifyContent="center"
        p="s06"
        role="alert"
      >
        <Heading mb="s04" variant="heading.700">
          {i18n.t('common', 'resourceError.userNotAuthorized.heading')}
        </Heading>
        <Text color="textLight" textAlign="center">
          {i18n.t('common', 'resourceError.userNotAuthorized.text')}
        </Text>
        <Spacer mb="s06" />
        <Button onClick={showModal} type="button" variant="primary">
          {i18n.t('common', 'resourceError.userNotAuthorized.showUserAgreementsButton.text')}
        </Button>
      </Flex>
    </>
  );
};
