import { useContext } from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';
import { TabBase, type TabBaseProps } from '../Tabs/TabBase';
import { TabContent, type TabContentProps } from '../Tabs/TabContent';
import { TabNavContext } from './TabNavContext';

const TabNavLinkBase = TabBase.withComponent(NavLink);

export type TabNavLinkProps = Omit<TabBaseProps, 'borderWidth'> &
  Pick<
    NavLinkProps,
    'caseSensitive' | 'end' | 'onClick' | 'preventScrollReset' | 'replace' | 'to'
  > &
  TabContentProps & {
    borderWidth?: TabBaseProps['borderWidth'];
  };

export const TabNavLink = ({
  badge,
  badgeCount,
  borderWidth = 3,
  caseSensitive,
  children,
  end,
  fontSize,
  onClick,
  // We can safely assume that in the majority of use-cases we do *not* want the
  // page to scroll to the top when clicking on a TabNavLink.
  preventScrollReset = true,
  py = 's03',
  replace,
  to,
  ...props
}: TabNavLinkProps) => {
  const context = useContext(TabNavContext);

  return (
    <TabNavLinkBase
      {...props}
      borderWidth={borderWidth}
      caseSensitive={caseSensitive}
      end={end}
      fontSize={fontSize}
      onClick={event => {
        context.onClick?.(event);
        onClick?.(event);
      }}
      preventScrollReset={preventScrollReset}
      py={py}
      replace={replace}
      to={to}
    >
      <TabContent {...({ badge, badgeCount, children } as TabContentProps)} />
    </TabNavLinkBase>
  );
};
