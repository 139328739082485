import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, queryOptions, webhookKeys } from '../utils';
import { GetEditWebhookFormData } from './graphql/GetEditWebhookFormData.gql';

export const getEditWebhookFormDataQueryOptions = (params: Params<'webhookId'>) =>
  queryOptions({
    queryKey: webhookKeys.webhookById_edit(getRouteParam(params.webhookId)),
    queryFn: ({ queryKey: [{ webhookId }] }) =>
      fetchDocument(GetEditWebhookFormData, { webhookId }),
    staleTime: LOADER_STALE_TIME
  });

export function editWebhookFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getEditWebhookFormDataQueryOptions(params));
}
