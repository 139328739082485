import { getRootNode } from './getRootNode';

const focusableElements = [
  'input:not([disabled]):not([type=hidden])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'a[href]',
  'area[href]',
  'summary',
  'iframe',
  'object',
  'embed',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]'
];

const FOCUSABLE_ELEMENT_SELECTOR = `${focusableElements.join(',')},[tabindex]`;

// Inspired by https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/focus/src/FocusScope.tsx
export function isFocusableElement(element: HTMLElement): boolean {
  return element.matches(FOCUSABLE_ELEMENT_SELECTOR);
}

const REACT_ROOT = getRootNode(document.body);

export function getFocusableElements(context: HTMLElement = REACT_ROOT): HTMLElement[] {
  return Array.prototype.slice.call(context.querySelectorAll(FOCUSABLE_ELEMENT_SELECTOR));
}
