import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { typography } from 'styled-system';
import type { FontSizeProps, FontWeightProps } from '../primitives/types';

type Props = FontSizeProps & FontWeightProps;

export const MutedSelect = styled('select', { shouldForwardProp })<Props>(typography, {
  background: 'none',
  border: 0
});
