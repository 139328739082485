import { memo, type MouseEventHandler } from 'react';
import type { To } from 'react-router-dom';
import { DropdownMenu } from '@/components/DropdownMenu';
import { useI18n } from '@/components/I18n';
import type { IconButtonSize } from '@/components/IconButton';
import type { ColorToken } from '@/css/types';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';

type Props = {
  communityId: string;
  communityLocation?: To;
  onDeleteCommunity?: MouseEventHandler;
  onEditCommunity: MouseEventHandler;
  showCommunityPageLink?: boolean;
  showConfigurationPageLink?: boolean;
  toggleButtonBgHoverColor?: ColorToken;
  toggleButtonColor?: ColorToken;
  toggleButtonHoverColor?: ColorToken;
  toggleButtonSize?: IconButtonSize;
  viewer: {
    canDeleteCommunity: boolean;
    canEditCommunity: boolean;
  };
};

export const CommunityDropdownMenu = memo<Props>(
  ({
    communityId,
    communityLocation,
    onDeleteCommunity,
    onEditCommunity,
    showCommunityPageLink = false,
    showConfigurationPageLink = false,
    toggleButtonBgHoverColor,
    toggleButtonColor,
    toggleButtonHoverColor,
    toggleButtonSize,
    viewer
  }) => {
    const i18n = useI18n();
    const locations = useSharedLocations('admin');

    return (
      <DropdownMenu
        menuButton={
          <DropdownMenu.IconMenuButton
            color={toggleButtonColor}
            hoverBgColor={toggleButtonBgHoverColor}
            hoverColor={toggleButtonHoverColor}
            size={toggleButtonSize}
          />
        }
      >
        {showCommunityPageLink && communityLocation && (
          <DropdownMenu.Item to={communityLocation}>
            {i18n.t('common', 'detailsLink.text')}
          </DropdownMenu.Item>
        )}
        {viewer.canEditCommunity && (
          <>
            {showCommunityPageLink && <DropdownMenu.Divider />}
            <DropdownMenu.Item onClick={onEditCommunity}>
              {i18n.t('management', 'community.editCommunityButton.text')}
            </DropdownMenu.Item>
          </>
        )}
        {showConfigurationPageLink && (
          <DropdownMenu.Item target="_blank" to={locations.getCommunityPage(communityId)}>
            {i18n.t('management', 'community.goToCommunityConfigurationPageLink.text')}
          </DropdownMenu.Item>
        )}
        {viewer.canDeleteCommunity && onDeleteCommunity && (
          <>
            {(viewer.canEditCommunity || showConfigurationPageLink) && <DropdownMenu.Divider />}
            <DropdownMenu.Item dangerous onClick={onDeleteCommunity}>
              {i18n.t('management', 'community.deleteCommunityButton.text')}
            </DropdownMenu.Item>
          </>
        )}
      </DropdownMenu>
    );
  }
);
