import { adminRouteConfig } from './adminRouteConfig';
import { debugRouteConfig } from './debugRouteConfig';
import { mainRouteConfig } from './mainRouteConfig';

export type RouteConfig<TPathMap extends Record<string, string>, TLocationMap> = {
  paths: TPathMap;
  locations: TLocationMap;
};

export const AppLocations = {
  admin: adminRouteConfig.locations,
  debug: debugRouteConfig.locations,
  main: mainRouteConfig.locations
} as const;
