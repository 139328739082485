import { useEffect, useState } from 'react';

export function useNetworkStatus() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const setOffline = () => setIsOnline(false);
    const setOnline = () => setIsOnline(true);

    // See https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/Online_and_offline_events#.22online.22_and_.22offline.22_events
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  return { isOnline };
}
