import { forwardRef, type HTMLAttributes, useMemo } from 'react';
import { css, type Keyframes, keyframes } from '@emotion/react';
import type { ColorToken } from '@/css/types';
import { getColorToken } from '@/css/utils';
import { exhaustiveCheck } from '@/types/utils';
import { useI18n } from '../I18n';
import { icons } from './icons';

/**
 * Based on Apple guidelines:
 * @url https://developer.apple.com/design/human-interface-guidelines/right-to-left
 */
const RTL_FLIP_ICONS: IconType[] = [
  'arrow-left',
  'arrow-right',
  'arrow-right-circle',
  'arrow-uturn-down',
  'arrow-uturn-up',
  'chart-pie',
  'chat-alt-2',
  'chevron-left',
  'chevron-right',
  'clipboard-list',
  'external-link',
  'file-text',
  'identification',
  'logout',
  'message-circle',
  'refresh',
  'repeat',
  'terminal',
  'thumbs-down',
  'thumbs-up',
  'trending-up',
  'view-grid-add'
];

const spin = keyframes`
  from { transform: rotate(  0deg); }
    to { transform: rotate(360deg); }
`;

const reversedSpin = keyframes`
  from { transform: rotate(360deg); scaleX(-1); }
    to { transform: rotate(  0deg); scaleX(-1); }
`;

type Animation = 'spin';

function iconStyle(flip: boolean, animation?: Animation) {
  let animationStyle: Keyframes | undefined;

  if (animation) {
    if (animation === 'spin') {
      // Right now we're assuming that "reverse" and "flip" are the same thing,
      // but in theory they're not. You might want an icon that reverses its
      // animation counter-clockwise without flipping the icon. If this scenario
      // comes up, we can add an additional `reverse` prop to separate the two.
      animationStyle = flip ? reversedSpin : spin;
    } else {
      /* istanbul ignore next */ exhaustiveCheck(animation);
    }
  }

  return css({
    flexShrink: 0,
    transition: 'stroke 150ms ease-out, fill 150ms ease-out',
    transform: flip ? 'scaleX(-1)' : undefined,
    animation: animationStyle
  });
}

export type IconType = keyof typeof icons;

type Props = Pick<
  HTMLAttributes<SVGSVGElement>,
  'aria-describedby' | 'aria-hidden' | 'aria-label' | 'aria-labelledby' | 'role' | 'title'
> & {
  animation?: Animation;
  color?: ColorToken;
  fillColor?: ColorToken;
  size?: number;
  type: IconType;
};

export const Icon = forwardRef<SVGSVGElement, Props>(
  (
    {
      'aria-hidden': ariaHidden = true,
      animation,
      color,
      fillColor,
      size = 20,
      type,
      role = 'presentation',
      ...props
    },
    ref
  ) => {
    const { isRtl } = useI18n();
    const flip = useMemo(() => isRtl && RTL_FLIP_ICONS.includes(type), [isRtl, type]);

    return (
      <svg
        ref={ref}
        aria-hidden={ariaHidden}
        css={iconStyle(flip, animation)}
        data-icon-type={type}
        data-testid={`icon--${type}`}
        fill={fillColor ? getColorToken(fillColor) : 'none'}
        height={size}
        role={role}
        stroke={color ? getColorToken(color) : 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        viewBox="0 0 24 24"
        width={size}
        {...props}
      >
        {icons[type]}
      </svg>
    );
  }
);
