import { css } from '@emotion/react';
import { getColorToken } from '@/css/utils';

export const form = css`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: auto;
`;

export const formContent = css`
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 20px;

  @media (min-width: 768px) {
    padding: 24px;
  }
`;

export const actions = css`
  background-color: ${getColorToken('background')};
  border-top: 1px solid ${getColorToken('neutral100')};
  padding: 12px 20px;

  @media (min-width: 768px) {
    padding: 16px 24px;
  }
`;
