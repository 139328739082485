import type ApolloClient from 'apollo-client';

async function loadAddDownvoteMutation() {
  return (await import('./AddDownvote.gql')).AddDownvote;
}

async function loadAddUpvoteMutation() {
  return (await import('./AddUpvote.gql')).AddUpvote;
}

async function loadUndoVoteMutation() {
  return (await import('./UndoVote.gql')).UndoVote;
}

type ContributionVotingService = {
  addDownvote: (contributionId: string) => Promise<void>;
  addUpvote: (contributionId: string) => Promise<void>;
  undoVote: (contributionId: string) => Promise<void>;
};

const noop = () => {};

export function createContributionVotingService(
  client: ApolloClient<object>
): ContributionVotingService {
  return {
    addDownvote: contributionId =>
      loadAddDownvoteMutation()
        .then(mutation => client.mutate({ mutation, variables: { input: { contributionId } } }))
        .then(noop),
    addUpvote: contributionId =>
      loadAddUpvoteMutation()
        .then(mutation => client.mutate({ mutation, variables: { input: { contributionId } } }))
        .then(noop),
    undoVote: contributionId =>
      loadUndoVoteMutation()
        .then(mutation => client.mutate({ mutation, variables: { input: { contributionId } } }))
        .then(noop)
  };
}
