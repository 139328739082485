import type { ReactChild, ReactNode } from 'react';
import styled from '@emotion/styled';
import { Divider } from '@/components/Divider';
import { Heading } from '@/components/Heading';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import { useId } from '@/utils/hooks/useId';

export const UiList = styled.ul({
  listStylePosition: 'inside',
  listStyleType: 'none',
  padding: 0
});

type Props = {
  children: ReactNode;
  hasDividerBefore?: boolean;
  label: ReactChild;
  isLabelVisible?: boolean;
};

export const DashboardFilterListItemGroup = ({
  children,
  hasDividerBefore = false,
  label,
  isLabelVisible = true
}: Props) => {
  const headingId = useId('heading');

  let heading = (
    <Heading color="textLight" id={headingId} mb="s03" variant="heading.400">
      {label}
    </Heading>
  );

  if (!isLabelVisible) {
    heading = <VisuallyHidden>{heading}</VisuallyHidden>;
  }

  return (
    <>
      {hasDividerBefore && (
        <li role="presentation">
          <Divider color="grey200" spacing="s04" />
        </li>
      )}
      <li>
        {heading}
        <UiList aria-labelledby={headingId}>{children}</UiList>
      </li>
    </>
  );
};
