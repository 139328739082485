import type { Module } from '../../components/ModuleCard';
import type { ModuleCardFragment } from './ModuleCardFragment.gql';

export const parseModuleCardFragment = (data: ModuleCardFragment): Module => ({
  communityId: data.communityId,
  endDate: data.endDate,
  id: data.id,
  image: data.image,
  programId: data.programId,
  programTitle: data.programTitle,
  startDate: data.startDate,
  status: data.status,
  // Give precedence to the (plain text) summary if it exists, if not, fall
  // back to the (stripped) description.
  summary: data.summary || data.description,
  title: data.title,
  totalContributionCount: 'totalContributionCount' in data ? data.totalContributionCount || 0 : 0,
  type: data.type,
  viewer: {
    canAddContribution:
      'viewer' in data && 'canAddContribution' in data.viewer
        ? data.viewer.canAddContribution
        : false
  }
});
