function setupScript(id: string, src: string) {
  id = id.startsWith('#') ? id.substring(1) : id;

  const existingNode = document.head.querySelector(`#${id}`);

  if (existingNode) {
    document.head.removeChild(existingNode);
  }

  const scriptNode = document.createElement('script');

  scriptNode.id = id;
  scriptNode.nonce = window.__CSP_NONCE__;
  scriptNode.async = true;
  scriptNode.src = src;

  document.head.appendChild(scriptNode);

  return scriptNode;
}

function isValidGoogleTagManagerOrAnalyticsVersion4TrackingId(trackingId: string) {
  return /^(G|GTM)-(.*)$/.test(trackingId);
}

const log = (message: string) => console.log(`[GoogleAnalytics] ${message}`); // eslint-disable-line no-console

export function setupGoogleAnalytics(trackingId: string, startTime: Date) {
  if (!isValidGoogleTagManagerOrAnalyticsVersion4TrackingId(trackingId)) {
    log('Invalid tracking ID.');
    return;
  }

  if (trackingId.startsWith('G-')) {
    /**
     * Based on the GA4 injection sample which pushes arguments to the dataLayer buffer:
     *
     * <!-- Google tag (gtag.js) -->
     * <script>
     *   window.dataLayer = window.dataLayer || [];
     *   function gtag(){dataLayer.push(arguments);}
     *   gtag('js', new Date());
     *   gtag('config', 'G-QTBGHR59F6');
     * </script>
     */
    window.dataLayer = window.dataLayer || [];

    // We need to use a gtag function combined with `push(arguments)` otherwise the gtag
    // initialization code doesn't copy over the dataLayer buffer for some reason. Simply doing
    // `window.dataLayer.push(['js', startTime])` for example does *not* work. Weird. What I'm
    // trying to say is: don't try to be clever and rewrite the code below, it won't work anymore.
    // Been there.
    // eslint-disable-next-line no-inner-declarations
    function gtag() {
      window.dataLayer?.push(arguments); // eslint-disable-line prefer-rest-params
    }

    // @ts-expect-error
    gtag('js', startTime);
    // @ts-expect-error
    gtag('config', trackingId);

    const node = setupScript('ga4', `https://www.googletagmanager.com/gtag/js?id=${trackingId}`);
    node.onload = () => log('Loaded GA4.');
    node.onerror = () => log('Error loading Google GA4.');
  } else if (trackingId.startsWith('GTM-')) {
    /**
     * Based on the GTM injection sample:
     *
     * <!-- Google Tag Manager -->
     * <script>
     *   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
     *   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
     *   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
     *   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
     *   })(window,document,'script','dataLayer','GTM-NR4DM3J');
     * </script>
     * <!-- End Google Tag Manager -->
     */
    window.dataLayer = window.dataLayer || [];
    // Unlike the gtag code above, this one seems to be less strict about how to populate the buffer.
    window.dataLayer.push({ 'gtm.start': startTime.getTime(), event: 'gtm.js' });

    const node = setupScript('gtm', `https://www.googletagmanager.com/gtm.js?id=${trackingId}`);
    node.onload = () => log('Loaded GTM.');
    node.onerror = () => log('Error loading GTM.');
  } else {
    log('Invalid tracking ID.');
  }
}
