import { ApolloLink } from 'apollo-link';
import { CSRF_HEADER } from '@/constants';
import { getCsrfToken } from '../csrf';

export function createCsrfTokenLink(debug = false) {
  return new ApolloLink((operation, forward) => {
    if (debug) {
      // eslint-disable-next-line no-console
      console.log('[CSRFTokenMiddleware] Adding csrf token header to the request.');
    }

    operation.setContext(({ headers = {} }) => ({
      credentials: 'include',
      headers: { ...headers, [CSRF_HEADER]: getCsrfToken() }
    }));

    return forward(operation);
  });
}
