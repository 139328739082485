import SupportedLanguagesJson from '@/locales/supported_languages.json';
import type { SupportedLanguage } from './types';

const SupportedLanguages = Object.values(SupportedLanguagesJson) as SupportedLanguage[];

// Code below can be used to filter out certain languages that are not ready
// for prime-time.
// export const IS_DEV_OR_DEMO_ENV = /^(local\.|devportal|demo)(.*)\.cognistreamer\.com$/.test(
//   window.location.hostname
// );
// .filter(
//   language => IS_DEV_OR_DEMO_ENV || language.code !== 'ja'
// ) as SupportedLanguage[];

export function getSupportedLanguages() {
  return SupportedLanguages;
}

export function isSupportedLanguage(localeId: string): localeId is SupportedLanguage['code'] {
  return SupportedLanguages.some(language => language.code === localeId);
}
