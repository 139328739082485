import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ComponentProps,
  type ReactNode,
  useCallback
} from 'react';
import type { To } from 'react-router-dom';
import type { MenuItemProps } from '@ariakit/react';
import { Box, Flex, Text } from '@/components/primitives';
import { StatusIcon } from '@/components/StatusIcon';
import { UnstyledLink } from '@/components/UnstyledLink';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import type { ColorToken } from '@/css/types';
import { hasValue } from '@/utils/common';
import { useId } from '@/utils/hooks/useId';
import type { LinkProps } from '../Link';
import { StyledDropdownMenuItem } from './StyledDropdownMenuItem';

type StatusIconProps = ComponentProps<typeof StatusIcon>;

type Props = Pick<MenuItemProps, 'hideOnClick' | 'onClick' | 'render'> & {
  children: ReactNode;
  dangerous?: boolean;
  emphasized?: boolean;
  errorText?: string;
  helpText?: string;
  isDisabled?: boolean;
  showStatus?: boolean;
  statusText?: string;
  statusType?: StatusIconProps['type'];
} & (
    | {
        download?: boolean;
        locationState?: LinkProps['state'];
        rel?: AnchorHTMLAttributes<HTMLAnchorElement>['rel'];
        target?: '_blank';
        to?: To;
        type?: never;
      }
    | {
        rel?: never;
        target?: never;
        to?: never;
        type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
      }
  );

export const DropdownMenuItem = ({
  children,
  dangerous = false,
  emphasized = false,
  helpText,
  onClick,
  isDisabled = false,
  showStatus: _showStatus = true,
  statusText,
  statusType,
  ...props
}: Props) => {
  const menuItemId = useId('menuItem');
  const labelId = `${menuItemId}-label`;
  const helpTextId = helpText ? `${menuItemId}-helpText` : undefined;
  const statusTextId = statusText ? `${menuItemId}-statusText` : undefined;
  const showStatus = _showStatus && hasValue(statusText);

  let textColor: ColorToken = 'text';
  let textHoverColor: ColorToken = 'textDark';
  let helpTextColor: ColorToken = 'textLight';

  if (isDisabled) {
    textColor = 'textLight';
    textHoverColor = 'textLight';
    helpTextColor = 'textLight';
  } else if (dangerous) {
    textColor = 'danger';
    textHoverColor = 'red600';
  }

  const handleOnClick = useCallback(
    event => {
      if (onClick) {
        // The timeout is used to correctly set the focus back on the toggle button
        // after closing a modal or dialog (Fixes a race condition)
        setTimeout(() => onClick(event), 0);
      }
    },
    [onClick]
  );

  const menuItem = (
    <Flex
      alignItems="center"
      gap="s02"
      overflowWrap="break-word"
      px="s05"
      // Overrides Ariakit menu items default pointerEvents: 'none'.
      // It fixes the tooltip being displayed when hovering over the status icon
      py="s02"
      style={showStatus ? { pointerEvents: 'initial' } : undefined}
      wordBreak="break-word"
    >
      <VisuallyHidden id={statusTextId}>{statusText}</VisuallyHidden>
      {/**
       * You might be tempted to drop the flex=1 below and possibly also the
       * Box, but that will break things such as the draft counter in the user
       * menu.
       */}
      <Box flex={1}>
        <Text fontWeight={emphasized ? 'semibold' : 'normal'} id={labelId}>
          {children}
        </Text>
        {helpText && (
          <Box mt="s01">
            <Text color={helpTextColor} fontSize="md" id={helpTextId}>
              {helpText}
            </Text>
          </Box>
        )}
      </Box>
      {showStatus && <StatusIcon helpText={statusText} isTabbable={false} type={statusType} />}
    </Flex>
  );

  const ariaProps = {
    'aria-disabled': isDisabled ? true : undefined,
    'aria-describedby': [helpTextId, statusTextId].filter(Boolean).join(' ') || undefined,
    'aria-labelledby': labelId
  };

  if (props.to && !isDisabled) {
    return (
      <StyledDropdownMenuItem
        {...props}
        {...ariaProps}
        disabled={isDisabled}
        render={props.render ?? <UnstyledLink to={props.to} />}
        textColor={textColor}
        textHoverColor={textHoverColor}
      >
        {menuItem}
      </StyledDropdownMenuItem>
    );
  }

  return (
    <StyledDropdownMenuItem
      {...props}
      {...ariaProps}
      disabled={isDisabled}
      onClick={handleOnClick}
      textColor={textColor}
      textHoverColor={textHoverColor}
    >
      {menuItem}
    </StyledDropdownMenuItem>
  );
};
