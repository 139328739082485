import type { MouseEventHandler, ReactNode } from 'react';
import type { To } from 'react-router-dom';
import styled from '@emotion/styled';
import { useI18n } from '@/components/I18n';
import { IconButton } from '@/components/IconButton';
import { Card, Flex, Text } from '@/components/primitives';
import { UnstyledLink } from '@/components/UnstyledLink';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';

const NavigationBarIconButton = styled(IconButton)({ width: 48, height: 48 });

type Props = {
  children?: ReactNode;
  hasParent?: boolean;
  isMenuExpanded: boolean;
  onToggleMenuClick: MouseEventHandler<HTMLButtonElement>;
  parentRoute?: To | null;
  menuToggleButtonText: string;
};

export const NavigationBar = ({
  children,
  hasParent = false,
  isMenuExpanded,
  onToggleMenuClick,
  parentRoute,
  menuToggleButtonText
}: Props) => {
  const i18n = useI18n();
  const goBackButtonText = i18n.t('common', 'goBackButton.text');
  const { getHomePage } = useSharedLocations('main');

  return (
    <Card bgColor="white" borderBottom={1} borderBottomColor="grey200">
      <Flex alignItems="center" gap="s05" height="3.5rem" p="s01" position="relative">
        {hasParent ? (
          <UnstyledLink
            aria-label={goBackButtonText}
            title={goBackButtonText}
            to={parentRoute || getHomePage()}
          >
            <NavigationBarIconButton
              aria-label={goBackButtonText}
              icon="arrow-left"
              role="presentation"
              tabIndex={-1}
            />
          </UnstyledLink>
        ) : (
          <NavigationBarIconButton
            aria-expanded={isMenuExpanded}
            aria-label={menuToggleButtonText}
            icon="menu"
            onClick={onToggleMenuClick}
            title={menuToggleButtonText}
          />
        )}
        <Text
          fontSize="lg"
          fontWeight="bold"
          letterSpacing="wide"
          lineHeight="loose"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {children}
        </Text>
      </Flex>
    </Card>
  );
};
