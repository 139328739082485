import { type Location, matchPath, type To } from 'react-router-dom';
import type { I18n } from '@/components/I18n';
import type { SessionUserContextValue } from '@/components/SessionUserContext';
import { AppLocations } from '@/pages/config';

type MenuItem = {
  isActive?: boolean | ((location: Location) => boolean);
  route: To;
  showNotification?: boolean;
  text: string;
};

const HOME_PATHS = [
  '/',
  '/feed',
  '/programs',
  '/tasks',
  '/communities/:communityId',
  '/communities/:communityId/feed',
  '/communities/:communityId/programs',
  '/communities/:communityId/tasks'
];

export function getMenuConfig(
  i18n: I18n,
  user: SessionUserContextValue,
  hasDpoAssigned: boolean
): MenuItem[] {
  const menu: MenuItem[] = [
    {
      isActive: location => HOME_PATHS.some(path => matchPath(path, location.pathname)),
      text: i18n.t('main', 'dashboard.label'),
      route: AppLocations.main.getHomePage()
    }
  ];

  if (user.canManageConfiguration || user.isDataProtectionOfficer) {
    menu.push({
      text: i18n.t('main', 'appNav.configurationLink.text'),
      route:
        user.isDataProtectionOfficer && !user.canManageConfiguration
          ? AppLocations.admin.getUserRemovalRequestListPage()
          : AppLocations.admin.getAdminPage(),
      showNotification: user.canManageDataProtectionSettings && !hasDpoAssigned
    });
  }

  if (user.canViewReports) {
    menu.push({
      text: i18n.t('main', 'report.label_multiple'),
      route: AppLocations.main.getReportListPage()
    });
  }

  return menu;
}
