import { type ButtonHTMLAttributes, forwardRef } from 'react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import type { DisplayProps } from 'styled-system';
import { baseStyle, fontStyle, type LinkVariant, variantStyle } from '@/components/Link';
import type { TextProps } from '@/components/primitives';
import { UnstyledButton } from '@/components/UnstyledButton';
import type { ColorToken } from '@/css/types';

type Props = ButtonHTMLAttributes<HTMLButtonElement> &
  DisplayProps &
  Omit<TextProps, 'as' | 'textStyle'> & {
    hoverColor?: ColorToken;
    variant?: LinkVariant;
  };

const FauxLinkButtonBase = styled(UnstyledButton, { shouldForwardProp })<Props>(
  baseStyle,
  fontStyle,
  variantStyle,
  { outlineOffset: 2 }
);

export const FauxLinkButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      color: _color = 'link',
      display = 'inline',
      hoverColor = 'linkHover',
      type = 'button',
      variant: _variant = 'underline-on-hover',
      ...props
    },
    ref
  ) => (
    <FauxLinkButtonBase
      {...props}
      ref={ref}
      color={_color}
      display={display}
      hoverColor={hoverColor}
      type={type}
      variant={_variant}
    />
  )
);

FauxLinkButton.displayName = 'FauxLinkButton';
