import { memo, type ReactElement } from 'react';
import { css } from '@emotion/react';
import { Button } from '@/components/Button';
import { EmptyListMessage } from '@/components/EmptyListMessage';
import {
  HorizontalScrollGrid,
  type HorizontalScrollGridProps,
  useHorizontalScrollContext
} from '@/components/HorizontalScrollGrid';
import { useI18n } from '@/components/I18n';
import { LoadingMessage } from '@/components/LoadingMessage';
import { Box, Flex } from '@/components/primitives';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import {
  type Contribution,
  ContributionCard,
  ContributionCardSkeleton,
  type ContributionVoteHandler
} from '../ContributionCard';

export type { Contribution };

// Hide the buttons when touch is the primary input.
const buttonContainerStyle = css({ '@media(hover: none)': { display: 'none' } });

export const HorizontalScrollButtons = () => {
  const i18n = useI18n();
  const state = useHorizontalScrollContext();

  if (state.visibleBoundary === 'both') {
    return null;
  }

  const backwardLabel = i18n.t('common', 'scrollButtons.scrollBackwardButton.text');
  const forwardLabel = i18n.t('common', 'scrollButtons.scrollForwardButton.text');

  return (
    <Flex css={buttonContainerStyle} gap="s02">
      <Button
        aria-label={backwardLabel}
        disabled={state.visibleBoundary === 'start'}
        icon={i18n.isRtl ? 'arrow-right' : 'arrow-left'}
        onClick={state.scrollBackward}
        size="sm"
        title={backwardLabel}
        type="button"
        variant="secondary"
      />
      <Button
        aria-label={forwardLabel}
        disabled={state.visibleBoundary === 'end'}
        icon={i18n.isRtl ? 'arrow-left' : 'arrow-right'}
        onClick={state.scrollForward}
        size="sm"
        title={forwardLabel}
        type="button"
        variant="secondary"
      />
    </Flex>
  );
};

type Props = Pick<HorizontalScrollGridProps, 'columnCount' | 'gap' | 'px'> & {
  bleed?: HorizontalScrollGridProps['bleed'];
  contributions: Contribution[];
  disableMyContributionBadge?: boolean;
  isLoading: boolean;
  onVote: ContributionVoteHandler;
  placeholderCount: number;
  showChallenge?: boolean;
};

export const HorizontalScrollContributionGrid = memo<Props>(
  ({
    bleed = 0.35,
    columnCount,
    contributions,
    disableMyContributionBadge = false,
    gap,
    isLoading,
    onVote,
    placeholderCount,
    px,
    showChallenge = false
  }) => {
    let content: ReactElement;

    if (isLoading && contributions.length === 0) {
      content = (
        <>
          <VisuallyHidden>
            <LoadingMessage />
          </VisuallyHidden>
          <HorizontalScrollGrid
            bleed={bleed}
            columnCount={columnCount}
            gap={gap}
            px={px}
            totalColumnCount={placeholderCount}
          >
            {Array.from({ length: placeholderCount }, (_, index) => (
              <ContributionCardSkeleton key={index} />
            ))}
          </HorizontalScrollGrid>
        </>
      );
    } else if (contributions.length > 0) {
      content = (
        <HorizontalScrollGrid
          bleed={bleed}
          columnCount={columnCount}
          gap={gap}
          px={px}
          totalColumnCount={contributions.length}
        >
          {contributions.map(contribution => (
            <ContributionCard
              key={contribution.id}
              contribution={contribution}
              disableMyContributionBadge={disableMyContributionBadge}
              onVote={onVote}
              showChallenge={showChallenge}
            />
          ))}
        </HorizontalScrollGrid>
      );
    } else {
      content = (
        <Box px={px}>
          <EmptyListMessage />
        </Box>
      );
    }

    return content;
  }
);

HorizontalScrollContributionGrid.displayName = 'memo(HorizontalScrollContributionGrid)';
