import type { MouseEvent } from 'react';
import { MenuButton, type MenuButtonProps } from '@ariakit/react';
import { useI18n } from '@/components/I18n';
import { IconButton, type IconButtonProps } from '@/components/IconButton';

type Props = Partial<
  Pick<IconButtonProps, 'color' | 'hoverBgColor' | 'hoverColor' | 'icon' | 'size'>
> &
  Pick<MenuButtonProps, 'aria-label'>;

export const DropdownIconMenuButton = ({
  'aria-label': ariaLabel,
  icon = 'more-horizontal',
  size = 'lg',
  ...props
}: Props) => {
  const i18n = useI18n();

  return (
    <MenuButton
      aria-label={ariaLabel ?? i18n.t('common', 'dropdownMenu.toggleMenuButton.helpText')}
      // Fixes the issue when the button is used inside another clickable element
      onClick={(event: MouseEvent<HTMLButtonElement>) => event.stopPropagation()}
      render={<IconButton {...props} icon={icon} size={size} />}
    />
  );
};
