import type { ColorToken, MotifColorToken, SpaceToken } from './types';
import { tokens } from './base.css';
import { colorTokens } from './color.css';

type FlatThemeNodes = Omit<typeof tokens, 'colors' | 'motifColors'>;

export function getToken<T extends keyof FlatThemeNodes>(key: T, name: keyof FlatThemeNodes[T]) {
  return tokens[key][name];
}

export function getColorToken(color: ColorToken): string {
  return colorTokens.colors[color];
}

export function getMotifColorToken(color: MotifColorToken): string {
  return colorTokens.motifColors[color];
}

export function stripVar(value: string) {
  return decodeURI(/var\((?<var>.*)\)/gm.exec(value)?.groups?.var || '');
}

export function getNegativeSpaceValue(token: SpaceToken) {
  return `calc(${getToken('space', token)} * -1)`;
}

export function isSpaceTokenKey(value: unknown): value is SpaceToken {
  return typeof value === 'string' && Object.keys(tokens.space).includes(value);
}
