import { type ReactElement, useMemo } from 'react';
import { Spacer } from '@/components/primitives';
import type { SpaceToken } from '@/css/types';
import type { Aria } from '@/types';
import { BlockList } from './BlockList';
import { BlockListItem } from './BlockListItem';

type Props = {
  aria?: Aria;
  divider?: ReactElement;
  items: ReactElement[];
  spacing?: SpaceToken;
};

export const BlockListBuilder = ({ aria, divider, items, spacing }: Props) => {
  const spacer: ReactElement | null = useMemo(
    () => (spacing ? <Spacer data-testid="divider" mb={spacing} /> : null),
    [spacing]
  );

  return (
    <BlockList
      aria-describedby={aria?.describedby}
      aria-label={aria?.label}
      aria-labelledby={aria?.labelledby}
    >
      {items.map((item, index) => (
        <BlockListItem key={index}>
          {item}
          {index < items.length - 1 && (divider || spacer)}
        </BlockListItem>
      ))}
    </BlockList>
  );
};
