import styled from '@emotion/styled';
import { getToken } from '@/css/utils';

type Props = {
  maxHeight?: string | null;
};

export const CodeBlockWrapper = styled.pre<Props>`
  font-size: ${getToken('fontSizes', 'sm')};
  line-height: ${getToken('lineHeights', 'snug')};
  margin-bottom: 0;
  margin-top: 0;
  max-height: ${props => props.maxHeight || 'auto'};
  overflow-x: auto;
  overflow-y: auto;
  text-align: left;
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
`;
