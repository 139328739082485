import { Skeleton } from '@/components/Skeleton/v2';
import { getHeightFromAspectRatio } from '@/components/Thumbnail';
import { ModuleCardLayout } from './ModuleCardLayout';

export const ModuleCardSkeleton = ({ showParent = true }: { showParent?: boolean }) => (
  <ModuleCardLayout
    aria-hidden
    data-testid="placeholder"
    slots={{
      content: (
        <>
          <Skeleton.Text lineHeight="snug" width="100%" />
          <Skeleton.Text lineHeight="snug" width="100%" />
          <Skeleton.Text lineHeight="snug" width="100%" />
          <Skeleton.Text lineHeight="snug" width="100%" />
        </>
      ),
      heading: <Skeleton.Text fontSize="lg" lineHeight="tight" mb="s03" width="35ch" />,
      meta: <Skeleton.Text width="22ch" />,
      parent: showParent ? <Skeleton.Text fontSize="md" width="70%" /> : null,
      thumbnail: (
        <Skeleton.Shape
          borderRadius="none"
          css={{ paddingBottom: getHeightFromAspectRatio(2.4) }}
          height={0}
        />
      )
    }}
  />
);
