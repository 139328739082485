import SupportedLanguagesJson from '@/locales/supported_languages.json';
import { polyfillKeyboardEventKeys } from '@/utils/keyboard/polyfillKeyboardEventKeys';

async function loadIntlPolyfills() {
  await import('@formatjs/intl-pluralrules/polyfill-force');
  return Promise.all(
    Object.keys(SupportedLanguagesJson)
      .map(language => language.split('-')[0])
      .map(
        locale =>
          // The dynamic import below works in tandem with a ContextReplacementPlugin inside the
          // webpack config. The ".js" extension is necessary to make it work.
          import(
            /* webpackChunkName: "intl-pluralrules/[request]" */
            `@formatjs/intl-pluralrules/locale-data/${locale}.js`
          )
      )
  );
}

export async function install() {
  polyfillKeyboardEventKeys();

  if (!Intl.PluralRules) {
    await loadIntlPolyfills();
  }

  if (!('scrollBehavior' in document.documentElement.style)) {
    (await import('smoothscroll-polyfill')).polyfill();
  }

  // Can be undefined in older browsers, see https://caniuse.com/resizeobserver for browser support.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!window.ResizeObserver) {
    window.ResizeObserver = (await import('resize-observer-polyfill')).default;
  }
}
