import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { communityKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetCommunityData } from './graphql/GetCommunityData.gql';

export function getCommunityDataQueryOptions(params: Params<'communityId'>) {
  return queryOptions({
    queryKey: communityKeys.communityById(getRouteParam(params.communityId)),
    queryFn: ({ queryKey: [{ communityId }] }) => fetchDocument(GetCommunityData, { communityId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function communityDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getCommunityDataQueryOptions(params));
}
