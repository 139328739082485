import { useRouteError } from 'react-router-dom';
import { RequestError } from '@/utils/api/error';
import { ResourceError, UserNotAuthorizedError } from '@/utils/errors';
import { ErrorAlert } from '../ErrorAlert';
import { ResourceNotFoundErrorBoundaryContent } from '../ErrorBoundary/ResourceNotFoundErrorBoundaryContent';
import { UserNotAuthorizedErrorBoundaryContent } from '../ErrorBoundary/UserNotAuthorizedErrorBoundaryContent';
import { useI18n } from '../I18n';

type Props = {
  rethrowAuthorizationError?: boolean;
  rethrowResourceError?: boolean;
};

export const RouteErrorAlert = ({ rethrowAuthorizationError, rethrowResourceError }: Props) => {
  const i18n = useI18n();
  const error = useRouteError();

  if (error instanceof UserNotAuthorizedError) {
    if (rethrowAuthorizationError) {
      throw error;
    }

    return (
      <UserNotAuthorizedErrorBoundaryContent
        error={error}
        // This *needs* a better solution, but we don't have access to a retry function since we
        // don't control the error boundary within the route. The fact is that as it is right now,
        // this RouteErrorAlert will most likely never be triggered for a UserNotAuthorizedError, so
        // for now this should be fine. _If_ it does get triggered, the refresh will still lead to
        // expected behavior, just in an ugly way.
        // Alternatively we could also get the apollo client and perform a resetStore(), but I'm not
        // sure if that would always clear the route error.
        onRetry={() => window.location.reload()}
      />
    );
  }

  if (error instanceof RequestError && error.networkError instanceof UserNotAuthorizedError) {
    if (rethrowAuthorizationError) {
      throw error.networkError;
    }

    return (
      <UserNotAuthorizedErrorBoundaryContent
        error={error.networkError}
        onRetry={() => window.location.reload()}
      />
    );
  }

  if (error instanceof ResourceError) {
    if (rethrowResourceError) {
      throw error;
    }

    return <ResourceNotFoundErrorBoundaryContent />;
  }

  if (error instanceof Error) {
    return <ErrorAlert error={error} message={error.message} />;
  }

  return <ErrorAlert message={i18n.t('common', 'applicationError.title')} />;
};
