import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type HookReturnValue = [isActive: boolean, openModal: () => void, closeModal: () => void];

/**
 * Hooks into the routing context and determines if a modal should be visible
 * or not, based on the presence of an "m" parameter in the query string. The
 * value of the parameter should be provided by the user.
 * The hook returns a boolean indicating the visibility state, an "open" handler
 * and a "close" handler, in order to encapsulate all location logic.
 *
 * My initial idea was to reuse this for all modals, but due to some of them
 * requiring additional parameters in the URL this is currently not feasible.
 * Will reevaluate this later. The current implementation suffices for the
 * CommunityView which has a certain complexity due to nested routes
 * (CommunityNav) which require some additional logic in order to preseve the
 * active sub-route.
 *
 * @param {string} paramValue The value of the "m" parameter.
 * @returns {HookReturnValue}
 */
export function useModalURLSearchParam(paramValue: string): HookReturnValue {
  const { search } = useLocation();
  const navigate = useNavigate();

  return useMemo(() => {
    const currentParams = new URLSearchParams(search);

    let params: URLSearchParams;
    let paramsWithModal: URLSearchParams;
    let isActive: boolean;

    const param = currentParams.get('m');

    if (param === paramValue) {
      // If the URL already contains an "m" param we need to create a param set
      // that does *not* contain it, for the close handler.
      isActive = true;
      paramsWithModal = currentParams;
      params = new URLSearchParams(currentParams);
      params.delete('m');
    } else {
      // If the URL does not contain an "m" param we need to create a param set
      // that contains it, for the open handler.
      isActive = false;
      params = currentParams;
      paramsWithModal = new URLSearchParams(currentParams);
      paramsWithModal.set('m', paramValue);
    }

    // If we only want to update the query string we don't need to create a copy
    // of the whole location object, we can simply push an object with a search
    // property onto the stack instead.
    const openModal = () => navigate({ search: paramsWithModal.toString() });
    const closeModal = () => navigate({ search: params.toString() });

    return [isActive, openModal, closeModal];
  }, [paramValue, navigate, search]);
}
