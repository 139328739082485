import {
  createContext,
  type Dispatch,
  type ReactNode,
  type RefCallback,
  useCallback,
  useContext,
  useEffect,
  useReducer
} from 'react';
import { focusElement } from '@/components/FocusManagement';
import { useId } from '@/utils/hooks/useId';
import { useLogger } from '@/utils/hooks/useLogger';
import { SkipNavLink } from './SkipNavLink';

type SkipNavState = {
  element: HTMLElement;
  id: string;
  label: string;
};

type State = SkipNavState[];

type Action =
  | { type: 'ADD_SKIP_NAV'; payload: { id: string; element: HTMLElement; label: string } }
  | { type: 'REMOVE_SKIP_NAV'; payload: { id: string } };

const INITIAL_STATE: State = [];

// See https://developer.mozilla.org/en-US/docs/Web/API/Node/compareDocumentPosition.
function compareSkipNavPositionInDocument(a: SkipNavState, b: SkipNavState) {
  const bitmask = a.element.compareDocumentPosition(b.element);

  // eslint-disable-next-line no-bitwise
  if (bitmask & Node.DOCUMENT_POSITION_FOLLOWING) {
    return -1;
  }

  // eslint-disable-next-line no-bitwise
  if (bitmask & Node.DOCUMENT_POSITION_PRECEDING) {
    return 1;
  }

  return 0;
}

const SkipNavContext = createContext<Dispatch<Action> | undefined>(undefined);

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ADD_SKIP_NAV':
      return [
        ...state,
        {
          element: action.payload.element,
          id: action.payload.id,
          label: action.payload.label
        }
      ].sort(compareSkipNavPositionInDocument);
    case 'REMOVE_SKIP_NAV':
      return state.filter(s => s.id !== action.payload.id);
    default:
      return state;
  }
}

type Props = {
  children?: ReactNode;
};

export function useSkipNav<T extends HTMLElement>(label: string): RefCallback<T> {
  const id = useId('skipNav');
  const dispatch = useContext(SkipNavContext);

  // But Pleun... Why? Well, dear friend... Refs (of the RefObject<T> kind)
  // don't correctly update when the element they're put on changes. As a result
  // they don't consistently trigger a `useEffect()` and cannot be implemented
  // that way (which was also my initial implementation). In my particular case
  // I was having issues with skip navs unregistering when clicking a link
  // (which caused their element to be unmounted - which is correct behavior),
  // but then refusing to REregister when going back to the previous tab. When
  // going back to the previous tab a new element would be created but would not
  // cause to call useEffect() with the new element reference, which is
  // incorrect.
  // This behavior and workaround is also kind of documented on the React
  // website: https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  //
  // It's a little bit hard to explain and test but this is the scenario that
  // failed for me:
  // Given: Community page with 3 skip navs on "Overview" tab, one "main" skip
  //        nav on "programs" tab
  //   1. Navigate to "programs" -> 2 skip navs get unregistered, only "Go to
  //      main content" remains
  //   2. Navigate back to "overview": 2 skip navs _should_ reregister, but only
  //      the main one is there. With the alternative solution below, this does
  //      work correctly.
  const ref = useCallback(
    (element: T | null) => {
      if (dispatch && element !== null) {
        // We don't have access to the state, so we don't know if this is an
        // initial ADD or an UPDATE. As a result we always attempt to clear it
        // first.
        dispatch({ type: 'REMOVE_SKIP_NAV', payload: { id } });
        dispatch({ type: 'ADD_SKIP_NAV', payload: { id, element, label } });
      }
    },
    [dispatch, id, label]
  );

  // The ref callback above does not get triggered when the component unmounts,
  // so far that use-case we still need a useEffect hook in order to clean up
  // remaining skip navs.
  useEffect(
    () => () => {
      if (dispatch) {
        dispatch({ type: 'REMOVE_SKIP_NAV', payload: { id } });
      }
    },
    [dispatch, id]
  );

  return ref;
}

export const SkipNavManager = ({ children }: Props) => {
  const logger = useLogger('SkipNavManager');
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <SkipNavContext.Provider value={dispatch}>
      {state.map(skipNav => (
        <SkipNavLink
          key={skipNav.id}
          onClick={event => {
            event.preventDefault();
            focusElement(logger, skipNav.element, true, 'scrollIntoViewIfNeeded');
          }}
          to=""
        >
          {skipNav.label}
        </SkipNavLink>
      ))}
      {children}
    </SkipNavContext.Provider>
  );
};
