import type { SelectHTMLAttributes } from 'react';
import { MutedSelect } from './MutedSelect';

type Language = {
  name: string;
  code: string;
};

type Props = SelectHTMLAttributes<HTMLSelectElement> & {
  languages: Language[];
};

export const MutedLanguageSelect = ({ languages, ...props }: Props) => (
  <MutedSelect {...props} fontSize="md" fontWeight="semibold">
    {languages.map(language => (
      <option key={language.code} value={language.code}>
        {language.name}
      </option>
    ))}
  </MutedSelect>
);
