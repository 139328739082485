import { Flex } from '@/components/primitives';
import { ThemedDonutLoader } from '@/components/ThemedDonutLoader';

type Props = {
  flex?: boolean;
  height?: string;
};

export const ContentLoader = ({ flex, height = '200px' }: Props) => (
  <Flex
    alignItems="center"
    flex={flex ? 1 : undefined}
    flexDirection="column"
    justifyContent="center"
    minHeight={height}
    py="s08"
  >
    <ThemedDonutLoader />
  </Flex>
);
