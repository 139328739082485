import type { ReactElement, ReactNode } from 'react';
import { DashboardFilterListCollapsibleItemGroup } from './DashboardFilterListCollapsibleItemGroup';
import { DashboardFilterListCommunityItem } from './DashboardFilterListCommunityItem';
import { DashboardFilterListHiddenCommunityItem } from './DashboardFilterListHiddenCommunityItem';
import { DashboardFilterListItem } from './DashboardFilterListItem';
import { DashboardFilterListItemGroup, UiList } from './DashboardFilterListItemGroup';
import { DashboardFilterListItemLayout } from './DashboardFilterListItemLayout';

type Props = {
  'aria-label': string;
  children: ReactNode;
};

type Component = {
  (props: Props): ReactElement;

  CommunityItem: typeof DashboardFilterListCommunityItem;
  HiddenCommunityItem: typeof DashboardFilterListHiddenCommunityItem;
  Item: typeof DashboardFilterListItem;
  ItemGroup: typeof DashboardFilterListItemGroup;
  CollapsibleItemGroup: typeof DashboardFilterListCollapsibleItemGroup;
  ItemLayout: typeof DashboardFilterListItemLayout;
};

export const DashboardFilterList: Component = ({ 'aria-label': ariaLabel, children }) => (
  <UiList aria-label={ariaLabel}>{children}</UiList>
);

DashboardFilterList.CommunityItem = DashboardFilterListCommunityItem;
DashboardFilterList.HiddenCommunityItem = DashboardFilterListHiddenCommunityItem;
DashboardFilterList.Item = DashboardFilterListItem;
DashboardFilterList.ItemGroup = DashboardFilterListItemGroup;
DashboardFilterList.ItemLayout = DashboardFilterListItemLayout;
DashboardFilterList.CollapsibleItemGroup = DashboardFilterListCollapsibleItemGroup;
