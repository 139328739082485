import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { UserGroupFilterValues } from '@/features/user-management/components/UserGroupListHeader';
import { fetchDocument } from '@/utils/api/fetcher';
import { hasValue } from '@/utils/common';
import { LOADER_STALE_TIME, queryOptions, userGroupKeys } from '../utils';
import { GetUserGroups, type GetUserGroupsQueryVariables } from './graphql/GetUserGroups.gql';

type QueryVariables = Pick<GetUserGroupsQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<UserGroupFilterValues>;
};

export function getUserGroupsQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: userGroupKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetUserGroups, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'NAME_ASC',
  filter: {}
};

export function userGroupsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getUserGroupsQueryOptions(defaultVariables));
}

export function parseVariables(variables: QueryVariables): GetUserGroupsQueryVariables {
  const {
    filter: { searchText, isAssignableToTasks, isDefaultGroupForRegisteredUsers },
    pagination: { page, size },
    sortOrder
  } = variables;

  const filter: NonNullable<GetUserGroupsQueryVariables['filter']> = {};

  if (hasValue(isAssignableToTasks)) {
    filter.isAssignableToTasks = isAssignableToTasks;
  }

  if (hasValue(isDefaultGroupForRegisteredUsers)) {
    filter.isDefaultGroupForRegisteredUsers = isDefaultGroupForRegisteredUsers;
  }

  if (searchText?.trim()) {
    filter.searchText = searchText.trim();
  }

  return {
    pagination: { page, size },
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
