import type { ElementType, ReactNode } from 'react';
import styled from '@emotion/styled';
import { border, boxShadow } from 'styled-system';
import { Box, type BoxProps } from './Box';
import type { BorderProps, BoxShadowProps } from './types';

export type CardProps = BorderProps &
  BoxProps &
  BoxShadowProps & {
    children?: ReactNode;
    as?: ElementType;
  }; // & OpacityProps;

export const Card = styled(Box)<CardProps>({ overflowWrap: 'break-word' }, border, boxShadow);
