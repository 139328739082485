import { memo, useMemo } from 'react';
import { css, keyframes } from '@emotion/react';
import { Box } from '@/components/primitives';
import type { Color } from '@/css/types';
import { getColorToken, getMotifColorToken } from '@/css/utils';
import { exhaustiveCheck } from '@/types/utils';
import { useI18n } from '../I18n';
import type { ButtonVariant } from './Button';

const SUPPORTED_VARIANTS = ['branded-primary', 'error', 'primary', 'secondary', 'muted'] as const;

type ButtonLoaderVariant = (typeof SUPPORTED_VARIANTS)[number];

export function supportsLoader(buttonVariant: ButtonVariant): buttonVariant is ButtonLoaderVariant {
  return SUPPORTED_VARIANTS.includes(buttonVariant);
}

export type ButtonLoaderProps = {
  mode: 'dark' | 'light';
  variant: ButtonLoaderVariant;
};

function getBgColor(variant: ButtonLoaderVariant, mode: 'dark' | 'light'): Color {
  let bgColor: Color;

  if (variant === 'branded-primary') {
    bgColor = mode === 'dark' ? getColorToken('primary') : getMotifColorToken('black');
  } else if (variant === 'error') {
    bgColor = getMotifColorToken('black');
  } else if (variant === 'primary') {
    bgColor = getColorToken('primary');
  } else if (variant === 'secondary' || variant === 'muted') {
    bgColor = mode === 'dark' ? getMotifColorToken('white') : getMotifColorToken('black');
  } else {
    /* istanbul ignore next */ exhaustiveCheck(variant);
  }

  return bgColor;
}

const slide = keyframes`
  from { transform: translateX(-180%); }
    to { transform: translateX( 180%); }
`;

const rtlSlide = keyframes`
  from { transform: translateX( 180%); }
    to { transform: translateX(-180%); }
`;

const style = css({ animation: `${slide} 1.5s linear infinite` });
const rtlStyle = css({ animation: `${rtlSlide} 1.5s linear infinite` });

export const ButtonLoader = memo<ButtonLoaderProps>(({ mode, variant }) => {
  const { isRtl } = useI18n();
  const bgColor = useMemo(() => getBgColor(variant, mode), [variant, mode]);

  return (
    <Box bgColor={bgColor} css={isRtl ? rtlStyle : style} height="0.25rem" mx="auto" width="60%" />
  );
});
