import { forwardRef, type ReactNode } from 'react';
import { Relative } from '@/components/primitives';

type Props = {
  children: ReactNode;
};

export const DropdownContainer = forwardRef<HTMLDivElement, Props>(({ children }, ref) => (
  <Relative ref={ref} display="flex">
    {children}
  </Relative>
));
