import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, userRegistrationSettingsKeys } from '../utils';
import { GetUserRegistrationSettings } from './graphql/GetUserRegistrationSettings.gql';

export const userRegistrationSettingsQueryOptions = queryOptions({
  queryKey: userRegistrationSettingsKeys.all,
  queryFn: () => fetchDocument(GetUserRegistrationSettings),
  staleTime: LOADER_STALE_TIME
});

export function userRegistrationSettingsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(userRegistrationSettingsQueryOptions);
}
