import type * as Types from '@/typings/api.generated.d';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetEditFieldTypeFormDataQueryVariables = Types.Exact<{
  fieldTypeId: Types.Scalars['GUID']['input'];
}>;


export type GetEditFieldTypeFormDataQuery = { fieldType: { __typename: 'FieldType', dataTypeId: Types.DataTypeId, id: Types.Scalars['GUID']['output'], name: Types.Scalars['String']['output'], semanticType: Types.Scalars['String']['output'] | null, settings: { __typename: 'ChoiceListFieldTypeSettings', options: Array<{ __typename: 'ChoiceOption', key: Types.Scalars['String']['output'], value: Types.Scalars['String']['output'] }> } | null } | null };


export const GetEditFieldTypeFormData = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEditFieldTypeFormData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"fieldTypeId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fieldType"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"fieldTypeId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dataTypeId"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"semanticType"}},{"kind":"Field","name":{"kind":"Name","value":"settings"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"ChoiceListFieldTypeSettings"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"options"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"value"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetEditFieldTypeFormDataQuery, GetEditFieldTypeFormDataQueryVariables>;