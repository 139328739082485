import { produce } from 'immer';
import { create } from 'zustand';
import { API_INFO_URL } from '@/constants';
import { COLOR_EY_BLACK, COLOR_EY_YELLOW } from '@/css/constants';
import { loadAppConfig } from '@/utils/loadAppConfig';
import type { JargonMap } from '../I18n';

export type AppConfig = {
  application_applicationName: string;
  application_branding_analyticsTrackingId: string | null;
  application_branding_logoFileId: string | null;
  application_branding_primaryColor: string;
  application_branding_primaryFont: string | null;
  application_branding_secondaryColor: string;
  application_contributionSimilarity_enabled: boolean;
  application_defaultLocaleId: string | null;
  application_languageDetection_enabled: boolean;
  application_portalVersion: string | null;
  application_tagExtraction_enabled: boolean;
  application_url: string;
  application_userMustSelectLocaleId: boolean;
  auth_login_endpoint: string;
  auth_logout_endpoint: string;
  auth_silentLogin_enabled: boolean;
  file_endpoint: string;
  graphql_endpoint: string;
  graphql_supportsIntrospection: boolean;
  graphql_supportsQueryBatching: boolean;
  image_endpoint: string;
  jargon: JargonMap;
  viewer_communityCount: number;
  viewer_favoriteCommunityIds: string[];
  viewer_hasUnsubmittedAndDeclinedGlobalUserAgreements: boolean;
  viewer_isAuthenticated: boolean;
  viewer_localeId: string | null;
  viewer_userId: string;
};

type SetStateAction<S> = S | ((prevState: S) => S);

type AppConfigStore = {
  config: AppConfig;
  reloadConfig: () => Promise<AppConfig>;
  setFavoriteCommunityIds: (value: SetStateAction<string[]>) => void;
};

const DEFAULT_CONFIG: AppConfig = {
  application_applicationName: 'Cognistreamer',
  application_branding_analyticsTrackingId: null,
  application_branding_logoFileId: null,
  application_branding_primaryColor: COLOR_EY_YELLOW,
  application_branding_primaryFont: null,
  application_branding_secondaryColor: COLOR_EY_BLACK,
  application_contributionSimilarity_enabled: false,
  application_defaultLocaleId: null,
  application_languageDetection_enabled: false,
  application_portalVersion: null,
  application_tagExtraction_enabled: false,
  application_url: '',
  application_userMustSelectLocaleId: false,
  auth_login_endpoint: '/auth/login',
  auth_logout_endpoint: '/auth/logout',
  auth_silentLogin_enabled: false,
  file_endpoint: '',
  graphql_endpoint: '',
  graphql_supportsIntrospection: false,
  graphql_supportsQueryBatching: false,
  image_endpoint: '',
  jargon: {
    community: 'COMMUNITY',
    contribution: 'CONTRIBUTION',
    ideationModule: 'CHALLENGE',
    program: 'PROGRAM'
  },
  viewer_communityCount: 0,
  viewer_favoriteCommunityIds: [],
  viewer_hasUnsubmittedAndDeclinedGlobalUserAgreements: false,
  viewer_isAuthenticated: false,
  viewer_localeId: null,
  viewer_userId: ''
};

export const useAppConfigStore = create<AppConfigStore>(set => ({
  config: DEFAULT_CONFIG,
  reloadConfig: () =>
    loadAppConfig(API_INFO_URL).then(config => {
      set({ config });
      return config;
    }),
  setFavoriteCommunityIds: value => {
    set(state => {
      let nextValue: string[];

      if (Array.isArray(value)) {
        nextValue = value;
      } else {
        nextValue = value(state.config.viewer_favoriteCommunityIds);
      }

      return {
        config: produce(state.config, next => {
          next.viewer_favoriteCommunityIds = nextValue;
        })
      };
    });
  }
}));

export function useJargon() {
  return useAppConfigStore(state => state.config.jargon);
}

export function useAppConfig() {
  return useAppConfigStore(state => state.config);
}

export function useAppConfigValue<T extends keyof AppConfig>(value: T): AppConfig[T] {
  return useAppConfigStore(state => state.config[value]);
}
