import { useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useAppConfigStore, useAppConfigValue } from '@/components/AppConfig';
import type { WrappedError } from '@/components/ErrorAlertList';
import { getApolloErrors } from '@/utils/errors';
import { AddCommunityToFavorites } from './AddCommunityToFavorites.gql';
import { ClearFavoriteCommunities } from './ClearFavoriteCommunities.gql';
import { RemoveCommunityFromFavorites } from './RemoveCommunityFromFavorites.gql';

type HookActions = {
  addFavorite: (value: string) => Promise<unknown>;
  clearFavorites: () => Promise<unknown>;
  removeFavorite: (value: string) => Promise<unknown>;
};

type HookReturnValue = [favorites: string[], actions: HookActions, errors: WrappedError[]];

const onError = () => {};

export function useFavoriteCommunities(): HookReturnValue {
  const communityIds = useAppConfigValue('viewer_favoriteCommunityIds');
  const setCommunityIds = useAppConfigStore().setFavoriteCommunityIds;

  // If necessary, we can still improve "local perf" by caching the communityIds
  // in local state, and initializing it with the appConfig value, i.e.
  //
  // const initialCommunityIds = useAppConfigValue('viewer_favoriteCommunityIds');
  // const [communityIds, setCommunityIds] = useState(initialCommunityIds);
  //
  // We do have to call both the setCommunityIds and the
  // store.setFavoriteCommunityIds whenever the value changes, but it does mean
  // that we get instant UI feedback rather than being dependent on the store
  // change listeners. For now it does not seem necessary, but we're uncertain
  // how well the performance of the "favorite" mutations scales on larger
  // instances.

  const [clearFavorites, clearFavoritesResult] = useMutation(ClearFavoriteCommunities, { onError });
  const [addFavorite, addFavoriteResult] = useMutation(AddCommunityToFavorites, { onError });
  const [removeFavorite, removeFavoriteResult] = useMutation(RemoveCommunityFromFavorites, {
    onError
  });

  const actions: HookActions = useMemo(
    () => ({
      addFavorite: communityId => {
        setCommunityIds(ids => (ids.includes(communityId) ? ids : ids.concat(communityId)));
        return addFavorite({ variables: { communityId } });
      },
      clearFavorites: () => {
        setCommunityIds([]);
        return clearFavorites();
      },
      removeFavorite: communityId => {
        setCommunityIds(ids => ids.filter(id => id !== communityId));
        return removeFavorite({ variables: { communityId } });
      }
    }),
    [addFavorite, removeFavorite, clearFavorites, setCommunityIds]
  );

  const errors = useMemo(
    () =>
      getApolloErrors(
        addFavoriteResult.error,
        clearFavoritesResult.error,
        removeFavoriteResult.error
      ),
    [addFavoriteResult.error, clearFavoritesResult.error, removeFavoriteResult.error]
  );

  return useMemo(() => [communityIds, actions, errors], [communityIds, actions, errors]);
}
