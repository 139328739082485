import type { AppConfig } from '@/components/AppConfig';
import { COLOR_EY_BLACK, COLOR_EY_YELLOW } from '@/css/constants';

export type ServerConfigObject = {
  application_applicationName: string | null;
  application_branding_analyticsTrackingId: string | null;
  application_branding_logoFileId: string | null;
  application_branding_primaryColor: string | null;
  application_branding_primaryFont: string | null;
  application_branding_secondaryColor: string | null;
  application_branding_secondaryFont: string | null;
  application_contributionSimilarity_enabled: boolean;
  application_customerName: string | null;
  application_defaultLocaleId: string;
  application_jargon_community: Api.JargonContainer['community'];
  application_jargon_contribution: Api.JargonContainer['contribution'];
  application_jargon_program: Api.JargonContainer['program'];
  application_jargon_ideationModule: Api.JargonContainer['ideationModule'];
  application_languageDetection_enabled: boolean;
  application_portalVersion: string;
  application_tagExtraction_enabled: boolean;
  application_url: string | null;
  application_userManagement_isPrivateUserPool: boolean;
  application_userMustSelectLocaleId: boolean;
  application_userRegistration_enabled: boolean;
  authentication_endpoints_login: string;
  authentication_endpoints_logout: string;
  authentication_silentLogin_enabled: boolean;
  file_endpoint: string;
  graphQl_endpoint: string;
  graphQl_supportsIntrospection: boolean;
  graphQl_supportsQueryBatching: boolean;
  viewer_communityCount: number;
  viewer_favoriteCommunityIds: string[];
  viewer_hasUnsubmittedAndDeclinedGlobalUserAgreements: boolean;
  viewer_isAuthenticated: boolean;
  viewer_localeId: string;
  viewer_userId: string;
};

export function loadAppConfig(url: string, debug = false): Promise<AppConfig> {
  return fetch(url, { credentials: 'include' })
    .then(response => response.json())
    .then((json: Partial<ServerConfigObject>) => {
      const config: AppConfig = {
        application_applicationName: json.application_applicationName ?? 'CogniStreamer',
        application_branding_analyticsTrackingId:
          json.application_branding_analyticsTrackingId ?? null,
        application_branding_logoFileId: json.application_branding_logoFileId ?? null,
        application_branding_primaryColor:
          json.application_branding_primaryColor ?? COLOR_EY_YELLOW,
        application_branding_primaryFont: json.application_branding_primaryFont ?? null,
        application_branding_secondaryColor:
          json.application_branding_secondaryColor ?? COLOR_EY_BLACK,
        application_contributionSimilarity_enabled:
          json.application_contributionSimilarity_enabled ?? false,
        application_defaultLocaleId: json.application_defaultLocaleId ?? 'en',
        application_languageDetection_enabled: json.application_languageDetection_enabled ?? false,
        application_portalVersion: json.application_portalVersion ?? '0.0.0',
        application_tagExtraction_enabled: json.application_tagExtraction_enabled ?? false,
        application_url: json.application_url ?? '',
        application_userMustSelectLocaleId: json.application_userMustSelectLocaleId ?? false,
        auth_login_endpoint: json.authentication_endpoints_login ?? '/auth/login',
        auth_logout_endpoint: json.authentication_endpoints_logout ?? '/auth/logout',
        auth_silentLogin_enabled: json.authentication_silentLogin_enabled ?? false,
        file_endpoint: json.file_endpoint ?? '/api/files',
        graphql_endpoint: json.graphQl_endpoint ?? '/api/graph',
        graphql_supportsIntrospection: json.graphQl_supportsIntrospection ?? false,
        graphql_supportsQueryBatching: json.graphQl_supportsQueryBatching ?? false,
        // TODO: We currently don't have a corresponding field on the /api/info.
        // Should probably add it at some point for safety.
        image_endpoint: '/api/images',
        jargon: {
          community: json.application_jargon_community ?? 'COMMUNITY',
          contribution: json.application_jargon_contribution ?? 'CONTRIBUTION',
          ideationModule: json.application_jargon_ideationModule ?? 'CHALLENGE',
          program: json.application_jargon_program ?? 'PROGRAM'
        },
        viewer_communityCount: json.viewer_communityCount ?? 0,
        viewer_favoriteCommunityIds: json.viewer_favoriteCommunityIds ?? [],
        viewer_hasUnsubmittedAndDeclinedGlobalUserAgreements:
          json.viewer_hasUnsubmittedAndDeclinedGlobalUserAgreements ?? false,
        viewer_isAuthenticated: json.viewer_isAuthenticated ?? false,
        viewer_localeId: json.viewer_localeId ?? null,
        viewer_userId: json.viewer_userId ?? ''
      };

      if (debug) {
        console.info('----------------------------------'); // eslint-disable-line no-console
        console.info('Running with the following config:'); // eslint-disable-line no-console
        console.info('----------------------------------'); // eslint-disable-line no-console
        console.table(config); // eslint-disable-line no-console
        console.info('----------------------------------'); // eslint-disable-line no-console
      }

      return config;
    });
}
