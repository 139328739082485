import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { CommunityFilterValues } from '@/features/communities/config/components/CommunityFilterList';
import { fetchDocument } from '@/utils/api/fetcher';
import { communityKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetCommunities, type GetCommunitiesQueryVariables } from './graphql/GetCommunities.gql';

type QueryVariables = Pick<GetCommunitiesQueryVariables, 'sortOrder'> & {
  filter: Partial<CommunityFilterValues>;
};

export function getCommunitiesQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: communityKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetCommunities, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  sortOrder: 'POSITION_ASC',
  filter: {}
};

export function communitiesLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getCommunitiesQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetCommunitiesQueryVariables {
  const {
    filter: { creators, searchText },
    sortOrder
  } = variables;

  const filter: NonNullable<GetCommunitiesQueryVariables['filter']> = {};

  if (creators?.length) {
    filter.creatorIds = creators.map(c => c.id);
  }

  if (searchText?.trim()) {
    filter.searchText = searchText.trim();
  }

  return {
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
