import { css } from '@emotion/react';
import { getColorToken, getMotifColorToken, getToken } from '@/css/utils';

export const mentionStyle = css({
  backgroundColor: getColorToken('blue050'),
  borderRadius: 4,
  fontSize: '0.95em',
  fontWeight: getToken('fontWeights', 'semibold'),
  color: '#334e68',
  padding: '0.125em 0.4em',
  ':hover': {
    textDecoration: 'none',
    color: getColorToken('blue700')
  },
  '::before': {
    content: '"@"',
    opacity: 0.5,
    fontWeight: getToken('fontWeights', 'normal'),
    lineHeight: 1,
    paddingRight: 1
  }
});

export const preStyle = css({
  backgroundColor: getMotifColorToken('grey050'),
  borderRadius: getToken('radii', 'lg'),
  border: `1px solid ${getColorToken('grey200')}`,
  whiteSpace: 'pre-wrap',
  fontSize: getToken('fontSizes', 'md'),
  lineHeight: getToken('lineHeights', 'snug'),
  padding: getToken('space', 's04'),
  tabSize: 2,
  overflowX: 'auto',
  position: 'relative',
  marginBlock: getToken('space', 's04')
});

export const richTextStyle = css({
  lineHeight: getToken('lineHeights', 'normal'),
  overflowWrap: 'anywhere',
  a: {
    color: getColorToken('link'),
    fontWeight: getToken('fontWeights', 'normal'),
    ':hover': {
      color: getColorToken('linkHover'),
      textDecoration: 'underline'
    }
  },
  'strong, b': {
    fontWeight: getToken('fontWeights', 'bold')
  },
  'a[data-mention-user-id]': mentionStyle,
  'ul, ol': {
    marginBlock: getToken('space', 's04'),
    paddingInlineStart: getToken('space', 's08')
  },
  'ul ul, ul ol, ol ul, ol ol': {
    marginBlock: 0
  },
  'code, pre': {
    fontFamily: getToken('fonts', 'mono')
  },
  // Inline code element
  code: {
    backgroundColor: getColorToken('grey050'),
    border: `1px solid ${getColorToken('grey200')}`,
    borderRadius: getToken('radii', 'base'),
    fontSize: '0.8em',
    paddingInline: getToken('space', 's01'),
    paddingBlock: 2,
    whiteSpace: 'nowrap'
  },
  // The lexical editor is using <code>, even though it should be <pre><code /></pre>
  pre: preStyle,
  hr: {
    borderColor: getColorToken('grey300'),
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    marginBlock: getToken('space', 's06')
  },
  blockquote: {
    borderLeft: `4px solid ${getColorToken('grey300')}`,
    color: getColorToken('textLight'),
    fontSize: getToken('fontSizes', 'xl'),
    marginInline: 0,
    marginBlock: getToken('space', 's06'),
    paddingInlineStart: getToken('space', 's06'),
    paddingBlock: getToken('space', 's04')
  },
  img: {
    maxWidth: '100%',
    marginInline: 'auto'
  },
  figure: {
    margin: 0,
    display: 'inline-block',
    img: {
      borderRadius: getToken('radii', 'base'),
      display: 'block'
    },
    figcaption: {
      textAlign: 'center',
      fontStyle: 'italic',
      color: getColorToken('textLight'),
      fontSize: getToken('fontSizes', 'md')
    },
    'img + figcaption': {
      marginTop: 3,
      paddingBlock: getToken('space', 's01')
    }
  },
  table: {
    borderCollapse: 'collapse',
    marginBlock: getToken('space', 's04'),
    wordBreak: 'normal',
    wordWrap: 'normal'
  },
  'table, td, th': {
    border: `1px solid ${getColorToken('grey300')}`
  },
  'td, th': {
    lineHeight: getToken('lineHeights', 'tight'),
    paddingInline: getToken('space', 's02'),
    verticalAlign: 'top'
  },
  td: {
    paddingBlock: getToken('space', 's02')
  },
  th: {
    fontWeight: getToken('fontWeights', 'semibold'),
    paddingBlock: getToken('space', 's01')
  },
  'img[data-align="start"]': { marginInlineStart: 0, marginInlineEnd: 'auto' },
  'img[data-align="start"] + figcaption': { textAlign: 'start' },
  'img[data-align="end"]': { marginInlineStart: 'auto', marginInlineEnd: 0 },
  'img[data-align="end"] + figcaption': { textAlign: 'end' },
  'img[data-width="two-thirds"]': { maxWidth: '66.66666667%' },
  'img[data-width="half"]': { maxWidth: '50%' },
  'img[data-width="one-third"]': { maxWidth: '33.33333333%' },
  'p + figure, figure + figure, figure + p': {
    marginTop: getToken('space', 's04')
  },
  '.videoEmbed-container': {
    height: 0,
    paddingBottom: '56.25%',
    position: 'relative',
    maxWidth: '100%',
    width: '100%',
    'iframe, object, embed': {
      height: '100%',
      left: 0,
      position: 'absolute',
      top: 0,
      width: '100%'
    }
  }
});
