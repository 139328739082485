import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { color, compose, space, type TextAlignProps, typography } from 'styled-system';
import type {
  FontFamilyProps,
  SpaceProps,
  TextColorProps,
  TextWrapProps
} from '@/components/primitives/types';
import { textWrap } from '@/components/primitives/utils';
import { css, variant } from '@/theme/utils';
import type { HeadingTagName, HeadingVariant } from './utils';

export type HeadingProps = FontFamilyProps &
  SpaceProps &
  TextAlignProps &
  TextColorProps &
  TextWrapProps & {
    as?: HeadingTagName;
    id?: string;
    showOutline?: boolean;
    variant: HeadingVariant;
  };

export const HeadingBase = styled('h1', { shouldForwardProp })<HeadingProps>(
  css({
    color: 'neutral800',
    marginBottom: 0,
    marginTop: 0,
    outlineOffset: 2,
    transition: 'font-size 200ms ease-out'
  }),
  props => css({ outline: props.showOutline ? undefined : 'none' }),
  compose(
    color,
    space,
    typography,
    textWrap,
    variant<'heading'>({
      prop: 'variant',
      variants: {
        heading: {
          // The original line-heights have been overridden with unit-less line-
          // heights, because a) it's easier and b) the original line-heights
          // were causing issues with the heading outlines having a weird shape
          // in Chromium browsers. These issues were not caused by the "rem"
          // units and can occur in unit-less line-heights as well, but they're
          // just easier to tweak if there's no unit involved.
          // Left the original line-heights in (but commented) in case we want
          // to dig into the "why this happens with outlines" at some point.
          100: {
            color: 'textLight',
            fontSize: '0.75rem',
            fontWeight: 'normal',
            letterSpacing: 0.6,
            lineHeight: 1.4,
            textTransform: 'uppercase'
            // lineHeight: '1rem',
            // marginTop: '1rem'
          },
          200: {
            color: 'textLight',
            fontSize: '0.875rem',
            fontWeight: 'semibold',
            lineHeight: 1.25
            // lineHeight: '1rem'
            // marginTop: '1rem'
          },
          300: {
            fontSize: '0.875rem',
            fontWeight: 'semibold',
            lineHeight: 1.25
            // lineHeight: '1rem',
            // marginTop: '1rem'
          },
          400: {
            fontSize: '1rem',
            fontWeight: 'semibold',
            letterSpacing: -0.05,
            lineHeight: 1.25
            // lineHeight: '1.25rem'
            // marginTop: '1rem'
          },
          500: {
            fontSize: '1.125rem',
            fontWeight: 'semibold', // 500
            letterSpacing: -0.05,
            lineHeight: 1.25
            // lineHeight: '1.25rem'
            // marginTop: '1.5rem'
          },
          600: {
            fontSize: '1.25rem',
            fontWeight: 'semibold', // 500
            letterSpacing: -0.07,
            lineHeight: 1.25
            // lineHeight: '1.5rem'
            // marginTop: '1.75rem'
          },
          700: {
            fontSize: '1.5rem',
            fontWeight: 'semibold', // 500
            letterSpacing: -0.1,
            lineHeight: 1.25
            // lineHeight: '1.75rem',
            // marginTop: '2.5rem'
          },
          800: {
            fontSize: '1.75rem',
            fontWeight: 'semibold', // 500
            letterSpacing: -0.5,
            lineHeight: 1.25
            // lineHeight: '2rem'
            // marginTop: '2.5rem'
          },
          900: {
            fontSize: '2rem',
            fontWeight: 'semibold', // 500
            letterSpacing: -0.75,
            lineHeight: 1.25
            // lineHeight: '2.5rem',
            // marginTop: '3.25rem'
          }
        }
      }
    })
  )
);
