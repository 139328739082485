import type { ElementType, ReactNode } from 'react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { color, compose, typography } from 'styled-system';
import { variant } from '@/theme/utils';
import type {
  FontFamilyProps,
  FontSizeProps,
  FontWeightProps,
  LetterSpacingProps,
  LineHeightProps,
  TextAlignProps,
  TextColorProps,
  TextWrapProps
} from './types';
import { textWrap } from './utils';

type TextStyle = 'capitalize' | 'lowercase' | 'uppercase';

export type TextProps = FontFamilyProps &
  FontSizeProps &
  FontWeightProps &
  LetterSpacingProps &
  LineHeightProps &
  TextAlignProps &
  TextColorProps &
  TextWrapProps & {
    as?: ElementType;
    children?: ReactNode;
    textStyle?: TextStyle;
    title?: string;
  };

export const Text = styled('div', { shouldForwardProp })<TextProps>(
  compose(
    color,
    textWrap,
    typography,
    // TODO: Not sure why styled-system makes this a variant, could just use the
    // textTransform prop instead.
    variant<TextStyle>({
      prop: 'textStyle',
      variants: {
        capitalize: { textTransform: 'capitalize' },
        lowercase: { textTransform: 'lowercase' },
        uppercase: { textTransform: 'uppercase' }
      }
    })
  )
);
