export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(decimals))} ${sizes[i]}`;
};

export const kBToBytes = (kiloBytes: number): number => kiloBytes * 1024;

export const mBToBytes = (megaBytes: number): number => megaBytes * 2 ** 20;

export const gBToBytes = (gigaBytes: number): number => gigaBytes * 2 ** 30;
