import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { border, layout, type LayoutProps } from 'styled-system';
import type { BorderProps } from '@/components/primitives/types';

type Props = BorderProps &
  LayoutProps & {
    alt?: string;
    src: string | undefined;
  };

export const Image = styled('img', { shouldForwardProp })<Props>(layout, border);

Image.defaultProps = {
  maxWidth: '100%'
};
