import type { MouseEventHandler } from 'react';
import { useI18n } from '@/components/I18n';
import { Icon } from '@/components/Icon';
import { NumberText } from '@/components/NumberText';
import { Flex } from '@/components/primitives';
import { UnstyledButton } from '@/components/UnstyledButton';
import type { ColorToken } from '@/css/types';
import { getColorToken } from '@/css/utils';

type Props = {
  allowVoting: boolean;
  count: number;
  isActive?: boolean;
  label: string;
  mode: 'down' | 'up';
  onClick: MouseEventHandler<HTMLButtonElement>;
};

export const ContributionCardVote = ({
  allowVoting,
  count,
  isActive = false,
  label,
  mode,
  onClick
}: Props) => {
  const i18n = useI18n();

  let buttonColor: ColorToken = 'neutral500';
  let textColor: ColorToken = 'text';

  let buttonAriaLabel: string;
  let buttonHoverColor: ColorToken;

  if (mode === 'down') {
    if (isActive) {
      buttonAriaLabel = i18n.t('main', 'voteButtons.undoDownvoteButton.helpText');
      buttonColor = 'red500';
      buttonHoverColor = 'red700';
      textColor = 'red700';
    } else {
      buttonAriaLabel = i18n.t('main', 'voteButtons.downvoteButton.helpText');
      buttonHoverColor = 'red500';
    }
  } else if (isActive) {
    buttonAriaLabel = i18n.t('main', 'voteButtons.undoUpvoteButton.helpText');
    buttonColor = 'green500';
    buttonHoverColor = 'green700';
    textColor = 'green700';
  } else {
    buttonAriaLabel = i18n.t('main', 'voteButtons.upvoteButton.helpText');
    buttonHoverColor = 'green500';
  }

  const valueText = (
    <NumberText
      aria-hidden
      color={textColor}
      fontFamily="system"
      fontSize="md"
      fontVariantNumeric="tabular-nums"
      fontWeight="semibold"
      lineHeight="none"
    >
      {count}
    </NumberText>
  );

  return (
    <Flex alignItems="center" title={label}>
      {allowVoting ? (
        <UnstyledButton
          aria-label={buttonAriaLabel}
          css={{
            color: getColorToken(buttonColor),
            ':hover': { color: getColorToken(buttonHoverColor) }
          }}
          onClick={onClick}
        >
          <Flex alignItems="center" gap="s01">
            <svg
              aria-hidden
              data-testid={`icon--thumbs-${mode}-solid`}
              fill="currentColor"
              height={20}
              role="presentation"
              viewBox="0 0 20 20"
              width={20}
              xmlns="http://www.w3.org/2000/svg"
            >
              {mode === 'up' ? (
                <path d="M2 10.5a1.5 1.5 0 113 0v6a1.5 1.5 0 01-3 0v-6zM6 10.333v5.43a2 2 0 001.106 1.79l.05.025A4 4 0 008.943 18h5.416a2 2 0 001.962-1.608l1.2-6A2 2 0 0015.56 8H12V4a2 2 0 00-2-2 1 1 0 00-1 1v.667a4 4 0 01-.8 2.4L6.8 7.933a4 4 0 00-.8 2.4z" />
              ) : (
                <path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z" />
              )}
            </svg>
            {valueText}
          </Flex>
        </UnstyledButton>
      ) : (
        <Flex alignItems="center" gap="s01">
          <Icon color={buttonColor} size={18} type={mode === 'up' ? 'thumbs-up' : 'thumbs-down'} />
          {valueText}
        </Flex>
      )}
    </Flex>
  );
};
