import { generatePath } from 'react-router-dom';

type View = 'logs' | 'submissions';

export const USER_AGREEMENT_PATH = ':userAgreementId/*';

type GetUserAgreementUrl = (id: string, options?: { view?: View }) => string;

export const getUserAgreementUrl: GetUserAgreementUrl = (userAgreementId, options) =>
  generatePath(USER_AGREEMENT_PATH, { userAgreementId, '*': options?.view ?? null });
