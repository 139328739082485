import { forwardRef, type ReactNode } from 'react';
import { css } from '@emotion/react';
import type { FlexDirectionProps } from 'styled-system';
import { Flex } from '@/components/primitives';
import { getBoolean } from '@/utils/store';

type Props = {
  children?: ReactNode;
  className?: string;
  debugName: string;
  flexDirection?: FlexDirectionProps['flexDirection'];
  id?: string;
  role?: string;
};

const debugA11y = getBoolean('isA11yDebugEnabled');
const disabledOutlineStyle = debugA11y ? undefined : css({ outline: 0 });

export const FocusWrapper = forwardRef<HTMLDivElement, Props>(
  ({ debugName, flexDirection = 'column', ...props }, ref) => (
    <Flex
      ref={ref}
      css={disabledOutlineStyle}
      data-testid={`focusWrapper-${debugName}`}
      flex={1}
      flexDirection={flexDirection}
      tabIndex={-1}
      {...props}
    />
  )
);
