import styled from '@emotion/styled';
import {
  bottom,
  type BottomProps,
  left,
  type LeftProps,
  right,
  type RightProps,
  top,
  type TopProps
} from 'styled-system';
import { Box } from './Box';

type Props = BottomProps & LeftProps & RightProps & TopProps;

export const Sticky = styled(Box)<Props>({ position: 'sticky' }, bottom, left, right, top);
