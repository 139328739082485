import { useMemo } from 'react';
import { type ItemTransformer, useCacheableTransformer } from './useCacheableTransformer';

/**
 * Computes item transforms and efficiently caches the results in an internal
 * WeakMap, so that they are automatically garbage collected when the source
 * item is dereferenced.
 * The given transform function does *not* need to be referentially stable, but
 * also should not be dynamic in nature since items that are cached will never
 * be recomputed with a newer transform function on subsequent renders.
 *
 * See {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap.}
 *
 * @param items The source items to transform.
 * @param transform The transform function.
 * @returns Array with the result of applying the transform function to the
 * source items.
 */
export function useWeakTransformCache<I extends object, O>(
  items: I[] | undefined,
  transform: ItemTransformer<I, O>
) {
  const transformer = useCacheableTransformer(transform);

  return useMemo(() => transformer(items), [items, transformer]);
}
