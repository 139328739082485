import { memo, type ReactElement, useState } from 'react';
import { Outlet } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useAppConfigStore, useAppConfigValue } from '@/components/AppConfig';
import { DateFnsLocaleProvider } from '@/components/I18n';
import type { AuthenticationClient } from '@/libs/cs-core-auth-client';
import { createFileApiClient, FileApiProvider } from '@/libs/cs-core-file-upload';
import { PendingGlobalUserAgreementsPage } from '@/pages/PendingGlobalUserAgreementsPage';
import { ApolloClientProvider } from '@/utils/apollo/ApolloClientProvider';
import { join } from '@/utils/url';

type Props = {
  authClient: AuthenticationClient;
  queryClient: QueryClient;
  debug?: boolean;
};

export const AuthenticatedRoot = memo<Props>(({ authClient, queryClient, debug = false }) => {
  const fileUrl = join(useAppConfigValue('application_url'), useAppConfigValue('file_endpoint'));
  const [fileApiClient] = useState(() => createFileApiClient({ uri: fileUrl }));
  const { reloadConfig } = useAppConfigStore();

  let content: ReactElement;

  if (useAppConfigValue('viewer_hasUnsubmittedAndDeclinedGlobalUserAgreements')) {
    content = <PendingGlobalUserAgreementsPage onSubmissionCompleted={reloadConfig} />;
  } else {
    content = <Outlet />;
  }

  return (
    <ApolloClientProvider authClient={authClient} debug={debug}>
      <QueryClientProvider client={queryClient}>
        <FileApiProvider client={fileApiClient}>
          <DateFnsLocaleProvider>
            {content}
            <ReactQueryDevtools initialIsOpen={false} />
          </DateFnsLocaleProvider>
        </FileApiProvider>
      </QueryClientProvider>
    </ApolloClientProvider>
  );
});
