import { createContext } from 'react';
import type { FileUploadOptions } from '../types';

/** @public */
export interface FileApiContextInterface {
  abortPendingUploads: () => void;
  uploadFile: (file: File, options?: FileUploadOptions) => Promise<string>;
}

const noop = () => {};
const noopPromise = () =>
  Promise.reject(new Error('Error: Could not find an instance of FileApiClient.'));

export const FileApiContext = createContext<FileApiContextInterface>({
  abortPendingUploads: noop,
  uploadFile: noopPromise
});
