import type { ReactNode } from 'react';
import { AutoPlacementGrid, type GridProps } from '@/components/AutoPlacementGrid';
import { useI18n } from '@/components/I18n';
import { LoadingMessage } from '@/components/LoadingMessage';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import { type Task, TaskCard, TaskCardSkeleton } from '../TaskCard';

export type { Task };

type Props = GridProps & {
  isLoading: boolean;
  placeholderCount: number;
  tasks: Task[];
};

export const TaskGrid = ({ columnCount, gap, isLoading, placeholderCount, tasks }: Props) => {
  const i18n = useI18n();
  let content: ReactNode;

  if (isLoading && tasks.length === 0) {
    content = (
      <>
        <VisuallyHidden>
          <LoadingMessage />
        </VisuallyHidden>
        <AutoPlacementGrid columnCount={columnCount} gap={gap}>
          {Array.from({ length: placeholderCount }, (_, index) => (
            <TaskCardSkeleton key={index} />
          ))}
        </AutoPlacementGrid>
      </>
    );
  } else if (tasks.length > 0) {
    content = (
      <AutoPlacementGrid columnCount={columnCount} gap={gap}>
        {tasks.map((task, index) => (
          <TaskCard key={index} task={task} />
        ))}
      </AutoPlacementGrid>
    );
  } else {
    content = i18n.t('main', 'taskList.empty.text');
  }

  return <>{content}</>;
};
