import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, roleKeys } from '../utils';
import { GetRoles, type GetRolesQueryVariables } from './graphql/GetRoles.gql';

type QueryVariables = GetRolesQueryVariables;

export function getRolesQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: roleKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetRoles, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'NAME_ASC'
};

export function rolesLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getRolesQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetRolesQueryVariables {
  const {
    searchText,
    pagination: { page, size },
    sortOrder
  } = variables;

  const _searchText = searchText?.trim();

  return {
    pagination: { page, size },
    sortOrder,
    searchText: _searchText || undefined
  };
}
