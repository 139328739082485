import { css } from '@emotion/react';
import { Icon, type IconType } from '@/components/Icon';
import { Box, Flex, Text } from '@/components/primitives';
import type { ColorToken } from '@/css/types';
import { truncateText } from '@/utils/common';

type Props = {
  title: string;
  type: Api.ModuleType;
};

const cardStyle = css({ opacity: 0.95 });

const moduleMap: Readonly<Record<Api.ModuleType, [IconType, ColorToken]>> = {
  ARCHIVE_MODULE: ['archive', 'grey600'],
  ENRICHMENT_MODULE: ['file-text', 'blue600'],
  IDEATION_MODULE: ['plus-circle', 'neutral600'],
  REVIEW_MODULE: ['star', 'red600']
};

export const ContributionCardLabel = ({ type, title }: Props) => {
  const [icon, bgColor] = moduleMap[type];

  return (
    <Box bgColor={bgColor} css={cardStyle} data-testid="moduleLabel" px="s02" py="s01">
      <Flex alignItems="center" gap="s02">
        <Icon color="white" size={16} type={icon} />
        <Text
          color="white"
          fontSize="sm"
          fontWeight="semibold"
          letterSpacing="tight"
          lineHeight="snug"
        >
          <bdi>{truncateText(title, 48)}</bdi>
        </Text>
      </Flex>
    </Box>
  );
};
