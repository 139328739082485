import { css } from '@emotion/react';
import { getColorToken } from '@/css/utils';

// This is hardcoded inside <NavLink> and added by default. Exporting is from
// here rather than the component in order to avoid a cyclic dependency error.
/** @public */
export const ACTIVE_CLASS_NAME = 'active';

export const link = css`
  color: ${getColorToken('neutral200')};
  display: block;
  width: 100%;

  :focus {
    outline: 1px solid #fff;
    outline-offset: -1px;
  }

  :hover,
  :active {
    color: ${getColorToken('white')};

    [data-icon-type] {
      stroke: ${getColorToken('primary')};
    }
  }
`;

const activeStyle = css`
  background-color: rgba(0, 0, 0, 0.2);
  color: ${getColorToken('white')};

  [data-icon-type] {
    stroke: ${getColorToken('primary')};
  }
`;

export const activeLink = css(link, activeStyle);

export const navLink = css`
  ${link};

  &.${ACTIVE_CLASS_NAME} {
    ${activeStyle}
  }
`;
