import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import type { WrappedError } from '@/components/ErrorAlertList';
import type { SuggestedContent } from '@/features/search/types';
import { removeEmptyElementsFromArray } from '@/utils/common';
import { getApolloErrors } from '@/utils/errors';
import { useDebouncedValue } from '@/utils/hooks/useDebouncedValue';
import { GetCompactSearchResults } from './GetCompactSearchResults.gql';

type SearchValue = string | null | undefined;

type QueryVars = {
  query?: SearchValue;
  searchTypes: Api.SimpleSearchType[];
  size: number;
};

const SEARCH_TYPES: QueryVars['searchTypes'] = [
  'CONTRIBUTION',
  'MODULE',
  'PROGRAM',
  'USER_PROFILE'
];

const PAGE_SIZE = 20;

type HookContext = {
  errors: WrappedError[];
  isLoading: boolean;
  setSearchText: (value: SearchValue) => void;
};

type HookReturnValue = [SuggestedContent[], HookContext];

export function useCompactSearch(): HookReturnValue {
  const [value, setValue] = useState<SearchValue>();
  const debouncedValue = useDebouncedValue(value, 200);

  const { data, error, networkStatus } = useQuery(GetCompactSearchResults, {
    skip: !debouncedValue,
    variables: {
      query: debouncedValue,
      searchTypes: SEARCH_TYPES,
      size: PAGE_SIZE
    }
  });

  const items = data?.simpleSearch.result.items;

  // TODO: SearchResult.title should be non-nullable
  const parsedItems: SuggestedContent[] = useMemo(
    () =>
      removeEmptyElementsFromArray(items ?? []).map(item => ({
        ...item,
        title: item.title ?? ''
      })),
    [items]
  );

  const context: HookContext = useMemo(
    () => ({
      errors: getApolloErrors(error),
      isLoading: networkStatus === 1,
      setSearchText: setValue
    }),
    [error, networkStatus]
  );

  return [value ? parsedItems : [], context];
}
