import type { ReactChild, ReactNode } from 'react';
import { Badge } from '@/components/Badge';
import { Divider } from '@/components/Divider';
import { Heading } from '@/components/Heading';
import { IconButton } from '@/components/IconButton';
import { Box, Flex } from '@/components/primitives';
import { useId } from '@/utils/hooks/useId';
import { useToggle } from '@/utils/hooks/useToggle';
import { UiList } from './DashboardFilterListItemGroup';

type Props = {
  children: ReactNode;
  count: number;
  hasDividerBefore?: boolean;
  label: ReactChild;
  toggleButtonLabel: string;
};

export const DashboardFilterListCollapsibleItemGroup = ({
  children,
  count,
  hasDividerBefore = false,
  label,
  toggleButtonLabel
}: Props) => {
  const panelId = useId('panel');
  const headingId = useId('heading');
  const [isExpanded, { toggle }] = useToggle(false);

  return (
    <>
      {hasDividerBefore && (
        <li role="presentation">
          <Divider color="grey200" spacing="s04" />
        </li>
      )}
      <li>
        <Flex alignItems="center" gap="s02" justifyContent="space-between" mb="s03">
          <Flex alignItems="center" flex={1} justifyContent="space-between">
            <Heading color="textLight" id={headingId} variant="heading.400">
              {label}
            </Heading>
            <Badge color="blue" fontSize="sm">
              {count}
            </Badge>
          </Flex>
          <IconButton
            aria-controls={panelId}
            aria-expanded={isExpanded}
            aria-label={toggleButtonLabel}
            icon={isExpanded ? 'chevron-up' : 'chevron-down'}
            onClick={toggle}
          />
        </Flex>
        <Box
          aria-hidden={!isExpanded}
          aria-labelledby={headingId}
          as={UiList}
          display={isExpanded ? undefined : 'none'}
          id={panelId}
        >
          {children}
        </Box>
      </li>
    </>
  );
};
