import type { ReactChild, ReactNode } from 'react';
import { Flex } from '@/components/primitives';

type Slots = {
  content: ReactChild;
  contentBefore?: ReactNode;
  contentAfter?: ReactNode;
};

type Props = {
  slots: Slots;
};

export const DashboardFilterListItemLayout = ({ slots }: Props) => (
  <Flex alignItems="center" as="li" gap="s03" py="s01">
    {slots.contentBefore}
    {slots.content}
    {slots.contentAfter}
  </Flex>
);
