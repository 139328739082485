import {
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react';
import type { To } from 'react-router-dom';
import styled from '@emotion/styled';
import { AppHeader, type MenuItem, type User } from '@/components/AppHeader';
import { AppSidebarMenu } from '@/components/AppSidebarMenu';
import { CloseButton } from '@/components/CloseButton';
import { useI18n } from '@/components/I18n';
import { NavigationBar } from '@/components/NavigationBar';
import { Box, Flex } from '@/components/primitives';
import { Sidebar } from '@/components/Sidebar';
import { VisuallyHidden } from '@/components/VisuallyHidden';
import { breakpoints } from '@/css/breakpoints';
import { getMotifColorToken } from '@/css/utils';
import { variant } from '@/theme/utils';
import { exhaustiveCheck } from '@/types/utils';
import { useMatchMedia } from '@/utils/hooks/useMatchMedia';
import { useUpdateEffect } from '@/utils/hooks/useUpdateEffect';
import { AppShellContext, type AppShellContextValue } from './AppShellContext';

type State = {
  hasParent: boolean;
  isSidebarOpen: boolean;
  parentRoute: To | null;
  title: string | null;
};

type Action =
  | {
      type: 'SET_PARENT';
      payload: { hasParent: boolean; parentRoute: To | null };
    }
  | {
      type: 'SET_TITLE';
      payload: { title: string | null };
    }
  | {
      type: 'TOGGLE_SIDEBAR';
      payload: { isOpen: boolean | undefined };
    };

function reducer(state: State, action: Action): State {
  let nextState = state;

  if (action.type === 'SET_PARENT') {
    const { hasParent, parentRoute } = action.payload;
    nextState = { ...state, hasParent, parentRoute };
  } else if (action.type === 'SET_TITLE') {
    nextState = { ...state, title: action.payload.title };
  } else if (action.type === 'TOGGLE_SIDEBAR') {
    nextState = { ...state, isSidebarOpen: action.payload.isOpen ?? !state.isSidebarOpen };
  } else {
    /* istanbul ignore next */ exhaustiveCheck(action);
  }

  return nextState;
}

const INITIAL_STATE: State = {
  hasParent: false,
  isSidebarOpen: false,
  parentRoute: null,
  title: null
};

type Props = {
  children: ReactNode;
  hasDpoAssigned: boolean;
  ideationModuleRequestsRoute: To;
  logoFileId: string | null;
  logoutUrl: string;
  menuConfig: MenuItem[];
  profileRoute: To;
  user: User;
};

/**
 * Shifts the main content off-screen when the sidebar is opened. Whether or not
 * this happens in an animated way can be dynamically set in order to support
 * moving everything back in place immediately when moving into desktop
 * breakpoints while having the sidebar open, since we don't want to animate
 * that particular transition.
 */
const AnimatedSidebarSiblingWrapper = styled(Flex)<{ isAnimated: boolean; isPushedAside: boolean }>(
  variant<boolean>({
    prop: 'isAnimated',
    variants: {
      true: {
        transition: 'transform 250ms ease-in-out'
      }
    }
  }),
  variant<boolean>({
    prop: 'isPushedAside',
    variants: {
      true: {
        transform: 'translateX(100vw)'
      },
      false: {
        transform: 'unset'
      }
    }
  })
);

export const AppShell = ({
  children,
  hasDpoAssigned,
  ideationModuleRequestsRoute,
  logoFileId,
  logoutUrl,
  menuConfig,
  profileRoute,
  user
}: Props) => {
  const i18n = useI18n();
  const isMdOrHigher = useMatchMedia(`(min-width: ${breakpoints.md})`);
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const restoreFocusTimerRef = useRef<number | null>(null);

  /**
   * Stores a reference to the last toggle button that opened the sidebar. Used
   * to restore focus after the sidebar close button gets clicked.
   */
  const lastSidebarTriggerRef = useRef<HTMLButtonElement | null>(null);

  const toggleSidebar = useCallback((isOpen?: boolean) => {
    dispatch({ type: 'TOGGLE_SIDEBAR', payload: { isOpen } });
  }, []);

  const closeSidebar = useCallback(() => toggleSidebar(false), [toggleSidebar]);

  /**
   * Toggles the sidebar and stores a reference to its event target so that we
   * can restore focus to it when the sidebar gets closed.
   */
  const handleToggleButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      lastSidebarTriggerRef.current = event.currentTarget;
      toggleSidebar();
    },
    [toggleSidebar]
  );

  /**
   * Closes the sidebar and attempts to restore focus to the toggle button that
   * initially opened the sidebar.
   *
   * We take care of focus restoration here rather than using FocusScope's
   * `restoreFocus` prop inside Sidebar because we don't want to restore focus
   * when the sidebar closes as result of a nav item being clicked, but only
   * when the sidebar is closed as a result of clicking the close button.
   */
  const handleCloseButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    closeSidebar();

    if (restoreFocusTimerRef.current) {
      clearTimeout(restoreFocusTimerRef.current);
    }

    if (lastSidebarTriggerRef.current) {
      restoreFocusTimerRef.current = window.setTimeout(() => {
        lastSidebarTriggerRef.current?.focus();
        lastSidebarTriggerRef.current = null;
        restoreFocusTimerRef.current = null;
      }, 50);
    }
  }, [closeSidebar]);

  const contextValue: AppShellContextValue = useMemo(
    () => ({
      setParent: (hasParent, parentRoute) => {
        dispatch({ type: 'SET_PARENT', payload: { hasParent, parentRoute } });
      },
      setTitle: title => {
        dispatch({ type: 'SET_TITLE', payload: { title } });
      }
    }),
    []
  );

  const { isSidebarOpen, hasParent, parentRoute, title } = state;

  // Closes the sidebar when switching from mobile to desktop view.
  useUpdateEffect(() => {
    if (isMdOrHigher && isSidebarOpen) {
      toggleSidebar(false);
    }
  }, [isMdOrHigher, isSidebarOpen, toggleSidebar]);

  // Clean up any lingering timers.
  useEffect(
    () => () => {
      if (restoreFocusTimerRef.current) {
        clearTimeout(restoreFocusTimerRef.current);
      }
    },
    []
  );

  const menuLabel = i18n.t('common', 'mainNavigation.helpText');
  const menuToggleButtonText = isSidebarOpen
    ? i18n.t('common', 'mainNavigation.toggleMenuButton.helpText_open')
    : i18n.t('common', 'mainNavigation.toggleMenuButton.helpText_closed');

  /**
   * We're doing a couple of things here. Some basic a11y guidelines that were
   * followed:
   *
   *   1. The navigation element should always be visible, regardless of whether
   *      the actual navigation menu _inside of it_ is collapsed or not.
   *   2. If a navigation menu is collapsible (i.e. our sidebar), the first
   *      element inside the <nav> should be a toggle button
   *      (aria-expanded=false) so that AT users can still use their shortcuts
   *      to jump to the <nav> and can then simply click the toggle button
   *      inside it to open it.
   *
   * When we try to apply the above guidelines we immediately run into some
   * issues:
   *
   *   1. We have two different "navigation menu" navs, one for desktop and one
   *      for mobile, with the latter obviously only available on mobile
   *      breakpoints and collapsed by default, complemented by a toggle button.
   *   2. The mobile <nav> toggle button is impossible to get inside the <nav>
   *      since the sidebar is completely decoupled... Or is it?
   *
   * Approach:
   *
   *   - By leveraging useMatchMedia() we can programatically implement logic
   *     and switch between the mobile & desktop nav while making sure there is
   *     absolutely no overlap. This is our starting point, as having 2
   *     navigations with the same name at the same time is a big no-no.
   *   - As soon as we hit the mobile breakpoint, we render a "dummy" <nav>
   *     wrapper for the collapsible sidebar with the same label as the desktop
   *     nav. This implements guideline #1 and resolves issue #1.
   *   - The toggle button inside <NavigationBar> will _always_ be outside the
   *     navigation, there's no way around it. What we can do however, is
   *     consider this toggle button to be for non-AT users, and provide a
   *     secondary visually-hidden button _inside_ the dummy <nav> we set up.
   *     This way AT users can still use their assistive shortcuts to
   *     immediately jump to the "navigation menu" mobile nav, and will then be
   *     greeted by a "Show navigation menu" toggle button also indicating that
   *     the actual menu is currently collapsed (aria-expanded=false). Click the
   *     button, show the menu, Bob's your uncle. This implements guideline #2
   *     and partially solves issue #2.
   *   - Issue #2 is only partially solved because now we have an expanded
   *     sidebar that contains 2 toggle buttons: one visually-hidden button that
   *     we set up earlier, and a second non-AT close button that is part of the
   *     sidebar itself, while we only want one. This second button will also
   *     automatically receive focus as soon as the sidebar opens (see
   *     FocusScope in <Sidebar>). One approach could be to "unrender" the
   *     initial toggle button and only display the close button, but then we
   *     break the "restore focus" behavior for AT users when the sidebar gets
   *     closed. An alternative approach is to put an "aria-hidden" attribute
   *     on this button for as long as the sidebar is expanded. As soon as the
   *     sidebar is closed the focus can then be restored back to the
   *     visually-hidden toggle button (or to the AppHeader toggle button if you
   *     clicked the non-AT toggle button). This completely resolves issue #2.
   */

  return (
    <AppShellContext.Provider value={contextValue}>
      {!isMdOrHigher && (
        /**
         * Not sure what the best approach is here... Rendering a heading or
         * labelling the <nav> directly. We should be careful not to add too
         * many headings to the page, however, gov.co.uk for example (which is a
         * good reference) uses a visually-hidden h2 for the main navigation.
         * The weird thing is that this would most likely be a red flag during
         * audits since it's not preceeded by an h1 which is a bad practice. For
         * now let's stick to an aria-label until we find a reason to do
         * otherwise.
         */
        <nav aria-label={menuLabel} data-testid="sidebar-nav">
          <VisuallyHidden>
            <button
              aria-expanded={isSidebarOpen}
              aria-hidden={isSidebarOpen}
              onClick={handleToggleButtonClick}
              type="button"
            >
              {menuToggleButtonText}
            </button>
          </VisuallyHidden>
          <Sidebar bgColor={getMotifColorToken('grey500')} isOpen={isSidebarOpen}>
            <Box py="s06">
              <Box mb="s04" mx="s05">
                <CloseButton
                  aria-expanded={isSidebarOpen}
                  aria-label={menuToggleButtonText}
                  color="white"
                  hoverBgColor="transparent"
                  hoverColor="neutral500"
                  onClick={handleCloseButtonClick}
                  size="xl"
                  title={menuToggleButtonText}
                />
              </Box>
              <AppSidebarMenu
                hasDpoAssigned={hasDpoAssigned}
                logoutUrl={logoutUrl}
                onMenuItemClick={closeSidebar}
              />
            </Box>
          </Sidebar>
        </nav>
      )}
      <AnimatedSidebarSiblingWrapper
        bgColor={getMotifColorToken('background')}
        flexDirection="column"
        isAnimated={!isMdOrHigher}
        isPushedAside={isSidebarOpen}
        minHeight="100vh"
      >
        <header>
          {isMdOrHigher ? (
            <AppHeader
              ideationModuleRequestsRoute={ideationModuleRequestsRoute}
              logoFileId={logoFileId}
              logoutUrl={logoutUrl}
              menuAriaLabel={menuLabel}
              menuConfig={menuConfig}
              profileRoute={profileRoute}
              user={user}
            />
          ) : (
            <NavigationBar
              hasParent={hasParent}
              isMenuExpanded={isSidebarOpen}
              menuToggleButtonText={menuToggleButtonText}
              onToggleMenuClick={handleToggleButtonClick}
              parentRoute={parentRoute}
            >
              {title}
            </NavigationBar>
          )}
        </header>
        {children}
      </AnimatedSidebarSiblingWrapper>
    </AppShellContext.Provider>
  );
};
