import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  alignContent,
  alignItems,
  type AlignItemsProps,
  compose,
  gridColumn,
  gridColumnGap,
  gridGap,
  gridRowGap,
  gridTemplateColumns,
  gridTemplateRows,
  justifyContent,
  justifyItems,
  layout,
  space
} from 'styled-system';
import type { GridProps } from './types';

export const Grid = styled('div', { shouldForwardProp })<AlignItemsProps & GridProps>(
  { display: 'grid' },
  compose(
    alignContent,
    alignItems,
    gridColumnGap,
    gridGap,
    gridColumn,
    gridRowGap,
    gridTemplateColumns,
    gridTemplateRows,
    justifyContent,
    justifyItems,
    space,
    layout
  )
);
