type User = {
  firstName: string | null;
  lastName: string | null;
};

export function getFullName({ firstName, lastName }: User) {
  return [firstName?.trim(), lastName?.trim()].filter(Boolean).join(' ');
}

export function getInitials({ firstName, lastName }: User) {
  return [firstName?.trim().charAt(0), lastName?.trim().charAt(0)]
    .filter(Boolean)
    .join('')
    .toUpperCase();
}

export function getAvatarColor(userId: string) {
  const substring = userId.substring(0, 8);
  let hash = 0;

  for (let i = 0; i < substring.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = userId.charCodeAt(i) + ((hash << 5) - hash);
  }

  return `hsl(${hash % 360}, 80%, 40%)`;
}
