import type { ReactElement, ReactNode } from 'react';
import {
  Menu,
  MenuButton,
  type MenuProps,
  MenuProvider,
  type MenuStoreProps,
  useMenuStore,
  useStoreState
} from '@ariakit/react';
import { css } from '@emotion/react';
import { Card } from '@/components/primitives';
import { useI18n } from '../I18n';
import { DropdownIconMenuButton } from './DropdownIconMenuButton';
import { DropdownMenuDivider } from './DropdownMenuDivider';
import { DropdownMenuItem } from './DropdownMenuItem';

type StaticComponents = {
  Divider: typeof DropdownMenuDivider;
  Item: typeof DropdownMenuItem;
  IconMenuButton: typeof DropdownIconMenuButton;
  MenuButton: typeof MenuButton;
};

type Props = Pick<MenuProps, 'gutter'> &
  Pick<MenuStoreProps, 'placement'> & {
    children?: ReactNode;
    menuButton?: ReactElement;
    width?: number | string;
  };

type Component = (props: Props) => ReactElement;

const menuStyle = css({
  // See https://ariakit.org/guide/styling#--popover-available-height
  maxHeight: 'var(--popover-available-height)',
  overflowY: 'auto',
  textAlign: 'initial',
  ':focus': {
    // Focus outline is not initially displayed but when the cursor is moved
    // outside the menu, the focus will be displayed. This will hide it.
    outline: 0
  }
});

export const DropdownMenu: Component & StaticComponents = ({
  children,
  gutter = 4,
  placement = 'bottom-end',
  menuButton,
  width = '16em',
  ...props
}) => {
  const { isRtl } = useI18n();
  const menu = useMenuStore({ placement });
  const isMenuOpen = useStoreState(menu, 'open');

  return (
    <MenuProvider store={menu}>
      {menuButton || <DropdownIconMenuButton />}
      {isMenuOpen && (
        <Menu
          {...props}
          gutter={gutter}
          // Fixes the issue when the button is used inside another clickable element
          onClick={event => event.stopPropagation()}
          render={
            <Card
              bgColor="white"
              border={1}
              borderColor="grey300"
              borderRadius="base"
              boxShadow="sm"
              css={menuStyle}
              dir={isRtl ? 'rtl' : 'ltr'}
              p="s01"
              width={width}
              zIndex={5}
            />
          }
        >
          {children}
        </Menu>
      )}
    </MenuProvider>
  );
};

DropdownMenu.Divider = DropdownMenuDivider;
DropdownMenu.Item = DropdownMenuItem;
DropdownMenu.IconMenuButton = DropdownIconMenuButton;
DropdownMenu.MenuButton = MenuButton;
