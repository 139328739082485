import { createContext } from 'react';
import type { FormTheme } from '@/features/forms/types';

export type FormContextValue = {
  theme: FormTheme;
};

export const FormContext = createContext<FormContextValue>({
  theme: {
    actions: undefined,
    form: undefined,
    formContent: undefined
  }
});
