export class ResourceError extends Error {
  resourceId: string;

  constructor(resourceId: string, message: string) {
    super(message);
    this.name = 'ResourceError';
    this.resourceId = resourceId;
  }
}

export class ResourceNotFoundError extends ResourceError {
  constructor(resourceId: string) {
    super(resourceId, `Requested resource "${resourceId}" could not be found.`);
    this.name = 'ResourceNotFoundError';
  }
}
