import { createPath, type To } from 'react-router-dom';
import type { DraftViewOptions } from '@/components/GlobalModalManager';
import { exhaustiveCheck } from '@/types/utils';
import { join } from '@/utils/url';
import type { GetDashboardLocation } from './main/DashboardPage';
import type { RouteConfig } from './config';

type ContributionPageOptions = {
  dialog?: 'edit';
  view?: 'comments' | 'similarities' | 'timeline' | ['timeline', TimelineRouteOptions];
};

type TimelineRouteOptions =
  | {
      type: Extract<Api.ModuleType, 'ARCHIVE_MODULE' | 'IDEATION_MODULE'>;
      id: string;
    }
  | {
      type: Extract<Api.ModuleType, 'ENRICHMENT_MODULE' | 'REVIEW_MODULE'>;
      id: string;
      view?: 'comments';
    };

export type MainLocationMap = {
  getContributionPage(id: string, options?: ContributionPageOptions): To;
  getCurrentUserIdeationModuleRequestPage(
    programId: string,
    requestId: string,
    view?: 'activity'
  ): To;
  getCurrentUserIdeationModuleRequestsPage(): To;
  getCurrentUserProfilePage(): To;
  getDraftsModal(options?: DraftViewOptions): To;
  getEnrichmentModuleTaskListPage(id: string, contributionId?: string): To;
  getHomePage: GetDashboardLocation;
  getModulePage(id: string, dialog?: 'add-contribution'): To;
  getProgramPage(id: string, dialog?: 'add-contribution'): To;
  getReportListPage(): To;
  getReportPage(id: string): To;
  getReviewModuleTaskListPage(id: string, contributionId?: string): To;
  getSearchPage(searchText?: string | null, tags?: string[]): To;
  getSubmittedUserAgreementsModal(id?: string): To;
  getUserProfilePage(id: string): To;
};

// The commented out routes are currently only as reference. There's no simple
// way to use these paths in react-router v6 due to the new relative routing.
const mainPaths = {
  contribution: '/contributions/:contributionId',
  // contribution_timeline: '/contributions/:contributionId/timeline',
  // contribution_similarities: '/contributions/:contributionId/similarities',
  currentUserProfile: '/profiles/me',
  enrichmentModuleTaskList: '/tasks/enrichments/:moduleId/:contributionId?',
  feed: '/feed',
  home: '/',
  // home_feed: '/feed',
  // home_programs: '/programs',
  // home_tasks: '/tasks',
  // community: '/communities/:communityId',
  // community_feed: '/communities/:communityId/feed',
  // community_programs: '/communities/:communityId/programs',
  // community_tasks: '/communities/:communityId/tasks',
  module: '/modules/:moduleId',
  program: '/programs/:programId',
  report: '/reports/:reportId',
  reportList: '/reports',
  reviewModuleTaskList: '/tasks/reviews/:moduleId/:contributionId?',
  search: '/search',
  userProfile: '/profiles/:userId'
} as const;

export type MainPathMap = typeof mainPaths;

export const mainRouteConfig: RouteConfig<MainPathMap, MainLocationMap> = {
  paths: mainPaths,
  locations: {
    getContributionPage: (id, options = {}) => {
      let pathname: string;

      const params = new URLSearchParams();

      if (options.dialog) {
        params.set('m', options.dialog);
      }

      if (options.view) {
        if (options.view === 'comments') {
          // Default route
          pathname = `/contributions/${id}`;
        } else if (options.view === 'similarities') {
          pathname = `/contributions/${id}/similarities`;
        } else if (options.view === 'timeline') {
          pathname = `/contributions/${id}/timeline`;
        } else {
          const [view, viewOptions] = options.view;

          if (view === 'timeline') {
            if (viewOptions.type === 'ARCHIVE_MODULE' || viewOptions.type === 'IDEATION_MODULE') {
              pathname = `/contributions/${id}/timeline/${viewOptions.id}`;
            } else if (
              viewOptions.type === 'ENRICHMENT_MODULE' ||
              viewOptions.type === 'REVIEW_MODULE'
            ) {
              pathname =
                viewOptions.view === 'comments'
                  ? `/contributions/${id}/timeline/${viewOptions.id}/comments`
                  : `/contributions/${id}/timeline/${viewOptions.id}`;
            } else {
              /* istanbul ignore next */ exhaustiveCheck(viewOptions.type);
            }
          } else {
            /* istanbul ignore next */ exhaustiveCheck(view);
          }
        }
      } else {
        pathname = `/contributions/${id}`;
      }

      return createPath({ pathname, search: params.toString() });
    },
    getCurrentUserIdeationModuleRequestPage: (programId, requestId, commentId) =>
      commentId
        ? join('/challenge-requests', requestId, programId, 'activity')
        : join('/challenge-requests', requestId, programId),
    getCurrentUserIdeationModuleRequestsPage: () => '/challenge-requests',
    getCurrentUserProfilePage: () => '/profiles/me',
    // Can't use createPath({ hash }) here since it defaults to path=/ and redirects to dashboard
    getEnrichmentModuleTaskListPage: (id, contributionId) =>
      contributionId ? `/tasks/enrichments/${id}/${contributionId}` : `/tasks/enrichments/${id}`,
    getHomePage: (options = {}) => {
      const params = new URLSearchParams();

      if (options.dialog) {
        params.set('m', options.dialog);
      }

      const segments = ['/'];

      if (Array.isArray(options.filter)) {
        segments.push('communities', options.filter[1]);
      } else if (options.filter === 'all') {
        params.set('filter', options.filter);
      } else {
        // Do nothing, filter=favorites always uses the `/` route.
      }

      if (options.view && options.view !== 'overview') {
        segments.push(options.view);
      }

      return createPath({ pathname: join(...segments), search: params.toString() });
    },
    // Can't use createPath({ hash }) here since it defaults to path=/ and redirects to dashboard
    getDraftsModal: options => {
      const params = new URLSearchParams({ m: 'drafts' });

      if (options?.view && options.view !== 'draftList') {
        params.set('view', options.view);

        if (options.view === 'invalidDraft') {
          params.set('id', options.id);
        }
      }

      return `#${params}`;
    },
    getModulePage: (id, dialog) => (dialog ? `/modules/${id}?m=${dialog}` : `/modules/${id}`),
    getProgramPage: (id, dialog) => (dialog ? `/programs/${id}?m=${dialog}` : `/programs/${id}`),
    getReportListPage: () => '/reports',
    getReportPage: id => `/reports/${id}`,
    getReviewModuleTaskListPage: (id, contributionId) =>
      contributionId ? `/tasks/reviews/${id}/${contributionId}` : `/tasks/reviews/${id}`,
    getSearchPage: (searchText, tags) => {
      const params = [];

      if (searchText) {
        params.push(searchText);
      }

      if (Array.isArray(tags) && tags.length > 0) {
        params.push(tags.map(tag => `#${tag}`).join(' '));
      }

      return createPath({
        pathname: '/search',
        search:
          params.length > 0
            ? new URLSearchParams({ query: params.join(' ') }).toString()
            : undefined
      });
    },
    getSubmittedUserAgreementsModal: id => {
      const params = new URLSearchParams({ m: 'user-agreements' });

      if (id) {
        params.set('id', id);
      }

      return `#${params}`;
    },
    getUserProfilePage: id => `/profiles/${id}`
  }
};
