import { type ReactElement, useLayoutEffect } from 'react';
import { useAppConfigValue } from '@/components/AppConfig';
import type { AuthenticationClient } from '@/libs/cs-core-auth-client';

type Props = {
  authenticationClient: AuthenticationClient;
  children: ReactElement;
};

export const AuthenticationGuard = ({ authenticationClient, children }: Props) => {
  const isAuthenticated = useAppConfigValue('viewer_isAuthenticated');

  useLayoutEffect(() => {
    if (!isAuthenticated) {
      authenticationClient.login(window.location.href);
    }
  }, [authenticationClient, isAuthenticated]);

  if (isAuthenticated) {
    return children;
  }

  return null;
};
