import type { ComponentProps, ReactNode } from 'react';
import { Box, Card, Flex } from '@/components/primitives';
import { Article } from '@/components/SectioningContentElement';

type Slots = {
  deadline: ReactNode;
  heading: ReactNode;
  parent: ReactNode;
  progressBar: ReactNode;
  progressText: ReactNode;
  status: ReactNode;
  type: ReactNode;
};

// TODO: Can't use CardProps here as it lacks the `css` prop. Investigate.
type Props = ComponentProps<typeof Card> & { slots: Slots };

// TODO: Check if it's necessary to put explicit `key`s on things in order to
// aid react's reconciling.

export const TaskCardLayout = ({ slots, ...props }: Props) => (
  <Card
    as={Article}
    bgColor="white"
    boxShadow="md"
    display="flex"
    flexDirection="column"
    position="relative"
    {...props}
  >
    <Box flex={1} p="s04">
      <Flex alignItems="flex-start" justifyContent="space-between" mb="s03">
        {slots.deadline}
        {slots.status}
      </Flex>
      {slots.heading}
      <Flex alignItems="center">{slots.parent}</Flex>
    </Box>
    <Flex alignItems="center" justifyContent="space-between" mb="s03" px="s04">
      {slots.progressText}
      {slots.type}
    </Flex>
    {slots.progressBar}
  </Card>
);
