import type { ReactNode } from 'react';
import type { To } from 'react-router-dom';
import { Heading } from '@/components/Heading';
import { Box, Flex, Text } from '@/components/primitives';
import { ViewMoreLink } from './ViewMoreLink';

type Props = {
  children: string;
  headingId: string;
  itemCount?: number;
  sideContent?: ReactNode;
  viewMoreLocation?: To;
};

export const SectionHeader = ({
  children,
  headingId,
  itemCount,
  sideContent,
  viewMoreLocation
}: Props) => (
  // Aligning this on flex-end in order to have a consistent spacing between
  // the heading and the elements below. Because of the "additionalContent"
  // this could otherwise vary quite a bit due to i.e. dropdowns.
  <Box mb={{ _: 's04', md: 's06' }}>
    <Flex alignItems="flex-end" flexWrap="wrap" gap="s04" justifyContent="space-between">
      <Flex alignItems="baseline" flexWrap="wrap">
        <Heading id={headingId} variant="heading.600">
          {children}
        </Heading>
        {viewMoreLocation && (
          <>
            <Flex mx="0.625rem">
              <Text as="span" color="grey400" fontSize="xl" fontWeight="normal" lineHeight="none">
                &mdash;
              </Text>
            </Flex>
            <ViewMoreLink
              fontSize="lg"
              fontWeight="semibold"
              itemCount={itemCount}
              lineHeight="none"
              to={viewMoreLocation}
            />
          </>
        )}
      </Flex>
      <Flex alignItems="center">{sideContent}</Flex>
    </Flex>
  </Box>
);
