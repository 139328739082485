import { type ReactNode, useMemo } from 'react';
import { useI18n } from '@/components/I18n';
import { Link } from '@/components/Link';
import { LoadingMessage } from '@/components/LoadingMessage';
import { Box, Flex, Text } from '@/components/primitives';
import { ThemedDonutLoader } from '@/components/ThemedDonutLoader';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';

type Props = {
  isLoading: boolean;
  onRequestClose: () => void;
  searchText: string | null | undefined;
};

const useMessages = () => {
  const i18n = useI18n();

  return useMemo(
    () => ({
      emptyText: i18n.t('main', 'searchResultList.empty.heading'),
      infoText: i18n.t('main', 'searchResultList.info.heading'),
      loadingText: <LoadingMessage />,
      searchPageLinkText: i18n.t('main', 'searchForm.searchPageLink.text')
    }),
    [i18n]
  );
};

export const AppHeaderSearchComboboxEmptyState = ({
  isLoading,
  onRequestClose,
  searchText
}: Props) => {
  const { getSearchPage } = useSharedLocations('main');
  const messages = useMessages();
  const searchUrl = getSearchPage(searchText);
  let heading: ReactNode;

  if (isLoading) {
    heading = messages.loadingText;
  } else if (!searchText) {
    heading = messages.infoText;
  } else {
    heading = messages.emptyText;
  }

  return (
    <Flex
      alignItems="center"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      minHeight={160}
      px="s08"
      role="alert"
    >
      {isLoading && (
        <Box mb="s02">
          <ThemedDonutLoader />
        </Box>
      )}
      <Text color="textLight" lineHeight="snug" textAlign="center">
        {heading}
      </Text>
      {!isLoading && (
        <Box mt="s02">
          <Link onClick={onRequestClose} to={searchUrl}>
            <Text fontSize="md">{messages.searchPageLinkText}</Text>
          </Link>
        </Box>
      )}
    </Flex>
  );
};
