import { lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { ErrorAlertList } from '@/components/ErrorAlertList';
import { FormModal } from '@/components/FormModal';
import { useI18n } from '@/components/I18n';
import { Box } from '@/components/primitives';
import { getApolloErrors } from '@/utils/errors';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { CONTRIBUTION_SUBMISSION_HISTORY_STATE_KEY } from '../../utils/constants';
import { GetIdeationModulesAvailableForContribution } from './GetIdeationModulesAvailableForContribution.gql';

const AddContributionFormWizard = lazy(async () => ({
  default: (await import('./AddContributionFormWizard')).AddContributionFormWizard
}));

type Props = {
  communityId?: string;
  moduleId?: string;
  onRequestClose: () => void;
  onSubmitted?: (id: string, isDraft: boolean) => void;
  programId?: string;
};

export const AddContributionFormWizardModal = ({
  communityId,
  moduleId: _moduleId,
  onRequestClose,
  onSubmitted,
  programId
}: Props) => {
  const title = useI18n().t('contribution', 'addContributionForm.title');
  const navigate = useNavigate();
  const { getContributionPage, getDraftsModal } = useSharedLocations('main');
  const hasInitialModule = Boolean(_moduleId);
  const queryResult = useQuery(GetIdeationModulesAvailableForContribution, {
    skip: hasInitialModule,
    variables: {
      filter: {
        communityIds: communityId ? [communityId] : undefined,
        programIds: programId ? [programId] : undefined
      },
      // We hardcode the pagination because the only thing we want to find out is if there is more
      // than one module that the user can choose from. If yes, we show the module selection step,
      // otherwise we take the (only) returned module and skip the selection step.
      pagination: { page: 1, size: 2 }
    }
  });

  let moduleId: string | undefined;

  if (hasInitialModule) {
    // A module id was passed as a prop, we just use that one rather than attempting to get one from
    // the (skipped) query.
    moduleId = _moduleId;
  } else if (queryResult.data?.modules.items.length === 1) {
    // No module id was passed as a prop and only one module was returned from the query. Since this
    // is the only module available, we'll use it by default and skip the module selection step.
    moduleId = queryResult.data.modules.items.at(0)?.id;
  }

  const errors = getApolloErrors(queryResult.error);

  return (
    <FormModal onRequestClose={onRequestClose} size="lg" title={title}>
      {errors.length > 0 && (
        <Box mb="s04">
          <ErrorAlertList errors={errors} />
        </Box>
      )}
      <AddContributionFormWizard
        communityId={communityId}
        moduleId={moduleId}
        onCancel={onRequestClose}
        onSubmitted={(contributionId, isDraft) => {
          onSubmitted?.(contributionId, isDraft);

          if (isDraft) {
            navigate(getDraftsModal());
          } else {
            // We're putting a parameter in the history state so the
            // contribution view knows to initialize the submission dialog.
            navigate(getContributionPage(contributionId), {
              state: { [CONTRIBUTION_SUBMISSION_HISTORY_STATE_KEY]: true }
            });
          }
        }}
        programId={programId}
      />
    </FormModal>
  );
};
