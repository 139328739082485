import styled from '@emotion/styled';

type Props = {
  borderRadius: string;
  color: string;
  percent: number;
};

export const ProgressBarInnerBar = styled.div<Props>`
  background: ${props => props.color};
  border-radius: ${props => props.borderRadius};
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: ${props => props.percent}%;
`;
