import type { MemberUnionFragment } from '@/features/common/graphql/fragments/MemberUnionFragment.gql';
import { parseMember } from '@/utils/api/parsers';
import type { MemberOption } from './types';

export const parseMemberOption = (
  member: MemberUnionFragment,
  isDisabled = false,
  statusText = null
): MemberOption => ({
  isDisabled,
  member: parseMember(member),
  statusText
});
