import { type ReactNode, useState } from 'react';
import { ApolloProvider } from '@apollo/react-common';
import { useAppConfigValue } from '@/components/AppConfig';
import type { AuthenticationClient } from '@/libs/cs-core-auth-client';
import { createApolloCache, createApolloClient } from '@/utils/apollo/createApolloClient';
import { useUpdateEffect } from '@/utils/hooks/useUpdateEffect';
import { join } from '@/utils/url';

const APOLLO_WARNING =
  'A new ApolloClient instance was created due to one of its dependencies changing reference. Generally this means bad news and should be avoided at all cost through memoization, unless this happened after a hot reload of the code.';

type Props = {
  authClient: AuthenticationClient;
  children: ReactNode;
  debug: boolean;
};

// We're keeping the cache separate and global in order not to loose it when for some reason we have
// to create a new ApolloClient instance.
const cache = createApolloCache();

export const ApolloClientProvider = ({ authClient, children, debug }: Props) => {
  const url = useAppConfigValue('application_url');
  const endpoint = useAppConfigValue('graphql_endpoint');
  const uri = join(url, endpoint);

  // The ApolloClient could potentially need to be be reinitialized when any of
  // its dependencies change, which in turn also needs to trigger a rerender of
  // the <ApolloProvider> below, therefore this is kept in state.
  const [client, setClient] = useState(() => createApolloClient(uri, { authClient, cache, debug }));

  /**
   * If on a subsequent render one of the apollo dependencies changes reference
   * we need to construct a new ApolloClient instance, otherwise we run into
   * problems.
   */
  useUpdateEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(APOLLO_WARNING); // eslint-disable-line no-console
    }

    setClient(createApolloClient(uri, { authClient, cache, debug }));
  }, [authClient, debug, uri]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
