import type { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { system } from 'styled-system';
import {
  alignItems,
  type AlignItemsProps,
  compose,
  flexDirection,
  type FlexDirectionProps,
  flexWrap,
  type FlexWrapProps,
  justifyContent,
  type JustifyContentProps
} from 'styled-system';
import { Box } from './Box';
import type { GapProps } from './types';

type BaseProps = AlignItemsProps &
  FlexDirectionProps &
  FlexWrapProps &
  GapProps &
  JustifyContentProps;

export type FlexProps = ComponentProps<typeof Flex>;

export const Flex = styled(Box)<BaseProps>(
  compose(
    alignItems,
    flexDirection,
    flexWrap,
    justifyContent,
    system({
      gap: {
        property: 'gap',
        scale: 'space'
      }
    })
  )
);

Flex.defaultProps = {
  display: 'flex'
};
