import { memo, type ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { usePortalTarget } from '@/utils/hooks/usePortalTarget';

type Props = {
  children: ReactNode;
  name: string;
};

export const Portal = memo<Props>(({ children, name }) =>
  createPortal(children, usePortalTarget(name))
);
