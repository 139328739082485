import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { type Scale, system } from 'styled-system';
import type { BoxProps } from '@/components/primitives';

type Props = {
  bottom?: BoxProps['marginBottom'];
  left?: BoxProps['marginLeft'];
  right?: BoxProps['marginRight'];
  top?: BoxProps['marginTop'];
  x?: BoxProps['mx'];
  y?: BoxProps['my'];
};

function getNegativeValue(value: number | string, scale?: Scale) {
  return `calc(${scale?.[value as number]} * -1)`;
}

/**
 * Renders a container with negative margins, allowing content to "bleed" (see
 * https://en.wikipedia.org/wiki/Bleed_(printing)) into the surrounding layout.
 * This is designed to make it easy to visually break out of a parent container
 * without having to refactor the entire component tree.
 */
export const Bleed = styled('div', { shouldForwardProp })<Props>(
  system({
    top: {
      property: 'marginTop',
      scale: 'space',
      transform: getNegativeValue
    },
    right: {
      property: 'marginRight',
      scale: 'space',
      transform: getNegativeValue
    },
    bottom: {
      property: 'marginBottom',
      scale: 'space',
      transform: getNegativeValue
    },
    left: {
      property: 'marginLeft',
      scale: 'space',
      transform: getNegativeValue
    },
    x: {
      properties: ['marginLeft', 'marginRight'],
      scale: 'space',
      transform: getNegativeValue
    },
    y: {
      properties: ['marginBottom', 'marginTop'],
      scale: 'space',
      transform: getNegativeValue
    }
  })
);
