import { css } from '@emotion/react';
import { getColorToken, getToken } from '@/css/utils';

export const input = css`
  background-color: ${getColorToken('neutral100')};
  border: 1px solid ${getColorToken('neutral300')};
  border-radius: 4px;
  box-shadow: none;
  color: ${getColorToken('blue800')};
  font-size: ${getToken('fontSizes', 'md')};
  line-height: ${getToken('lineHeights', 'normal')};
  outline: none;
  padding: 0.5rem 0.75rem;
  transition: border-color 150ms ease-out;
  width: 280px;

  :focus {
    border-color: ${getColorToken('neutral400')};
  }

  ::placeholder {
    color: ${getColorToken('textLight')};
  }
`;
