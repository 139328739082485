import type { ElementType, ReactNode } from 'react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  alignSelf,
  compose,
  type DisplayProps,
  flex,
  flexBasis,
  type FlexBasisProps,
  flexDirection,
  type FlexDirectionProps,
  type FlexProps,
  flexShrink,
  type FlexShrinkProps,
  gridColumn,
  type GridColumnProps,
  layout,
  type LayoutProps,
  order,
  type OrderProps,
  type OverflowProps,
  position,
  type PositionProps,
  space,
  system
} from 'styled-system';
import type {
  AlignSelfProps,
  BackgroundColorProps,
  BackgroundImageProps,
  BackgroundProps,
  SpaceProps,
  TextWrapProps
} from './types';
import { textWrap } from './utils';

export type BoxProps = AlignSelfProps &
  BackgroundColorProps &
  BackgroundImageProps &
  DisplayProps &
  FlexBasisProps &
  FlexDirectionProps &
  FlexProps &
  FlexShrinkProps &
  GridColumnProps &
  LayoutProps &
  OrderProps &
  OverflowProps &
  Pick<BackgroundProps, 'bg'> &
  PositionProps &
  SpaceProps &
  TextWrapProps & {
    as?: ElementType;
    children?: ReactNode;
  };

const background = system({
  bgColor: {
    property: 'backgroundColor',
    scale: 'colors'
  },
  bgImage: {
    property: 'backgroundImage'
  },
  bg: {
    property: 'background',
    scale: 'colors'
  }
});

export const Box = styled('div', { shouldForwardProp })<BoxProps>(
  {
    boxSizing: 'border-box'
  },
  compose(
    alignSelf,
    background,
    position,
    flex,
    flexBasis,
    flexDirection,
    flexShrink,
    gridColumn,
    layout,
    order,
    space,
    textWrap
  )
);

Box.defaultProps = {
  display: 'block'
};
