import _shouldForwardProp from '@styled-system/should-forward-prop';

export const shouldForwardProp = (prop: string) =>
  _shouldForwardProp(prop) ||
  prop === 'to' ||
  prop === 'state' ||
  prop === 'reloadDocument' ||
  prop === 'replace' ||
  prop === 'preventScrollReset' ||
  prop === 'end';
