import { useState } from 'react';
import { Text, type TextProps } from '@/components/primitives';
import { useUpdateEffect } from '@/utils/hooks/useUpdateEffect';
import { isNumber, isNumeric } from '@/utils/number';

// See https://developer.mozilla.org/en-US/docs/Web/CSS/font-variant-numeric
type FontVariantNumeric =
  | 'diagonal-fractions'
  | 'lining-nums'
  | 'normal'
  | 'oldstyle-nums stacked-fractions'
  | 'oldstyle-nums'
  | 'ordinal'
  | 'proportional-nums'
  | 'slashed-zero'
  | 'stacked-fractions'
  | 'tabular-nums'
  // Using the literal union + freeform string intellisense hack in order to
  // get intellisense:
  // https://github.com/microsoft/TypeScript/issues/29729#issuecomment-661959682
  | (string & {});

// Using an alias here to clarify the format of these locales (BCP-47). This
// used to be available as `Intl.BCP47LanguageTag` but seemingly got removed
// from the TS definitions in 4.3.
type BCP47LanguageTag = string;

type Props = Omit<TextProps, 'children'> & {
  children?: number | string;
  fontVariantNumeric?: FontVariantNumeric;
  locales?: BCP47LanguageTag | BCP47LanguageTag[];
  options?: Intl.NumberFormatOptions;
};

function getNumberFormatInstance(
  locales?: BCP47LanguageTag | BCP47LanguageTag[],
  initialOptions?: Intl.NumberFormatOptions
): Intl.NumberFormat | undefined {
  let options = initialOptions;

  if (options?.currencyDisplay === 'narrowSymbol') {
    // "narrowSymbol" is currently not supported in IE and non-chromium Edge.
    options = { ...options, currencyDisplay: 'symbol' };
  }

  return new Intl.NumberFormat(locales, options);
}

export const NumberText = ({
  children: value,
  fontVariantNumeric,
  locales,
  options,
  ...props
}: Props) => {
  const [formatter, setFormatter] = useState(() => getNumberFormatInstance(locales, options));

  let outputValue: string = (value ?? '').toString();

  if (formatter) {
    if (isNumber(value)) {
      outputValue = formatter.format(value);
    } else if (isNumeric(value)) {
      outputValue = formatter.format(parseInt(value, 10));
    }

    if (options?.currencyDisplay === 'narrowSymbol') {
      // "narrowSymbol" is currently not supported in IE and non-chromium Edge.
      // We mimic its behavior by stripping of "US". Of course this only deals
      // with USD... But that's the biggest issue.
      outputValue = outputValue.replace('US$', '$');
    }
  }

  useUpdateEffect(() => {
    setFormatter(getNumberFormatInstance(locales, options));
  }, [locales, options]); // Suboptimal since we'll probably lose ref on a rerender

  let { fontFamily } = props;

  if (fontVariantNumeric && !fontFamily) {
    fontFamily = 'system';
  }

  return (
    <Text {...props} css={{ fontVariantNumeric }} fontFamily={fontFamily}>
      {outputValue}
    </Text>
  );
};
