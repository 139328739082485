import {
  Component,
  type ContextType,
  type ElementType,
  type ErrorInfo,
  type ReactNode
} from 'react';
import { ErrorAlert } from '@/components/ErrorAlert';
import { I18nContext } from '@/components/I18n';
import { RequestError } from '@/utils/api/error';
import { ResourceError, UserNotAuthorizedError } from '@/utils/errors';
import { ResourceNotFoundErrorBoundaryContent } from './ResourceNotFoundErrorBoundaryContent';
import { UserNotAuthorizedErrorBoundaryContent } from './UserNotAuthorizedErrorBoundaryContent';

type State = {
  error: Error | null;
  info: ErrorInfo | null;
};

const CHILDREN_VALUE_UNDEFINED_MSG =
  'Received a children prop with a value of `undefined`. Please check your code, as this would normally cause a runtime error and should be avoided.';

type Props = {
  children?: ReactNode;
  FallbackComponent?: ElementType;
  onError?(error: Error, info: ErrorInfo): void;
  rethrowAuthorizationError?: boolean;
  rethrowResourceError?: boolean;
  title?: string;
};

export class ErrorBoundary extends Component<Props, State> {
  static contextType = I18nContext;

  declare context: ContextType<typeof I18nContext>;

  state: State = { error: null, info: null };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ error, info });
    this.props.onError?.(error, info);
  }

  handleRetryClick = () => {
    this.setState({ error: null, info: null });
  };

  render() {
    const {
      FallbackComponent = ErrorAlert,
      rethrowAuthorizationError,
      rethrowResourceError,
      title
    } = this.props;
    const { error, info } = this.state;
    const i18n = this.context;

    if (error) {
      if (error.name === 'ChunkLoadError') {
        return (
          <FallbackComponent
            code="CHUNK_LOAD_ERROR"
            error={error}
            heading={i18n.t('common', 'applicationError.title_chunkLoadError')}
            message={i18n.t('common', 'applicationError.message_chunkLoadError')}
            stack={`${error.stack || ''}\n${info?.componentStack || ''}`}
          />
        );
      }

      if (error instanceof UserNotAuthorizedError) {
        if (rethrowAuthorizationError) {
          throw error;
        }

        return (
          <UserNotAuthorizedErrorBoundaryContent error={error} onRetry={this.handleRetryClick} />
        );
      }

      if (error instanceof RequestError && error.networkError instanceof UserNotAuthorizedError) {
        if (rethrowAuthorizationError) {
          throw error.networkError;
        }

        return (
          <UserNotAuthorizedErrorBoundaryContent
            error={error.networkError}
            onRetry={this.handleRetryClick}
          />
        );
      }

      if (error instanceof ResourceError) {
        if (rethrowResourceError) {
          throw error;
        }

        return <ResourceNotFoundErrorBoundaryContent />;
      }

      return (
        <FallbackComponent
          code="UNCAUGHT_REACT_ERROR"
          error={error}
          heading={title || i18n.t('common', 'applicationError.title')}
          // Initially we were planning to always show a generic error message
          // in a production build, however, the API tends to return errors
          // containing accurate descriptions of what went wrong, so we want to
          // forward these to the user when possible.
          message={error.message || i18n.t('common', 'applicationError.message')}
          onRequestRetry={this.handleRetryClick}
          stack={`${error.stack || ''}\n${info?.componentStack || ''}`}
        />
      );
    }

    /**
     * A react component cannot return `undefined` directly. Therefore, if
     * this.props.children happens to be `undefined`, this component will throw
     * an internal react error that gets minified in production and could be
     * rather tricky to hunt down.
     * We want to avoid the error in production, but want it visible in
     * development so that we can remediate immediately. To achieve this we'll
     * do a NODE_ENV check and in case of production will wrap the children in
     * a fragment (basically <>{undefined}</>) which works around the issue,
     * while simultaneously logging an error to the console for troubleshooting.
     *
     * This change was triggered by an issue with NSW on Sept 6th 2021, caused
     * by an incomplete union type in `ReviewSummaryListBuilder`.
     */
    if (process.env.NODE_ENV === 'development') {
      return this.props.children;
    }

    if (this.props.children === undefined) {
      // eslint-disable-next-line no-console
      console.error(CHILDREN_VALUE_UNDEFINED_MSG);
      return <>{this.props.children}</>;
    }

    return this.props.children;
  }
}
