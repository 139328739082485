import { memo, type ReactNode, useCallback, useMemo } from 'react';
import { createPath, type To, useLocation, useNavigate } from 'react-router-dom';
import { Divider } from '@/components/Divider';
import { ErrorAlertList, type WrappedError } from '@/components/ErrorAlertList';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import {
  HorizontalScrollContextProvider,
  type HorizontalScrollGridProps as GridProps
} from '@/components/HorizontalScrollGrid';
import { useI18n } from '@/components/I18n';
import { MotifSelect } from '@/components/MotifSelect';
import { Box } from '@/components/primitives';
import { HideAt, ShowAt } from '@/components/Responsive';
import { Section } from '@/components/SectioningContentElement';
import type { Contribution } from '@/features/contributions/components/ContributionGrid';
import {
  HorizontalScrollButtons,
  HorizontalScrollContributionGrid
} from '@/features/contributions/components/ContributionGrid/HorizontalScrollContributionGrid';
import { useContributionVoting } from '@/features/contributions/hooks/useContributionVoting';
import { type Module, ModuleGrid } from '@/features/modules/common/components/ModuleGrid';
import type { ModuleSortOrder } from '@/features/modules/common/utils/sortOrder';
import { type Task, TaskGrid } from '@/features/tasks/components/TaskGrid';
import type { ParsedMember } from '@/types';
import { exhaustiveCheck } from '@/types/utils';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import { GlobalContributionGridViewModal } from '../GlobalContributionGridViewModal';
import { GlobalModuleGridViewModal } from '../GlobalModuleGridViewModal';
import { Bleed } from './Bleed';
import { SectionHeader } from './SectionHeader';

export type { Contribution, Task };

type Community = {
  id: string;
  name: string;
};

export type CommunityFilter = Community[];

type SortOrderOption<TSortOrder> = {
  value: TSortOrder;
  label: string;
};

type Props = {
  communityFilter?: CommunityFilter;
  errors: WrappedError[];
  isLoading: boolean;
  moduleCount?: number;
  modules: Module[];
  moduleSortOrder: ModuleSortOrder;
  moduleSortOrderOptions: SortOrderOption<ModuleSortOrder>[];
  onModuleSortOrderChange: (sortOrder: ModuleSortOrder) => void;
  recentContributions: Contribution[];
  trendingContributions: Contribution[];
  tasks: Task[];
  taskCount?: number;
  tasksRoute: To;
  viewer: ParsedMember;
  viewerHasContributions?: boolean;
  viewerHasTasks?: boolean;
  viewerContributionCount?: number;
  viewerContributions: Contribution[];
};

const MODALS = [
  'challenges',
  'my-recent-contributions',
  'recent-contributions',
  'trending-contributions'
] as const;

type ModalType = (typeof MODALS)[number];

function isValidModal(value: string): value is ModalType {
  return MODALS.includes(value);
}

const GRID_GAP: GridProps['gap'] = { _: 's06', xxl: 's08' };
const CONTRIBUTION_COLS: GridProps['columnCount'] = { _: 1, xs: 2, md: 3, lg: 4, xxl: 6 };
const MODULE_COLS: GridProps['columnCount'] = { _: 1, xs: 2, xl: 3 };
const GRID_PAD_X: GridProps['px'] = { _: 's04', md: 's08', xl: 's12', xxl: 's08' };

const SectionDivider = () => <Divider color="grey200" spacing="s12" />;

export const OverviewView = memo<Props>(
  ({
    communityFilter,
    errors,
    isLoading,
    moduleCount,
    modules,
    moduleSortOrder,
    moduleSortOrderOptions,
    onModuleSortOrderChange,
    recentContributions,
    taskCount,
    tasks,
    tasksRoute,
    trendingContributions,
    viewer,
    viewerContributionCount,
    viewerContributions,
    viewerHasContributions = false,
    viewerHasTasks = false
  }) => {
    const i18n = useI18n();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);
    const onVote = useContributionVoting();

    const [id0, aria0] = useHeadingIdAriaPair();
    const [id1, aria1] = useHeadingIdAriaPair();
    const [id2, aria2] = useHeadingIdAriaPair();
    const [id3, aria3] = useHeadingIdAriaPair();
    const [id4, aria4] = useHeadingIdAriaPair();

    const activeModal = useMemo(() => {
      const param = params.get('m');
      return param && isValidModal(param) ? param : null;
    }, [params]);

    const handleModalClose = useCallback(() => {
      const _params = new URLSearchParams(params);
      _params.delete('m');
      navigate({ search: _params.toString() });
    }, [navigate, params]);

    // TODO: find a better approach to perhaps inject locations from above.
    function getLocation(modal: ModalType): To {
      const _params = new URLSearchParams(params);
      _params.set('m', modal);
      // Need to include `pathname` here as it defaults to "/" when not set. This
      // is also what was causing the draft modal "/" redirect a while ago.
      return createPath({ pathname, search: _params.toString() });
    }

    let modal: ReactNode = null;

    if (activeModal) {
      if (activeModal === 'challenges') {
        modal = (
          <GlobalModuleGridViewModal
            communityFilter={communityFilter}
            initialSortOrder={moduleSortOrder}
            onRequestClose={handleModalClose}
          />
        );
      } else if (
        activeModal === 'my-recent-contributions' ||
        activeModal === 'recent-contributions' ||
        activeModal === 'trending-contributions'
      ) {
        modal = (
          <GlobalContributionGridViewModal
            communityFilter={communityFilter}
            creatorOrInventorFilter={
              activeModal === 'my-recent-contributions' ? [viewer] : undefined
            }
            initialSortOrder={
              activeModal === 'trending-contributions' ? 'POPULARITY' : 'DATE_CREATED_DESC'
            }
            onRequestClose={handleModalClose}
          />
        );
      } else {
        /* istanbul ignore next */ exhaustiveCheck(activeModal);
      }
    }

    const showTasks = Boolean(viewerHasTasks || tasks.length);
    const showViewerContributions = Boolean(viewerHasContributions || viewerContributions.length);
    const hasModules = modules.length > 0;

    const modulesLocation = hasModules ? getLocation('challenges') : undefined;
    const recentContributionsLocation = recentContributions.length
      ? getLocation('recent-contributions')
      : undefined;

    const trendingContributionsLocation = trendingContributions.length
      ? getLocation('trending-contributions')
      : undefined;

    const moduleFilter = (
      <div css={{ visibility: hasModules ? undefined : 'hidden' }}>
        <MotifSelect
          label={i18n.t('common', 'sortOrder.helpText')}
          onChange={value => {
            if (value) onModuleSortOrderChange(value);
          }}
          options={moduleSortOrderOptions}
          value={moduleSortOrder}
        />
      </div>
    );

    return (
      <>
        {modal}

        {/** Errors */}
        {errors.length > 0 && (
          <Box mb="s04">
            <ErrorAlertList errors={errors} />
          </Box>
        )}

        {/** Pending tasks */}
        {showTasks && (
          <>
            <Section aria={aria0}>
              <SectionHeader headingId={id0} itemCount={taskCount} viewMoreLocation={tasksRoute}>
                {i18n.t('main', 'myIncompleteTasksList.title')}
              </SectionHeader>
              <ErrorBoundary>
                <TaskGrid
                  columnCount={{ _: 1, lg: 2, xl: 3 }}
                  gap={GRID_GAP}
                  isLoading={isLoading}
                  placeholderCount={3}
                  tasks={tasks}
                />
              </ErrorBoundary>
            </Section>
            <SectionDivider />
          </>
        )}

        {/** Session user contributions */}
        {showViewerContributions && (
          <HorizontalScrollContextProvider>
            <Section aria={aria1}>
              <SectionHeader
                headingId={id1}
                itemCount={viewerContributionCount}
                sideContent={
                  viewerContributions.length > 0 ? <HorizontalScrollButtons /> : undefined
                }
                viewMoreLocation={getLocation('my-recent-contributions')}
              >
                {i18n.t('contribution', 'myRecentContributionList.title')}
              </SectionHeader>
              <ErrorBoundary>
                <Bleed x={GRID_PAD_X}>
                  <HorizontalScrollContributionGrid
                    columnCount={CONTRIBUTION_COLS}
                    contributions={viewerContributions}
                    disableMyContributionBadge
                    gap={GRID_GAP}
                    isLoading={isLoading}
                    onVote={onVote}
                    placeholderCount={6}
                    px={GRID_PAD_X}
                    showChallenge
                  />
                </Bleed>
              </ErrorBoundary>
            </Section>
            <SectionDivider />
          </HorizontalScrollContextProvider>
        )}

        {/** Active ideation modules */}
        <Section aria={aria2}>
          <SectionHeader
            headingId={id2}
            itemCount={moduleCount}
            sideContent={<ShowAt breakpoint="md">{moduleFilter}</ShowAt>}
            viewMoreLocation={modulesLocation}
          >
            {i18n.t('challenge', 'ideationModule.label_multiple')}
          </SectionHeader>
          <HideAt breakpoint="md">
            <Box mb="s04">{moduleFilter}</Box>
          </HideAt>
          <ErrorBoundary>
            <ModuleGrid
              columnCount={MODULE_COLS}
              gap={GRID_GAP}
              isLoading={isLoading}
              modules={modules}
              placeholderCount={6}
            />
          </ErrorBoundary>
        </Section>

        <SectionDivider />

        {/** Recent contributions */}
        <HorizontalScrollContextProvider>
          <Section aria={aria3}>
            <SectionHeader
              headingId={id3}
              sideContent={recentContributions.length > 0 ? <HorizontalScrollButtons /> : undefined}
              viewMoreLocation={recentContributionsLocation}
            >
              {i18n.t('contribution', 'mostRecentContributionList.title')}
            </SectionHeader>
            <ErrorBoundary>
              <Bleed x={GRID_PAD_X}>
                <HorizontalScrollContributionGrid
                  columnCount={CONTRIBUTION_COLS}
                  contributions={recentContributions}
                  disableMyContributionBadge
                  gap={GRID_GAP}
                  isLoading={isLoading}
                  onVote={onVote}
                  placeholderCount={6}
                  px={GRID_PAD_X}
                  showChallenge
                />
              </Bleed>
            </ErrorBoundary>
          </Section>
        </HorizontalScrollContextProvider>

        <SectionDivider />

        {/** Trending contributions */}
        <HorizontalScrollContextProvider>
          <Section aria={aria4}>
            <SectionHeader
              headingId={id4}
              sideContent={
                trendingContributions.length > 0 ? <HorizontalScrollButtons /> : undefined
              }
              viewMoreLocation={trendingContributionsLocation}
            >
              {i18n.t('contribution', 'trendingContributionList.title')}
            </SectionHeader>
            <ErrorBoundary>
              <Bleed x={GRID_PAD_X}>
                <HorizontalScrollContributionGrid
                  columnCount={CONTRIBUTION_COLS}
                  contributions={trendingContributions}
                  disableMyContributionBadge
                  gap={GRID_GAP}
                  isLoading={isLoading}
                  onVote={onVote}
                  placeholderCount={6}
                  px={GRID_PAD_X}
                  showChallenge
                />
              </Bleed>
            </ErrorBoundary>
          </Section>
        </HorizontalScrollContextProvider>
      </>
    );
  }
);
