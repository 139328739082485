import { lazy, useState } from 'react';
import { useI18n } from '@/components/I18n';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import { GridModal } from '../../components/GridModal';
import type { Community, SortOrder } from './GlobalModuleGridViewContainer';

const GlobalModuleGridViewContainer = lazy(async () => ({
  default: (await import('./GlobalModuleGridViewContainer')).GlobalModuleGridViewContainer // prettier-ignore
}));

type Props = {
  communityFilter?: Community[];
  initialSortOrder?: SortOrder;
  onRequestClose?: () => void;
};

export const GlobalModuleGridViewModal = ({
  communityFilter,
  initialSortOrder,
  onRequestClose
}: Props) => {
  const i18n = useI18n();
  const [id, aria] = useHeadingIdAriaPair();
  const [count, setCount] = useState<number>();

  return (
    <GridModal aria={aria} onRequestClose={onRequestClose}>
      <GridModal.Header id={id} itemCount={count} onCloseClick={onRequestClose}>
        {i18n.t('challenge', 'ideationModule.label_multiple')}
      </GridModal.Header>
      <GridModal.Content>
        <GlobalModuleGridViewContainer
          columnCount={{ _: 1, md: 2, xl: 3, xxl: 4 }}
          communityFilter={communityFilter}
          gap={{ _: 's04', xl: 's06', xxl: 's08' }}
          initialPageSize={12}
          initialSortOrder={initialSortOrder}
          onCountChange={setCount}
        />
      </GridModal.Content>
    </GridModal>
  );
};
