const EMPTY_ARRAY: unknown[] = [];

/**
 * Returns an empty array of type T.
 *
 * This function exists to work around some referential equality issues when
 * empty arrays are defined inline in a react component, which leads to
 * unnecessary rerenders and sometimes reinitializations of components.
 */
export function getEmptyArray<T>() {
  return EMPTY_ARRAY as T[];
}

function arrayMoveMutable<T>(array: T[], fromIndex: number, toIndex: number) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

function arrayMoveImmutable<T>(array: T[], fromIndex: number, toIndex: number) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

export { arrayMoveImmutable as arrayMove };
