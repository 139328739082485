import { type ReactNode, useMemo } from 'react';
import { css, type SerializedStyles } from '@emotion/react';
import { Box, Flex, Text } from '@/components/primitives';
import { ProgressBarInnerBar } from '@/components/ProgressBar/ProgressBarInnerBar';
import { ProgressBarInnerBarWrapper } from '@/components/ProgressBar/ProgressBarInnerBarWrapper';
import { getColorToken } from '@/css/utils';

type ProgressBarMap = {
  items: ReactNode[];
  totalOffset: number;
};

type ProgressBarSegment = {
  color?: string;
  label?: string;
  percent: number;
};

type Props = {
  backgroundColor?: string;
  barCss?: SerializedStyles;
  borderRadius?: string;
  className?: string;
  height?: string;
  segments: ProgressBarSegment[];
  showInfo?: boolean;
};

export const ProgressBarWithSegments = ({
  backgroundColor = getColorToken('grey200'),
  barCss,
  borderRadius = '5px',
  className,
  height = '12px',
  segments,
  showInfo = false
}: Props) => {
  const { items } = useMemo(
    () =>
      segments.reduce<ProgressBarMap>(
        (result, segment, index) => {
          const isFirst = index === 0;
          const isLast = index === segments.length - 1;

          result.items.push(
            <ProgressBarInnerBar
              key={index}
              borderRadius={borderRadius}
              color={segment.color ?? backgroundColor}
              css={css(barCss, {
                left: result.totalOffset > 0 ? `${result.totalOffset}%` : undefined,
                borderTopLeftRadius: isFirst ? borderRadius : 0,
                borderBottomLeftRadius: isFirst ? borderRadius : 0,
                borderTopRightRadius: isLast ? borderRadius : 0,
                borderBottomRightRadius: isLast ? borderRadius : 0
              })}
              data-testid="progressBarSegment"
              data-value={segment.percent}
              percent={segment.percent}
            />
          );

          result.totalOffset += segment.percent;
          return result;
        },
        {
          items: [],
          totalOffset: 0
        }
      ),
    [backgroundColor, barCss, borderRadius, segments]
  );

  return (
    <>
      <ProgressBarInnerBarWrapper
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        className={className}
        data-testid="progressBar"
        height={height}
      >
        {items}
      </ProgressBarInnerBarWrapper>
      {showInfo && (
        <Flex alignItems="center" gap="s04" mt="s02">
          {segments
            .filter(segment => segment.label)
            .map((segment, index) => (
              <Flex key={index} alignItems="center" gap="s02">
                <Box bgColor={segment.color ?? backgroundColor} height={12} width={12} />
                <Text fontSize="md" lineHeight="none">
                  {segment.label}
                </Text>
              </Flex>
            ))}
        </Flex>
      )}
    </>
  );
};
