import type { DefaultOptions } from 'apollo-client';

export const defaultOptions: DefaultOptions = {
  mutate: {
    errorPolicy: 'none'
  },
  query: {
    errorPolicy: 'all'
  },
  watchQuery: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  }
};
