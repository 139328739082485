import { CSRF_HEADER } from '@/constants';
import type { AbortableFile } from '@/types';
import { getCsrfToken } from '@/utils/csrf';
import type { FileUploadOptions } from './types';

function addXhrEventListeners(xhr: XMLHttpRequest, options: FileUploadOptions) {
  const {
    onComplete: _onComplete,
    onAbort: _onAbort,
    onError: _onError,
    onProgress: _onProgress
  } = options;

  return new Promise<string>((resolve, reject) => {
    xhr.addEventListener('load', () => {
      if (_onComplete) {
        try {
          _onComplete(JSON.parse(xhr.responseText));
        } catch (error) {
          let message: string;

          if (xhr.status > 400) {
            message = xhr.responseText;
          } else if (error instanceof Error) {
            message = error.message;
          } else {
            message = 'An unknown error occurred';
          }

          if (_onError) _onError(message);
          reject(new Error(message));
        }
      }

      resolve(xhr.responseText);
    });

    xhr.addEventListener('error', () => {
      if (_onError) {
        _onError(xhr.responseText);
      }

      reject(new Error(xhr.responseText));
    });

    if (_onAbort) {
      xhr.addEventListener('abort', () => {
        _onAbort();
      });
    }

    if (_onProgress) {
      xhr.upload.addEventListener(
        'progress',
        event => {
          _onProgress({
            percent: event.lengthComputable ? event.loaded / event.total : 0
          });
        },
        false
      );
    }
  });
}

export class FileApiClient {
  private uri: string;

  private pendingUploads: XMLHttpRequest[];

  public constructor({ uri }: { uri: string }) {
    this.uri = uri;
    this.pendingUploads = [];
  }

  private sendFile(url: string, file: AbortableFile, options: FileUploadOptions = {}) {
    return new Promise<string>(resolve => {
      const csrfToken = getCsrfToken();
      const data = new FormData();
      const method = options.method || 'POST';
      const xhr = new XMLHttpRequest();

      resolve(addXhrEventListeners(xhr, options));

      data.append('file', file);
      file.abort = () => xhr.abort();

      xhr.open(method, url, true);
      xhr.withCredentials = true;

      if (csrfToken) {
        xhr.setRequestHeader(CSRF_HEADER, csrfToken);
      }

      xhr.send(data);
      this.pendingUploads.push(xhr);
    });
  }

  public uploadFile(file: File, options?: FileUploadOptions) {
    return this.sendFile(this.uri, file as AbortableFile, options);
  }

  public abortPendingUploads() {
    this.pendingUploads.forEach(xhr => {
      xhr.abort();
    });

    this.pendingUploads = [];
  }
}

export function createFileApiClient(args: { uri: string }) {
  return new FileApiClient(args);
}
