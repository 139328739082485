function normalize(strArray: string[]) {
  const resultArray = [];

  // If the first part is a plain protocol, we combine it with the next part.
  if (/^[^/:]+:\/*$/.exec(strArray[0]) && strArray.length > 1) {
    const first = strArray.shift();
    strArray[0] = first + strArray[0];
  }

  // There must be two or three slashes in the file protocol, two slashes in anything else.
  if (/^file:\/\/\//.exec(strArray[0])) {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1:///');
  } else {
    strArray[0] = strArray[0].replace(/^([^/:]+):\/*/, '$1://');
  }

  for (let i = 0; i < strArray.length; i += 1) {
    let component = strArray[i];

    if (component === '') {
      continue;
    }

    if (i > 0) {
      // Removing the starting slashes for each component but the first.
      component = component.replace(/^[/]+/, '');
    }

    if (i < strArray.length - 1) {
      // Removing the ending slashes for each component but the last.
      component = component.replace(/[/]+$/, '');
    } else {
      // For the last component we will combine multiple slashes to a single one.
      component = component.replace(/[/]+$/, '/');
    }

    resultArray.push(component);
  }

  let str = resultArray.join('/');
  // Each input component is now separated by a single slash except the possible first plain protocol part.

  // remove trailing slash before parameters or hash
  str = str.replace(/\/(\?|&|#[^!])/g, '$1');

  // replace ? in parameters with &
  const parts = str.split('?');

  str = (parts.shift() as string) + (parts.length > 0 ? '?' : '') + parts.join('&');

  return str;
}

export function join(...parts: string[]) {
  let input;

  if (typeof parts[0] === 'object') {
    input = parts[0]; // eslint-disable-line prefer-destructuring
  } else {
    input = [].slice.call(parts);
  }

  return normalize(input);
}

export function isLocalhost(hostname: string) {
  return ['localhost', '127.0.0.1', '::1'].includes(hostname);
}

export function stripProtocol(url: string) {
  return url.replace(/(^\w+:|^)\/\//, '');
}

/**
 * Builds a "mailto:" url that can be passed to a href attribute.
 *
 * @param {string | null} [recipient] The recipient of the email. This can be empty.
 * @param {string | null} [subject] The subject of the email. This should not be URL-encoded.
 * @param {string} [body] The body of the email. This should not be URL-encoded.
 * @returns A string containing a mailto: url.
 */
export function mailto(recipient?: string | null, subject?: string | null, body?: string) {
  const params = [];

  if (subject) params.push(`subject=${encodeURIComponent(subject)}`);
  if (body) params.push(`body=${encodeURIComponent(body)}`);

  // TODO: Validate the recipient (if given)
  // TODO: Check whether at least one argument is given

  return `mailto:${recipient || ''}${params.length > 0 ? `?${params.join('&')}` : ''}`;
}
