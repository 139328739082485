import type { MouseEventHandler, ReactNode } from 'react';
import { CloseButton } from '@/components/CloseButton';
import { Heading } from '@/components/Heading';
import { useI18n } from '@/components/I18n';
import { Card, Flex } from '@/components/primitives';

type Props = {
  children: ReactNode;
  className?: string;
  id: string;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
};

export const ModalHeader = ({ children, className, id, onCloseClick }: Props) => {
  const i18n = useI18n();

  return (
    <Card borderBottom={1} borderBottomColor="neutral200" className={className}>
      <Flex alignItems="center" justifyContent="space-between" pl="s06" pr="s04" py="s04">
        <Heading id={id} variant="heading.500">
          {children}
        </Heading>
        {onCloseClick && (
          <CloseButton
            aria-label={i18n.t('common', 'modalWindow.closeButton.text')}
            hoverColor="danger"
            onClick={onCloseClick}
          />
        )}
      </Flex>
    </Card>
  );
};
