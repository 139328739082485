import { Flex } from '@/components/primitives';
import { BouncyLoader, type BouncyLoaderProps } from './BouncyLoader';

export const FlexBouncyLoader = (props: BouncyLoaderProps) => (
  <Flex
    alignItems="center"
    alignSelf="stretch"
    flex={1}
    flexDirection="column"
    justifyContent="center"
  >
    <BouncyLoader {...props} />
  </Flex>
);
