import type { ReactNode } from 'react';
import { FocusWrapper } from '@/components/FocusManagement';
import { useI18n } from '@/components/I18n';
import { useId } from '@/utils/hooks/useId';
import { useSkipNav } from './SkipNavManager';

type Props = {
  children?: ReactNode;
  label?: string;
  role?: string;
};

export const SkipNavTarget = ({ children, label, role }: Props) => {
  const i18n = useI18n();
  const targetId = useId('skipNavTarget');
  const skipNavRef = useSkipNav(label ?? i18n.t('common', 'skipNav.goToContentLink.text'));

  // IE11 does not support <main> so also has no inherent role semantics for
  // this tag. As a result we should, for now, always use role=main instead
  // of trying to render a <main> element. Currently there is no possibility
  // to provide a custom component here, but initially the SkipNavTarget
  // component has an "as" prop that would allow this behavior.

  return (
    <FocusWrapper ref={skipNavRef} debugName={targetId} id={targetId} role={role}>
      {children}
    </FocusWrapper>
  );
};
