import type { ReactNode } from 'react';
import { Icon, type IconType } from '@/components/Icon';
import { Box, Card, type CardProps, Flex, Text } from '@/components/primitives';
import type { ColorToken, SwatchColor } from '@/css/types';
import { useI18n } from '../I18n';

export type AlertIconPosition = 'middle' | 'top';

type Props = {
  children?: ReactNode;
  heading?: string;
  icon?: IconType;
  iconPosition?: AlertIconPosition;
  role?: 'alert' | 'status';
  swatchColor?: SwatchColor;
};

export const Alert = ({
  children,
  heading,
  icon,
  iconPosition = 'top',
  role,
  swatchColor = 'neutral'
}: Props) => {
  const i18n = useI18n();
  const bgColor: ColorToken = `${swatchColor}100`;
  const borderColor: ColorToken = `${swatchColor}300`;
  const iconBgColor: ColorToken = `${swatchColor}200`;
  const iconColor: ColorToken = `${swatchColor}600`;

  const borderProps: Pick<
    CardProps,
    'borderLeft' | 'borderLeftColor' | 'borderRight' | 'borderRightColor'
  > = {};

  if (i18n.isRtl) {
    borderProps.borderRight = 3;
    borderProps.borderRightColor = borderColor;
  } else {
    borderProps.borderLeft = 3;
    borderProps.borderLeftColor = borderColor;
  }

  return (
    <Card {...borderProps} bgColor={bgColor} borderRadius="base" data-testid="alert" role={role}>
      <Flex gap="s05" px="s05" py="s04">
        {icon && (
          <Card
            alignSelf={iconPosition === 'middle' ? 'center' : 'flex-start'}
            bgColor={iconBgColor}
            borderRadius="full"
            data-testid="alert-icon-container"
            display="flex"
            p="s02"
          >
            <Icon color={iconColor} size={24} type={icon} />
          </Card>
        )}
        <Box flex={1}>
          {heading && (
            <Box mb="s02">
              <Text fontWeight="semibold">{heading}</Text>
            </Box>
          )}
          <Text fontSize="md">{children}</Text>
        </Box>
      </Flex>
    </Card>
  );
};
