import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { infiniteQueryOptions, LOADER_STALE_TIME, userInviteLinkKeys } from '../utils';
import {
  GetUserInviteLinkUsers,
  type GetUserInviteLinkUsersQueryVariables
} from './graphql/GetUserInviteLinkUsers.gql';

type QueryVariables = Omit<
  GetUserInviteLinkUsersQueryVariables,
  'filter' | 'inviteLinkId' | 'page'
> & {
  filter: NonNullable<GetUserInviteLinkUsersQueryVariables['filter']>;
};

export function getUserInviteLinkUsersQueryOptions(
  params: Params<'inviteLinkId'>,
  variables: QueryVariables
) {
  return infiniteQueryOptions({
    queryKey: userInviteLinkKeys.userInviteLinkById_users(
      getRouteParam(params.inviteLinkId),
      parseVariables(variables)
    ),
    queryFn: ({ queryKey: [{ inviteLinkId, variables: vars }], pageParam: page = 1 }) =>
      fetchDocument(GetUserInviteLinkUsers, { ...vars, inviteLinkId, page }),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  size: 25,
  filter: {}
};

export function userInviteLinkUsersLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => {
    const queryOptions = getUserInviteLinkUsersQueryOptions(params, defaultVariables);

    // We're dealing with an infinite query here which means we can't use
    // ensureQueryData which uses fetchQuery rather than fetchInfiniteQuery
    // under the hood. There's also no equivalent helper specifically for
    // infinite queries so we have to make our own.
    // See https://github.com/TanStack/query/discussions/6712.
    return client.getQueryData(queryOptions.queryKey) ?? client.fetchInfiniteQuery(queryOptions);
  };
}

function parseVariables(
  variables: QueryVariables
): Omit<GetUserInviteLinkUsersQueryVariables, 'inviteLinkId' | 'page'> {
  const { filter, size } = variables;
  const searchText = filter.searchText?.trim();

  return {
    size,
    filter: searchText ? { searchText } : undefined
  };
}
