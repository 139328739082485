import { useContext } from 'react';
import {
  format as formatDate,
  formatDistanceStrict,
  formatDistanceToNow,
  parseJSON
} from 'date-fns';
import { DateFnsLocaleContext } from '@/components/I18n/DateFnsLocaleContext';
import { TimeBase, type TimeBaseProps } from './TimeBase';

type FormatDistanceToNowArgs = Parameters<typeof formatDistanceToNow>;
type FormatDistanceStrictArgs = Parameters<typeof formatDistanceStrict>;

type Props = TimeBaseProps &
  (
    | {
        dateTime: FormatDistanceStrictArgs[0] | string;
        dateToCompare: FormatDistanceStrictArgs[1] | string;
        options?: FormatDistanceStrictArgs[2];
      }
    | {
        dateTime: FormatDistanceToNowArgs[0] | string;
        dateToCompare?: null;
        options?: FormatDistanceToNowArgs[1];
      }
  );

function parseDate(value: Date | number | string): Date {
  if (value instanceof Date) return value;
  if (typeof value === 'string') return parseJSON(value);
  return new Date(value);
}

export const RelativeTime = ({ dateTime, dateToCompare, options, ...props }: Props) => {
  const locale = useContext(DateFnsLocaleContext) ?? undefined;
  const parsedDateTime = parseDate(dateTime);
  const label = formatDate(parsedDateTime, 'PPPp', { locale });

  let output: string;

  if (dateToCompare) {
    output = formatDistanceStrict(parsedDateTime, parseDate(dateToCompare), { ...options, locale });
  } else {
    output = formatDistanceToNow(parsedDateTime, { ...options, locale });
  }

  return (
    <TimeBase {...props} dateTime={parsedDateTime.toISOString()} title={label}>
      {output}
    </TimeBase>
  );
};
