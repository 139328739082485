import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import {
  borderRadius,
  boxShadow,
  type BoxShadowProps,
  color,
  compose,
  fontSize,
  fontWeight,
  margin,
  padding,
  system
} from 'styled-system';
import type {
  BackgroundColorProps,
  BorderRadiusProps,
  FontSizeProps,
  FontWeightProps,
  MarginProps,
  PaddingProps,
  TextColorProps
} from '@/components/primitives/types';
import { getToken } from '@/css/utils';

type BaseProps = BackgroundColorProps &
  BorderRadiusProps &
  BoxShadowProps &
  FontSizeProps &
  FontWeightProps &
  MarginProps &
  PaddingProps &
  TextColorProps;

export const BadgeBase = styled('div', { shouldForwardProp })<BaseProps>(
  {
    display: 'inline-block',
    lineHeight: getToken('lineHeights', 'relaxed'),
    marginBottom: '0.125rem',
    marginLeft: '0.25rem',
    marginRight: '0.25rem',
    marginTop: '0.125rem',
    paddingLeft: '0.35em',
    paddingRight: '0.35em',
    textTransform: 'uppercase',
    verticalAlign: 'middle'
  },
  compose(
    borderRadius,
    boxShadow,
    color,
    fontSize,
    fontWeight,
    margin,
    padding,
    system({
      bgColor: {
        property: 'backgroundColor',
        scale: 'colors'
      }
    })
  )
);
