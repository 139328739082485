import type { FontSizeToken } from '@/css/types';
import { exhaustiveCheck } from '@/types/utils';
import type { UserAvatarSize } from './types';

export function mapSizeToRem(size: UserAvatarSize): string {
  if (size === 'sm') return '1.75rem';
  if (size === 'md') return '2.25rem';
  if (size === 'lg') return '2.75rem';
  /* istanbul ignore next */ return exhaustiveCheck(size);
}

export function mapSizeToFontSize(size: UserAvatarSize): FontSizeToken {
  if (size === 'sm') return 'sm';
  if (size === 'md') return 'md';
  if (size === 'lg') return 'lg';
  /* istanbul ignore next */ return exhaustiveCheck(size);
}

export function mapSizeToPx(size: UserAvatarSize): number {
  if (size === 'sm') return 44;
  if (size === 'md') return 36;
  if (size === 'lg') return 28;
  /* istanbul ignore next */ return exhaustiveCheck(size);
}
