import { useCallback } from 'react';
import { type IntersectionOptions, useInView } from 'react-intersection-observer';

export function useHorizontalScrollBoundaryRef(
  root: Element | null,
  threshold: number,
  onIntersectionChange: (isIntersecting: boolean) => void
) {
  // There's a bug (?) in Edge's IntersectionObserver API where it already
  // sets "isIntersecting" to true as soon as the element intersects,
  // regardless of the provided threshold. It then fires a second time as soon
  // as the threshold is actually reached. This means that with a threshold
  // of 0.95, you would get two triggers when an element starts intersecting:
  //  1. isIntersecting = true, intersectionRatio = 0
  //  2. isIntersecting = true, intersectionRatio = 0.95(ish)
  // Modern browsers do not behave this way and only call the handler once,
  // when the threshold is actually reached, which means you can simply reply on
  // the "isIntersecting" value.
  // We only care about the second trigger and want to filter out the first. To
  // achieve this we perform an additional intersectionRatio check so we send
  // down the correct isIntersecting value to Edge (and other) browsers.
  const onChange: NonNullable<IntersectionOptions['onChange']> = useCallback(
    (inView, entry) => onIntersectionChange(inView && entry.intersectionRatio >= threshold),
    [onIntersectionChange, threshold]
  );

  const [ref] = useInView({
    onChange,
    root,
    // Only interested in horizontal intersection, 100% on top and bottom
    // basically ignores vertical intersection.
    rootMargin: '100% 0px 100% 0px',
    // There's a bug in IntersectionObserver where it doesn't always fire
    // despite the whole element being in view. This is most likely related to a
    // known issue with subpixels calculations. This issue might be more
    // prevalent on i.e. retina screens, which is why we run into it often.
    // See https://github.com/w3c/IntersectionObserver/issues/477.
    // Workaround is to lower the threshold from 1 to 0.95 so we have a bit of
    // extra breathing room. The scroll snap will take care of scrolling the
    // whole element into view regardless (= threshold of 1) so this shouldn't
    // be an issue in this particular scenario.
    threshold
  });

  return ref;
}
