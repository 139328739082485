import { useContext, useMemo } from 'react';
import { FormTeardownContext } from './FormTeardownContext';

type FormTeardownHookOptions = {
  onCompleted: () => void;
};

type FormTeardownHookReturnValue = {
  runFormTeardown: () => void;
};

export function useFormTeardown({
  onCompleted
}: FormTeardownHookOptions): FormTeardownHookReturnValue {
  const { ref } = useContext(FormTeardownContext);
  const returnValue: FormTeardownHookReturnValue = useMemo(
    () => ({
      runFormTeardown: () =>
        // If this hook gets used in a component hierarchy that contains a <Form> child, the ref
        // will be set to the imperative runFormTeardown handler (see `Form.tsx`) _after_ the
        // initial render (because that's how useRef() works). If this is the case, always run the
        // handler and pass the callback. If not, we most likely don't contain a <Form> child and
        // can immediately invoke the callback instead. Alternatively we could throw as this should
        // only be used inside of forms (or form modals) but let's try this for now.
        ref.current?.runFormTeardown ? ref.current.runFormTeardown(onCompleted) : onCompleted()
    }),
    [onCompleted, ref]
  );

  return returnValue;
}
