/**
 * Determines whether or not a given element is currently visible in the
 * viewport.
 * @param el Element to check.
 * @returns A boolean indicating if the element is currently positioned inside
 * the viewport.
 */
export function isElementInViewport(el: HTMLElement) {
  const rect = el.getBoundingClientRect();

  return (
    rect.bottom > 0 &&
    rect.right > 0 &&
    rect.left <
      (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */ &&
    rect.top <
      (window.innerHeight || document.documentElement.clientHeight) /* or $(window).height() */
  );
}
