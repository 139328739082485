import { Icon, type IconType } from '@/components/Icon';
import { Card } from '@/components/primitives';
import type { ColorToken } from '@/css/types';

type Props = {
  bgColor: ColorToken;
  icon: IconType;
};

export const DashboardFilterListItemIcon = ({ bgColor, icon }: Props) => (
  <Card
    bgColor={bgColor}
    borderRadius="sm"
    css={{ alignItems: 'center', justifyContent: 'center' }}
    display="flex"
    flexShrink={0}
    height={28}
    width={28}
  >
    <Icon color="white" size={16} type={icon} />
  </Card>
);
