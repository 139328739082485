export function hasValue<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}

export function hasNonEmptyStringValue<T>(value: T | null | undefined): value is T {
  return hasValue(value) && (typeof value !== 'string' || value !== '');
}

export function removeEmptyElementsFromArray<T>(array: (T | null | undefined)[]): T[] {
  return array.filter(hasValue);
}

export function isObject(obj: unknown): obj is Record<string, unknown> {
  return Object.prototype.toString.call(obj) === '[object Object]';
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export function isFunction(f: unknown): f is Function {
  return typeof f === 'function';
}

export function truncateText(text: string | null | undefined, numChars = 0): string {
  const strippedText = (text ?? '').replace(/\s+/g, ' ');

  if (numChars > 0) {
    return (
      strippedText.replace(/\s+/g, ' ').slice(0, numChars) +
      (strippedText.length > numChars ? '…' : '')
    );
  }

  return strippedText;
}
