import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { borderRadius, color, compose, type ResponsiveValue, system } from 'styled-system';
import type {
  BackgroundColorProps,
  BorderRadiusProps,
  TextColorProps
} from '@/components/primitives/types';
import type { ColorToken } from '@/css/types';
import { getColorToken } from '@/css/utils';
import { css, variant } from '@/theme/utils';

export type IconButtonSize = 'lg' | 'md' | 'sm' | 'xl' | 'xs';

type BaseProps = BackgroundColorProps &
  BorderRadiusProps &
  TextColorProps & {
    hasBorder?: boolean;
    hoverBgColor: ColorToken;
    hoverColor?: ColorToken;
    size?: ResponsiveValue<IconButtonSize>;
  };

export const IconButtonBase = styled('button', { shouldForwardProp })<BaseProps>(
  css({
    alignItems: 'center',
    border: 'none',
    display: 'inline-flex',
    flexShrink: 0,
    justifyContent: 'center',
    lineHeight: 1,
    outline: 'none',
    p: 0,
    transition: 'all 200ms ease-out',
    ':focus': { boxShadow: 'outline' },
    ':not([disabled], [aria-disabled="true"])': { cursor: 'pointer' },
    '&[disabled], &[aria-disabled="true"]': {
      cursor: 'not-allowed',
      opacity: 0.5
    }
  }),
  props => ({
    ':not([disabled], [aria-disabled="true"]):hover': {
      backgroundColor: getColorToken(props.hoverBgColor),
      color: props.hoverColor ? getColorToken(props.hoverColor) : undefined
    }
  }),
  compose(
    borderRadius,
    color,
    system({
      bgColor: {
        property: 'backgroundColor',
        scale: 'colors'
      }
    })
  ),
  variant<IconButtonSize>({
    prop: 'size',
    variants: {
      xs: { size: 18 },
      sm: { size: 24 },
      md: { size: 28 },
      lg: { size: 32 },
      xl: { size: 36 }
    }
  }),
  variant<boolean>({
    prop: 'hasBorder',
    variants: {
      true: {
        backgroundColor: 'white',
        border: 1,
        borderColor: 'neutral200',
        ':hover': {
          borderColor: 'neutral200'
        }
      }
    }
  })
);

IconButtonBase.defaultProps = {
  bgColor: 'transparent',
  borderRadius: 'base',
  hasBorder: false,
  size: 'md'
};
