import { Flex } from '@/components/primitives';
import type { ColorToken } from '@/css/types';
import { getColorToken } from '@/css/utils';
import { Circle, type CircleProps } from './Circle';

export type BouncyLoaderProps = {
  color?: ColorToken;
  size?: CircleProps['size'];
};

export const BouncyLoader = ({ color = 'neutral500', size = '1rem' }: BouncyLoaderProps) => {
  const themeColor = getColorToken(color);

  return (
    <Flex aria-busy data-testid="loader" justifyContent="center">
      <Circle color={themeColor} size={size} />
      <Circle color={themeColor} css={{ animationDelay: '200ms' }} size={size} />
      <Circle color={themeColor} css={{ animationDelay: '200ms' }} size={size} />
    </Flex>
  );
};
