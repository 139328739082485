import { useContext, useMemo } from 'react';
import { HeadingContextProvider } from '@/components/Heading';
import { Modal, type ModalMode, type ModalProps } from '@/components/Modal';
import { useFormTeardown } from '@/features/forms/components/Form/useFormTeardown';
import { FormContext, type FormContextValue } from '@/features/forms/components/FormContext';
import { useHeadingIdAriaPair } from '@/utils/hooks/useHeadingIdAriaPair';
import * as css from './FormModal.styles';

const noop = () => {};

type Props<T extends ModalMode> = Omit<ModalProps<T>, 'aria' | 'contentLabel'> & {
  title: string;
};

export const FormModal = <T extends ModalMode>({
  children,
  onRequestClose,
  title,
  ...props
}: Props<T>) => {
  const [id, aria] = useHeadingIdAriaPair();
  const context = useContext(FormContext);
  const nextContext: FormContextValue = useMemo(
    () => ({
      ...context,
      theme: {
        actions: css.actions,
        form: css.form,
        formContent: css.formContent
      }
    }),
    [context]
  );

  const teardownContext = useFormTeardown({
    onCompleted: useMemo(() => onRequestClose ?? noop, [onRequestClose])
  });

  let handleClose: (() => void) | undefined;

  if (onRequestClose) {
    handleClose = teardownContext.runFormTeardown;
  }

  return (
    <Modal {...props} aria={aria} onRequestClose={handleClose}>
      <Modal.Header id={id} onCloseClick={handleClose}>
        {title}
      </Modal.Header>
      {/**
       * The Modal.Header will be an h1, which means we need to increment the
       * heading level for the modal content.
       */}
      <HeadingContextProvider previousContext={1}>
        <FormContext.Provider value={nextContext}>
          <Modal.Content>{children}</Modal.Content>
        </FormContext.Provider>
      </HeadingContextProvider>
    </Modal>
  );
};
