import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import createCache, { type StylisPlugin } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { setElementVars } from '@vanilla-extract/dynamic';
import type { i18n } from 'i18next';
import { meetsContrastGuidelines } from 'polished';
import { prefixer } from 'stylis';
import { useAppConfigValue } from '@/components/AppConfig';
import { I18nProvider } from '@/components/I18n';
import { DocumentLanguageAttributeManager } from '@/components/I18n/DocumentLanguageAttributeManager';
import { getColorToken } from '@/css/utils';
import { ThemeManager } from '@/theme/ThemeManager';
import { OverflowWrapStylisPlugin } from '@/utils/OverflowWrapStylisPlugin';

const cache = createCache({
  key: 's',
  nonce: window.__CSP_NONCE__,
  stylisPlugins: [prefixer as StylisPlugin, OverflowWrapStylisPlugin]
});

// This is a workaround for emotion not digging the ":first-child" pseudo-
// selector we use in TableCell components. The reason for this is that their
// SSR injection method might potentially break position-dependent pseudo-
// selectors since it injects <style> nodes in unpredictable places. We don't
// care about SSR though.
// Ideally we do try to remove those pseudo-selectors at some point in the
// future.
// Also used in <TestProviders>.
cache.compat = true;

type Props = {
  i18nClient: i18n;
};

export const Root = ({ i18nClient }: Props) => {
  const primaryColor = useAppConfigValue('application_branding_primaryColor');
  const secondaryColor = useAppConfigValue('application_branding_secondaryColor');

  useEffect(() => {
    const values: Record<string, string> = {};

    if (primaryColor) {
      values[getColorToken('primary')] = primaryColor;
      values[getColorToken('textOnPrimary')] = getColorToken(
        meetsContrastGuidelines(primaryColor, '#fff').AA ? 'white' : 'grey900'
      );
    }

    if (secondaryColor) {
      values[getColorToken('secondary')] = secondaryColor;
    }

    if (Object.keys(values).length > 0) {
      setElementVars(document.body, values);
    }
  }, [primaryColor, secondaryColor]);

  return (
    <I18nProvider client={i18nClient}>
      <CacheProvider value={cache}>
        <ThemeManager>
          <DocumentLanguageAttributeManager client={i18nClient} />
          <Outlet />
        </ThemeManager>
      </CacheProvider>
    </I18nProvider>
  );
};
