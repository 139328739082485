import { Suspense } from 'react';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { Box, type BoxProps } from '@/components/primitives';
import { ModalContentLoader } from './ModalContentLoader';

export const ModalContent = ({ children, ...props }: BoxProps) => (
  <Box display="flex" flex="1 1 auto" flexDirection="column" overflowY="auto" {...props}>
    <ErrorBoundary>
      <Suspense fallback={<ModalContentLoader />}>{children}</Suspense>
    </ErrorBoundary>
  </Box>
);
