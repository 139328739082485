import { type CSSProperties, forwardRef, type HTMLProps, type ReactNode } from 'react';

type Props = HTMLProps<HTMLSpanElement> & {
  children?: ReactNode;
  style?: CSSProperties;
};

export const VisuallyHidden = forwardRef<HTMLSpanElement, Props>(
  ({ children, style, ...props }, ref) => (
    <span
      {...props}
      ref={ref}
      data-visually-hidden=""
      style={{
        position: 'absolute',
        border: 0,
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        whiteSpace: 'nowrap',
        wordWrap: 'normal',
        ...style
      }}
    >
      {children}
    </span>
  )
);
