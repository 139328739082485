import type { To } from 'react-router-dom';
import type { RouteConfig } from './config';

type DebugLocationMap = {
  getDebugSettingsPage(): To;
  getSandboxPage(): To;
  getGraphQlPage(): To;
};

const debugPaths = {
  debugSettings: '/debug/settings',
  graphQl: '/debug/graphql',
  sandbox: '/debug/sandbox'
} as const;

export type DebugPathMap = typeof debugPaths;

export const debugRouteConfig: RouteConfig<DebugPathMap, DebugLocationMap> = {
  paths: debugPaths,
  locations: {
    getDebugSettingsPage: () => '/debug/settings',
    getGraphQlPage: () => '/debug/graphql',
    getSandboxPage: () => '/debug/sandbox'
  }
};
