import { createPath } from 'react-router-dom';
import { decodeBase64Action, getActionUrl } from './utils/actionFragment';

/**
 * Redirect legacy /app/ routes to the corresponding current route. These are in
 * order of priority and should not be split up or reordered or they will break.
 * Added in 3.9, to be removed in a future release.
 */
export function findUpdatedRoute(pathname: string): string | undefined {
  let next;

  if (pathname.startsWith('/app/profile/')) {
    next = pathname.replace(/^\/app\/profile\//, '/profiles/');
  } else if (pathname.startsWith('/app/contribution/')) {
    next = pathname.replace(/^\/app\/contribution\//, '/contributions/');
  } else if (pathname.startsWith('/app/module/')) {
    next = pathname.replace(/^\/app\/module\//, '/modules/');
  } else if (pathname.startsWith('/app/program/')) {
    next = pathname.replace(/^\/app\/program\//, '/programs/');
  } else if (pathname.startsWith('/reviews/')) {
    next = pathname.replace(/^\/reviews\//, '/tasks/reviews/');
  } else if (pathname.startsWith('/enrichments/')) {
    next = pathname.replace(/^\/enrichments\//, '/tasks/enrichments/');
  } else if (pathname.startsWith('/configuration/')) {
    next = pathname.replace(/^\/configuration\//, '/admin/');
  } else if (pathname.startsWith('/app/')) {
    next = pathname.replace(/^\/app\//, '/');
  } else if (pathname === '/app') {
    next = '/';
  }

  return next;
}

export function redirectLegacyRoutes() {
  const hashMatch = /^#\/(.*)/.exec(window.location.hash);

  // Strip leading /#/ if present and if the pathname is "/".
  if (hashMatch && window.location.pathname === '/') {
    window.history.replaceState(null, '', `/${hashMatch[1]}${window.location.search}`);
  } else if (hashMatch) {
    // We seemingly already have a pathname, so we don't really want to replace
    // it with the hash route. We _could_ make sure we strip the hash as well,
    // but it's better to leave it since this is unexpected. Our router will
    // ignore it anyway.
  }

  // Look up an updated route, if one exists.
  const newRoute = findUpdatedRoute(window.location.pathname);

  if (newRoute) {
    window.history.replaceState(null, '', newRoute + window.location.search);
  }
}

export function redirectActionFragment() {
  const hash = window.location.hash.slice(1);
  const fragment = hash ? new URLSearchParams(hash).get('act') : null;

  if (fragment) {
    // Remove has fragment from URL
    window.history.replaceState(null, '', window.location.href.split('#')[0]);

    const action = decodeBase64Action(fragment.trim());

    if (action) {
      const to = getActionUrl(action);

      if (to) {
        const redirectUrl = typeof to === 'string' ? to : createPath(to);
        window.history.replaceState(null, '', redirectUrl);
      }
    }
  }
}
