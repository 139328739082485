import { Heading } from '@/components/Heading';
import { useI18n } from '@/components/I18n';
import { Link } from '@/components/Link';
import { Flex, Spacer, Text } from '@/components/primitives';

export const ResourceNotFoundErrorBoundaryContent = () => {
  const i18n = useI18n();

  return (
    <Flex
      alignItems="center"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      p="s06"
      role="alert"
    >
      <Heading mb="s04" variant="heading.700">
        {i18n.t('common', 'resourceError.resourceNotFound.heading')}
      </Heading>
      <Text color="textLight" textAlign="center">
        {i18n.t('common', 'resourceError.resourceNotFound.text')}
      </Text>
      <Spacer mb="s06" />
      <Link to="/">{i18n.t('common', 'resourceError.resourceNotFound.goToHomeLink.text')}</Link>
    </Flex>
  );
};
