import { Children, cloneElement, isValidElement, memo, type ReactNode } from 'react';
import { isForwardRef } from 'react-is';
import { useHorizontalScrollContext } from './HorizontalScrollContext';
import { ScrollContainer, type ScrollContainerProps } from './ScrollContainer';

function tryGetKeyFromElement(node: ReactNode) {
  if (isValidElement(node)) {
    return node.key;
  }

  return null;
}

export type HorizontalScrollGridProps = ScrollContainerProps & {
  children: ReactNode[];
};

export const HorizontalScrollGrid = memo<HorizontalScrollGridProps>(props => {
  const state = useHorizontalScrollContext();

  return (
    <ScrollContainer
      ref={state.containerRef}
      {...props}
      data-testid="scrollContainer"
      data-visible-boundary={state.visibleBoundary}
    >
      {Children.map(props.children, (child, index) => {
        let boundaryRef: ((instance: HTMLDivElement) => void) | undefined;

        if (index === 0) {
          boundaryRef = state.startBoundaryRef;
        }

        if (index === props.children.length - 1) {
          boundaryRef = state.endBoundaryRef;
        }

        // If the child already supports ref forwarding we don't need to wrap it.
        if (isForwardRef(child)) {
          return cloneElement(child, { ref: boundaryRef });
        }

        // If the child does not support ref forwarding we wrap it with a div
        // and reuse the key (if any). This creates additional DOM nodes, so we
        // should use forwardRef whenever possible for grid children.
        return (
          <div key={tryGetKeyFromElement(child)} ref={boundaryRef}>
            {child}
          </div>
        );
      })}
    </ScrollContainer>
  );
});

HorizontalScrollGrid.displayName = 'memo(HorizontalScrollGrid)';
