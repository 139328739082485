import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Box, type BoxProps } from '@/components/primitives';
import type { PaddingProps } from '@/components/primitives/types';
import { getMotifColorToken } from '@/css/utils';

type BackgroundImageProps = Omit<BoxProps, 'bg' | 'height' | 'pt'> & {
  height: PaddingProps['paddingTop'];
  src: string | null | undefined;
};

const boxStyle = css({ transition: 'padding-top 150ms ease-in' });

export const HeaderBackgroundImage = ({ height, src, ...props }: BackgroundImageProps) => {
  const bg = useMemo(
    () =>
      src
        ? [
            `linear-gradient(to bottom, transparent, ${getMotifColorToken('background')})`,
            `url(${src}) center / cover no-repeat`
          ].join(', ')
        : undefined,
    [src]
  );

  return <Box bg={bg} css={boxStyle} data-testid="headerBackgroundImage" pt={height} {...props} />;
};
