import { ApolloError } from 'apollo-client';
import type { ServerError, ServerParseError } from 'apollo-link-http-common';
import type { GraphQLError } from 'graphql-v15';
import type { WrappedError } from '@/components/ErrorAlertList';
import { removeEmptyElementsFromArray } from '@/utils/common';
import { getEmptyArray } from '../array';
import { UserNotAuthorizedError } from './AuthorizationError';

function isServerError(error: Error | ServerError | ServerParseError): error is ServerError {
  return (
    Object.prototype.hasOwnProperty.call(error, 'response') &&
    Object.prototype.hasOwnProperty.call(error, 'result') &&
    Object.prototype.hasOwnProperty.call(error, 'statusCode')
  );
}

function isServerParseError(
  error: Error | ServerError | ServerParseError
): error is ServerParseError {
  return (
    Object.prototype.hasOwnProperty.call(error, 'bodyText') &&
    Object.prototype.hasOwnProperty.call(error, 'response') &&
    Object.prototype.hasOwnProperty.call(error, 'statusCode')
  );
}

function parseGraphQLError(error: GraphQLError): WrappedError {
  const wrapped: WrappedError = {
    error,
    message: error.message
  };

  // "extensions" can in fact be undefined depending on the GraphQL server implementation.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (typeof error.extensions?.code === 'string') {
    wrapped.code = error.extensions.code;
  }

  return wrapped;
}

export function unwrapApolloErrors(error: ApolloError | Error) {
  const errors: WrappedError[] = [];

  if (error instanceof ApolloError) {
    const { graphQLErrors, networkError } = error;

    if (graphQLErrors.length > 0) {
      errors.push(...graphQLErrors.map(parseGraphQLError));
    }

    if (networkError) {
      if (
        networkError instanceof UserNotAuthorizedError &&
        networkError.operationType === 'query'
      ) {
        throw networkError;
      }

      if (isServerError(networkError)) {
        if (Array.isArray(networkError.result.errors) && networkError.result.errors.length > 0) {
          errors.push(...networkError.result.errors.map(parseGraphQLError));
        } else {
          errors.push({
            code: networkError.statusCode,
            error: networkError,
            heading: 'A network error occurred.',
            message: networkError.message
          });
        }
      } else if (isServerParseError(networkError)) {
        errors.push({
          code: networkError.statusCode,
          error: networkError,
          heading: 'A network error occurred.',
          message: networkError.message
        });
      } else {
        errors.push({
          error: networkError,
          heading: 'An unknown error occurred.',
          message: networkError.message
        });
      }
    }

    if (graphQLErrors.length === 0 && !networkError) {
      errors.push({
        error,
        heading: 'An unknown error occurred.',
        message: error.message
      });
    }
  } else if (error instanceof Error) {
    errors.push({
      code: error.name,
      error,
      heading: 'A script error occurred.',
      message: error.message
    });
  }

  return errors;
}

// Every empty array has a new reference, so every time getApolloErrors() is
// called, a new reference is returned. This causes unnecessary rerenders in
// many components since they all call getApolloErrors() on every render. We can
// optimize this by returning a constant that retains the reference on calls in
// cases where no errors are present (which is 99.999%).
export function getApolloErrors(...args: (ApolloError | Error | undefined)[]): WrappedError[] {
  const errors = removeEmptyElementsFromArray(args);

  return errors.length === 0
    ? getEmptyArray()
    : errors.reduce<WrappedError[]>(
        (result, error) => result.concat(unwrapApolloErrors(error)),
        []
      );
}
