import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { FieldTypeFilterValues } from '@/features/program-management/components/FieldTypeFilterList';
import { fetchDocument } from '@/utils/api/fetcher';
import { fieldTypeKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetFieldTypes, type GetFieldTypesQueryVariables } from './graphql/GetFieldTypes.gql';

type QueryVariables = Pick<GetFieldTypesQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<FieldTypeFilterValues>;
};

export function getFieldTypesQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: fieldTypeKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetFieldTypes, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'NAME_ASC',
  filter: { statuses: ['NOT_ARCHIVED'] }
};

export function fieldTypesLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getFieldTypesQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetFieldTypesQueryVariables {
  const {
    filter: { creators, dataTypes, searchText, statuses },
    pagination: { page, size },
    sortOrder
  } = variables;

  const filter: NonNullable<GetFieldTypesQueryVariables['filter']> = {};

  if (statuses?.length === 1) {
    if (statuses.includes('NOT_ARCHIVED')) {
      filter.isArchived = false;
    } else if (statuses.includes('ARCHIVED')) {
      filter.isArchived = true;
    }
  }

  if (dataTypes?.length) {
    filter.dataTypeIds = dataTypes;
  }

  if (creators?.length) {
    filter.creatorIds = creators.map(c => c.id);
  }

  if (searchText?.trim()) {
    filter.searchText = searchText.trim();
  }

  return {
    pagination: { page, size },
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
