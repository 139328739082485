import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export type CircleProps = {
  color: string;
  size: number | string;
};

const bounceAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0.1;
    transform: translateY(-1rem);
  }
`;

export const Circle = styled.span<CircleProps>(({ color, size }) => ({
  animation: `${bounceAnimation} 600ms infinite alternate`,
  background: color,
  borderRadius: '50%',
  height: size,
  margin: '3rem 0.2rem',
  width: size
}));
