import { forwardRef, type ReactNode, useMemo } from 'react';
import { domAnimation, LazyMotion, m, type Variants } from 'framer-motion';
import type { DropdownAlign } from './types';
import { getContainerStyles } from './utils';

const variants: Variants = {
  hidden: {
    marginTop: 0,
    opacity: 0,
    transition: { duration: 0.1 }
  },
  visible: (marginTop: number) => ({
    marginTop,
    opacity: 1,
    transition: { duration: 0.1 }
  })
};

/** @public */
export type AnimatedDropdownWrapperProps = {
  align: DropdownAlign;
  children?: ReactNode;
  isVisible: boolean;
  marginTop?: number;
  maxWidth?: string;
  offsetX?: number;
  width?: string;
};

export const AnimatedDropdownWrapper = forwardRef<HTMLDivElement, AnimatedDropdownWrapperProps>(
  ({ align, children, isVisible, marginTop, maxWidth, offsetX, width }, ref) => {
    const containerStyles = useMemo(
      () => getContainerStyles(align, offsetX, width, maxWidth),
      [align, maxWidth, offsetX, width]
    );

    return (
      <LazyMotion features={domAnimation}>
        <m.div
          ref={ref}
          animate={isVisible ? 'visible' : 'hidden'}
          custom={marginTop}
          data-testid="dropdown"
          initial="hidden"
          // At this moment the exit animation is broken due to having to set the
          // display on "none" according to the prop value. If we instead put
          // `display: 'none'` as `transitionEnd` on the variant we completely
          // break the dropdown behavior in combination with downshift. <motion.div>
          // seemingly does some weird things whenever it encounters display values
          // while animating, so until we found a solution we're just immediately
          // hiding it which in turn also hides the fadeout opacity effect. Meh.
          style={{ ...containerStyles, display: isVisible ? 'block' : 'none' }}
          variants={variants}
        >
          {children}
        </m.div>
      </LazyMotion>
    );
  }
);
