import { memo, type MouseEventHandler } from 'react';
import { useJargon } from '@/components/AppConfig';
import { lookupJargonTranslation, useI18n } from '@/components/I18n';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { type CommunityData, DashboardFilterList } from '../DashboardFilterList';
import { ClearFavoritesButton } from './ClearFavoritesButton';

export type DashboardFilterValue = 'all' | 'favorites' | [type: 'community', value: string];

export type { CommunityData };

type Props = {
  activeFilter: DashboardFilterValue | null;
  communities: CommunityData[];
  favoriteCommunities: CommunityData[];
  hiddenCommunities: CommunityData[];
  onAddCommunityToFavorites: (communityId: string) => Promise<unknown>;
  onClearFavoriteCommunities: () => Promise<unknown>;
  onFilterClick?: MouseEventHandler<HTMLAnchorElement>;
  onHiddenCommunityClick: (communityId: string) => void;
  onRemoveCommunityFromFavorites: (communityId: string) => Promise<unknown>;
};

export const DashboardFilter = memo<Props>(
  ({
    activeFilter,
    communities,
    favoriteCommunities,
    hiddenCommunities,
    onAddCommunityToFavorites,
    onClearFavoriteCommunities,
    onFilterClick,
    onHiddenCommunityClick,
    onRemoveCommunityFromFavorites
  }) => {
    const i18n = useI18n();
    const jargon = useJargon();
    const { getHomePage } = useSharedLocations('main');
    const selectedCommunityId = Array.isArray(activeFilter) ? activeFilter[1] : null;
    const hasFavorites = favoriteCommunities.length > 0;

    const label = lookupJargonTranslation<'community'>(jargon.community, {
      COMMUNITY: () => i18n.t('main', 'community.label_multiple')
    });

    const allLinkText = lookupJargonTranslation<'community'>(jargon.community, {
      COMMUNITY: () => i18n.t('main', 'dashboardFilter.allCommunitiesLink.text')
    });

    const toggleButtonLabel = lookupJargonTranslation<'community'>(jargon.community, {
      COMMUNITY: () => i18n.t('main', 'dashboardFilter.toggleHiddenCommunitiesGroupButton.text')
    });

    const hiddenCommunitiesLabel = lookupJargonTranslation<'community'>(jargon.community, {
      COMMUNITY: () => i18n.t('main', 'dashboardFilter.hiddenCommunitiesGroup.label')
    });

    return (
      <DashboardFilterList aria-label={label}>
        <DashboardFilterList.ItemGroup
          isLabelVisible={false}
          label={i18n.t('main', 'dashboardFilter.shortcutsGroup.label')}
        >
          {hasFavorites && (
            <DashboardFilterList.Item
              contentAfter={<ClearFavoritesButton onClick={onClearFavoriteCommunities} />}
              icon="star"
              iconBgColor="blue500"
              isSelected={activeFilter === 'favorites'}
              onClick={onFilterClick}
              to={getHomePage({ filter: 'favorites' })}
            >
              {i18n.t('main', 'dashboardFilter.favoritedCommunitiesLink.text', {
                totalCount: favoriteCommunities.length
              })}
            </DashboardFilterList.Item>
          )}
          <DashboardFilterList.Item
            icon="user-group"
            iconBgColor="grey600"
            isSelected={activeFilter === 'all'}
            onClick={onFilterClick}
            to={hasFavorites ? getHomePage({ filter: 'all' }) : getHomePage()}
          >
            {allLinkText}
          </DashboardFilterList.Item>
        </DashboardFilterList.ItemGroup>
        {hasFavorites && (
          <DashboardFilterList.ItemGroup
            hasDividerBefore
            label={i18n.t('main', 'dashboardFilter.favoritedCommunitiesGroup.label')}
          >
            {favoriteCommunities.map(community => (
              <DashboardFilterList.CommunityItem
                key={community.id}
                data={community}
                isFavorited
                isSelected={selectedCommunityId === community.id}
                onAddToFavorites={onAddCommunityToFavorites}
                onClick={onFilterClick}
                onRemoveFromFavorites={onRemoveCommunityFromFavorites}
                to={getHomePage({ filter: ['community', community.id] })}
              />
            ))}
          </DashboardFilterList.ItemGroup>
        )}
        {communities.length > 0 && (
          <DashboardFilterList.ItemGroup
            hasDividerBefore
            isLabelVisible={false}
            label={i18n.t('main', 'dashboardFilter.otherCommunitiesGroup.label')}
          >
            {communities.map(community => (
              <DashboardFilterList.CommunityItem
                key={community.id}
                data={community}
                isFavorited={false}
                isSelected={selectedCommunityId === community.id}
                onAddToFavorites={onAddCommunityToFavorites}
                onClick={onFilterClick}
                onRemoveFromFavorites={onRemoveCommunityFromFavorites}
                to={getHomePage({ filter: ['community', community.id] })}
              />
            ))}
          </DashboardFilterList.ItemGroup>
        )}
        {hiddenCommunities.length > 0 && (
          <DashboardFilterList.CollapsibleItemGroup
            count={hiddenCommunities.length}
            hasDividerBefore
            label={hiddenCommunitiesLabel}
            toggleButtonLabel={toggleButtonLabel}
          >
            {hiddenCommunities.map(community => (
              <DashboardFilterList.HiddenCommunityItem
                key={community.id}
                data={community}
                onClick={() => onHiddenCommunityClick(community.id)}
              />
            ))}
          </DashboardFilterList.CollapsibleItemGroup>
        )}
      </DashboardFilterList>
    );
  }
);
