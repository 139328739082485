import { useEffect, useState } from 'react';

function getScrollbarWidth() {
  const width = window.innerWidth - document.documentElement.clientWidth;
  // There's issues with the reported window.innerWidth in some cases when the
  // user has zoomed in or out, with the reported value being 1px off. There
  // does't seem to be any way to detect this reliably. To work around this
  // we'll overreport by 1px, except in the cases where the width = 0 meaning no
  // scrollbar is visible.
  // If this turns out to be a problem somewhere we can always revert this.
  return width > 0 ? width + 1 : width;
}

export function useScrollbarWidth() {
  const [width, setWidth] = useState(getScrollbarWidth());

  useEffect(() => {
    const handler = () => setWidth(getScrollbarWidth());
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  return width;
}
