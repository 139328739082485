import { Fragment } from 'react';
import { Divider } from '@/components/Divider';
import type { FlexProps } from '@/components/primitives';
import { FeedEntrySkeleton } from '../FeedEntry/FeedEntrySkeleton';

type Props = {
  placeholderCount: number;
  py?: FlexProps['py'];
  showContent?: boolean;
};

export const FeedSkeleton = ({ placeholderCount, py, showContent }: Props) => (
  <>
    {Array.from({ length: placeholderCount }, (_, index) => (
      <Fragment key={index}>
        <FeedEntrySkeleton py={py} showContent={showContent} />
        {index < placeholderCount - 1 && <Divider color="neutral300" variant="dotted" />}
      </Fragment>
    ))}
  </>
);
