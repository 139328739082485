import type { MemberUnionFragment } from '@/features/common/graphql/fragments/MemberUnionFragment.gql';
import type { Contribution } from '../components/ContributionCard';
import type { ContributionCardFragment } from '../graphql/fragments/ContributionCardFragment.gql';
import { isVotingAllowedOnContribution } from './helpers';

function isBaseUser(
  member: MemberUnionFragment
): member is Extract<MemberUnionFragment, { __typename: 'BaseUser' }> {
  return member.__typename === 'BaseUser';
}

export function parseContributionCardFragment(
  contribution: ContributionCardFragment,
  similarContentLevel?: Api.SimilarContentLevel
): Contribution {
  return {
    commentCount: contribution.commentCount,
    creator: contribution.creator,
    dateCreated: contribution.dateCreated,
    id: contribution.id,
    moduleId: contribution.moduleId,
    originatingModuleId: contribution.originatingModuleId,
    viewer: contribution.viewer,
    allowDownvotes: contribution.votes.settings.allowDownvotes,
    description: contribution.data.presentation.description,
    downvoteCount: contribution.votes.downvoteCount,
    inventors: contribution.data.presentation.inventors.filter(isBaseUser),
    isVotingAllowed: isVotingAllowedOnContribution(contribution),
    moduleTitle: contribution.module?.title ?? null,
    moduleType: contribution.module?.type ?? null,
    originatingModuleTitle: contribution.originatingModule?.title ?? null,
    similarContentLevel,
    thumbnail: contribution.data.presentation.thumbnail,
    title: contribution.data.presentation.title,
    upvoteCount: contribution.votes.upvoteCount
  };
}
