import { createContext, type ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { tokens } from '@/css/base.css';
import { breakpoints } from '@/css/breakpoints';
import { colorTokens, darkTheme } from '@/css/color.css';
import { get, remove, set } from '@/utils/store';

type ThemeMode = 'dark' | 'light';

function isValidThemeMode(mode: unknown): mode is ThemeMode {
  return mode === 'light' || mode === 'dark';
}

const storeValue = get('themeMode');
let themeMode: ThemeMode | null = null;

if (isValidThemeMode(storeValue)) {
  themeMode = storeValue;
} else {
  remove('themeMode');
}

type ThemeModeContextValue = {
  mode: ThemeMode;
  setMode: (mode: ThemeMode) => void;
};

export const ThemeModeContext = createContext<ThemeModeContextValue>({
  mode: 'light',
  setMode: () => {}
});

type Props = {
  children: ReactNode;
};

export const ThemeManager = ({ children }: Props) => {
  const [mode, setMode] = useState(themeMode ?? 'light');
  const contextValue: ThemeModeContextValue = useMemo(
    () => ({
      mode,
      setMode: nextMode => {
        set('themeMode', nextMode);
        setMode(nextMode);
      }
    }),
    [mode]
  );

  useLayoutEffect(() => {
    if (mode === 'dark') {
      document.body.classList.add(darkTheme);
      // document.body.classList.remove(lightTheme);
    } else {
      // document.body.classList.add(lightTheme);
      document.body.classList.remove(darkTheme);
    }
  }, [mode]);

  return (
    <ThemeModeContext.Provider value={contextValue}>
      <ThemeProvider theme={{ ...tokens, ...colorTokens, breakpoints }}>{children}</ThemeProvider>
    </ThemeModeContext.Provider>
  );
};
