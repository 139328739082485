/**
 * @url https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
 */
interface NetworkInformation {
  downlink: number;
  downlinkMax: number;
  effectiveType: '2g' | '3g' | '4g' | 'slow-2g';
  rtt: number;
  saveData: boolean;
  type: 'bluetooth' | 'cellular' | 'ethernet' | 'none' | 'other' | 'unknown' | 'wifi' | 'wimax';
}

// This is an experimental API and there's no guarantee that every browser
// adopts every property at the same time, therefore we're returning a Partial.
interface NavigatorWithNetworkInformation extends Navigator {
  connection: Partial<NetworkInformation>;
}

function supportsNetworkInformation(
  navigator: Navigator
): navigator is NavigatorWithNetworkInformation {
  return 'connection' in navigator;
}

/**
 * Checks if `NetworkInformation.saveData` is supported. This is an experimental
 * API that's widely supported by Chromium browsers but not yet by any others,
 * nor is it included in TypeScript's lib.d.ts definition.
 * @url https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation/saveData
 * @param networkInformation The `NetworkInformation` object.
 * @returns Whether or not the `saveData` property is supported.
 */
function supportsSaveData(
  navigator: Navigator
): navigator is NavigatorWithNetworkInformation & { connection: { saveData: boolean } } {
  return supportsNetworkInformation(navigator) && 'saveData' in navigator.connection;
}

function isDataSavingEnabled() {
  return supportsSaveData(window.navigator) ? window.navigator.connection.saveData : false;
}

const IS_DATA_SAVING_ENABLED = isDataSavingEnabled();

/**
 * Returns a pixel value adjusted for the device pixel ratio and data saving mode.
 * @param value The pixel value to adjust.
 * @returns Adjusted pixel value.
 */
export function pxToDPR(value: number) {
  return IS_DATA_SAVING_ENABLED ? value : Math.ceil(value * (window.devicePixelRatio || 1));
}
