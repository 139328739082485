import type { ComponentProps, ReactNode } from 'react';
import { Flex, Spacer } from '@/components/primitives';
import { Article } from '@/components/SectioningContentElement';

type Slots = {
  content: ReactNode;
  heading: ReactNode;
  meta: ReactNode;
  parent: ReactNode;
  thumbnail: ReactNode;
};

type Props = ComponentProps<typeof Article> & {
  slots: Slots;
};

export const ModuleCardLayout = ({ slots, ...props }: Props) => (
  <Article {...props}>
    {slots.thumbnail}
    <Spacer mb="s05" />
    {slots.heading}
    {slots.content}
    {slots.parent && <Flex mt="s03">{slots.parent}</Flex>}
    <Flex mt="s03">{slots.meta}</Flex>
  </Article>
);
