import type { OperationTypeNode } from 'graphql-v15';

export class UserNotAuthorizedError extends Error {
  operationType: OperationTypeNode | null;
  operationName: string | null;
  // reason: 'userAgreementGuard' | null;
  resourceId: string | null;

  constructor(
    resourceId: string | null,
    operationName: string | null,
    operationType: OperationTypeNode | null
  ) {
    const message = resourceId
      ? `Not authorized to access resource "${resourceId}".`
      : 'Not authorized to access this resource.';

    super(message);
    this.name = 'UserNotAuthorizedError';
    this.resourceId = resourceId;
    this.operationName = operationName;
    this.operationType = operationType;
  }
}
