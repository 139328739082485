import type { ElementType } from 'react';
import styled from '@emotion/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';
import { border, boxShadow, compose, fontSize, fontWeight, space, system } from 'styled-system';
import { getColorToken } from '@/css/utils';
import { css, variant } from '@/theme/utils';
import type {
  BorderProps,
  BoxShadowProps,
  ColorProps,
  FontSizeProps,
  FontWeightProps,
  ResponsiveValue,
  SpaceProps
} from './types';

/**
 * Button
 */

type ButtonSize = 'lg' | 'md' | 'sm';

type ButtonVariant = 'primary' | 'secondary' | 'tertiary';

type ButtonProps = BorderProps &
  BoxShadowProps &
  ColorProps &
  FontSizeProps &
  FontWeightProps &
  SpaceProps & {
    as?: ElementType;
    size?: ResponsiveValue<ButtonSize>;
    stretch?: ResponsiveValue<boolean>;
    variant?: ResponsiveValue<ButtonVariant>;
  };

export const Button = styled('button', {
  shouldForwardProp: prop => shouldForwardProp(prop.toString()) || prop === 'routed'
})<ButtonProps>(
  css({
    appearance: 'none',
    cursor: 'pointer',
    lineHeight: 'inherit',
    px: '1.25em',
    py: '0.5em',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'background-color 150ms ease-out',
    ':disabled': {
      cursor: 'not-allowed'
    }
  }),
  variant<ButtonSize>({
    prop: 'size',
    variants: {
      sm: { fontSize: 'sm' },
      md: { fontSize: 'md' },
      lg: { fontSize: 'base' }
    }
  }),
  compose(
    border,
    boxShadow,
    fontSize,
    fontWeight,
    space,
    system({
      color: {
        property: 'color',
        scale: 'colors'
      },
      bgColor: {
        property: 'backgroundColor',
        scale: 'colors'
      }
    })
  ),
  variant<boolean>({
    prop: 'stretch',
    variants: {
      false: {
        display: 'inline-block',
        width: 'auto'
      },
      true: {
        display: 'block',
        width: '100%'
      }
    }
  }),
  variant<ButtonVariant>({
    prop: 'variant',
    variants: {
      primary: {
        backgroundColor: getColorToken('grey600'),
        color: getColorToken('white'),
        ':disabled': {
          backgroundColor: getColorToken('grey400'),
          color: getColorToken('grey100')
        },
        ':hover': {
          backgroundColor: getColorToken('grey700')
        }
      },
      secondary: {
        backgroundColor: getColorToken('grey200'),
        color: getColorToken('grey600'),
        ':disabled': {
          backgroundColor: getColorToken('grey100'),
          color: getColorToken('grey300')
        },
        ':hover': {
          backgroundColor: getColorToken('grey300')
        }
      },
      tertiary: {
        backgroundColor: getColorToken('white'),
        border: `1px solid ${getColorToken('grey200')}`,
        color: getColorToken('grey600'),
        ':disabled': {
          backgroundColor: getColorToken('white'),
          border: `1px solid ${getColorToken('grey100')}`,
          color: getColorToken('grey300')
        },
        ':hover': {
          backgroundColor: getColorToken('grey100')
        }
      }
    }
  })
);

Button.defaultProps = {
  as: 'button',
  bgColor: 'grey600',
  border: 1,
  borderColor: 'transparent',
  borderRadius: 'base',
  color: 'white',
  size: 'md'
};
