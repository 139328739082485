import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { from } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import type { AuthenticationClient } from '@/libs/cs-core-auth-client';
import { createAuthenticationLink } from './createAuthenticationLink';
import { createAuthorizationErrorLink } from './createAuthorizationErrorLink';
import { createCsrfTokenLink } from './createCsrfTokenLink';
import { omitTypenameLink } from './createOmitTypenameLink';
import { defaultOptions } from './defaultOptions';
import introspectionQueryResultData from './fragment-matcher-introspection-data.generated.json';

type ApolloClientOptions = {
  authClient: AuthenticationClient;
  cache: InMemoryCache;
  debug: boolean;
};

export function createApolloCache() {
  return new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({ introspectionQueryResultData }),
    freezeResults: true
  });
}

export function createApolloClient(uri: string, options: ApolloClientOptions) {
  return new ApolloClient({
    assumeImmutableResults: true,
    cache: options.cache,
    connectToDevTools: true,
    defaultOptions,
    link: from([
      omitTypenameLink,
      createCsrfTokenLink(options.debug),
      createAuthorizationErrorLink(),
      createAuthenticationLink(options.authClient, options.debug),
      new HttpLink({ uri })
    ])
  });
}
