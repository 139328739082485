import { lazy, memo, type ReactNode, Suspense, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppConfigValue } from '@/components/AppConfig';
import { AppShell } from '@/components/AppShell';
import { Delay } from '@/components/Delay';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { GuidedFocusManager } from '@/components/FocusManagement';
import { useI18n } from '@/components/I18n';
import { useYupLocales } from '@/components/I18n/useYupLocales';
import { NetworkStatusAlert } from '@/components/NetworkStatusAlert';
import { SessionUserContext } from '@/components/SessionUserContext';
import { SkipNavManager } from '@/components/SkipNav';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { get } from '@/utils/store';
import { getMenuConfig } from './getMenuConfig';

const GlobalModalManager = lazy(async () => ({
  default: (await import('@/components/GlobalModalManager')).GlobalModalManager
}));

const LanguageSelectionFormModal = lazy(async () => ({
  default: (await import('@/features/language-settings/views/LanguageSelectionFormModal')).LanguageSelectionFormModal // prettier-ignore
}));

const ProfileCompletionModal = lazy(async () => ({
  default: (await import('@/features/profile/components/ProfileCompletionModal')).ProfileCompletionModal // prettier-ignore
}));

const CurrentUserUnhandledUserAgreementsManager = lazy(async () => ({
  default: (await import('@/features/user-agreements/main/components/CurrentUserUnhandledUserAgreementsManager')).CurrentUserUnhandledUserAgreementsManager // prettier-ignore
}));

type Props = {
  hasDpoAssigned: boolean;
  logoutUrl: string;
  onLanguageSelected: () => void;
  onProfileCompleted: () => void;
};

const ONBOARDING_DELAY = process.env.NODE_ENV === 'test' ? 0 : 1500;

export const App = memo<Props>(
  ({ hasDpoAssigned, logoutUrl, onLanguageSelected, onProfileCompleted }) => {
    const i18n = useI18n();
    const locations = useSharedLocations('main');
    const sessionUser = useContext(SessionUserContext);
    const menu = getMenuConfig(i18n, sessionUser, hasDpoAssigned);
    const userMustSelectLocaleId = useAppConfigValue('application_userMustSelectLocaleId');
    const logoFileId = useAppConfigValue('application_branding_logoFileId');
    const localeId = useAppConfigValue('viewer_localeId');

    useYupLocales();

    let onboardingContent: ReactNode = null;

    if (userMustSelectLocaleId && !localeId && !get('localeId')) {
      // We don't show the language selection when we know there's a cached localeId in localStorage,
      // since AppContainer will take care of synchronizing this locale with the server automatically.
      onboardingContent = <LanguageSelectionFormModal onSubmitted={onLanguageSelected} />;
    } else if (sessionUser.canEditProfile && sessionUser.hasToCompleteProfile) {
      onboardingContent = <ProfileCompletionModal onSubmitted={onProfileCompleted} />;
    } else {
      onboardingContent = <CurrentUserUnhandledUserAgreementsManager />;
    }

    return (
      <GuidedFocusManager>
        <SkipNavManager>
          <AppShell
            hasDpoAssigned={hasDpoAssigned}
            ideationModuleRequestsRoute={locations.getCurrentUserIdeationModuleRequestsPage()}
            logoFileId={logoFileId}
            logoutUrl={logoutUrl}
            menuConfig={menu}
            profileRoute={locations.getCurrentUserProfilePage()}
            user={sessionUser}
          >
            <ErrorBoundary>
              <Delay delayInMs={ONBOARDING_DELAY}>
                <Suspense fallback={null}>{onboardingContent}</Suspense>
              </Delay>
            </ErrorBoundary>
            <ErrorBoundary>
              <Suspense fallback={null}>
                <GlobalModalManager />
              </Suspense>
            </ErrorBoundary>
            <NetworkStatusAlert />
            <Outlet />
          </AppShell>
        </SkipNavManager>
      </GuidedFocusManager>
    );
  }
);
