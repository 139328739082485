import { css } from '@emotion/react';
import { Link, type LinkProps } from '@/components/Link';
import { Card } from '@/components/primitives';

const linkStyle = css({
  borderRadius: 0,
  left: '-9999em',
  position: 'absolute',
  outlineOffset: 0,
  zIndex: 1,
  ':focus': {
    left: 4,
    top: 4
  },
  '[dir="rtl"] &': {
    left: 'auto',
    right: '-9999em',
    ':focus': {
      left: 'auto',
      right: 4
    }
  }
});

export const SkipNavLink = (props: LinkProps) => (
  <Link css={linkStyle} {...props}>
    <Card bgColor="#fff" px="s05" py="s03">
      {props.children}
    </Card>
  </Link>
);
