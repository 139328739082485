import type { HTMLAttributes, ReactNode } from 'react';
import { Flex } from '@/components/primitives';

type Slots = {
  comments: ReactNode;
  inventors: ReactNode;
  votes: ReactNode;
};

type Props = HTMLAttributes<HTMLElement> & {
  slots: Slots;
};

export const ContributionCardMetaLayout = ({ slots, ...props }: Props) => (
  <Flex alignItems="center" justifyContent="space-between" {...props}>
    {slots.inventors}
    <Flex alignItems="center" flexWrap="nowrap" gap="s04">
      {slots.votes}
      {slots.comments}
    </Flex>
  </Flex>
);
