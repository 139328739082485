import styled from '@emotion/styled';
import { Link } from '@/components/Link';

export const UnstyledLink = styled(Link)(props => ({
  color: 'inherit',
  fontWeight: 'normal',
  textDecoration: 'none',
  transition: 'none',
  ':hover': {
    color: props.hoverColor ?? 'inherit',
    textDecoration: 'none'
  }
}));
