import { createContext, createRef, type RefObject } from 'react';

type FormTeardownRefObject = {
  runFormTeardown: (callback?: () => void) => void;
};

type FormTeardownContextValue = {
  ref: RefObject<FormTeardownRefObject>;
};

export const FormTeardownContext = createContext<FormTeardownContextValue>({
  ref: createRef<FormTeardownRefObject>()
});
