import type { ReactNode } from 'react';
import { CodeBlockWrapper } from './CodeBlockWrapper';

type Props = {
  children?: ReactNode;
  className?: string;
  json?: string;
  maxHeight?: string | null;
  obj?: { [key: string]: unknown } | [{ [key: string]: unknown }];
};

export const CodeBlock = ({ children, className, json, maxHeight = '480px', obj }: Props) => (
  <CodeBlockWrapper className={className} maxHeight={maxHeight}>
    {json && JSON.stringify(JSON.parse(json), null, 2)}
    {obj && JSON.stringify(obj, null, 2)}
    {children}
  </CodeBlockWrapper>
);
