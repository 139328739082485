import styled from '@emotion/styled';

type Props = {
  backgroundColor: string;
  borderRadius: string;
  height: string;
};

export const ProgressBarInnerBarWrapper = styled.div<Props>`
  background-color: ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius};
  height: ${props => props.height};
  overflow: hidden;
  position: relative;
`;
