import { forwardRef } from 'react';
import { Skeleton } from '@/components/Skeleton/v2';
import { ContributionCardLayout } from './ContributionCardLayout';
import { ContributionCardMetaSkeleton } from './ContributionCardMetaSkeleton';

export const ContributionCardSkeleton = forwardRef<HTMLDivElement, { showParent?: boolean }>(
  ({ showParent = true }, ref) => (
    <ContributionCardLayout
      ref={ref}
      aria-hidden
      data-testid="placeholder"
      slots={{
        additionalContent: null,
        content: (
          <>
            <Skeleton.Text fontSize="md" lineHeight="snug" width="100%" />
            <Skeleton.Text fontSize="md" lineHeight="snug" width="100%" />
            <Skeleton.Text fontSize="md" lineHeight="snug" width="100%" />
            <Skeleton.Text fontSize="md" lineHeight="snug" width="75%" />
          </>
        ),
        heading: <Skeleton.Text lineHeight="tight" my="s02" width="15ch" />,
        meta: <ContributionCardMetaSkeleton />,
        parent: showParent ? <Skeleton.Text fontSize="sm" lineHeight="tight" width="80%" /> : null,
        thumbnail: <Skeleton.Shape borderRadius="none" css={{ paddingBottom: '60%' }} height={0} />,
        timestamp: <Skeleton.Text fontSize="sm" width="12ch" />
      }}
    />
  )
);
