/** @public */
export function isAndroid() {
  return window.navigator.userAgent.toLowerCase().includes('android');
}

/** @public */
export function isIos(): boolean {
  // @ts-expect-error
  return /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
}

/** @public */
export function isChrome() {
  return /Chrome/i.test(window.navigator.userAgent);
}

/** @public */
export function isJSDOM() {
  return /jsdom/i.test(window.navigator.userAgent);
}

/** @public */
export function isWebKit() {
  // JSDOM identifies as WebKit for some reason.
  return /AppleWebKit/i.test(window.navigator.userAgent) && !isChrome() && !isJSDOM();
}

/** @public */
export function isMobile() {
  return isIos() || isAndroid();
}

/** @public */
export function isOldEdge() {
  // Chromium edge uses "Edg/<number>" instead.
  return window.navigator.userAgent.includes('Edge/');
}

export function getIEOrEdgeVersion(): number | null {
  const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(navigator.userAgent);

  if (match) return parseInt(match[2], 10);
  return null;
}
